import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as EditButtonsActions from 'actions/editableButtonsAction';

class PhoneNumberPanel extends Component {
  constructor(props) {
    super(props);
    this.handleChangePhonenumber = this.handleChangePhonenumber.bind(this);
  }

  handleChangePhonenumber = (e) => {
    e.preventDefault();
    const { setEditPhoneNumber } = this.props;
    setEditPhoneNumber(true);
  }

  render() {
    const {
      customer: { custInfo }, pageModel,
      policy,
    } = this.props;

    if (!pageModel) { return null; }

    return (
      <div className="col-12 col-sm-6">
        <div className="tile box">
          <h4>{pageModel.title}</h4>
          <div className="Info sessioncamhidetext">
            <p className="long primary">
              <div className="row mb-2">
                <div className="col-sm-2">{`${pageModel.mobilePhoneLabel}:`}</div>
                <div className="col-sm-10">{custInfo.MobileTelephone ? custInfo.MobileTelephone : pageModel.noNumberText}</div>
              </div>
              <div className="row mb-2">
                <div className="col-sm-2">{`${pageModel.homePhoneLabel}:`}</div>
                <div className="col-sm-10">{custInfo.HomeTelephone ? custInfo.HomeTelephone : pageModel.noNumberText}</div>
              </div>
              <div className="row">
                <div className="col-sm-2">{`${pageModel.workPhoneLabel}:`}</div>
                <div className="col-sm-10">{custInfo.WorkTelephone ? custInfo.WorkTelephone : pageModel.noNumberText}</div>
              </div>
            </p>
            {policy.policyInfo.CanEditPhoneNumbers && (
              <p className="action edit">
                <button
                  type="button"
                  className="MyRacForm__button-link link Panel__button"
                  onClick={this.handleChangePhonenumber}
                >
                  {pageModel.buttons.changeButton.text}
                </button>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
  editValues: state.edit,
});

const mapDispatchToProps = (dispatch) => ({
  setEditPhoneNumber: (editPhoneNumber) => {
    dispatch(EditButtonsActions.setEditPhoneNumber(editPhoneNumber));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberPanel);
