import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import block from 'bem-css-modules';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './UpgradeConfirmationPage.module.scss';
import useViewport from '../../../utils/useViewportHelper';
import UpgradePanels from './UpgradePanels';
import Spinner from '../../../components/Spinner';
import { MY_COVER } from '../../../constants/routeConstants';
import trackEvent from '../../../services/AnalyticsService';
import { dispatch } from '../../../store';
import { updateVehicles } from '../../../actions/vehiclesInfoActions';
import { setPolicyInfo } from '../../../actions/policyInfoActions';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(styles);

const UpgradeConfirmationPage = (props) => {
  const { customer: { custInfo }, policy, vehicles } = props;
  const { state } = useLocation();
  const { isPaymentJourney, pageModel } = state || { isPaymentJourney: {}, pageModel: {} }; // Read values passed on state
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const confirmationTitle = pageModel?.confirmation?.confirmationTitle;

  const lastIndex = confirmationTitle?.lastIndexOf(' ');
  const thankYouHeaderMessage = confirmationTitle?.substring(0, lastIndex);

  const viewportHelper = useViewport();

  useEffect(() => {
    trackEvent(pageModel?.confirmation?.analyticsTag);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (pageModel?.isVsoe) {
      dispatch(setPolicyInfo({ ...policy.policyInfo, CanEditVehicles: false }));
      // Find vehicle that matches the vehicle on policy by registration.
      const vehicle = vehicles?.vehicleInfo?.reminderVehicles?.find((reminderVehicle) => reminderVehicle.Registration === policy?.policyInfo?.Vehicles[0]?.Registration);
      if (vehicle) {
        dispatch(updateVehicles({ ...vehicle, canEditVehicle: false, Registration: vehicle.Registration }));
      }
    }
    setLoading(false);
  }, []);

  const displayConfirmationImage = (data) => {
    // Mobile
    if (viewportHelper.isBootstrapSm && data?.confirmation?.mobileImage) {
      return (
        <img
          className={`${bem('confirmation-image')}`}
          src={data.confirmation.mobileImage.url}
          alt={data.confirmation.mobileImage.fileName}
          title={data.confirmation.mobileImage.title}
        />
      );
    }
    // Desktop
    if (data?.confirmation?.desktopImage) {
      return (
        <img
          className={`${bem('confirmation-image')} img-fluid w-100 h-100`}
          src={data.confirmation.desktopImage.url}
          alt={data.confirmation.desktopImage.fileName}
          title={data.confirmation.desktopImage.title}
        />
      );
    }
    return null;
  };

  return pageModel && !loading ? (
    <div className="row">
      <div className="col-md-12">
        <h3 className={`${bem('confirmation-header')}`}>
          {thankYouHeaderMessage} <span className={`${bem('customer-name')}`}>{custInfo.Forename}</span>
        </h3>
        <p className={`${bem('confirmation-description')}`}>
          { pageModel?.confirmation?.confirmationDescription}
        </p>
        <div className={`${bem('confirmation-details-wrapper')}`}>
          <div className={`${bem('confirmation-info-wrapper')}`}>
            <UpgradePanels pageModel={pageModel} isPaymentJourney={isPaymentJourney} confirmationType />
          </div>
          <div className={`${bem('confirmation-image-wrapper')}`}>
            { displayConfirmationImage(pageModel) }
          </div>
        </div>
        <div className={`${bem('back-button-container')}`}>
          <button type="button" onClick={() => navigate(MY_COVER)}>
            {pageModel?.confirmation?.backButton}
          </button>
        </div>
      </div>
    </div>
  ) : <Spinner defaultSpinner spinnerMsg="Saving your breakdown upgrade. Please wait..." />;
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  vehicles: state.vehicles,
});

export default connect(mapStateToProps)(UpgradeConfirmationPage);
