import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RenderLink from '../../../components/RenderLink';
import Advert from './Advert';
import MobileMenu from './MobileMenu';
import { GetContent, mapURL } from '../../../services/ContentService';
import * as DefaultText from '../../../configuration/defaultText';
import styles from './NewMenu.module.scss';
import NavigationArrow from '../../../assets/images/button/down-arrow.svg';

const NewMenu = ({ toggleMobileMenu, session, pageModel, navRef }) => {
  const [newMenu, setNewMenu] = useState();
  const [myRacMenu, setMyRacMenu] = useState();
  const [showMoreSub, setShowMoreSub] = useState();

  const location = useLocation();

  const handleClickOutside = (event) => {
    if (navRef && !navRef.current.contains(event.target)) {
      setShowMoreSub(undefined);
    }
  };

  const selectSubNav = (item, e) => {
    e.preventDefault();
    setShowMoreSub(showMoreSub ? undefined : item);
  };

  const preventClickAndSetMenuLevel = (item, e) => {
    e.preventDefault();
    setShowMoreSub((showMoreSub && item.title === showMoreSub.title) ? undefined : item);
  };

  const updateMenu = (menu) => {
    if (menu && pageModel && !showMoreSub) {
      const mainMenu = pageModel?.header?.menuItems;
      const firstItem = {
        advert: '',
        mobileMenuItems: [...menu.menuItems],
        title: menu.menuItems[0].text,
        url: menu.menuItems[0],
      };
      if (mainMenu[0].advert !== firstItem.advert) {
        mainMenu.unshift(firstItem);
      }
      if (mainMenu[0].advert === firstItem.advert && mainMenu[0].mobileMenuItems.length !== firstItem.mobileMenuItems.length) {
        mainMenu[0] = firstItem;
      }
      setNewMenu(mainMenu);
      // Opens the myRac menu by default.
      setShowMoreSub(mainMenu[0]);
    }
  };

  const firstElement = (item) => (
    <li key={`${item.title} desktop`} className={`${showMoreSub && showMoreSub.title === item.title ? 'open clicked' : ''} ${styles.firstElement}`}>
      <RenderLink
        url="/insurance"
        title="Insurance"
        isExternal={false}
        onClick={(e) => preventClickAndSetMenuLevel(item, e)}
        onKeyDown={(e) => preventClickAndSetMenuLevel(item, e)}
      >
        <span>{myRacMenu.menuTitle}</span>
      </RenderLink>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span onClick={(e) => selectSubNav(item, e)} onKeyDown={(e) => { selectSubNav(item, e); }} />
      <div className="navSub">
        <div>
          <div className="navsub-menu">
            {item.mobileMenuItems.length && <MobileMenu menu={item.mobileMenuItems} toggleMobileMenu={toggleMobileMenu} /> }
          </div>
        </div>
      </div>

    </li>
  );

  const otherElements = (item) => (
    <li key={item.title} className={`${showMoreSub && showMoreSub.title === item.title ? `open clicked ${styles.navListItemClicked}` : ''} ${styles.navListItem}`}>
      <button
        className={styles.parentButton}
        type="button"
        onClick={(e) => preventClickAndSetMenuLevel(item, e)}
        onKeyDown={(e) => preventClickAndSetMenuLevel(item, e)}
      >
        <span>{item.url.text}</span>
        <img src={NavigationArrow} alt="Navigation arrow" className={`${styles.arrowImage} ${showMoreSub && showMoreSub.title === item.title ? styles.arrowImageTransition : ''}`} />
      </button>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span onClick={(e) => { selectSubNav(item, e); }} onKeyDown={(e) => { selectSubNav(item, e); }} />
      <div className="navSub">
        <div>
          <div className="navSub-title">{item.title}</div>
          <div className="navSub-menu">
            <h3 className="navSub-subTitle">
              <RenderLink link={item.url} />
            </h3>
            <ul>
              <li className="mobileOnlyLink"><RenderLink onClick={() => { toggleMobileMenu(); }} link={item.url} /></li>
              {item.leftSubItems?.map((sub) => (
                <li key={sub.url + sub.text} className={styles.subNavItem}>
                  {sub.url ? (<RenderLink link={sub} />) : sub.title}
                </li>
              ))}
            </ul>
            <ul>
              {item.rightSubItems?.map((sub) => (
                <li key={sub.url + sub.text} className={styles.subNavItem}>
                  {sub.url ? (<RenderLink link={sub} />) : sub.title}
                </li>
              ))}
            </ul>
          </div>
          {item.advert && item.advert.show ? (
            <Advert adv={item.advert} />
          ) : null}
        </div>
      </div>
    </li>
  );

  useEffect(() => {
    async function getMyRacMenu() {
      const pageTitle = session.isLoggedIn ? 'Logged Left Hand Navigation' : 'Anonymous Users Left Navigation';
      await GetContent(
        'leftnavigationmenus',
        pageTitle,
        ['menuitems', 'menuitems.menusubitems'],
      ).then((response) => {
        const items = response.payload.menuitems.map((item) => {
          const model = mapURL(item);
          if (item.menusubitems.length) {
            model.subitems = item.menusubitems.map((sub) => mapURL(sub));
          }
          return model;
        });
        const menu = {
          menuTitle: response.payload.menutitle,
          menuItems: items,
        };
        setMyRacMenu(menu);
        updateMenu(menu);
      }).catch(() => {
        setMyRacMenu({ error: DefaultText.APIError });
      });
    }
    getMyRacMenu();
  }, [session.isLoggedIn]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Keep the myRAC tab open by default even on page changes.
  useEffect(() => {
    if (newMenu) {
      setShowMoreSub(newMenu[0]);
    }
  }, [location.pathname]);

  const renderItems = () => newMenu.map((item, index) => {
    if (index === 0) {
      return firstElement(item);
    }
    return otherElements(item);
  });

  return newMenu ? <ul className={styles.listContainer}>{renderItems()}</ul> : null;
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(NewMenu);
