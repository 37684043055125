import React from 'react';
import PropTypes from 'prop-types';
import {
  monthByNumber, daysInMonth, unit,
} from './helper';

class DropdownDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startYear: null,
      startMonth: null,
      startDay: null,
      endYear: null,
      endMonth: null,
      endDay: null,
      selectedYear: -1,
      selectedMonth: -1,
      selectedDay: -1,
    };
    this.generateYearOptions = this.generateYearOptions.bind(this);
    this.generateMonthOptions = this.generateMonthOptions.bind(this);
    this.generateDayOptions = this.generateDayOptions.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderYear = this.renderYear.bind(this);
    this.renderMonth = this.renderMonth.bind(this);
    this.renderDay = this.renderDay.bind(this);
    // validation
    this.sendvalidation = this.sendvalidation.bind(this);
    this.renderParts = {
      year: this.renderYear,
      month: this.renderMonth,
      day: this.renderDay,
    };
  }

  componentDidMount() {
    let startYear;
    let startMonth;
    let startDay;
    let endYear;
    let endMonth;
    let endDay;
    let selectedYear;
    let selectedMonth;
    let selectedDay;
    const { startDate, endDate, selectedDate } = this.props;
    if (startDate) {
      const date = new Date(startDate);
      startYear = date.getFullYear();
      startMonth = date.getMonth();
      startDay = date.getDate();
    } else {
      startYear = 1900;
      startMonth = 0;
      startDay = 1;
    }
    if (endDate) {
      const date = new Date(endDate);
      endYear = date.getFullYear();
      endMonth = date.getMonth();
      endDay = date.getDate();
    } else {
      const date = new Date();
      endYear = date.getFullYear();
      endMonth = date.getMonth();
      endDay = date.getDate();
    }
    if (selectedDate) {
      const date = new Date(selectedDate);
      selectedYear = date.getFullYear();
      selectedMonth = date.getMonth();
      selectedDay = date.getDate();
    } else {
      selectedYear = -1;
      selectedMonth = -1;
      selectedDay = -1;
    }
    this.setState({
      startYear,
      startMonth,
      startDay,
      endYear,
      endMonth,
      endDay,
      selectedYear,
      selectedMonth,
      selectedDay,
    });
  }

  componentDidUpdate(prevProps) {
    this.getUpdatedDateData(prevProps);
  }

  handleDateChange(type, value) {
    const { onDateChange } = this.props;
    if (onDateChange) {
      let { selectedYear, selectedMonth, selectedDay } = this.state;
      if (type === unit.year) {
        selectedYear = value;
      } else if (type === unit.month) {
        selectedMonth = value;
      } else if (type === unit.day) {
        selectedDay = value;
      }
      if (selectedYear !== -1 && selectedMonth !== -1 && selectedDay !== -1) {
        onDateChange(
          new Date(selectedYear, selectedMonth, selectedDay),
        );
        this.sendvalidation(true);
      }
      if (selectedYear === -1 || selectedMonth === -1 || selectedDay === -1) {
        this.sendvalidation(false);
      }
    }
  }

  handleDayChange(day) {
    // eslint-disable-next-line no-param-reassign
    day = parseInt(day);
    this.setState({ selectedDay: day });
    const { onDayChange } = this.props;
    if (onDayChange) {
      onDayChange(day);
    }
    this.handleDateChange(unit.day, day);
  }

  handleMonthChange(month) {
    // eslint-disable-next-line no-param-reassign
    month = parseInt(month);
    const { onMonthChange } = this.props;
    if (month === 1) {
      this.setState({ selectedMonth: month, selectedDay: parseInt(-1) });
      this.sendvalidation(false);
    } else {
      this.setState({ selectedMonth: month });
      if (onMonthChange) {
        onMonthChange(monthByNumber[month]);
      }
      this.handleDateChange(unit.month, month);
    }
  }

  handleYearChange(year) {
    // eslint-disable-next-line no-param-reassign
    year = parseInt(year);
    const { onYearChange } = this.props;
    this.setState({ selectedYear: year });
    if (onYearChange) {
      onYearChange(year);
    }
    this.handleDateChange(unit.year, year);
  }

  getUpdatedDateData(prevProps) {
    const { selectedDate } = this.props;
    if (prevProps.selectedDate !== selectedDate) {
      const date = new Date(selectedDate);
      const selectedYear = date.getFullYear();
      const selectedMonth = date.getMonth();
      const selectedDay = date.getDate();
      this.setState({ selectedYear, selectedMonth, selectedDay });
    }
  }

  generateYearOptions() {
    const { startYear, endYear } = this.state;
    const yearOptions = [];
    const { options, defaultValues, classes } = this.props;
    yearOptions.push(
      <option
        key={-1}
        value="-1"
        className={
          classes && classes.yearOptions
            ? classes.yearOptions
            : null
        }
      >
        {defaultValues && defaultValues.year
          ? defaultValues.year
          : ''}
      </option>,
    );
    if (options && options.yearReverse) {
      for (let i = endYear; i >= startYear; i--) {
        yearOptions.push(
          <option
            key={i}
            value={i}
            className={
              classes && classes.yearOptions
                ? classes.yearOptions
                : null
            }
          >
            {i}
          </option>,
        );
      }
    } else {
      for (let i = startYear; i <= endYear; i++) {
        yearOptions.push(
          <option
            key={i}
            value={i}
            className={
              classes && classes.yearOptions
                ? classes.yearOptions
                : null
            }
          >
            {i}
          </option>,
        );
      }
    }
    return yearOptions;
  }

  generateMonthOptions() {
    const {
      startMonth,
      endMonth,
      startYear,
      endYear,
      selectedYear,
    } = this.state;
    let months = [];
    if (selectedYear === startYear) {
      for (let i = startMonth; i <= 11; i++) {
        months.push({
          value: i,
          month: monthByNumber[i],
        });
      }
    } else if (selectedYear === endYear) {
      for (let i = 0; i <= endMonth; i++) {
        months.push({
          value: i,
          month: monthByNumber[i],
        });
      }
    } else {
      for (let i = 0; i <= 11; i++) {
        months.push({
          value: i,
          month: monthByNumber[i],
        });
      }
    }

    const { options, defaultValues, classes } = this.props;

    if (options && options.monthShort) {
      months = months.map((elem) => ({
        value: elem.value,
        month: elem.month.substring(0, 3),
      }));
    }

    if (options && options.monthCaps) {
      months = months.map((elem) => ({
        value: elem.value,
        month: elem.month.toUpperCase(),
      }));
    }

    const monthOptions = [];
    monthOptions.push(
      <option
        key={-1}
        value="-1"
        className={
          classes && classes.monthOptions
            ? classes.monthOptions
            : null
        }
      >
        {defaultValues && defaultValues.month
          ? defaultValues.month
          : ''}
      </option>,
    );
    months.forEach((elem) => {
      monthOptions.push(
        <option
          key={elem.value}
          value={elem.value}
          className={
            classes && classes.monthOptions
              ? classes.monthOptions
              : null
          }
        >
          {elem.month}
        </option>,
      );
    });

    return monthOptions;
  }

  generateDayOptions() {
    const {
      startYear,
      startMonth,
      startDay,
      endYear,
      endMonth,
      endDay,
      selectedYear,
      selectedMonth,
    } = this.state;
    const dayOptions = [];
    const { defaultValues, classes } = this.props;
    dayOptions.push(
      <option
        key={-1}
        value="-1"
        className={
          classes && classes.dayOptions
            ? classes.dayOptions
            : null
        }
      >
        {defaultValues && defaultValues.day
          ? defaultValues.day
          : ''}
      </option>,
    );

    let monthDays;
    if (selectedYear === startYear) {
      if (selectedMonth === startMonth) {
        monthDays = selectedYear % 4 === 0 && selectedMonth === 1
          ? daysInMonth[selectedMonth] + 1
          : daysInMonth[selectedMonth];
        for (let i = startDay; i <= monthDays; i++) {
          dayOptions.push(
            <option
              key={i}
              value={i}
              className={
                classes && classes.dayOptions
                  ? classes.dayOptions
                  : null
              }
            >
              {i}
            </option>,
          );
        }
      } else {
        monthDays = selectedYear % 4 === 0 && selectedMonth === 1
          ? daysInMonth[selectedMonth] + 1
          : daysInMonth[selectedMonth];
        for (let i = 1; i <= monthDays; i++) {
          dayOptions.push(
            <option
              key={i}
              value={i}
              className={
                classes && classes.dayOptions
                  ? classes.dayOptions
                  : null
              }
            >
              {i}
            </option>,
          );
        }
      }
    } else if (selectedYear === endYear) {
      if (selectedMonth === endMonth) {
        for (let i = 1; i <= endDay; i++) {
          dayOptions.push(
            <option
              key={i}
              value={i}
              className={
                classes && classes.dayOptions
                  ? classes.dayOptions
                  : null
              }
            >
              {i}
            </option>,
          );
        }
      } else {
        monthDays = selectedYear % 4 === 0 && selectedMonth === 1
          ? daysInMonth[selectedMonth] + 1
          : daysInMonth[selectedMonth];
        for (let i = 1; i <= monthDays; i++) {
          dayOptions.push(
            <option
              key={i}
              value={i}
              className={
                classes && classes.dayOptions
                  ? classes.dayOptions
                  : null
              }
            >
              {i}
            </option>,
          );
        }
      }
    } else if (selectedMonth) {
      monthDays = selectedYear % 4 === 0 && selectedMonth === 1
        ? daysInMonth[selectedMonth] + 1
        : daysInMonth[selectedMonth];
      for (let i = 1; i <= monthDays; i++) {
        dayOptions.push(
          <option
            key={i}
            value={i}
            className={
              classes && classes.dayOptions
                ? classes.dayOptions
                : null
            }
          >
            {i}
          </option>,
        );
      }
    } else {
      for (let i = 1; i <= 31; i++) {
        dayOptions.push(
          <option
            key={i}
            value={i}
            className={
              classes && classes.dayOptions
                ? classes.dayOptions
                : null
            }
          >
            {i}
          </option>,
        );
      }
    }
    return dayOptions;
  }

  sendvalidation(val) {
    const { sendValidation } = this.props;
    sendValidation(val);
  }

  renderYear() {
    const {
      classes, ids, names,
    } = this.props;
    const { selectedYear } = this.state;
    return (
      <div
        key="year"
        id="dropdown-year"
        className={
          classes && classes.yearContainer
            ? classes.yearContainer
            : null
        }
      >
        <select
          id={
            ids && ids.year ? ids.year : null
          }
          name={
            names && names.year
              ? names.year
              : null
          }
          className={
            classes && classes.year
              ? classes.year
              : null
          }
          onChange={(e) => this.handleYearChange(e.target.value)}
          value={selectedYear}
        >
          {this.generateYearOptions()}
        </select>
      </div>
    );
  }

  renderMonth() {
    const { classes, ids, names } = this.props;
    const { selectedMonth } = this.state;
    return (
      <div
        key="month"
        id="dropdown-month"
        className={
          classes && classes.monthContainer
            ? classes.monthContainer
            : null
        }
      >
        <select
          id={
            ids && ids.month ? ids.month : null
          }
          name={
            names && names.month
              ? names.month
              : null
          }
          className={
            classes && classes.month
              ? classes.month
              : null
          }
          onChange={(e) => this.handleMonthChange(e.target.value)}
          value={selectedMonth}
        >
          {this.generateMonthOptions()}
        </select>
      </div>
    );
  }

  renderDay() {
    const { classes, ids, names } = this.props;
    const { selectedDay } = this.state;
    return (
      <div
        key="day"
        id="dropdown-day"
        className={
          classes && classes.dayContainer
            ? classes.dayContainer
            : null
        }
      >
        <select
          id={ids && ids.day ? ids.day : null}
          name={
            names && names.day
              ? names.day
              : null
          }
          className={
            classes && classes.day
              ? classes.day
              : null
          }
          onChange={(e) => this.handleDayChange(e.target.value)}
          value={selectedDay}
        >
          {this.generateDayOptions()}
        </select>
      </div>
    );
  }

  render() {
    const { classes, order } = this.props;
    return (
      <div
        id="dropdown-date"
        className={
          classes && classes.dateContainer
            ? classes.dateContainer
            : null
        }
      >
        {order.map((part) => this.renderParts[part]())}
      </div>
    );
  }
}

DropdownDate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.array,
};

DropdownDate.defaultProps = {
  order: ['year', 'month', 'day'],
};

export default DropdownDate;
