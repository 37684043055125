import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { GetRenewalPaymentIdAndLockToken } from '../../services/MyRacService';
import RenewalPaymentIFrame from './Renewal/PaymentIFrame';
import visaAndMastercardImage from '../../images/paymentPage/VisaandMasterCard.svg';
import styles from './PaymentScreen.module.scss';
import TextButton from '../../shared/components/TextButton/TextButton';
import backIcon from '../../images/icons/caret-left-black.svg';
import Spinner from '../Spinner';
import trackEvent from '../../services/AnalyticsService';
import { RENEWAL, RENEWAL_ERROR } from '../../constants/routeConstants';
import PopupModal from '../PopupModal/PopupModal';

const PaymentScreen = (props) => {
  const { route, session, renewalPaymentPayload } = props;
  const { state } = useLocation();

  const [loading, setLoading] = useState(true);
  const [payFrame, setPayFrame] = useState();

  // eslint-disable-next-line no-unused-vars
  const [policyLockToken, setPolicyLockToken] = useOutletContext();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModal, setErrorModal] = useState({});
  const [reloadRenewal, setReloadRenewal] = useState(false);

  const navigate = useNavigate();

  // Adobe tag switch
  const featureTag = state?.MonthlyLongerTermPolicy ? 'MyRAC_Renewal_MLTP' : 'MyRAC_Renewal';

  useEffect(() => {
    if (!renewalPaymentPayload) {
      // The route value is passed through MyRAC.jsx and we can use this to path future payment journeys
      if (route === RENEWAL) {
        navigate(RENEWAL_ERROR, { state: { isPaymentError: false, ...renewalPaymentPayload }, replace: true });
      }
    }
  }, []);

  const onBackClick = async () => {
    if (route === RENEWAL) {
      trackEvent(`${featureTag}_Payment_Back`);
      navigate(RENEWAL, { state: { reloadRenewal } });
    }
  };

  const onModalClick = async () => {
    if (route === RENEWAL) {
      navigate(RENEWAL, { state: { reloadRenewal } });
    }
  };

  const fetchRenewalPaymentId = async () => {
    try {
      const response = await GetRenewalPaymentIdAndLockToken();
      // Payload determines eligibility
      if (response?.payload) {
        const { createdPaymentId, lockToken, displayErrorMessage, errorMessage, errorTitle, errorReloadRisk } = response.payload;

        if (displayErrorMessage) {
          setErrorModal({ message: errorMessage, title: errorTitle });
          setReloadRenewal(errorReloadRisk);
          if (errorReloadRisk) {
            // true
            trackEvent('MyRAC_Renewal_PaymentError_PolicyUpdated');
          } else {
            // false
            trackEvent('MyRAC_Renewal_PaymentError_PolicyLocked');
          }
          setShowErrorModal(true);
          setLoading(false);
          return;
        }
        // Policy locked with token for all cases except discount renewals where null is passed
        const token = session.discountRenewal?.isDiscountedPrice ? null : lockToken;
        if (token) {
          setPolicyLockToken(token);
        }
        trackEvent(`${featureTag}_Payment`);
        // MYR-14728
        trackEvent('MyRAC_Renewal_Cardpayment_Viewed');
        setPayFrame(<RenewalPaymentIFrame paymentId={createdPaymentId} payload={renewalPaymentPayload} renewalToken={token} />);
      } else {
        navigate(RENEWAL_ERROR, { state: { isPaymentError: false, ...renewalPaymentPayload }, replace: true });
      }
      setLoading(false);
    } catch (e) {
      navigate(RENEWAL_ERROR, { state: { isPaymentError: false, ...renewalPaymentPayload }, replace: true });
    }
  };

  useEffect(() => {
    if (route === RENEWAL) {
      fetchRenewalPaymentId();
    }
  }, []);

  if (loading) {
    return <Spinner bootSpinner />;
  }
  return (
    <div className="container din-font">
      <div className={styles.nav}>
        <TextButton buttonText="Back" onClickHandler={onBackClick} iconImage={backIcon} iconImageAltText="Back" noUnderline />
      </div>
      <div className={styles.header}>
        <h2 className={styles.title}>Pay by credit or debit card</h2>
      </div>
      <div className={styles.container}>
        <div className={styles.iframe}>
          {payFrame}
        </div>
        <div className={styles.info}>
          <div className={styles.greybox}>
            <div className="mb-3">
              <img src={visaAndMastercardImage} alt="Visa secure and Mastercard ID check" />
            </div>
            <p>We use Visa Secure and MasterCard Identify Check to make sure your payment details are protected.</p>
            <p>We’ll store your payment details so we can process refunds or take payment for any cancellations or changes you might make, but for no other purpose.</p>
          </div>
        </div>
        <div className={styles.clear} />
      </div>
      {showErrorModal
        && (
          <PopupModal
            innerCloseButton
            modalOk="Ok"
            action={onModalClick}
            resultTitle={errorModal.title}
            resultText={errorModal.message}
            displayDialogInMiddle
          />
        )}
    </div>
  );
};

// Props
const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
  renewalPaymentPayload: state.renewal.paymentPayload,
  featureFlags: state.featureToggle,
});

export default connect(mapStateToProps)(PaymentScreen);
