import { getState } from '../../../../store';
import { RENEWAL } from '../../../../constants/routeConstants';
import ctaActions from '../../../../utils/ctaActions.enum';
import trackEvent from '../../../../services/AnalyticsService';

const getStateSession = () => {
  const state = getState();
  if (state && state.session) {
    return state.session;
  }
  return undefined;
};

const getStateFeatureFlags = () => {
  const state = getState();
  if (state && state.featureToggle) {
    return state.featureToggle;
  }
  return undefined;
};

const openTescoJourney = (analytics) => {
  if (analytics?.eventTag) { trackEvent(analytics.eventTag); }
  const url = `${getStateFeatureFlags().tescoRenewalAks ? process.env.REACT_APP_TESCO_AKS_LOGIN_REDIRECT_URL : process.env.REACT_APP_TESCO_LOGIN_REDIRECT_URL}?JID=${getStateSession().jidTesco}${process.env.REACT_APP_TESCO_URL_QUERYSTRING}`;
  const openTescoLink = window.open(url, '_blank');
  if (openTescoLink) {
    openTescoLink.focus();
  }
};

// Standard link opening action
const openNewTabLink = (url, analytics) => {
  // Fire off an event tag if there is one
  if (analytics?.eventTag) {
    trackEvent(analytics.eventTag);
  }

  // Activate the link
  const link = window.open(url, '_blank');
  if (link) {
    link.focus();
  }
};

// Open in same tab, remaining within MyRAC
const openInternalLink = (url, analytics) => {
  // Fire off an event tag if there is one
  if (analytics?.eventTag) {
    trackEvent(analytics.eventTag);
  }

  // Activate the link
  window.open(url, '_self');
};

const openAdobeJourney = (url, analytics) => {
  // Tile 4
  if (analytics?.eventTag) { trackEvent(analytics.eventTag); }
  setTimeout(() => {
    // Renewal journey requires redirect and not new window
    if (url.includes('renewal')) {
      window.location.href = process.env.PUBLIC_URL + RENEWAL;
    } else {
      const openNewAbodeTab = window.open(url, '_blank');
      if (openNewAbodeTab) {
        openNewAbodeTab.focus();
      }
    }
  }, 600);
};

// eslint-disable-next-line import/prefer-default-export
export const getClickAction = (action, params) => {
  switch (action) {
    case ctaActions.tesco: openTescoJourney(params.analyticsTag); break;
    case ctaActions.adobe: openAdobeJourney(params.url, params.analyticsTag); break;
    case ctaActions.internal: openInternalLink(params.url, params.analyticsTag); break;
    case ctaActions.newWindow:
    default: openNewTabLink(params.url, params.analyticsTag);
  }
};
