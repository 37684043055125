import * as log from 'loglevel'; // npmjs.com/package/loglevel
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

const getAppInsightLogLevel = (loglevel) => {
  switch (loglevel) {
    case log.levels.TRACE:
    case log.levels.DEBUG:
    case log.levels.INFO:
      return SeverityLevel.Information;
    case log.levels.WARN:
      return SeverityLevel.Warning;
    case log.levels.ERROR:
      return SeverityLevel.Error;
    case log.levels.SILENT:
    default:
      return undefined;
  }
};

const appInsightLogger = (logLevel) => {
  const key = process.env.REACT_APP_APP_INSIGHT_KEY;
  if (key) {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: key,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    const originalFactory = log.methodFactory;
    // eslint-disable-next-line func-names,no-import-assign
    log.methodFactory = function (methodName, logLev, loggerName) {
      const appInsightLoglevel = getAppInsightLogLevel(logLev);
      if (appInsightLoglevel) {
        const rawMethod = originalFactory(methodName, logLev, loggerName);
        // eslint-disable-next-line func-names
        return function (message) {
          rawMethod(message);
          appInsights.trackTrace({
            message,
            severityLevel: appInsightLoglevel,
          });
        };
      }
      return undefined;
    };
    log.setLevel(logLevel, false);
  } else {
    log.setLevel(log.levels.SILENT, false);
  }
};

const loggerFunc = (loggerName) => {
  const logging = log.getLogger(loggerName);
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
    case 'local':
    case 'sit':
    case 'pre':
      // Logs that contain the most detailed messages and may contain sensitive application data (should never be enabled in a production environment)
      logging.setLevel(log.levels.TRACE, false);
      appInsightLogger(log.levels.TRACE);
      break;
    case 'prd':
      // Logs that track the general flow of the application
      logging.setLevel(log.levels.WARN, false);
      appInsightLogger(log.levels.WARN);
      break;
    case 'uat':
      // Logging disabled
      logging.setLevel(log.levels.TRACE, false);
      break;
    default:
      logging.setLevel(log.levels.TRACE, false);
      break;
  }
  return logging;
};

// eslint-disable-next-line import/prefer-default-export
export { loggerFunc };
