export function generateUID(len) {
  const maxlen = 5;
  // eslint-disable-next-line no-restricted-properties
  const min = 16 ** (Math.min(len, maxlen) - 1);
  // eslint-disable-next-line no-restricted-properties
  const max = 16 ** Math.min(len, maxlen) - 1;
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  let r = n.toString(16);
  while (r.length < len) {
    r += generateUID(len - maxlen);
  }
  return r;
}

function userLeavingMessage(e) {
  // Cancel the event as stated by the standard.
  e.preventDefault();
  const confirmationMessage = 'Session will be reset, Are you sure?';

  (e || window.event).returnValue = confirmationMessage; // Gecko + IE
  return confirmationMessage;
}

export const registerUserLeavingMessage = () => {
  window.addEventListener('beforeunload', userLeavingMessage, true);
};

export const deregisterUserLeavingMessage = () => {
  window.removeEventListener('beforeunload', userLeavingMessage, true);
};

export const isObjectEmptyOrUndefinedOrNull = (obj) => {
  if (obj === undefined || obj === null) {
    return true;
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const replaceValue = (value, find, replace) => {
  if (value) {
    return value.replace(find, replace);
  }
  return '';
};

export const replaceAllValues = (value, find, replace) => {
  for (let i = 0; i < find.length; i++) {
    // eslint-disable-next-line no-param-reassign
    value = value.replace(new RegExp(find[i], 'gi'), replace[i]);
  }
  return value;
};
