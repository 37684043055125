import React, { useState, useEffect } from 'react';
import { loggerFunc } from 'utils/logger';
import { UsePrompt } from '../../../hooks/routerBlocker';
import { API_GET_PAYMENT_IFRAME } from '../../../constants/apiConstants';
import { PRODUCT_UPGRADE_CONFIRMATION, PRODUCT_UPGRADE_FAILED } from '../../../constants/routeConstants';
import { GetPaymentStatus, UpdateProductUpgradePayment } from '../../../services/MyRacService';
import paymentResponse from '../../../constants/paymentServiceConstants';
import TechnicalError from '../TechnicalError';
import TimeoutWarningModal from '../../TimeoutWarningModal';
import Spinner from '../../Spinner';
import styles from './PaymentIFrame.module.scss';

const PaymentIFrame = ({ paymentId, pageModel, leaveJourney, payload }) => {
  const [iFrameSrc, setIframeSrc] = useState(<Spinner bootSpinner />);
  const [showPrompt, setShowPrompt] = useState(true);

  UsePrompt('Changes you made may not be saved.', showPrompt);

  const logger = loggerFunc('PaymentService');

  // Get values from payload
  const { customerId, productName, membershipNumber, price } = payload;

  const handleConfirmation = async () => {
    // Upon successful transaction navigate to product confirmation page
    await UpdateProductUpgradePayment(paymentId, customerId, productName, membershipNumber, price)
      .then(() => {
        leaveJourney(PRODUCT_UPGRADE_CONFIRMATION, { state: { isPaymentJourney: true, pageModel }, replace: true });
      })
      .catch((error) => {
        logger.debug(error);
      });
  };

  const handleError = async () => {
    // After three failed attempts navigate to the failed payment page
    await UpdateProductUpgradePayment(paymentId, customerId, productName, membershipNumber, price)
      .then(() => {
        leaveJourney(PRODUCT_UPGRADE_FAILED, { state: { isPaymentJourney: true, pageModel }, replace: true });
      })
      .catch((error) => {
        logger.debug(error);
      });
  };

  const getPaymentStatus = async () => {
    // Polling payment service
    GetPaymentStatus(paymentId).then(async (response) => {
      const paymentStatus = response?.payload;
      if (paymentStatus.success && paymentStatus.statusCode === paymentResponse.SUCCESS && !paymentStatus.isAwaitingResponse) {
        // Payment polling service returned success therefore redirect user to confirmation page
        handleConfirmation();
      }
      if (paymentStatus.attempts === 3 && !paymentStatus.success) {
        // After 3 failed attempts redirect user to error page
        handleError();
      }
    }).catch((error) => {
      logger.debug(error);
    });
  };

  const getPaymentForm = async () => {
    if (paymentId) {
      fetch(API_GET_PAYMENT_IFRAME(paymentId))
        .then((response) => {
          if (response.status === 200) {
            // Payment form
            setIframeSrc(<iframe id="paymentIFrame" title="Payment IFrame" className={styles.hpp_iframe} src={API_GET_PAYMENT_IFRAME(paymentId)} scrolling="no" />);
          } else {
            // Error, try again
            setIframeSrc(<TechnicalError buttonClickHandler={getPaymentForm} />);
          }
        })
        .catch(() => {
          // Error, try again
          setIframeSrc(<TechnicalError buttonClickHandler={getPaymentForm} />);
        });
    }
  };

  // Event listener waits for a message event to be triggered from the payment response service
  async function listener(event) {
    if (event.data && event.data.paymentId) {
      setShowPrompt(!(event.data.success || event.data.maxAttempt));
      // Method added to prevent further propagation of the current event for potential bubbling/conflict with javascript from live person chat bot
      event.stopPropagation();
    }
  }

  useEffect(() => {
    // Event listener for GlobalPay message response
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [paymentId]);

  useEffect(() => {
    // Interval to poll payment status from RAC payment service
    const paymentStatusInterval = window.setInterval(
      getPaymentStatus,
      process.env.REACT_APP_PAYMENT_SERVICE_STATUS_INTERVAL_TIME,
    );
    return () => {
      window.clearInterval(paymentStatusInterval);
    };
  }, []);

  useEffect(() => {
    // Show payment form or technical error in iFrame
    async function renderIframe() {
      await getPaymentForm();
    }
    renderIframe();
  }, []);

  return (
    <div>
      <div className={styles.container}>
        { iFrameSrc }
      </div>
      <TimeoutWarningModal />
    </div>
  );
};

export default PaymentIFrame;
