import adobeOfferStatusConstants from 'constants/adobeOfferStatusConstants';

export const createUpgradeAmendment = (upgrade) => {
  const amendment = {
    type: 'Add',
    code: upgrade?.productCode,
    price: upgrade?.discountedPrice || upgrade?.price,
  };
  return amendment;
};

export const createVehicleAmendment = (vehicle) => {
  const amendment = {
    type: 'Modify',
    code: 'Vehicle',
    price: 0,
    data: {
      from: {
        registration: vehicle?.OldReg,
      },
      to: {
        registration: vehicle?.Registration,
      },
    },
  };
  return amendment;
};

export const createAdobeOfferPayload = (status, freeLoyaltyRewards, propositionId) => {
  if (freeLoyaltyRewards) {
    // AdobeOffersAvailable and where user has selected a reward
    if (freeLoyaltyRewards && propositionId && status === adobeOfferStatusConstants.AdobeOffersAvailable) {
      // Payload for post back to Adobe by filtering array list by selected item
      const reward = freeLoyaltyRewards.find((item) => parseInt(item.propositionId) === parseInt(propositionId));
      return reward;
    }
    // NoAdobeConnection
    if (status === adobeOfferStatusConstants.NoAdobeConnection) {
      return null;
    }
    // Default that covers NoOfferAvailable and AdobeOffersAvailable where user has not made a selection
    return freeLoyaltyRewards[0];
  }
  return null;
};

// For AdobeOffersAvailable scenario use adobeOfferSelected boolean flag passed in or otherwise default to original status value from pageModel
// eslint-disable-next-line no-nested-ternary
export const selectedAdobeOfferStatus = (adobeOfferStatus, propositionId) => {
  if (adobeOfferStatus === adobeOfferStatusConstants.AdobeOffersAvailable) {
    return (propositionId ? adobeOfferStatusConstants.OneOfferSelected : adobeOfferStatusConstants.NoOfferSelected);
  }
  return adobeOfferStatus;
};
