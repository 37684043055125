import React from 'react';
import block from 'bem-css-modules';
import chevronLeftOrange from '../../../../assets/images/button/chevron-left-thin-orange.svg';
import chevronUpOrange from '../../../../assets/images/button/chevron-up-thin-orange.svg';
import styles from './CollapsibleMobileTile.module.scss';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

export const CollapsibleMobileTile = (props) => {
  const {
    pageModel, action, children, displayState,
  } = props;

  const backgroundColour = pageModel.backgroundColourMobile;
  const collapsableTileContent = () => (displayState ? null : (<div>{children}</div>));

  const heading = () => (displayState ? pageModel.tileContent.collapsedHeading : pageModel.tileContent.expandedHeading);

  return (
    <div
      className={bem()}
      style={{ backgroundColor: backgroundColour }}
    >
      <div
        className={`${bem('header-wrapper')} ${displayState ? null : bem('underline')}`}
        role="button"
        onClick={action}
        onKeyDown={() => action}
        tabIndex={0}
      >
        <p className={bem('heading-text')}>
          {heading()}
        </p>
        <img className={bem(displayState ? 'chevron-left-icon' : 'chevron-down-icon')} src={displayState ? chevronLeftOrange : chevronUpOrange} alt="chevron" />
      </div>
      {collapsableTileContent()}
    </div>
  );
};
export default CollapsibleMobileTile;
