/**
 * Function that validates string via a regular expression
 * @param    {String} errorMessage  A string representing an email address.
 * @return   {boolean} True if valid email address.
 */
export const ValidateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isEmpty = (value) => {
  let returnValue = false;
  if (value === '' || value === undefined) {
    returnValue = true;
  } else {
    returnValue = false;
  }
  return returnValue;
};
