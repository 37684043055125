import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './paymentPage.module.scss';
import Note from './components/note/Note';
import PaymentIFrame from '../../../../components/PaymentService/ProductUpgrade/PaymentIFrame';
import visaAndMastercardImage from '../../../../images/paymentPage/VisaandMasterCard.svg';
import mobileImage from '../../../../images/paymentPage/mobile.svg';
import { GetPaymentId } from '../../../../services/MyRacService';
import trackEvent from '../../../../services/AnalyticsService';
import TechnicalError from './components/technicalError/TechnicalError';
import Spinner from '../../../../components/Spinner';

const PaymentPage = (props) => {
  const { session, pageModel, customer, leaveJourney } = props;
  const [paymentIframeDisplay, setDisplayPaymentIFrame] = useState(<Spinner defaultSpinner />);

  const getPaymentId = () => {
    GetPaymentId(
      customer.custInfo.Id,
      pageModel?.productName,
      session.memberNo,
      pageModel?.price,
      pageModel?.vehicleRegistration,
      pageModel?.extraPersonTitle,
      pageModel?.extraPersonFirstname,
      pageModel?.extraPersonSurname,
      pageModel?.extraPersonDateOfBirth,
    ).then((response) => {
      // Construct payload for payment iframe
      setDisplayPaymentIFrame(
        <PaymentIFrame
          paymentId={response?.payload}
          pageModel={pageModel}
          leaveJourney={leaveJourney}
          payload={{
            customerId: customer.custInfo.Id,
            productName: pageModel?.productName,
            membershipNumber: session.memberNo,
            price: pageModel?.price,
          }}
        />,
      );
    })
      .catch(() => {
        setDisplayPaymentIFrame(<TechnicalError buttonClickHandler={getPaymentId} />);
      });
  };

  useEffect(() => {
    trackEvent(pageModel?.analyticsTagPaymentPage);
  }, [pageModel?.analyticsTagPaymentPage]);

  useEffect(() => {
    getPaymentId();
  }, [customer.custInfo.Id, pageModel.productName, session.memberNo, pageModel.price]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Enter your card details</h1>
      <div className={styles.priceContainer}>
        <p className={styles.price}>£{pageModel?.price?.toFixed(2)}</p>
        <img src={visaAndMastercardImage} alt="Visa Secure and Mastercard ID Check" className={styles.image} />
      </div>

      <div className={styles.greyContainer}>
        <Note
          heading="Pay by credit / debit card"
          headingColor="#47474A"
          subHeading="There are no additional charges for using your credit card."
          text="Your payment is secure. We use Visa Secure and Mastercard Identity Check to make sure your payment details are protected."
          textColor="#47474A"
        />
        <Note
          heading="Just so you know..."
          headingColor="#F95108"
          text="Your bank may ask you to verify this payment by texting you a passcode or asking you to confirm it via your mobile banking app."
          textColor="#386C87"
          imageSrc={mobileImage}
          imageAltText="Mobile Authentication Image."
          backgroundColor="#FFFFFF"
          reverseContentOrder
        />
      </div>
      <div className={styles.paymentIframeContainer}>
        { paymentIframeDisplay }
      </div>

    </div>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
  documents: state.documents,
  featureFlags: state.featureToggle,
});

export default connect(mapStateToProps)(PaymentPage);
