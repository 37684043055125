import React from 'react';
import styles from './ProductUpgradeModal.module.scss';

const ProductUpgradeModal = ({ action, modalTitle, modalBody }) => {
  let title;
  let description;

  if ((modalBody instanceof Array && React.isValidElement(modalBody[0])) || modalTitle instanceof Array) {
    description = modalBody;
    title = modalTitle;
  } else {
    description = <div>{modalBody}</div>;
    title = modalTitle;
  }

  return (
    <div>
      <div className={styles.overflow} role="dialog">
        <div className={styles.container}>
          <div className={styles.closeButton}>
            <button
              type="button"
              title="Close"
              className="inner-close-button close ascii-cross"
              onClick={action}
              data-dismmiss="modal"
              aria-hidden="true"
            >
              &#215;
            </button>
          </div>
          <h4 className={styles.title}>
            {title}
          </h4>
          <div className={styles.description}>
            {description}
          </div>
        </div>
      </div>
      <div className={styles.backdrop} />
    </div>
  );
};

export default ProductUpgradeModal;
