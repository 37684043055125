import React, { useState } from 'react';
import block from 'bem-css-modules';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import styles from './Faqs.module.scss';

const bem = block(styles);

const { string, arrayOf, shape } = PropTypes;

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const Faqs = ({ fields, faqsOpen = false }) => {
  const setFaqStatus = () => {
    const faqIds = {};
    fields.stack.forEach((stack) => {
      stack.faqs.forEach((faq) => {
        faqIds[faq.uid] = faqsOpen;
      });
    });
    return faqIds;
  };
  const [faqToggleStatus, setFaqToggleStatus] = useState(setFaqStatus);

  const faqToggleHandler = (faqId) => {
    setFaqToggleStatus({
      ...faqToggleStatus,
      [faqId]: !faqToggleStatus[faqId],
    });
  };

  const getFaqs = (faqs) => faqs.map((faq) => (
    <div key={faq.uid} className={bem('faq')}>
      <div tabIndex={0} role="button" className={bem('faq-question')} onClick={() => faqToggleHandler(faq.uid)} onKeyDown={() => faqToggleHandler(faq.uid)}>
        <span className={bem('faq-question-title')}>
          {faq.heading}
        </span>
        {faqToggleStatus[faq.uid] ? (
          <i className={`${bem('orange')} fas fa-chevron-up`} />
        ) : (
          <i className="fas fa-chevron-down" />
        )}
      </div>
      {faqToggleStatus[faq.uid] && <div className={bem('faq-answer')}>{ReactHtmlParser(faq.htmldescription)}</div>}
    </div>
  ));

  const getFaqStacks = () => fields.stack.map((stack) => (
    <div key={uniqid()} className={bem('faq-stack')}>
      <h3 className={bem('heading', { sub: true })}>{stack.heading}</h3>
      {getFaqs(stack.faqs)}
    </div>
  ));

  return (
    <section id={fields.uid} className={bem()}>
      <div className={bem('box')}>
        <h2 className={bem('heading', { main: true })}>{fields.heading}</h2>
        {getFaqStacks()}
      </div>
    </section>
  );
};

Faqs.propTypes = {
  heading: string,
  stack: arrayOf(
    shape({
      heading: string,
      faqs: arrayOf(
        shape({
          heading: string,
          htmldescription: string,
          uid: string,
        }),
      ),
    }),
  ),
  uid: string,
};

Faqs.defaultProps = {
  heading: '',
  stack: [
    {
      heading: '',
      faqs: [],
    },
  ],
  uid: '',
};

export default Faqs;
