import * as CustomerActions from 'actions/customerInfoActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import trackEvent from 'services/AnalyticsService';
import { ResponseCode } from 'services/models/httpResponse';
import { UpdateCommunicationsPref } from 'services/MyRacService';
import PopupModal from '../../../../components/PopupModal/PopupModal';
import Spinner from '../../../../components/Spinner';
import modalTypes from '../../../../utils/modalTypesEnum';
import ProtectedForm from '../../../../shared/components/ProtectedForm/ProtectedForm';

class CommunicationPreferencesPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCommPref: false,
      commsPrefs: false,
      showModal: false,
      changesWereMade: false,
      loading: false,
    };
    this.toggleShowCommPref = this.toggleShowCommPref.bind(this);
    this.changeMarketingPreference = this.changeMarketingPreference.bind(this);
    this.saveMarketingPreference = this.saveMarketingPreference.bind(this);
    this.toggleShowModal = this.toggleShowModal.bind(this);
  }

  componentDidMount() {
    const { customer: { custInfo: { CommunicationPreferences } } } = this.props;
    if (CommunicationPreferences) {
      const clonedPreferences = CommunicationPreferences.map((pref) => ({ ...pref }));
      this.setState({
        commsPrefs: clonedPreferences,
      });
    }
  }

    toggleShowModal = (e) => {
      e.preventDefault();
      const { showModal } = this.state;
      this.setState({ showModal: !showModal });
    };

    changeMarketingPreference = (e) => {
      const { commsPrefs } = this.state;
      const { customer } = this.props;
      const { custInfo } = customer;
      const newPrefs = commsPrefs.map((pref) => ({ ...pref }));
      newPrefs.filter(
        (pref) => pref.Id === e.target.id,
      )[0].OptedIn = e.target.checked;

      const initialPreferences = custInfo && custInfo.CommunicationPreferences.map((preference) => ({ ...preference }));

      const updates = newPrefs.filter((newPref) => newPref.OptedIn !== initialPreferences
        .find((original) => newPref.Id === original.Id).OptedIn);

      if (updates && updates.length > 0) {
        this.setState({
          changesWereMade: true,
        });
      } else {
        this.setState({
          changesWereMade: false,
        });
      }

      this.setState({ commsPrefs: newPrefs });
      trackEvent(`myrac.comms${e.target.attributes.analytics.value}${
        e.target.checked ? 'ticked' : 'untick'}`);
    };

    toggleShowCommPref = (e) => {
      e.preventDefault();

      const { changesWereMade, showCommPref } = this.state;
      if (!changesWereMade) {
        this.setState({ showCommPref: !showCommPref });
      }
    }

    saveMarketingPreference = async () => {
      const { customer: { custInfo }, pageModel, setCommsPrefs } = this.props;
      const { commsPrefs } = this.state;

      const updates = commsPrefs.filter((newPref) => newPref.OptedIn !== custInfo.CommunicationPreferences
        .find((original) => newPref.Id === original.Id).OptedIn);
      updates.forEach((change) => trackEvent(
        `myrac.comms${change.Analytics}${change.OptedIn
          ? '' : 'untick'}.submit`,
      ));

      this.setState({ loading: true });

      try {
        const response = await UpdateCommunicationsPref(
          custInfo.Id,
          commsPrefs,
        );
        if (response.responseCode === ResponseCode.SUCCESS) {
          const comPrf = [...commsPrefs];
          setCommsPrefs(comPrf);

          this.setState({
            showModal: true,
            title: pageModel.messages.successTitle,
            message: pageModel.messages.successMessage,
            changesWereMade: false,
            loading: false,
          });
        }
      } catch (error) {
        this.setState({
          showModal: true,
          title: pageModel.messages.failTitle,
          message: pageModel.messages.failMessage,
          loading: false,
        });
      }
    };

    render() {
      const { policy: { policyInfo }, pageModel } = this.props;
      const {
        showCommPref, commsPrefs, showModal, title, message, changesWereMade, loading,
      } = this.state;
      return (
        <div>
          {loading && (
            <div className="row border pt-4 pb-3">
              <Spinner defaultSpinner />
            </div>
          )}
          {!loading && policyInfo.CanEditMarketingPreferences && (
            <div className="row border pt-4 pb-3">
              <div className="account-preferences__icon col-sm-1 col-1">
                <img src={pageModel.image.url} alt="Email" />
              </div>
              <div className="col-sm-11 col-11">
                <h3 className="account-preferences__prefs-title">{pageModel.title}</h3>
                <p className="action edit">
                  <button
                    type="button"
                    className="MyRacForm__button-link link Panel__button"
                    onClick={this.toggleShowCommPref}
                  >
                    {pageModel.button}
                    {' '}
                    &gt;
                  </button>
                </p>
                {showCommPref
                  && (
                  <div>
                    <p>{pageModel.text}</p>
                    <div>
                      <ProtectedForm formChanged={changesWereMade} className="account-preferences__marketing-wrapper">
                        {commsPrefs.map((item) => (
                          <div className="myrac__checkbox--wrapper" key={item.Id}>
                            <label
                              className="myrac__checkbox--text myrac__checkbox--label"
                              htmlFor={item.Id}
                            >
                              <input
                                className="myrac__checkbox--input"
                                id={item.Id}
                                type="checkbox"
                                checked={item.OptedIn}
                                analytics={item.Analytics}
                                onChange={this.changeMarketingPreference}
                              />
                              <span className="myrac__checkbox--span" />
                              <span
                                className="myrac__checkbox--text checkbox--verticalAlign"
                              >
                                {item.Description}
                              </span>
                            </label>
                          </div>
                        ))}
                      </ProtectedForm>
                    </div>
                    <div className="account-preferences__button-wrapper">
                      <button
                        type="button"
                        onClick={this.saveMarketingPreference}
                        className="btn btn-cta btn-primary"
                        data-dismiss="modal"
                        disabled={!changesWereMade}
                      >
                        {pageModel.submit}
                      </button>
                    </div>
                  </div>
                  )}
              </div>
            </div>
          )}
          {showModal
            && (
            <PopupModal
              resultTitle={title}
              resultText={message}
              modalOk={pageModel.modalOk}
              action={this.toggleShowModal}
              modalType={modalTypes.infoModal}
            />
            )}
        </div>
      );
    }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
});

const mapDispatchToProps = (dispatch) => ({
  setCommsPrefs: (commsPrefs) => {
    dispatch(CustomerActions.setCommsPrefs(commsPrefs));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationPreferencesPanel);
