import React from 'react';
import Spinner from 'components/Spinner';

const SearchUpdatingText = () => (
  <div className="modal-body">
    <Spinner spinnerMsg="We are updating your information." defaultSpinner />
  </div>
);

export default SearchUpdatingText;
