/* eslint-disable arrow-body-style */
import React, { Component } from 'react';
import { format, isAfter } from 'date-fns';
import { connect } from 'react-redux';

import ComponentView from 'common/ComponentView';
import RenderLink from 'components/RenderLink';
import Spinner from 'components/Spinner';
import * as DefaultText from 'configuration/defaultText';
import { documentErrors } from 'constants/cms/messageConstants';
import { getMessage } from 'services/MessageService';
import { GetDocument } from 'services/MyRacService';
import { loggerFunc } from 'utils/logger';

const logger = loggerFunc('DocumentsListing');

class DocumentsListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDoc: undefined,
      errorText: undefined,
    };
    this.getDocument = this.getDocument.bind(this);
    this.linkRef = React.createRef();
    this.onErrorModalClose = this.onErrorModalClose.bind(this);
  }

  onErrorModalClose = () => {
    this.setState({
      errorText: undefined,
    });
  };

  handleError = (e, text) => {
    this.setState({
      errorText: text,
    });
    logger.error(text, e);
  }

  getDocument = (e, fileData) => {
    e.preventDefault();
    const { customer, policy } = this.props;

    this.setState({
      selectedDoc: fileData.DocumentId,
    });

    const documenttype = (fileData.DocumentType === 'Booklet') ? fileData.DocumentType.toLowerCase() : policy.policyInfo.PolicyId;

    return GetDocument(customer.custInfo.Id, documenttype, fileData.DocumentId)
      .then((blob) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // its been assumed that the file is ALWAYS going to be pdf
          window.navigator.msSaveOrOpenBlob(blob.payload, `${fileData.Name}.pdf`);
        } else {
          const url = URL.createObjectURL(new Blob([blob.payload], { type: 'application/pdf' }));
          const ref = this.linkRef;
          if (ref) {
            ref.current.href = url;
            ref.current.download = `${fileData.Name}.pdf`;
            ref.current.click();
          }
        }
        this.setState({
          selectedDoc: undefined,
        });
      }).catch((err) => {
        this.handleError(err, getMessage(documentErrors.DOWNLOAD_ERROR));
        this.setState({
          selectedDoc: undefined,
        });
      });
  }

  // eslint-disable-next-line default-param-last
  createDocumentList = (documents = [], selectedDoc, documentsIcon, downloadlinktext, documentStatus) => {
    return (
      <div>
        {documents.length > 0 ? (
          <div className="myrac-docs-container p-3 mb-4">
            {documents.map((doc) => (
              <div className="myrac-doc card p-3 m-2" key={doc.DocumentId}>
                <div className="row align-items-center">
                  <div className="col-sm-1 col-2 text-center">
                    <img src={documentsIcon} alt="doc" />
                  </div>
                  <div className="col">
                    <h6><b>{doc.Name}</b></h6>
                    <span>{isAfter(new Date(), new Date(doc.DocumentDate)) ? 'Issued on' : 'Effective from'}</span>
                  &nbsp;
                    <span>
                      {format(new Date(doc.DocumentDate), 'do MMM yyyy')}
                    </span>
                  </div>
                  <div className="col-sm-1 col-2 text-center">
                    {
                    (doc.DocumentId === selectedDoc)
                      ? <Spinner iconSpinner />
                      : (
                        <RenderLink
                          className="fas fa-download fs-4"
                          url="notARealLink"
                          onClick={(e) => this.getDocument(e, doc)}
                          title={doc.Name}
                          name={doc.Name}
                          text={downloadlinktext}
                        />
                      )
                  }
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="alert alert-secondary" role="alert">
            {`We don't have any ${documentStatus} documents for you`}
          </div>
        )}
        <a href="empty" className="display__none" ref={this.linkRef}>ref for documents to link to for download</a>
      </div>
    );
  }

  render() {
    const {
      documents, documentsIcon, downloadlinktext, documentStatus,
    } = this.props;
    const { errorText } = this.state;
    const { selectedDoc } = this.state;

    if (!documents || Object.keys(documents).length <= 0) { return DefaultText.WaitingDoc; }

    return (
      <ComponentView errorText={errorText} onErrorModalClose={this.onErrorModalClose}>
        {this.createDocumentList(documents, selectedDoc, documentsIcon, downloadlinktext, documentStatus)}
      </ComponentView>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
});

export default connect(mapStateToProps)(DocumentsListing);
