import React, { useEffect, useState } from 'react';
import { isAfter } from 'date-fns';
import trackEvent from '../../../services/AnalyticsService';

const ExtraPersonForm = (props) => {
  const { onAddPerson } = props;

  const [state, setState] = useState({
    personDetails: null,
  });
  const [errors, setErrors] = useState({
    Firstname: false,
    Lastname: false,
    Dob: false,
  });

  const handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    // Get object from props
    const updatedInputs = { ...state.personDetails };
    // Set key value
    if (value) {
      // Update if input is not empty
      updatedInputs[name] = value;
      // Form validation
      const nameRegex = /^[a-zA-Z '.-]*$/;
      if (name === 'Firstname') {
        if (!nameRegex.test(value)) {
          // Fail
          setErrors((prev) => ({ ...prev, Firstname: true }));
          // Remove key in order to reflect count in parent component
          delete updatedInputs[name];
        } else {
          // Pass
          setErrors((prev) => ({ ...prev, Firstname: false }));
        }
      }
      if (name === 'Lastname') {
        if (!nameRegex.test(value)) {
          // Fail
          setErrors((prev) => ({ ...prev, Lastname: true }));
          // Remove key in order to reflect count in parent component
          delete updatedInputs[name];
        } else {
          // Pass
          setErrors((prev) => ({ ...prev, Lastname: false }));
        }
      }
      // Because of a known bug with html date input where more than 4 chars can be entered manually, check if year is in a valid format
      if (name === 'Dob') {
        if (value.length > 10 || isAfter(new Date(value), new Date())) {
          // Remove key in order to reflect count in parent component
          delete updatedInputs[name];
        }
      }
    } else {
      // Remove empty keys
      delete updatedInputs[name];
    }
    // Update state object in parent component
    setState((prev) => ({ ...prev, personDetails: updatedInputs }));
  };

  useEffect(() => {
    if (state?.personDetails) {
      onAddPerson(state);
    }
  }, [state?.personDetails]);

  return (
    <div className="container p-3 p-md-4" style={{ background: '#ededed' }}>
      <div className="row">
        <form name="personForm" className="rac-form">
          <div className="row p-1">
            <div className="col-sm-2 form-floating mb-3 mb-md-0">
              <select onChange={handleInputChange} onFocus={() => { trackEvent('MyRAC_Upgrade_AddBeneficiary_Title'); }} className="form-select" name="Title" aria-label="Title">
                <option selected disabled>Please select</option>
                <option value="Mr">Mr</option>
                <option value="Miss">Miss</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Doctor">Doctor</option>
                <option value="Sir">Sir</option>
              </select>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="Title" className="mx-2">Title</label>
            </div>
            <div className="col-sm-3 form-floating mb-3 mb-md-0">
              <input onChange={handleInputChange} onFocus={() => { trackEvent('MyRAC_Upgrade_AddBeneficiary_FirstName'); }} type="text" name="Firstname" className={`form-control ${errors?.Firstname && ('is-invalid')}`} maxLength="30" />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="Firstname" className="mx-2">First name</label>
              <sub className="mt-2 px-2 invalid-feedback">
                {errors?.Firstname && ('Please enter a valid first name')}
              </sub>
            </div>
            <div className="col-sm-3 form-floating mb-3 mb-md-0">
              <input onChange={handleInputChange} onFocus={() => { trackEvent('MyRAC_Upgrade_AddBeneficiary_LastName'); }} type="text" name="Lastname" className={`form-control ${errors?.Lastname && ('is-invalid')}`} maxLength="30" />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="Lastname" className="mx-2">Last name</label>
              <sub className="mt-2 px-2 invalid-feedback">
                {errors?.Lastname && ('Please enter a valid last name')}
              </sub>
            </div>
            <div className="col-sm-4 form-floating">
              <input onChange={handleInputChange} onFocus={() => { trackEvent('MyRAC_Upgrade_AddBeneficiary_DateOfBirth'); }} type="date" name="Dob" className="form-control" />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="Dob" className="mx-2">Date of birth</label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExtraPersonForm;
