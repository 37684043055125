import React from 'react';
import styles from './MoreForMoreExtra.module.scss';

const MoreForMoreExtras = ({ children }) => (
  <div className={styles.container}>
    <div className="row">
      <div className="col-sm-12 d-flex justify-content-center my-auto">
        {children}
      </div>
    </div>
  </div>
);

export default MoreForMoreExtras;
