import React from 'react';
import LandingBanner from '../shared/components/LandingBanner/LandingBanner';
import Brokendown from '../shared/components/Brokendown/Brokendown';
import Promotions from '../shared/components/Promotions/Promotions';
import Faqs from '../shared/components/Faqs/Faqs';
import InformationBlock from '../shared/components/InformationBlock/InformationBlock';
import VerifyUserDetails from '../shared/components/VerifyUserDetails/VerifyUserDetails';

export const LANDING_BANNER_COMPONENT = 'banner';
export const LANDING_BROKENDOWN_COMPONENT = 'brokendown';
export const LANDING_PROMOTIONAL_COMPONENT = 'promotions';
export const LANDING_FAQS = 'faqstack';
export const INFORMATION_BLOCK = 'informationhtml';
export const VERIFY_USER_DETAILS_COMPONENT = 'verifyuserdetails';

const mappings = {
  [LANDING_BANNER_COMPONENT]: (props) => <LandingBanner fields={props} />,
  [LANDING_BROKENDOWN_COMPONENT]: (props) => <Brokendown fields={props} />,
  [LANDING_PROMOTIONAL_COMPONENT]: (props) => <Promotions fields={props} />,
  [LANDING_FAQS]: (props) => <Faqs fields={props} />,
  [INFORMATION_BLOCK]: (props) => <InformationBlock fields={props} />,
  [VERIFY_USER_DETAILS_COMPONENT]: (props) => <VerifyUserDetails fields={props} />,
};

export const componentMappingService = (componentName, props) => (mappings[componentName] ? mappings[componentName](props) : null);
