import React, { useEffect, useState } from 'react';
import styles from './NavQuickLinks.module.scss';
import NavQuickLink from './NavQuickLink';
import {
  ACCOUNT_DETAILS,
  MY_COVER,
  DOCUMENTS,
  MEMBER_REWARDS, PERSONAL_DETAILS,
  VEHICLE_DETAILS,
} from '../../constants/routeConstants';

const NavQuickLinks = () => {
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const [myContainer, setMyContainer] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isMouseScroll, setIsMouseScroll] = useState(false);
  const [containerStyles, setContainerStyles] = useState({});

  const mouseDownHandler = (event) => {
    setIsMouseDown(true);
    setContainerStyles({
      cursor: 'grabbing',
      userSelect: 'none',
    });
    setStartX(event.pageX - myContainer.offsetLeft);
    setScrollLeft(myContainer.scrollLeft);
  };

  const mouseLeaveHandler = () => {
    setIsMouseDown(false);
    setIsMouseScroll(false);
    setContainerStyles({});
  };

  const mouseMoveHandler = (event) => {
    if (isMouseDown) {
      const x = event.pageX - myContainer.offsetLeft;
      const walk = (x - startX) / 1; // scroll-fast
      myContainer.scrollLeft = scrollLeft - walk;
    }
  };

  const mouseOnScroll = () => {
    setIsMouseScroll(true);
  };

  useEffect(() => {
    if (myContainer === null) {
      setMyContainer(document.getElementById('navQuickLinksContainer'));
    }
  });

  return (
    <div
      className={styles.container}
      style={containerStyles}
      role="button"
      aria-label="Quick links"
      id="navQuickLinksContainer"
      tabIndex="0"
      onMouseUp={mouseLeaveHandler}
      onMouseDown={mouseDownHandler}
      onMouseMove={mouseMoveHandler}
      onMouseLeave={mouseLeaveHandler}
      onScroll={mouseOnScroll}
    >
      <NavQuickLink isScrolling={isMouseScroll} text="Report Breakdown" absoluteUrl={`${process.env.REACT_APP_BREAKDOWN_URL}${process.env.REACT_APP_BREAKDOWN_BUTTON_QUERYSTRING}`} />
      <NavQuickLink isScrolling={isMouseScroll} text="Upgrade" linkUrl={MY_COVER} />
      <NavQuickLink isScrolling={isMouseScroll} text="Rewards" linkUrl={MEMBER_REWARDS} />
      <NavQuickLink isScrolling={isMouseScroll} text="Reminders" linkUrl={VEHICLE_DETAILS} />
      <NavQuickLink isScrolling={isMouseScroll} text="Documents" linkUrl={DOCUMENTS} />
      <NavQuickLink isScrolling={isMouseScroll} text="My Details" linkUrl={PERSONAL_DETAILS} />
      <NavQuickLink isScrolling={isMouseScroll} text="Settings" linkUrl={ACCOUNT_DETAILS} />
    </div>
  );
};

export default NavQuickLinks;
