import React from 'react';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import styles from './UpgradePanels.module.scss';
import UpgradePanel from './UpgradePanel';
import { RENEWAL_TYPE } from '../../../constants/policyInfoConstants';
import { replaceValue } from '../../../utils/commons';

const UpgradePanels = (props) => {
  const { pageModel, isPaymentJourney, confirmationType, policy } = props;

  const displayAdditionalRenewalMessage = () => {
    if (policy.policyInfo.RenewalType === RENEWAL_TYPE.annualContinuous && policy.policyInfo.DaysToRenewal <= 60) {
      return ReactHtmlParser(replaceValue(pageModel?.policyRenewalNote, '{date}', format(new Date(policy.policyInfo.RenewalDate), 'dd/MM/yyyy')));
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <UpgradePanel icon={pageModel?.startDate?.startDateIcon?.url} iconAlt={`${pageModel?.startDate?.startDateTitle} icon`} title={pageModel?.startDate?.startDateTitle} description={ReactHtmlParser(pageModel?.startDate?.startDateDescription)} confirmationType={confirmationType} />
      {!isPaymentJourney && (<UpgradePanel icon={pageModel?.paymentMethod?.paymentIcon?.url} iconAlt={`${pageModel?.paymentMethod?.paymentTitle} icon`} title={pageModel?.paymentMethod?.paymentTitle} description={ReactHtmlParser(pageModel?.paymentMethod?.paymentDescription)} confirmationType={confirmationType} />)}
      <UpgradePanel icon={pageModel?.whatHappensNext?.whatHappensNextIcon?.url} iconAlt={`${pageModel?.whatHappensNext?.whatHappensNextIcon?.url} icon`} title={pageModel?.whatHappensNext?.whatHappensNextTitle} description={ReactHtmlParser(pageModel?.whatHappensNext?.whatHappensNextDescription)} additionalDescription={displayAdditionalRenewalMessage()} confirmationType={confirmationType} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
});

export default connect(mapStateToProps)(UpgradePanels);
