import React from 'react';
import ReactHtmlParser from '@orrisroot/react-html-parser';

const ConfirmationText = (props) => {
  const {
    title, text, onOkCallback, cancelCallback,
  } = props;

  return (
    <div className="modal-body success-text">
      <div className="header mt-5">
        <span className="myrac-icon-checked" />
        <h4 className="title">{ReactHtmlParser(title)}</h4>
        <p>{ReactHtmlParser(text)}</p>
      </div>
      <div className="text-center">
        <button
          className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--grey"
          type="button"
          onClick={cancelCallback}
        >
          Cancel
        </button>
        <button
          className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--left-margin"
          type="button"
          data-dismiss="modal"
          onClick={onOkCallback}
        >
          Ok
        </button>
      </div>
    </div>
  );
};

export default ConfirmationText;
