import React from 'react';
import imgSrc from 'images/ajax-loader.gif';

const Spinner = (props) => {
  const {
    defaultSpinner,
    iconSpinner,
    addClass,
    spinnerMsg,
    bootSpinner,
    buttonSpinner,
  } = props;
  if (defaultSpinner) {
    return (
      <div className="mx-auto text-center d-block my-4 din-font">
        <p className="text-center mb-2">{spinnerMsg}</p>
        <img alt="Loading..." src={imgSrc} />
        <p className="text-center mt-2">Please wait...</p>
      </div>
    );
  }
  if (iconSpinner) {
    return (
      <img className={addClass} alt="Loading..." src={imgSrc} />
    );
  }
  if (bootSpinner) {
    return (
      <div className={`d-flex justify-content-center ${addClass ?? 'pt-5'}`}>
        <div className="spinner-border" style={{ color: '#f95108', height: '3rem', width: '3rem' }} role="status">
          <span className="sr-only">Loading...</span>
          { spinnerMsg && <p>{spinnerMsg}</p> }
        </div>
      </div>
    );
  }
  if (buttonSpinner) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border spinner-border-sm p-2" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className="mx-auto text-center d-block my-4 din-font">
      <h2>Loading MyRAC</h2>
      <p className="text-center my-2">Please wait...</p>
      <img alt="Loading..." src={imgSrc} />
      <p className="text-center mt-2">{spinnerMsg}</p>
      <p className="text-center mt-2">Did you know? 1 in 3 member call outs are from home</p>
    </div>
  );
};

export default Spinner;
