import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import trackEvent from 'services/AnalyticsService';

class RenderLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: {
        isExternal: props.isExternal || (props.link && props.link.isExternal) || false,
        openInNewWindow: props.openInNewWindow
          || (props.link && props.link.openInNewWindow) || false,
        url: props.url || (props.link && props.link.url) || null,
        title: props.title || (props.link && props.link.title) || null,
        text: props.text || (props.link && props.link.text) || null,
        onClick: props.onClick || null,
        className: props.className || (props.link && props.link.cssClass) || null,
        isLink: props.isLink || (props.link && props.link.isLink) || null,
        analytics: props.analytics || (props.link && props.link.analytics) || false,
      },
    };
    this.manageClick = this.manageClick.bind(this);
  }

  manageClick = () => {
    const { link } = this.state;
    if (link.analytics) {
      trackEvent(link.analytics);
    }
    if (link.onClick) {
      link.onClick();
    }
  }

  render() {
    const { link } = this.state;
    const { children } = this.props;
    if (!link.url) { return null; }

    if (link.isExternal) {
      return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={link.url}
          title={link.title}
          className={link.className}
          target={link.openInNewWindow ? '_BLANK' : '_SELF'}
          rel={link.openInNewWindow ? 'external noopener noreferrer' : ''}
        >
          {children || link.text}
        </a>
      );
    }

    if (link.isLink) {
      return (
        <Link
          to={{ pathname: link.url }}
          className={link.className}
          title={link.title}
          onClick={link.analytics ? this.manageClick : link.onClick}
          data-analytics={link.analytics}
        >
          {children || link.text}
        </Link>
      );
    }

    return (
      <NavLink
        to={{ pathname: link.url }}
        className={link.className}
        title={link.title}
        onClick={link.analytics ? this.manageClick : link.onClick}
        data-analytics={link.analytics}
      >
        {children || link.text}
      </NavLink>
    );
  }
}

export default RenderLink;
