import React from 'react';
import ReactHtmlParser from '@orrisroot/react-html-parser';

const SuccessText = (props) => {
  const { title, text, action } = props;

  return (
    <>
      <div className="message-dialog-wrapper quick-modal-overlay modal myrac fade in" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered Modal__visible"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                title="Close"
                type="button"
                className="close ascii-cross"
                onClick={action}
                data-dismmiss="modal"
                aria-hidden="true"
              >
                &#215;
              </button>
            </div>
            <div className="modal-body success-text">
              <div className="header">
                <span className="myrac-icon-checked" />
                <h4 className="title">{ReactHtmlParser(title)}</h4>
                <p>{ReactHtmlParser(text)}</p>
              </div>
              <div className="MyRacForm__center_button">
                <button
                  className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide"
                  type="button"
                  data-dismiss="modal"
                  onClick={action}
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade in Modal__block"
      />
    </>
  );
};

export default SuccessText;
