import React from 'react';
import { loggerFunc } from 'utils/logger';
import MyRac from './views/MyRac/MyRac';

const logger = loggerFunc('App');

const App = () => {
  logger.info(`My Rac has Started. version: ${process.env.REACT_APP_VERSION}`);

  return (
    <MyRac />
  );
};

export default App;
