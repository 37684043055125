import React, { useEffect, useState } from 'react';
import { isEmpty } from 'utils/utils_validation';
import { regNoRegex } from 'utils/vehicleHelpers';
import { ResponseCode } from 'services/models/httpResponse';
import { FindVehicle } from 'services/MyRacService';
import { loggerFunc } from 'utils/logger';
import { getMessage } from 'services/MessageService';
import { generalMessages, vehicleFormErrors, vehicleErrors } from 'constants/cms/messageConstants';
import trackEvent from 'services/AnalyticsService';

import block from 'bem-css-modules';
import styles from './VehicleForm.module.scss';
import Spinner from '../Spinner';

import { PRODUCT_UPGRADES } from '../../constants/vehicleConstants';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(styles);

const logger = loggerFunc('AddVehicleInUpgrades');

const VehicleForm = (props) => {
  // Add vehicle form component for Product Upgrades and Renewals (determined here by 'isModal' as form for this journey is inside <PopupModal>)
  const { policyVehicles, newVehicles, onAddVehicle, isEdit, isModal, product, analyticsTag, index } = props;
  // Pick main vehicle on policy (not applicable for Renewals)
  const policyVehicle = !isModal && policyVehicles ? policyVehicles[0] : null;
  const [state, setState] = useState({
    isEdit: false,
    isValidReg: false,
    loading: false,
    saved: false,
    showSearch: !policyVehicle || product === PRODUCT_UPGRADES.EXTRA_VEHICLE,
    showManualEntry: false,
    vehicleDetails: product !== PRODUCT_UPGRADES.EXTRA_VEHICLE && policyVehicle,
    vehicleInfo: null,
  });
  const [errors, handleErrors] = useState({
    errorText: null,
    formError: false,
    invalidReg: false,
  });

  const handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    const updatedInputs = { ...state.vehicleDetails };
    updatedInputs[name] = value;
    setState((prev) => ({ ...prev, vehicleDetails: updatedInputs }));
  };

  const validateNumericEntry = (e) => {
    // Only allow numeric values to be entered into field
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const switchForms = (e) => {
    e.preventDefault();
    if (state.showManualEntry) {
      setState((prev) => ({ ...prev, showSearch: true, showManualEntry: false, loading: false, isEdit: false }));
      handleErrors((prev) => ({ ...prev, formError: false }));
    } else {
      setState((prev) => ({ ...prev, showSearch: false, showManualEntry: true, loading: false, isEdit: true }));
      handleErrors((prev) => ({ ...prev, invalidReg: false }));
      trackEvent(`${analyticsTag}_Vehicle_Manualdetails`);
    }
  };

  const findVehicle = async (e) => {
    e.preventDefault();
    const regNo = e.target.regNo.value;
    const regCheck = policyVehicles && policyVehicles.some((vehicle) => vehicle.Registration === regNo.replace(/\s/g, '').toUpperCase());
    if (!isEmpty(regNo) && regNoRegex.test(regNo) && !regCheck) {
      setState((prev) => ({ ...prev, invalidReg: false, errorText: null, loading: true }));
      const findVehiclePayload = {
        Registration: regNo,
      };
      try {
        let vehicle;
        const vehicleData = await FindVehicle(findVehiclePayload);
        const normalisedRegNo = regNo.replace(/\s/g, '').toUpperCase();
        if (vehicleData.responseCode === ResponseCode.SUCCESS) {
          if (!vehicleData.payload) {
            // Fail
            setState((prev) => ({ ...prev, loading: false }));
            handleErrors((prev) => ({ ...prev, invalidReg: true, errorText: getMessage(vehicleFormErrors.REG_SEARCH_ERROR) }));
          } else {
            // Success
            vehicle = vehicleData.payload;
            vehicle.Registration = normalisedRegNo;
            setState((prev) => ({ ...prev, vehicleDetails: vehicle, isValidReg: true, showSearch: false, showManualEntry: true, loading: false }));
            handleErrors((prev) => ({ ...prev, invalidReg: false }));
          }
        }
      } catch (err) {
        if (err?.responseCode === 'error') {
          // Fail
          setState((prev) => ({ ...prev, loading: false }));
          handleErrors((prev) => ({ ...prev, invalidReg: true, errorText: getMessage(vehicleFormErrors.REG_SEARCH_ERROR) }));
          logger.error(err, getMessage(vehicleErrors.VEHICLE_INVALID_FORMAT));
        }
        if (err === 'Http status code not defined') {
          // Service unavailable
          setState((prev) => ({ ...prev, showSearch: false, showManualEntry: true, loading: false, isEdit: true }));
          handleErrors((prev) => ({ ...prev, invalidReg: true, errorText: 'We were unable to complete your request - please try again later.' }));
          trackEvent(`${analyticsTag}_Vehicle_Manualdetails`);
          logger.error(err);
          return;
        }
      }
    } else {
      // Form validation error
      handleErrors((prev) => ({ ...prev, invalidReg: true, errorText: regCheck ? getMessage(vehicleFormErrors.SAME_REG) : getMessage(vehicleFormErrors.EMPTY_REG) }));
    }
    if (isModal) {
      // Tracking for form on Renewals only
      trackEvent(`${analyticsTag}_Vehicle_Find`);
    }
  };

  const saveVehicle = async (e) => {
    e.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));
    const formInput = [
      e.target.Registration.value,
      e.target.YearOfManufacture.value,
      e.target.Make.value,
      e.target.Model.value,
    ];
    const regNo = e.target.Registration.value;
    const regCheck = policyVehicles && policyVehicles.some((vehicle) => vehicle.Registration === regNo.replace(/\s/g, '').toUpperCase());
    // Check to ensure there are no empty form values and the year entered is in a valid format
    const isFormComplete = formInput.every((element) => !!element) && e.target.YearOfManufacture.value.length === 4 && !regCheck;
    if (isFormComplete) {
      const editedVehicle = state.vehicleDetails;
      if (isModal) {
        // Multiple vehicles can be edited at Renewal therefore passing extra parameters in object to differentiate in parent component
        const renewalVehicle = { ...editedVehicle, OldReg: policyVehicles[index].Registration, edit: true };
        // Index value used to replace vehicle in the existing array with new vehicle
        newVehicles[index] = renewalVehicle;
      }
      // Save vehicle details ensuring no validation errors were found
      if (state.isEdit) {
        if (isModal) {
          onAddVehicle(newVehicles);
          trackEvent(`${analyticsTag}_Vehicle_Update`);
        } else {
          // Add vehicle for Product Upgrades
          onAddVehicle(editedVehicle);
        }
        setState((prev) => ({ ...prev, saved: true, vehicleDetails: editedVehicle, showSearch: false, showManualEntry: false, loading: false, isEdit: false }));
        handleErrors((prev) => ({ ...prev, formError: false }));
      } else if (state.isValidReg) {
        // Success
        if (isModal) {
          onAddVehicle(newVehicles);
          trackEvent(`${analyticsTag}_Vehicle_Update`);
        } else {
          // Add vehicle for Product Upgrades
          onAddVehicle(editedVehicle);
        }
        setState((prev) => ({ ...prev, saved: true, showSearch: false, showManualEntry: false, isValidReg: false, loading: false, isEdit: false }));
        handleErrors((prev) => ({ ...prev, formError: false }));
      }
    } else {
      // Form validation error
      setState((prev) => ({ ...prev, loading: false }));
      handleErrors((prev) => ({ ...prev, formError: true, errorText: regCheck ? getMessage(vehicleFormErrors.SAME_REG) : getMessage(generalMessages.REQUIRED_FIELDS) }));
    }
  };

  useEffect(() => {
    // For product upgrade journey only to disable buy now button when editing vehicles
    if (isEdit && !isModal) {
      isEdit(state.isEdit);
    }
    // Tag fired for Renewal vehicle modal form on initial load
    if (isModal && !state.tagged) {
      setState((prev) => ({ ...prev, tagged: true }));
      trackEvent(`${analyticsTag}_Vehicle_Clicked`);
    }
  }, [state.isEdit, state.tagged]);

  return (
    <>
      <div className={`container p-4 mb-4 ${bem('grey-bg')}`}>
        <div className="row">
          {((policyVehicle && state.vehicleDetails && product !== PRODUCT_UPGRADES.EXTRA_VEHICLE) || (state.vehicleDetails && state.saved && !state.showManualEntry && product !== PRODUCT_UPGRADES.EXTRA_VEHICLE)) && (
            <div className="col-md-12 fs-6 din-font">
              <div className="fw-bold">{policyVehicle?.Registration ?? state.vehicleDetails?.Registration}</div>
              <div>{policyVehicle?.Make ?? state.vehicleDetails?.Make}</div>
              <div>{policyVehicle?.Model ?? state.vehicleDetails?.Model}</div>
              <div>{policyVehicle?.YearOfManufacture ?? state.vehicleDetails?.YearOfManufacture}</div>
              {!policyVehicle && !isModal && (
                <div onClick={() => setState((prev) => ({ ...prev, showManualEntry: true, isEdit: true }))} aria-hidden="true" className={`${bem('info-link')} pt-3 m-0`}>
                  Edit vehicle
                </div>
              )}
              {isModal && (isEdit(false))}
            </div>
          )}
          {(product === PRODUCT_UPGRADES.EXTRA_VEHICLE) && state.vehicleDetails && state.saved && !state.showManualEntry && (
            <div className="col-md-12 fs-6 din-font">
              <div className="fw-bold">{state.vehicleDetails?.Registration}</div>
              <div>{state.vehicleDetails?.Make}</div>
              <div>{state.vehicleDetails?.Model}</div>
              <div>{state.vehicleDetails?.YearOfManufacture}</div>
              <div onClick={() => setState((prev) => ({ ...prev, showManualEntry: true, isEdit: true }))} aria-hidden="true" className={`${bem('info-link')} pt-3 m-0`}>
                Edit vehicle
              </div>
            </div>
          )}
          {/* Find vehicle form */}
          {state.showSearch && !state.showManualEntry && (
            <form noValidate name="vehicleRegSearch" onSubmit={findVehicle} className="rac-form">
              <div className="row">
                <div className={`${isModal ? 'w-100' : 'col-sm-5'}`}>
                  <input type="text" name="regNo" className="form-control p-2" placeholder="Vehicle registration" required />
                  {errors.invalidReg && !state.loading && (
                    <div className="mt-1 px-1 red">
                      {errors.errorText}
                    </div>
                  )}
                </div>
                {!isModal && (
                  <div className="col-sm-4">
                    <button
                      type="submit"
                      className={`${bem('find')} ${bem('button')} ${bem('darkgrey')}`}
                    >
                      {state.loading ? <Spinner buttonSpinner /> : <><i className="fas fa-search" /><span className="px-3">{getMessage(generalMessages.FIND_BUTTON_TEXT)}</span></>}
                    </button>
                  </div>
                )}
              </div>
              {isModal && (
                <div className="row">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn-secondary rounded p-2 mt-3 w-100"
                    >
                      {state.loading ? <Spinner buttonSpinner /> : getMessage(generalMessages.FIND_BUTTON_TEXT)}
                    </button>
                  </div>
                </div>
              )}
              {isModal && (isEdit(true))}
            </form>
          )}
          {/* Manual entry vehicle form */}
          {state.showManualEntry && (
            <form noValidate name="vehicleSaveEditForm" onSubmit={saveVehicle} className="rac-form">
              <div className="row p-1">
                <div className={`${isModal ? 'col-sm-6' : 'col-sm-4'}`}>
                  <input onChange={handleInputChange} type="text" name="Registration" className="form-control p-2 mb-1" value={state.vehicleDetails?.Registration ?? ''} placeholder="Vehicle registration" maxLength="8" required />
                </div>
                <div className={`${isModal ? 'col-sm-6' : 'col-sm-4'}`}>
                  <input onKeyPress={validateNumericEntry} onChange={handleInputChange} type="text" name="YearOfManufacture" className="form-control p-2" value={state.vehicleDetails?.YearOfManufacture ?? ''} placeholder="Vehicle year" maxLength="4" required />
                </div>
              </div>
              <div className="row p-1">
                <div className={`${isModal ? 'col-sm-6' : 'col-sm-4'}`}>
                  <input onChange={handleInputChange} type="text" name="Make" className="form-control p-2 mb-1" value={state.vehicleDetails?.Make ?? ''} placeholder="Vehicle make" required />
                </div>
                <div className={`${isModal ? 'col-sm-6' : 'col-sm-4'}`}>
                  <input onChange={handleInputChange} type="text" name="Model" className="form-control p-2" value={state.vehicleDetails?.Model ?? ''} placeholder="Vehicle model" required />
                </div>
                {!isModal && (
                  <div className="col-sm-4">
                    <button
                      type="submit"
                      className={`${bem('save')} ${bem('button')} ${bem('darkgrey')}`}
                    >
                      {state.loading ? <Spinner buttonSpinner /> : getMessage(generalMessages.SAVE_BUTTON_TEXT)}
                    </button>
                  </div>
                )}
              </div>
              {errors.formError && !state.loading && (
                <div className="row">
                  <div className="col mt-1 px-3 red">
                    {errors.errorText}
                  </div>
                </div>
              )}
              {isModal && (
                <div className="row">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn-secondary rounded p-2 mt-3 w-100"
                    >
                      {state.loading ? <Spinner buttonSpinner /> : getMessage(generalMessages.UPDATE_BUTTON_TEXT)}
                    </button>
                  </div>
                </div>
              )}
            </form>
          )}
        </div>
      </div>
      {((!policyVehicle && !state.saved && state.showManualEntry) || (product === PRODUCT_UPGRADES.EXTRA_VEHICLE && !state.saved && state.showManualEntry)) && (
        <div onClick={switchForms} aria-hidden="true" className={`${bem('info-link')}`}>
          Find vehicle details automatically
        </div>
      )}
      {((!policyVehicle && !state.saved && state.showSearch) || (product === PRODUCT_UPGRADES.EXTRA_VEHICLE && !state.saved && state.showSearch)) && (
        <div onClick={switchForms} aria-hidden="true" className={`${bem('info-link')}`}>
          Enter details manually
        </div>
      )}
    </>
  );
};

export default VehicleForm;
