import { ADD_PAGE, ADD_TILES, ADD_FLAG_IS_APP_VIEW } from '../constants/contentConstants';

const content = (
  // eslint-disable-next-line default-param-last
  state = {
    pages: [],
    tiles: {},
  },
  action,
) => {
  const { pages } = state;
  const findPageByUid = () => {
    // Get all uid values in state.pages array
    const uidArray = pages.map((page) => {
      if (page && page.uid) {
        return page.uid;
      }
      return null;
    });
    // Check if action value already exists to avoid duplicates being added
    const match = uidArray.includes(action.id);
    return match;
  };
  switch (action.type) {
    case ADD_PAGE:
      // eslint-disable-next-line no-case-declarations
      const isAdded = findPageByUid();
      if (!pages.length || !isAdded) {
        // Push page object to state pages array on initial population or when new content found where uid does not already exist in store
        state.pages.push(action.page);
      }
      return isAdded
        ? state
        : {
          ...state,
          pages: [...state.pages],
        };
    case ADD_TILES:
      return {
        ...state,
        tiles: { ...state.tiles, [action.tileSet]: [...action.payload] },
      };
    case ADD_FLAG_IS_APP_VIEW:
      return {
        ...state,
        isAppView: action.payload,
      };
    default:
      return state;
  }
};

export default content;
