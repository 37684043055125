import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-css-modules';
import Loader from 'react-loader-spinner';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format, differenceInCalendarDays } from 'date-fns';

import trackEvent from 'services/AnalyticsService';
import styles from './MembershipReminders.module.scss';
import Button from '../../../../../shared/components/Button/Button';
import useViewport from '../../../../../utils/useViewportHelper';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const {
  string,
  bool,
  shape,
  number,
  arrayOf, // Get prop types
} = PropTypes;

const bem = block(styles);

export const MembershipReminders = (props) => {
  const { pageModel, vehicles } = props;
  const viewportHelper = useViewport();
  const vehicleReminders = vehicles.vehicleInfo.upcomingReminders;
  const navigate = useNavigate();
  const noVehicleMessage = vehicles.vehicleInfo.hasError ? pageModel.tileContent.failureMessage : pageModel.tileContent.noRemindersText;

  const seeReminders = () => {
    navigate(pageModel.tileContent.seeAllRemindersButton.button.callToAction.url);
  };

  const setReminders = () => {
    navigate(pageModel.tileContent.setReminderButton.button.callToAction.url);
    trackEvent('MyRAC_Overview_set_reminders');
  };

  const getWidgetStyle = () => {
    const widgetStyles = {};
    if (viewportHelper.isBootstrapSm) {
      if (pageModel.backgroundImageMobile && pageModel.backgroundImageMobile.url) {
        widgetStyles.backgroundImage = `url(${pageModel.backgroundImageMobile.url})`;
        widgetStyles.backgroundSize = 'cover';
      }
      if (pageModel.backgroundColourMobile) {
        widgetStyles.backgroundColor = `${pageModel.backgroundColourMobile}`;
      }
    }

    if (!viewportHelper.isBootstrapSm) {
      if (pageModel.backgroundImageDesktop && pageModel.backgroundImageDesktop.url) {
        widgetStyles.backgroundImage = `url(${pageModel.backgroundImageDesktop.url})`;
        widgetStyles.backgroundSize = 'cover';
      }
      if (pageModel.backgroundColourDesktop) {
        widgetStyles.backgroundColor = `${pageModel.backgroundColourDesktop}`;
      }
    }

    return widgetStyles;
  };

  const getButtonStyle = (buttonVars) => {
    const buttonStyles = {
      backgroundColor: buttonVars.buttonColour,
      color: buttonVars.textColour,
    };

    if (buttonVars.isBackgroundColorTransparent) {
      buttonStyles.backgroundColor = 'transparent';
      buttonStyles.border = `1px solid ${buttonVars.buttonColour}`;
    }

    return buttonStyles;
  };

  const getExpireText = (reminder) => {
    const daysLeft = differenceInCalendarDays(new Date(reminder.date), new Date());
    if (daysLeft < 0) {
      return { text: 'Expired on', warning: true };
    }
    if (daysLeft < 30) {
      return { text: 'Due', warning: true };
    }
    return { text: 'Due', warning: false };
  };

  const getVehicleInfoFromId = (vehicleId, reminderVehicles) => reminderVehicles.find((vehicle) => vehicle.vehicleId === vehicleId);

  const orderReminders = (remindersArray) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    remindersArray.sort((a, b) => {
      const aDiff = differenceInCalendarDays(new Date(a.date), new Date());
      const bDiff = differenceInCalendarDays(new Date(b.date), new Date());

      if (aDiff < bDiff) {
        return -1;
      }

      if (aDiff > bDiff) {
        return 1;
      }

      if (aDiff === bDiff) {
        return getVehicleInfoFromId(a.vehicleId, vehicles.vehicleInfo.reminderVehicles).Registration.localeCompare(
          getVehicleInfoFromId(b.vehicleId, vehicles.vehicleInfo.reminderVehicles).Registration,
        );
      }

      return 0;
    });

  const getReminders = (reminders) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    orderReminders(reminders)
      .slice(0, 3)
      .map((reminder) => (
        <div className={bem('reminder')} key={uniqid()}>
          <div>
            <strong>{reminder.reminderType}</strong>
            &nbsp;for&nbsp;
            <strong>{getVehicleInfoFromId(reminder.vehicleId, vehicles.vehicleInfo.reminderVehicles).Registration}</strong>
          </div>
          <div className={getExpireText(reminder).warning ? bem('date', { warning: true }) : bem('date')}>
            {getExpireText(reminder).text}
            &nbsp;
            <strong>{format(new Date(reminder.date), 'dd/MM/yyyy')}</strong>
          </div>
        </div>
      ));

  const getLoading = () => (
    <div className={bem('loader')}>
      <Loader type="Oval" color="#000000" height={50} width={50} />
      <span>{pageModel.tileContent.progressiveLoadingText}</span>
    </div>
  );

  const getNoReminders = () => (
    <div className={bem('reminder')}>
      <strong>{noVehicleMessage}</strong>
    </div>
  );

  const getTileData = () => (
    <>
      <div className={bem('content-container')}>
        <h2 className={bem('title')}>{pageModel.tileContent.reminderHeadings}</h2>
        <div className={bem('reminders')}>
          {vehicleReminders && vehicleReminders.length ? getReminders(vehicles.vehicleInfo.upcomingReminders, vehicles.reminderVehicles) : getNoReminders()}
        </div>
      </div>
      <div className={bem('button-container')}>
        {vehicleReminders && vehicleReminders.length ? (
          <Button
            buttonText={pageModel.tileContent.seeAllRemindersButton.button.buttonText}
            onClickHandler={seeReminders}
            chevronBlack
            ghostButton
            outlineBlack={viewportHelper.isBootstrapSm}
            colorBlack
            small
            buttonStyles={!viewportHelper.isBootstrapSm ? getButtonStyle(pageModel.tileContent.seeAllRemindersButton.button) : null}
          />
        ) : (
          <Button
            buttonText={pageModel.tileContent.setReminderButton.button.buttonText}
            onClickHandler={setReminders}
            chevronBlack
            ghostButton
            outlineBlack={viewportHelper.isBootstrapSm}
            colorBlack
            small
            buttonStyles={!viewportHelper.isBootstrapSm ? getButtonStyle(pageModel.tileContent.setReminderButton.button) : null}
          />
        )}
      </div>
    </>
  );

  return (
    <section className={bem()} style={getWidgetStyle(pageModel)}>
      {Object.keys(vehicles.vehicleInfo).length ? getTileData() : getLoading()}
    </section>
  );
};

MembershipReminders.propTypes = {
  pageModel: shape({
    content_Type_UID: string,
    tags: string,
    backgroundColourDesktop: string,
    backgroundImageDesktop: shape({
      url: string,
      fileName: string,
      title: string,
    }),
    backgroundColourMobile: string,
    backgroundImageMobile: shape({
      url: string,
      fileName: string,
      title: string,
    }),
    rows: number,
    columns: number,
    isCollapsible: bool,
    isCollapsed: bool,
    tileContent: shape({
      reminderHeadings: string,
      noRemindersText: string,
      seeAllRemindersButton: shape({
        button: shape({
          buttonColour: string,
          buttonText: string,
          textColour: string,
          isBackgroundColorTransparent: bool,
          callToAction: shape({
            url: string,
            actionType: string,
          }),
        }),
      }),
      setReminderButton: shape({
        button: shape({
          buttonColour: string,
          buttonText: string,
          textColour: string,
          isBackgroundColorTransparent: bool,
          callToAction: shape({
            url: string,
            actionType: string,
          }),
        }),
      }),
      progressiveLoadingText: string,
      failureMessage: string,
      expiredOnText: string,
      dueText: string,
      collapsedHeading: string,
      expandedHeading: string,
      title: string,
      tags: string,
    }),
  }),
  vehicles: shape({
    vehicleInfo: shape({
      userId: string,
      membershipNumber: string,
      canAddVehicles: bool,
      upcomingReminders: arrayOf(
        shape({
          reminderType: string,
          date: string,
          vehicleId: number,
          emailReminderEnabled: bool,
          expiresIn: string,
        }),
      ),
      reminderVehicles: arrayOf(
        shape({
          vehicleId: number,
          name: string,
          image: string,
          reminders: arrayOf(
            shape({
              reminderType: string,
              date: string,
              vehicleId: number,
              emailReminderEnabled: bool,
              expiresIn: string,
            }),
          ),
          canEditVehicle: bool,
          canEditVehicleAdditionalInfo: bool,
          canDeleteVehicle: bool,
          isDeleted: bool,
          isOnPolicy: bool,
          coverState: string,
          Registration: string,
          Make: string,
          Model: string,
          YearOfManufacture: string,
          Transmission: string,
          FuelType: string,
        }),
      ),
    }),
  }),
};

MembershipReminders.defaultProps = {
  pageModel: {
    content_Type_UID: '',
    tags: null,
    backgroundColourDesktop: '',
    backgroundImageDesktop: {
      url: '',
      fileName: '',
      title: '',
    },
    backgroundColourMobile: '',
    backgroundImageMobile: {
      url: '',
      fileName: '',
      title: '',
    },
    rows: undefined,
    columns: undefined,
    isCollapsible: undefined,
    isCollapsed: undefined,
    tileContent: {
      reminderHeadings: '',
      noRemindersText: '',
      seeAllRemindersButton: {
        button: {
          buttonColour: '',
          buttonText: '',
          textColour: '',
          isBackgroundColorTransparent: undefined,
          callToAction: {
            url: '',
            actionType: '',
          },
        },
      },
      setReminderButton: {
        button: {
          buttonColour: '',
          buttonText: '',
          textColour: '',
          isBackgroundColorTransparent: undefined,
          callToAction: {
            url: '',
            actionType: '',
          },
        },
      },
      progressiveLoadingText: '',
      failureMessage: '',
      expiredOnText: '',
      dueText: '',
      collapsedHeading: '',
      expandedHeading: '',
      title: '',
      tags: undefined,
    },
  },
  vehicles: {
    vehicleInfo: {
      userId: '',
      membershipNumber: null,
      canAddVehicles: undefined,
      upcomingReminders: [],
      reminderVehicles: [],
    },
  },
};

const mapStateToProps = (state) => ({
  vehicles: state.vehicles,
});

export default connect(mapStateToProps)(MembershipReminders);
