import React from 'react';
import block from 'bem-css-modules';
import useViewport from '../../utils/useViewportHelper';
import styles from '../../styles/infoBanner.module.scss';
import infoWhiteSvg from '../../assets/images/button/info-white.svg';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

const InfoBanner = (props) => {
  const { switchBannerDisplay, bannerMessage } = props;

  // Due to the overlaying bootstrap styling,
  // on Mobile, marginLeft assigned -15px to avoid unnecessary gap on the left-hand side of banner
  // Although there is no mobile for IE, this is done in the case of re-usability of the banner
  const bannerMobileStyle = {
    marginLeft: '-15px',
  };

  return (
    <div className={bem()} style={useViewport().isMobile ? bannerMobileStyle : null}>
      <div className={bem('banner-body')}>
        <div className={bem('banner-inner-body')}>
          <div className={bem('banner-message-container')}>
            <div className={bem('banner-icon-container')}>
              <img className={bem('banner-info-icon')} src={infoWhiteSvg} alt="info" />
            </div>
            <p className={bem('banner-message')}>{bannerMessage}</p>
          </div>
          <div className={bem('banner-close-button-container')}>
            <button type="button" className={bem('banner-button')} onClick={switchBannerDisplay}>
              <div className={bem('banner-close-button')}>x</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBanner;
