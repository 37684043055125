import React, { Component } from 'react';
import { connect } from 'react-redux';
import trackEvent from 'services/AnalyticsService';
import { UpdateDocumentPrefs } from 'services/MyRacService';
import * as CustomerActions from 'actions/customerInfoActions';
import { generalMessages, eFulfilmentMessages } from 'constants/cms/messageConstants';
import { getMessage } from 'services/MessageService';
import Spinner from 'components/Spinner';

class Efulfilment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      online: true,
      loading: false,
    };
  }

  changeDocumentPreference = (e, online) => {
    e.preventDefault();
    trackEvent(`myrac.efulfilmodal.${e.currentTarget.value}`);
    this.setState({
      online,
    });
  }

  handleError = () => {
    const { toggleHideEFulfilmentModal } = this.props;
    toggleHideEFulfilmentModal();
  }

  continue = async (e) => {
    const { customer: { custInfo } } = this.props;
    const { online } = this.state;

    e.preventDefault();
    const updateDocPrefBody = {
      isPaperLess: online,
    };

    this.setState({ loading: true });

    try {
      await UpdateDocumentPrefs(custInfo.Id, updateDocPrefBody);
      trackEvent(`myrac.efulfilmodal.${online ? 'online' : 'paper'}submit`);
      const customerInformation = { ...custInfo };
      customerInformation.isPaperLess = online;
      const { setCustomerInfo, toggleShowModal } = this.props;
      setCustomerInfo(customerInformation);
      this.setState({ loading: false });
      toggleShowModal(e);
    } catch (error) {
      this.setState({ loading: false });
      this.handleError();
    }
  }

  render() {
    const { online, loading } = this.state;
    let modalContent;

    if (loading) {
      modalContent = (
        <div className="modal-body mt-5 mb-5">
          <Spinner defaultSpinner />
        </div>
      );
    } else {
      modalContent = (
        <>
          <div className="modal-body mt-5">
            <h4 className="modal-title text-black">
              {getMessage(eFulfilmentMessages.PAPERLESS_HEADER)}
            </h4>
            <div>
              <img src="" alt="" />
              <div className="paperless-text">
                <p>{getMessage(eFulfilmentMessages.PAPERLESS_TEXT)}</p>
                <div className="myrac-doc__efulfilment--wrapper">
                  <label htmlFor="eFulfilmentOnline" className={online ? 'myrac-doc__efulfilment--radio selected selected: true' : 'myrac-doc__efulfilment--radio'}>
                    <input id="eFulfilmentOnline" name="eFulfilment" type="radio" onChange={(e) => this.changeDocumentPreference(e, true)} value="online" />
                    <span className="myrac-doc__efulfilment--radio-span">Online</span>
                  </label>
                  <label htmlFor="eFulfilmentPaper" className={!online ? 'myrac-doc__efulfilment--radio selected selected: true' : 'myrac-doc__efulfilment--radio'}>
                    <input id="eFulfilmentPaper" name="eFulfilment" type="radio" onChange={(e) => this.changeDocumentPreference(e, false)} value="paper" />
                    <span className="myrac-doc__efulfilment--radio-span">Paper</span>
                  </label>
                </div>
                <p className="paperless-env">
                  <img alt="paperless" src="https://racprdmyracsa.z16.web.core.windows.net/app_themes/components/pagetemplates/bs3master/assets/scripts/myrac/img/efulfilment/paperless.png" />
                  <span>{getMessage(eFulfilmentMessages.PAPERLESS_SUBTEXT)}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.continue}
              className="btn btn-cta btn-primary"
              data-dismiss="modal"
            >
              {getMessage(generalMessages.CONTINUE_BUTTON_LABEL)}
            </button>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="modal myrac fade in EfulfilledModal Modal__overflow" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              {modalContent}
            </div>
          </div>
        </div>
        <div
          className="modal-backdrop fade in Modal__block"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
});

const mapDispatchToProps = (dispatch) => ({

  setCustomerInfo: (customerInfo) => {
    dispatch(CustomerActions.setCustomerInfo(customerInfo));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Efulfilment);
