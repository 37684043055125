import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/components/Button/Button';
import trackEvent from '../services/AnalyticsService';
import { setTescoPopupShown } from '../actions/sessionActions';

const TescoPanel = (props) => {
  const { updateTescoPopupShown } = props;
  const navigate = useNavigate();

  const handleTescoRedirect = () => {
    trackEvent('MyRAC_Tesco_Renew_Clubcard');
    let url = window.location.origin;
    const { jid, featureFlags } = props;
    url = `${featureFlags.tescoRenewalAks ? process.env.REACT_APP_TESCO_AKS_LOGIN_REDIRECT_URL : process.env.REACT_APP_TESCO_LOGIN_REDIRECT_URL}?JID=${jid}${process.env.REACT_APP_TESCO_URL_QUERYSTRING}`;
    window.open(url, '_blank');
  };

  const toggleTescoPopup = () => {
    trackEvent('MyRAC_Tesco_Renew_Direct');
    // Store action in session so that popup is not displayed again until next user session
    updateTescoPopupShown(true);
    // Redirect to Renewal journey
    navigate('/renewal');
  };

  return (
    <div className="container">
      <div className="row">
        <p style={{ align: 'center' }}>You previously used your Tesco Clubcard vouchers to buy RAC Breakdown Cover. How would you like to renew today?</p>
        <div className="col-md-6 mb-3">
          <Button
            onClickHandler={() => handleTescoRedirect()}
            buttonText="Use Tesco Vouchers"
            buttonStyles={{ backgroundColor: '#0152A1' }}
          />
        </div>
        <div className="col-md-6">
          <Button
            onClickHandler={() => toggleTescoPopup()}
            buttonText="Renew Directly"
            chevronWhite
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  featureFlags: state.featureToggle,
});

const mapDispatchToProps = (dispatch) => ({
  updateTescoPopupShown: (tescoPopupShown) => {
    dispatch(setTescoPopupShown(tescoPopupShown));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TescoPanel);
