import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RenderLink from '../../../components/RenderLink';
import * as RouteConstants from '../../../constants/routeConstants';
import styles from './MyCover.module.scss';
import trackEvent from '../../../services/AnalyticsService';
import CollapsibleSection from './CollapsibleSection';

const WhoIsCovered = ({ policy, extraPersonUpgradeAllowed }) => {
  const items = useMemo(() => {
    if (!policy?.policyInfo?.Members) return [];

    return policy?.policyInfo?.Members?.map((item) => ({
      fullName: [
        item.Title,
        item.FirstName,
        item.Surname,
        item.LeadMember === true ? '(You)' : '',
      ].join(' ').trim(),
    }));
  }, [policy]);
  const navigate = useNavigate();

  const translation = {
    title: 'Who\'s covered',
    description: 'The people listed below are covered as a driver or passenger in any vehicle.',
    actions: {
      add: 'Add a person',
      edit: 'Edit',
    },
  };

  const canEdit = policy?.policyInfo?.CanEditAdditionalMembers === true;
  let ctaButtonLabel = translation.actions.add;
  let ctaDisabled = false;

  if (!extraPersonUpgradeAllowed) {
    ctaDisabled = true;
    ctaButtonLabel = undefined;
  }

  const onAdd = useCallback(() => {
    if (!extraPersonUpgradeAllowed) { return; }

    trackEvent('MyRAC_MyCover_WhosCoveredAdd');
    navigate({
      pathname: RouteConstants.PRODUCT_UPGRADE_PAGE,
      search: '?product=Extra Person',
    });
  }, [navigate, extraPersonUpgradeAllowed]);

  return (
    <CollapsibleSection title={translation.title}>
      <p className="my-3">{translation.description}</p>
      <div className="clearfix flex-column my-2">
        {items.map((item) => (
          <div key={item.fullName} className="item d-flex justify-content-between py-2">
            <div>
              <strong>{item.fullName}</strong>
            </div>
            {canEdit && (
              <RenderLink
                url={RouteConstants.PERSONAL_DETAILS}
                className={styles.actionLink}
                analytics="MyRAC_MyCover_WhosCoveredEdit"
              >
                {translation.actions.edit}
              </RenderLink>
            )}
          </div>
        ))}
      </div>
      {ctaButtonLabel && (
        <button
          className={`${styles.outlineButton} my-1 px-4`}
          onClick={onAdd}
          type="button"
          disabled={ctaDisabled}
        >
          {ctaButtonLabel}
        </button>
      )}
    </CollapsibleSection>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
});

export default connect(mapStateToProps)(WhoIsCovered);
