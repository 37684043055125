import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import block from 'bem-css-modules';
import { loggerFunc } from 'utils/logger';
import Loader from 'react-loader-spinner';
import ctaActions from '../../../../utils/ctaActions.enum';
import styles from './ManagedCta.module.scss';
import CtaPositioning from '../../../../utils/ctaPositioning.enum';
import { GetTescoLoginJID } from '../../../../services/MyRacService';
import { getClickAction } from './ManagedCta';
import * as SessionActions from '../../../../actions/sessionActions';

const logger = loggerFunc('TileButton');

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(styles);

export const TileButton = (props) => {
  const {
    ctaData, ctaPosition, analyticsTag, session, customer, setTescoJID,
  } = props;

  const isTescoJourney = ctaData.callToAction.actionType === ctaActions.tesco;

  useEffect(() => {
    if (isTescoJourney && customer && session) {
      GetTescoLoginJID(customer?.custInfo.Id, session.memberNo).then((body) => {
        setTescoJID(body.payload);
      }).catch((error) => logger.error(error));
    }
  }, []);

  const getButtonStyle = (buttonProperty) => ({
    backgroundColor: buttonProperty.buttonColour,
    color: buttonProperty.textColour,
  });

  const applyCTAPosition = () => {
    switch (ctaPosition) {
      case (CtaPositioning.bottomLeft): return ('bottom-left');
      case (CtaPositioning.bottomCenter): return ('bottom-center');
      default: return 'center-center';
    }
  };

  const getButton = () => (
    <button
      type="button"
      className={`${bem()} ${bem(applyCTAPosition())}`}
      onClick={(params) => getClickAction(ctaData.callToAction.actionType, {
        ...params, analyticsTag, url: ctaData.callToAction.url,
      })}
      style={getButtonStyle(ctaData)}
    >
      {ctaData.buttonText}
    </button>
  );

  const getLoader = () => (
    <div className={`${bem('loader')} ${bem(applyCTAPosition())}`}>
      <Loader type="Oval" color="#ffffff" height={40} width={40} />
    </div>
  );

  return (
    isTescoJourney && !session?.jidTesco ? getLoader() : getButton()
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTescoJID: (jidTesco) => {
    dispatch(SessionActions.setTescoJID(jidTesco));
  },
});

const mapStateToProps = (state) => ({
  session: state.session,
  customer: state.customer,
  featureFlags: state.featureToggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(TileButton);
