import React from 'react';
import block from 'bem-css-modules';
import { connect } from 'react-redux';
import {
  format, isValid, isSameDay, isAfter,
} from 'date-fns';

import coverStatus from '../../../../../utils/coverStatusEnum';
import * as coverStatusConstant from '../../../../../constants/coverStatusConstants';
import styles from './CoverDates.module.scss';
import memberSinceDateDisplay from '../../../../../utils/date';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

export const CoverDates = (props) => {
  const { coverDateContent, policy: { policyInfo } } = props;

  // New changes applies that there is a difference between monthly payment type based on if policy is LTP or not
  const isNonLTPMonthly = policyInfo.RenewalType === 'Monthly Continuous' && !policyInfo.isLongerTermPolicy;

  const formatDate = (date) => (isValid(new Date(date)) ? format(new Date(date), 'dd.MM.yy') : null);

  const coverStartDate = formatDate(policyInfo.CoverStart);
  const cancellationDate = formatDate(policyInfo.CancellationDate);

  // As there is a default BackEnd cancellation date of 0001-01-01T00:00:00, additional check if cancellation date is today's date or a future day from today, used in Live status where cancellation date exist
  const isPostDatedCancellation = isSameDay(new Date(policyInfo.CancellationDate), new Date()) || isAfter(new Date(policyInfo.CancellationDate), new Date());

  // Cancellation pending has a Drive Cover Status of Live, check if cancellation date is postDated thus status is cancellation pending else status is Live(active). Note there is a need to differentiate live status with single annual as date displayed is renewal date
  const isCancellationPending = (status) => {
    if (status && isPostDatedCancellation) {
      return coverStatusConstant.CANCELLATION_PENDING;
    }

    if (status && isNonLTPMonthly) {
      return coverStatusConstant.ACTIVE_MONTHLY_CONTINUOUS_NON_LTP;
    }

    return coverStatusConstant.ACTIVE;
  };

  const determineCoverStatus = (status) => {
    switch (status) {
      case (coverStatus.cancelled): return coverStatusConstant.CANCELLED;
      case (coverStatus.active || coverStatus.reinstated): return isCancellationPending(status);
      case (coverStatus.lapsed): return coverStatusConstant.LAPSED;
      case (coverStatus.starting): return coverStatusConstant.CONFIRMED;
      case (coverStatus.expired): return coverStatusConstant.EXPIRED;
      default: return null;
    }
  };

  // Cancel, cancelled pending, lapsed, expired to inherit color used in the status as per UI design
  const setSecondFieldLabelColor = (policy) => {
    const currentPolicy = determineCoverStatus(policy);
    if (
      currentPolicy === coverStatusConstant.CANCELLATION_PENDING
        || currentPolicy === coverStatusConstant.CANCELLED
        || currentPolicy === coverStatusConstant.LAPSED
        || currentPolicy === coverStatusConstant.EXPIRED) {
      return coverDateContent.coverStatus.statusColour;
    }
    return null;
  };

  const isMemberSince = isAfter(new Date(policyInfo?.MemberSince), new Date('1900-01-01T23:59:59'));

  const setSecondFieldLabel = (policy) => {
    const currentPolicy = determineCoverStatus(policy);
    switch (currentPolicy) {
      case (coverStatusConstant.EXPIRED):
      case (coverStatusConstant.LAPSED): return 'Expiration date';
      case (coverStatusConstant.CANCELLATION_PENDING):
      case (coverStatusConstant.CANCELLED): return 'Cancellation date';
      default: return isMemberSince ? coverDateContent.secondFieldCoverDatesLabel : 'Renewal date';
    }
  };

  const setSecondFieldDate = (policy) => {
    switch (determineCoverStatus(policy)) {
      case (coverStatusConstant.EXPIRED):
      case (coverStatusConstant.LAPSED): return formatDate(policyInfo.CoverEnd);
      case (coverStatusConstant.CANCELLATION_PENDING):
      case (coverStatusConstant.CANCELLED): return cancellationDate;
      case (coverStatusConstant.CONFIRMED):
      case (coverStatusConstant.ACTIVE): return formatDate(policyInfo.RenewalDate);
      case (coverStatusConstant.ACTIVE_MONTHLY_CONTINUOUS_NON_LTP): return isMemberSince ? memberSinceDateDisplay(policyInfo?.MemberSince) : formatDate(policyInfo?.RenewalDate);
      default: return null;
    }
  };

  return (
    <div className={bem()}>
      <div>
        <div className={bem('first-label')}>
          {coverDateContent.firstFieldCoverDatesLabel}
        </div>
        <div className={bem('start-date')}>
          {coverStartDate}
        </div>
      </div>
      <div>
        <div className={bem('second-label')} style={{ color: setSecondFieldLabelColor(policyInfo.CoverStatus) }}>
          {setSecondFieldLabel(policyInfo.CoverStatus)}
        </div>
        <div className={bem('last-field-date')}>
          {setSecondFieldDate(policyInfo.CoverStatus)}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
});

export default connect(mapStateToProps)(CoverDates);
