import React from 'react';
import block from 'bem-css-modules';
import PropTypes from 'prop-types';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import trackEvent from 'services/AnalyticsService';
import styles from './Promotions.module.scss';
import appleStore from '../../../assets/images/landing/apple_store.svg';
import androidStore from '../../../assets/images/landing/android_store.svg';

const { string, bool, shape } = PropTypes;

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

const Promotions = ({ fields }) => {
  const displayAppStoreLinks = () => (fields.showapplinks ? (
    <div className={bem('app-store-links')}>
      <a href="https://apps.apple.com/gb/app/myrac/id350330435" onClick={() => trackEvent('MyRAC_Landingpromopanel1')}><img className={bem('app-store-link')} src={appleStore} alt="apple store" /></a>
      <a href="https://play.google.com/store/apps/details?id=uk.co.rac.roadside" onClick={() => trackEvent('MyRAC_Landingpromopanel1')}><img className={bem('app-store-link')} src={androidStore} alt="android store" /></a>
    </div>
  ) : null);

  return (
    <section className={bem()} id={fields.uid}>
      <div className={bem('box')}>
        <div className={bem('wrapper')}>
          <div className={bem('inner-wrapper', { mobile: true })}>
            <a
              href={fields.rightimageurllink.href}
              onClick={() => trackEvent('MyRAC_Landingpromopanel1')}
            >
              <img className={bem('image')} src={fields.rightimageurl.url} alt="promo" />
            </a>
          </div>
          <div className={bem('inner-wrapper')}>
            <h2 className={bem('heading')}>{fields.title}</h2>
            <div className={bem('body-text')}>{ReactHtmlParser(fields.body)}</div>
            {displayAppStoreLinks()}
          </div>
          <div className={bem('inner-wrapper', { desktop: true })}>
            <a
              href={fields.rightimageurllink.href}
              onClick={() => trackEvent('MyRAC_Landingpromopanel1')}
              target={fields.rightimageurllink.openinnewwindow ? '_blank' : undefined}
              title={fields.rightimageurllink.title}
              rel="noreferrer"
            >
              <img className={bem('image')} src={fields.rightimageurl.url} alt={fields.rightimageurllink.alttext} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

Promotions.propTypes = {
  title: string,
  body: string,
  rightimageurl: shape({
    url: string,
  }),
  rightimageurllink: shape({
    href: string,
    alttext: string,
    openinnewwindow: bool,
    title: string,
  }),
  uid: string,
};

Promotions.defaultProps = {
  title: '',
  body: '',
  rightimageurl: {
    url: '',
  },
  rightimageurllink: {
    href: '',
    alttext: '',
    openinnewwindow: true,
    title: '',
  },
  uid: '',
};

export default Promotions;
