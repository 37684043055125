import JWTDecode from 'jwt-decode';
import { dispatch, getState } from 'store';
import { differenceInSeconds, fromUnixTime, differenceInMilliseconds } from 'date-fns';

import * as SessionActions from 'actions/sessionActions';
import ResetStore from 'actions/appActions';
import GigyaClient from 'auth/GigyaClient';
import { loggerFunc } from 'utils/logger';

import tokenStatus from '../constants/tokenStatus';

const logger = loggerFunc('UserManager');

const getSession = () => {
  const state = getState();
  if (state && state.session) {
    return state.session;
  }
  return false;
};

const getToken = () => {
  const session = getSession();
  return session ? session.jwtToken : undefined;
};

export const getSessionExpirationTimeInMilliseconds = () => {
  const noTimeLeft = 0;
  const token = getToken();
  if (token) {
    const decoded = JWTDecode(token);
    if (decoded.exp) {
      const expiryTime = fromUnixTime(new Date(decoded.exp));
      const millisecondsLeft = differenceInMilliseconds(expiryTime, new Date());
      return millisecondsLeft;
    }
  }
  return noTimeLeft;
};

export const populateNewSessionToken = () => {
  const client = new GigyaClient();
  return new Promise((resolve, reject) => {
    client.getJWT((updatedJwtResponse) => {
      if (updatedJwtResponse.status === 'OK') {
        if (updatedJwtResponse.id_token != null) {
          dispatch(SessionActions.setJwtToken(updatedJwtResponse.id_token));
          resolve();
        } else {
          const err = new Error('No JWT token found');
          logger.error(err);
          reject(err);
        }
      } else {
        const err = {
          errorCode: updatedJwtResponse.errorCode,
          errorMessage: updatedJwtResponse.errorMessage,
        };
        reject(err);
      }
    }, process.env.REACT_APP_SESSION_TIME_EXTENSION_IN_SECONDS);
  });
};

export const logoutUser = (logoutSuccessCallback, logoutErrorCallback) => {
  const client = new GigyaClient();
  client.logout((e) => {
    if (e.status === 'OK') {
      dispatch(ResetStore());
      sessionStorage.clear();
      logoutSuccessCallback();
    } else {
      logoutErrorCallback(e);
    }
  });
};

export const hasValidToken = () => {
  const token = getToken();
  if (token) {
    const decoded = JWTDecode(token);
    if (decoded.exp) {
      const expiryTime = fromUnixTime(new Date(decoded.exp));
      const secondsLeft = differenceInSeconds(expiryTime, new Date());
      if (secondsLeft <= 0) {
        logger.debug('Session expired');
        return tokenStatus.isInvalid;
      }
      if (secondsLeft <= process.env.REACT_APP_SESSION_TIME_NEW_TOKEN_THRESHOLD && secondsLeft > 0) {
        return tokenStatus.requiresNewToken;
      }
      return tokenStatus.isValid;
    }
  }
  return tokenStatus.isInvalid;
};
