export const COVER_STATES = {
  NONE: 'None',
  IS_COVERED_BY_BREAKDOWN: 'IsCoveredByBreakdown',
  IS_COVERED_FOR_REGISTERED_USERS: 'IsCoveredForRegisteredUsers',
  IS_HYBRID_COVERED_BY_BREAKDOWN: 'IsHybridCoveredByBreakDown',
  IS_HYBRID_COVERED_FOR_REGISTERED_USERS: 'IsHybridCoveredForRegisteredUsers',
  IS_MEMBERSHIP_ONLY: 'IsMembershipOnly',
  IS_PAY_AS_YOU_GO_COVERED: 'IsPayAsYouGoCovered',
  IS_PAY_AS_YOU_GO_NOT_COVERED: 'IsPayAsYouGoNotCovered',
  IS_NOT_ACTIVE_BREAKDOWN: 'IsNotActiveBreakdown',
  IS_NOT_ACTIVE_REGISTERED_USER: 'IsNotActiveRegisteredUser',
  IS_NOT_ACTIVE: 'IsNotActive',
};

export const PRODUCT_UPGRADES = {
  TYRE_REPLACE: 'Tyre Replace',
  BATTERY_REPLACE: 'Battery Replace',
  EXTRA_PERSON: 'Extra Person',
  EXTRA_VEHICLE: 'Extra Vehicle',
};
