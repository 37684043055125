import React, { useState, useEffect } from 'react';
import block from 'bem-css-modules';
import { connect } from 'react-redux';
import styles from './MembershipCard.module.scss';
import { getCoverStatusMeta, formatDate } from './utils';
import * as coverStatusConstant from '../../../constants/coverStatusConstants';
import whiteLogo from '../../../assets/images/rac-white.svg';
import membershipStatus from '../../../constants/membershipType';
import {
  GetPolicyStatus,
  GetPolicyDetails,
  GetPolicyRenewalDetails,
} from '../../../services/MyRacService';
import Spinner from '../../../components/Spinner';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(styles);

const MembershipCard = ({ session, cover }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetchMembershipCardData = async () => {
    const [policyStatusRaw, policyDetailsRaw, policyRenewalDetailsRaw] = await Promise.all([
      GetPolicyStatus(),
      GetPolicyDetails(),
      GetPolicyRenewalDetails(),
    ]);

    setData({
      policyStatus: policyStatusRaw?.payload,
      policyDetails: policyDetailsRaw?.payload,
      policyRenewalDetails: policyRenewalDetailsRaw?.payload,
    });
  };

  const meta = data ? getCoverStatusMeta(
    data?.policyStatus,
    data?.policyDetails,
    data?.policyRenewalDetails,
  ) : null;

  const isGreyedOut = data ? meta.status === coverStatusConstant.EXPIRED
  || meta.status === coverStatusConstant.LAPSED
  || meta.status === coverStatusConstant.CANCELLED : null;

  const coverTypeLabelMap = {
    [membershipStatus.hybrid]: 'Hybrid cover',
    [membershipStatus.personal]: 'Personal cover',
    [membershipStatus.vehicle]: 'Vehicle cover',
  };

  let dateCol = null;
  let coverTypeLabel = null;

  if (data) {
    coverTypeLabel = coverTypeLabelMap[cover.type] || 'Cover';

    switch (meta.status) {
      case (coverStatusConstant.EXPIRED):
      case (coverStatusConstant.LAPSED):
        dateCol = {
          label: 'Expiration date',
          value: formatDate(data.policyStatus?.coverEnd),
        };
        break;
      case (coverStatusConstant.CANCELLATION_PENDING):
      case (coverStatusConstant.CANCELLED):
        dateCol = {
          label: 'Cancellation date',
          value: formatDate(data.policyDetails?.cancellationDate),
        };
        break;
      case (coverStatusConstant.CONFIRMED):
      case (coverStatusConstant.ACTIVE):
        dateCol = {
          label: 'Renewal date',
          value: formatDate(data.policyRenewalDetails?.renewalDate),
        };
        break;
      case (coverStatusConstant.ACTIVE_MONTHLY_CONTINUOUS_NON_LTP):
        dateCol = {
          label: 'Monthly renewal date',
          value: formatDate(data.policyRenewalDetails?.renewalDate),
        };
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (data) {
      setLoading(false);
    } else {
      fetchMembershipCardData();
    }
  }, [data]);

  return loading && !data ? <Spinner spinnerMsg="Loading policy information" defaultSpinner />
    : (
      <div className={`${bem('card')} ${isGreyedOut ? bem('card-expired') : ''} d-flex flex-column p-3 justify-content-between`}>
        <img src={whiteLogo} alt="RAC" className={bem('image')} />
        <div>
          <h3 className={bem('title')}>{coverTypeLabel}</h3>
          <div className={bem('subtitle')}>{data?.policyDetails?.coverTypeTitle}</div>
          <div className="d-flex align-items-stretch">
            <div className="flex-fill">
              <div className={bem('listing-title')}>Membership number</div>
              <div className={bem('listing-content')}>{session?.memberNo}</div>
            </div>
            <div className="flex-fill">
              {dateCol && (
                <>
                  <div className={bem('listing-title')}>{dateCol.label}</div>
                  <div className={bem('listing-content')}>{dateCol.value}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(MembershipCard);
