const renwalWindowTimeFrame = 30;

/**
 * Function that determines whether the user is in their 30-day renewal window.
 * @param    {Number} daysLeftOfCover  Number of days left of cover until expiration.
 * @return   {Boolean} True if user within 30-day renewal window.
 */
const isInRenewalPeriod = (daysLeftOfCover) => {
  if (daysLeftOfCover <= renwalWindowTimeFrame) {
    return true;
  }
  return false;
};

export default isInRenewalPeriod;
