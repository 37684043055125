import { SET_DOCUMENTS, SET_DOCUMENTS_ERROR } from '../constants/documentConstants';

const documentsReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    documents: {},
    documentError: { hasError: false, error: undefined },
  },
  action,
) => {
  switch (action.type) {
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };

    case SET_DOCUMENTS_ERROR: {
      return {
        ...state,
        documentError: { hasError: true, error: action.payload },
      };
    }
    default:
      return state;
  }
};

export default documentsReducer;
