import React from 'react';
import { connect } from 'react-redux';
import block from 'bem-css-modules';
import styles from './CoverBadge.module.scss';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

export const CoverBadge = (props) => {
  const { coverImage: { coverBadge } } = props;

  return (
    <div className={bem()}>
      <img className={bem('badge')} src={coverBadge?.url} alt={coverBadge?.title} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
});

export default connect(mapStateToProps)(CoverBadge);
