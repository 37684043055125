export function setCustomerInfo(customerInfo) {
  return {
    type: 'SET_CUSTOMERINFO',
    payload: customerInfo,
  };
}

export function setUpdatedPhoneNumber(phoneNumberPayload) {
  return {
    type: 'UPDATE_PHONENUMBER',
    payload: phoneNumberPayload,
  };
}

export function setUpdatedAddress(addressPayload) {
  return {
    type: 'UPDATE_ADDRESS',
    payload: addressPayload,
  };
}

export function setUpdateDob(dob) {
  return {
    type: 'UPDATE_DOB',
    payload: dob,
  };
}

export function setCommsPrefs(preferences) {
  return {
    type: 'SET_COMMS_PREFS',
    payload: preferences,
  };
}

export function invalidateCustomerInfo() {
  return {
    type: 'INVALIDATE_CUSTOMERINFO',
    payload: '',
  };
}

export function custInfoHasError(hasError) {
  return {
    type: 'CUSTINFO_HAS_ERROR',
    payload: hasError,
  };
}
