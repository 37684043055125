import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import trackEvent from '../../../services/AnalyticsService';
import styles from './ProductUpgradeJourney.module.scss';
import * as SessionActions from '../../../actions/sessionActions';
import PopupModal from '../../../components/PopupModal/PopupModal';
import ProductUpgradeModal from './ProductUpgradeModal/ProductUpgradeModal';
import Spinner from '../../../components/Spinner';
import Button from '../../../shared/components/Button/Button';
import { getMessage } from '../../../services/MessageService';
import { generalMessages } from '../../../constants/cms/messageConstants';
import VehicleForm from '../../../components/VehicleForm/VehicleForm';
import ExtraPersonForm from './ExtraPersonForm';
import policyUpgrade from '../../../utils/policyUpgrade.enum';
import { isEmpty } from '../../../utils/utils_validation';
import { SaveProductUpgradeProduct } from '../../../services/MyRacService';
import { ResponseCode } from '../../../services/ContentService';
import { PRODUCT_UPGRADE_CONFIRMATION } from '../../../constants/routeConstants';
import { PRODUCT_UPGRADES } from '../../../constants/vehicleConstants';
import { useApplicationState } from '../../../hooks/applicationState-Context';

const ProductUpgradeJourney = (props) => {
  const {
    nextStep, setStepData, leaveJourney, pageModel, product, updateProductSelected, session, customer: { custInfo: { Id } }, policy,
  } = props;

  const [toggleError, setToggleError] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const termsAndConditionRef = useRef();

  const [loading, setLoading] = useState(false);
  const { setPageLoading } = useApplicationState();

  // Check if policy has a registered vehicle and return index array data
  const vehicles = policy?.policyInfo?.Vehicles?.length > 0 ? policy?.policyInfo?.Vehicles : null;
  // Set if no vehicles found on policy and vehicle form is used passing new vehicle details to this parent component
  const [newVehicle, setVehicle] = useState();
  const [isEdit, setIsEdit] = useState();

  // Extra Person form values object
  const [newPerson, setPerson] = useState();
  const personDetails = !!(newPerson?.personDetails && Object.values(newPerson?.personDetails).length === 4);

  const disabledButtonState = (product === PRODUCT_UPGRADES.TYRE_REPLACE && !vehicles && isEmpty(newVehicle))
    || (product === PRODUCT_UPGRADES.BATTERY_REPLACE && !vehicles && isEmpty(newVehicle))
    || (product === PRODUCT_UPGRADES.EXTRA_VEHICLE && isEmpty(newVehicle))
    || (product === PRODUCT_UPGRADES.EXTRA_PERSON && !personDetails);

  const isFormWithoutSavedVehicle = (product === PRODUCT_UPGRADES.TYRE_REPLACE && !vehicles && isEmpty(newVehicle))
    || (product === PRODUCT_UPGRADES.BATTERY_REPLACE && !vehicles && isEmpty(newVehicle))
    || (product === PRODUCT_UPGRADES.EXTRA_VEHICLE && isEmpty(newVehicle));

  useEffect(() => {
    if (pageModel && termsAndConditionRef) {
      setPageLoading(false);
      const [documentsLink] = termsAndConditionRef.current.getElementsByTagName('a');
      documentsLink.addEventListener('click', (event) => {
        setTimeout(() => {
          event.preventDefault();
        }, 1000);
        trackEvent(pageModel?.basket?.termsAndConditions?.analyticsTags);
      });
    }
  }, [pageModel, termsAndConditionRef]);

  useEffect(() => {
    trackEvent(pageModel?.analyticsTag);
  }, [pageModel?.analyticsTag]);

  useEffect(() => {
    // iOS workaround to ensure page does not load with focus on input elements
    window.scrollTo(0, 0);
  }, []);

  const displayErrorModal = () => (
    <PopupModal
      resultText={getMessage(generalMessages?.UPDATE_FAILURE_ERROR)}
      action={() => setToggleError(false)}
      modalOk={getMessage(generalMessages?.OK_BUTTON_TEXT)}
      innerCloseButton
      displayDialogAtTopQuarter
    />
  );

  const policyProductUpgrade = async (payload) => {
    const { memberNo } = session;
    const { isVsoe, price } = pageModel;

    // Tyre/Battery Replace & Extra Vehicle
    const policyVehicleReg = isVsoe && product !== PRODUCT_UPGRADES.EXTRA_VEHICLE ? policy?.policyInfo?.Vehicles[0]?.Registration : null;
    const vehicleRegistration = policyVehicleReg ?? payload?.vehicleRegistration;

    // Extra Person
    const extraPersonTitle = payload?.Title;
    const extraPersonFirstname = payload?.Firstname;
    const extraPersonSurname = payload?.Lastname;
    const extraPersonDateOfBirth = payload?.Dob;

    setLoading(true);

    // Annual Card Payment
    if (policy?.policyInfo?.AllowPolicyProductUpgrade === policyUpgrade.YesPayment) {
      // Pass props to parent journey manager
      setStepData({
        ...pageModel,
        vehicleRegistration,
        extraPersonTitle,
        extraPersonFirstname,
        extraPersonSurname,
        extraPersonDateOfBirth,
      });
      // Go to payment screen
      nextStep();
      setLoading(false);
    }
    // Monthly
    if (policy?.policyInfo?.AllowPolicyProductUpgrade === policyUpgrade.YesNoPayment) {
      const saveProductPayload = {
        customerId: Id,
        productName: product,
        membershipNumber: memberNo,
        price,
        vehicleRegistration,
        extraPersonTitle,
        extraPersonFirstname,
        extraPersonSurname,
        extraPersonDateOfBirth,
      };
      // API request with above payload
      try {
        const saveUpgradedProduct = await SaveProductUpgradeProduct(saveProductPayload);
        if (saveUpgradedProduct.responseCode === ResponseCode.SUCCESS && !saveUpgradedProduct.error) {
          updateProductSelected({ [product]: true });
          // Confirmation page
          leaveJourney(PRODUCT_UPGRADE_CONFIRMATION, {
            state: {
              isPaymentJourney: false,
              pageModel,
            },
            replace: true,
          });
        } else {
          setToggleError(true);
        }
      } catch (error) {
        if (error) {
          setToggleError(true);
        }
      }
      setLoading(false);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (product) {
      trackEvent(`MyRAC_Upgrade_${product.replaceAll(' ', '')}_CTA`);
    }
    if (product === PRODUCT_UPGRADES.EXTRA_PERSON) {
      // Extra Person
      if (personDetails) {
        // Send payload to POST request
        policyProductUpgrade(newPerson.personDetails);
      }
    } else {
      // Tyre/Battery Replace & Extra Vehicle
      const { isVsoe } = pageModel;
      let vehicleRegistration = null;
      if (isVsoe) {
        // User eligible so get vehicle already on policy OR the vehicle saved using form
        const vehicle = vehicles ?? newVehicle;
        // Extra vehicle form must not use pre-populated vehicle so use this boolean as override when setting vehicleRegistration
        const isExtraVehicle = product === PRODUCT_UPGRADES.EXTRA_VEHICLE;
        vehicleRegistration = isExtraVehicle ? newVehicle?.Registration : vehicle?.Registration;
      }
      // Send payload to POST request
      const payload = { vehicleRegistration };
      policyProductUpgrade(payload);
    }
  };

  const modalButton = (tellMeMore) => (
    <div className={styles.infoLink}>
      <i className={`${styles.icon} fa fa-info-circle`} />
      <span
        onClick={() => setDisplayModal(true)}
        aria-hidden="true"
        className="px-2"
      >
        {tellMeMore.buttonLabel}
      </span>
    </div>
  );

  // Tyre/Battery Replace & Extra Vehicle form
  const selectedVehicle = (policyVehicles, vehicleSectionDetails) => (
    <>
      <h3 className={styles.formTitle}>{vehicleSectionDetails?.selectedVehicleLabel}</h3>
      <VehicleForm product={product} isEdit={setIsEdit} onAddVehicle={setVehicle} policyVehicles={policyVehicles} />
    </>
  );

  // Extra Person form
  const addExtraPerson = () => (
    <>
      <h3 className={styles.formTitle}>Cover an extra person:</h3>
      <ExtraPersonForm onAddPerson={setPerson} />
      <p className="mt-3">Please note only one person can be added at a time</p>
    </>
  );

  // Check if modal is available to be displayed for this product
  const isModalAvailable = pageModel?.tellMeMore?.buttonLabel && pageModel?.tellMeMore?.modalTitle && pageModel?.tellMeMore?.modalBody;

  return !loading && pageModel ? (
    <>
      {/* Product */}
      <div className={styles.productTitle}>
        {ReactHtmlParser(pageModel?.productTitle)}
      </div>
      <div className="container p-0">
        <div className="row my-lg-4">
          {/* Product Photo */}
          <div className="col-sm-4">
            <img src={pageModel?.image?.url} className={`${styles.photoFrame} img-fluid`} alt="Product" />
          </div>
          {/* Product Description */}
          <div className="col-sm-8 p-3">
            {ReactHtmlParser(pageModel?.productDescription)}
            {isModalAvailable && modalButton(pageModel?.tellMeMore)}
          </div>
        </div>
      </div>
      {/* Form */}
      {pageModel?.isVsoe && selectedVehicle(vehicles, pageModel?.vehicleSectionDetails)}
      {product === PRODUCT_UPGRADES.EXTRA_PERSON && addExtraPerson()}
      {/* Basket */}
      <div className="container border border-3 border-orange my-lg-4">
        <div className="row">
          <div className="col-sm p-4">
            {pageModel?.price && (
              <div className={styles.basketPrice}>
                <h3>£{pageModel?.price?.toFixed(2)}</h3>
                <p className="fs-5">{pageModel?.basket?.paymentMethodText}</p>
              </div>
            )}
            <div className="fs-5">
              {pageModel?.basket?.infoText}
            </div>
            <div ref={termsAndConditionRef} className="py-3">
              {ReactHtmlParser(pageModel?.basket?.termsAndConditions.termsAndConditionsText)}
            </div>
            {isFormWithoutSavedVehicle && (
              <div className="text-danger pb-2">
                Make sure to press &quot;Save&quot; after adding your car details.
              </div>
            )}
            {!isFormWithoutSavedVehicle && isEdit && (
              <div className="text-danger pb-2">
                Make sure to press &quot;Save&quot; after editing your car details.
              </div>
            )}
            <div className="col-sm-12 col-md-3">
              <Button
                onClickHandler={handleFormSubmit}
                disabled={disabledButtonState || isEdit}
                buttonText="Buy now"
                chevronWhite
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      {/* Modal */}
      {toggleError && displayErrorModal()}
      {isModalAvailable && displayModal && (
        <ProductUpgradeModal
          modalTitle={pageModel?.tellMeMore?.modalTitle}
          modalBody={ReactHtmlParser(pageModel?.tellMeMore?.modalBody)}
          action={() => setDisplayModal(false)}
        />
      )}
    </>
  ) : <Spinner addClass="position-absolute top-50 start-50 translate-middle" bootSpinner />;
};

const mapDispatchToProps = (dispatch) => ({
  updateProductSelected: (upgradedProduct) => {
    dispatch(SessionActions.setSelectedProductUpgrade(upgradedProduct));
  },
});

const mapStateToProps = (state) => ({
  session: state.session,
  customer: state.customer,
  policy: state.policy,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductUpgradeJourney);
