import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as StepActions from '../../../../actions/journeyManagerStepAction';
import { GetProductUpgradeData } from '../../../../services/MyRacService';
import { LOGIN, MY_COVER } from '../../../../constants/routeConstants';
import { TACTICAL_UPGRADE_JOURNEY } from '../../../../constants/journeyManagerStepConstants';
import { useApplicationState } from '../../../../hooks/applicationState-Context';
import { useUserJourney } from '../../../../hooks/userJourney-Context';

const JourneyManager = (props) => {
  const { setCurrentStep, setStepData, step: { stepIndex, stepData, journeyTitle, journeyInProgress }, journeySteps, setStartJourney, setEndJourney, journeyName, customer } = props;
  const { setPageLoading } = useApplicationState();
  const { userJourneyState } = useUserJourney();

  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const productQuery = params.get('product');

  // Get data depending on the journey
  const journeyData = async () => {
    let data;
    switch (journeyName) {
      case TACTICAL_UPGRADE_JOURNEY:
        await GetProductUpgradeData(customer.custInfo.Id, productQuery).then((response) => {
          const newStepData = {
            productName: productQuery,
            ...response.payload,
          };
          data = newStepData;
        });
        break;
      default:
        break;
    }
    return data;
  };

  const startJourney = async () => {
    // Valid alphanumeric key must be passed in props otherwise assume url has been manually entered bypassing eligibility check on Cover Page also pageHistory is checked to ensure this was not a deep link
    if ((!userJourneyState.pageHistory[userJourneyState.pageHistory.length - 2] === LOGIN) && location.key === 'default') {
      // Redirect user back to cover page if no valid key found
      navigate(MY_COVER);
    }
    // User journey state records route so if the url is tampered with then the pageHistory value will be undefined
    if (!userJourneyState.pageHistory[userJourneyState.pageHistory.length - 2] && location.key === 'default') {
      // Redirect user back to cover page
      navigate(MY_COVER);
    }
    // Get content and start journey
    const data = await journeyData();
    setStartJourney(journeyName, 0, data);
  };

  // TODO - Ask backend team for additional database column to say that the user has been on this product journey before
  useEffect(() => {
    if ((!stepData || stepData.productName !== productQuery)) {
      // Reset the data before loading new data
      startJourney().then(() => { setPageLoading(false); }).catch((error) => error); // TODO - Handle the error if no data is received for Journey Manager.
    }
  }, [stepData]);

  // Return function in useEffect is triggered when unmounting of Journey Manager occurs.
  useEffect(() => () => {
    setEndJourney();
  }, [location.pathname]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const goToNextStep = () => {
    const nextStep = stepIndex === journeySteps.length ? journeySteps.length : stepIndex + 1;
    setCurrentStep(nextStep);
    scrollToTop();
  };

  const goToPreviousStep = () => {
    const prevStep = stepIndex ? stepIndex - 1 : 0;
    setCurrentStep(prevStep);
    scrollToTop();
  };

  const leaveJourney = (route, options) => {
    setEndJourney();
    navigate(route, options);
  };

  const getActivePage = () => {
    if (typeof stepIndex === 'number') {
      const Component = journeySteps[stepIndex];
      return <Component nextStep={goToNextStep} prevStep={goToPreviousStep} leaveJourney={leaveJourney} pageModel={stepData} product={productQuery} startJourney={startJourney} journeyTitle={journeyTitle} journeyInProgress={journeyInProgress} setStepData={setStepData} />;
    }

    return null;
  };

  return getActivePage();
};

const mapDispatchToProps = (dispatch) => ({
  setStartJourney: (name, index, data) => {
    dispatch(StepActions.startJourney(name, index, data));
  },
  setCurrentStep: (stepIndex) => {
    dispatch(StepActions.setCurrentStep(stepIndex));
  },
  setStepData: (stepData) => {
    dispatch(StepActions.setStepData(stepData));
  },
  setEndJourney: () => {
    dispatch(StepActions.endJourney());
  },
});

// newJourney

const mapStateToProps = (state) => ({
  step: state.step,
  customer: state.customer,
});

export default connect(mapStateToProps, mapDispatchToProps)(JourneyManager);
