import React from 'react';
import block from 'bem-css-modules';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import PropTypes from 'prop-types';
import styles from './Brokendown.module.scss';
import triangle from '../../../assets/images/landing/hazard_triangle.svg';
import trackEvent from '../../../services/AnalyticsService';

const { string } = PropTypes;

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

const Brokendown = ({ fields }) => (
  <section className={bem()} id={fields.uid}>
    <h2 className={bem('header')}>
      <img className={bem('header-icon')} src={triangle} alt="hazard triangle" />
      <span className={bem('header-text')}>
        <a
          className={fields.url.cssclass}
          href={fields.url.href}
          title={fields.url.title}
          target={fields.url.openinnewwindow ? '_BLANK' : '_SELF'}
          onClick={() => {
            if (fields.url.analyticstag) {
              trackEvent(fields.url.analyticstag);
            }
          }}
          rel="noreferrer"
        >
          {fields.heading}
        </a>
      </span>
      <img className={bem('header-icon')} src={triangle} alt="hazard triangle" />
    </h2>
    <div className={bem('body-text')}>{ReactHtmlParser(fields.body)}</div>
  </section>
);

Brokendown.propTypes = {
  heading: string,
  body: string,
  uid: string,
};

Brokendown.defaultProps = {
  heading: '',
  body: '',
  uid: '',
};

export default Brokendown;
