import contentstack from 'contentstack';
import { getState, dispatch } from 'store';
import { loggerFunc } from 'utils/logger';
import { GetLayoutGrid } from './MyRacService';
import { addTileContent, addPage } from '../actions/content';

const logger = loggerFunc('ContentService');

const MyRACStack = contentstack.Stack({
  api_key: process.env.REACT_APP_MY_RAC_CONTENTSTACK_API_KEY,
  delivery_token: process.env.REACT_APP_MY_RAC_CONTENTSTACK_TOKEN,
  environment: process.env.REACT_APP_MY_RAC_CONTENTSTACK_ENVIRONMENT,
});

const RACSharedStack = contentstack.Stack({
  api_key: process.env.REACT_APP_SHARED_CONTENTSTACK_API_KEY,
  delivery_token: process.env.REACT_APP_SHARED_CONTENTSTACK_TOKEN,
  environment: process.env.REACT_APP_SHARED_CONTENTSTACK_ENVIRONMENT,
});

export const ResponseCode = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const mapImage = (data) => ({
  url: data.url,
  title: data.title,
});

export const mapURL = (data) => {
  const urlObj = data.urlinfo ? data.urlinfo : data;

  return {
    url: urlObj.href,
    isExternal: !urlObj.ismyracpage,
    openInNewWindow: urlObj.openinnewwindow,
    title: urlObj.alttext,
    text: urlObj.title,
    cssClass: urlObj.cssclass,
    analytics: urlObj.analyticstag,
  };
};

export const MapAdvertPanel = (pageData) => {
  // eslint-disable-next-line no-underscore-dangle
  const advertorial = pageData.componentitems.filter((item) => item._content_type_uid === 'advertorials')[0];

  return {
    background: mapImage(advertorial.backgroundimage),
    items: advertorial.items.map((item) => ({
      text: item.description,
      image: mapImage(item.image),
      url: mapURL(item.url),
    })),
  };
};

/*
* Returns Redux store state. If the store exists.
* @return {store State} Redux store state.
*/
const getPageState = async () => {
  const state = await getState();
  if (state && state.content) {
    return state.content;
  }
  return false;
};

export const GetTileContent = async (customerId, membershipId, tileSet, mobile) => {
  const responseObject = {
    payload: null,
    error: null,
    responseCode: ResponseCode.SUCCESS,
  };

  try {
    const response = await GetLayoutGrid(customerId, membershipId, tileSet, mobile);
    if (response) {
      dispatch(addTileContent(response.payload.tiles, tileSet));
    }
  } catch (error) {
    logger.error('GetContent error', error);
    responseObject.error = error;
    responseObject.responseCode = ResponseCode.ERROR;
  }

  return responseObject;
};

export const GetContent = async (contentType, pageTitle, references, expectList = false) => {
  const contentData = await getPageState();
  const pageData = contentData.pages;
  const responseObject = {
    payload: null,
    error: null,
    responseCode: ResponseCode.SUCCESS,
  };

  if (pageData.length) {
    const page = pageData.find((data) => data && data.title === pageTitle);
    if (page) {
      // if we're here, then we've got a match from the cache
      responseObject.payload = page;
      return responseObject;
    }
  }
  let query = (['footer', 'header']
    .find((item) => item === contentType)) ? RACSharedStack : MyRACStack;

  query = query.ContentType(contentType).Query();
  query = pageTitle ? query.where('title', pageTitle) : query;
  query = references ? query.includeReference(references) : query;
  query = query.language('en').toJSON();

  try {
    const result = expectList ? await query.find() : await query.findOne();
    responseObject.payload = result;
    responseObject.responseCode = ResponseCode.SUCCESS;
    logger.debug(`ContentResponse for ${contentType}: `, responseObject);
  } catch (error) {
    logger.error('GetContent error', error);
    responseObject.error = error;
    responseObject.responseCode = ResponseCode.ERROR;
  }
  dispatch(addPage(responseObject.payload));

  return responseObject;
};

export const GetAdvertPanel = async (page) => {
  try {
    let pageData = await GetContent('loggedinpages', page, ['componentitems']);

    if (pageData.responseCode === ResponseCode.SUCCESS) {
      pageData = MapAdvertPanel(pageData.payload);
    }

    return pageData;
  } catch (e) {
    logger.error('GetAdvertPanel error', e);
    return null;
  }
};

class ContentService {
  static requestContentList = (contentType, page, references, callback) => {
    this.requestContent(contentType, page, references, callback, true);
  }

  static requestContent = (contentType, page, references, callback, expectList = false) => {
    const responseObject = {
      payload: null,
      error: null,
      responseCode: ResponseCode.PENDING,
    };

    let query = (['footer', 'header']
      .find((item) => item === contentType)) ? RACSharedStack : MyRACStack;

    query = query.ContentType(contentType).Query();
    query = page ? query.where('title', page) : query;
    query = references ? query.includeReference(references) : query;
    query = query.language('en').toJSON();
    query = expectList ? query.find() : query.findOne();

    query.then((results) => {
      logger.debug(results);
      responseObject.payload = results;
      responseObject.responseCode = ResponseCode.SUCCESS;
      callback(responseObject);
    }, (error) => {
      logger.error('requestContent error', error);
      responseObject.error = error;
      responseObject.responseCode = ResponseCode.ERROR;
      callback(responseObject);
    });
  }
}

export default ContentService;
