import {
  OK, CREATED, ACCEPTED, NO_CONTENT,
  UNPROCESSABLE_ENTITY, NOT_MODIFIED,
  BAD_REQUEST, UNAUTHORIZED, FORBIDDEN,
  NOT_FOUND, METHOD_NOT_ALLOWED,
  GONE, UNSUPPORTED_MEDIA_TYPE,
  TOO_MANY_REQUESTS, INTERNAL_SERVER_ERROR,
} from 'http-status-codes';
import { ResponseCode } from 'services/models/httpResponse';
import { loggerFunc } from 'utils/logger';
import { push } from 'redux-first-history';
import * as RouteConstants from 'constants/routeConstants';
import { getState, dispatch } from '../store';
import * as commons from './commons';

const numberOfAttempts = Number(`${process.env.REACT_APP_RETRIES}`);
const delay = Number(`${process.env.REACT_APP_RETRYDELAY}`);

const logger = loggerFunc('httpHelper');

const getHeaders = (method, token, sessionId) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('sessionId', sessionId);
  headers.append('correlationId', commons.generateUID(8));
  switch (method) {
    case 'GET':
      headers.append('Accept', 'application/json');
      break;
    case 'POST':
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      break;
    case 'PATCH':
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      break;
    case 'PUT':
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      break;
    case 'DELETE':
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      break;
    default:
      throw new Error('Invalid method. Only GET,POST,PUT,DELETE or PATCH are allowed.');
  }
  return headers;
};

const getSession = async () => {
  const state = getState();
  if (state && state.session) {
    return state.session;
  }
  return undefined;
};

const getFullApiUrl = (uri) => {
  if (uri) {
    return `${process.env.REACT_APP_WEB_EXP_HOST}${uri}`;
  }
  throw Error('missing argument');
};

const responseModel = (payload, error, status) => ({
  payload,
  responseCode: status,
  error,
});

// Delay
// eslint-disable-next-line no-promise-executor-return
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const processResponse = async (response, options, attempt) => {
  try {
    switch (response.status) {
      case OK:
        try {
          if (options.method === 'PATCH') {
            return Promise.resolve(responseModel(
              null,
              null,
              ResponseCode.SUCCESS,
            ));
          }
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.indexOf('application/json') !== -1) {
            const payload = await response.json();
            return Promise.resolve(responseModel(
              payload.payload,
              payload.error,
              ResponseCode.SUCCESS,
            ));
          }
          const payload = await response.blob();
          return Promise.resolve(responseModel(
            payload,
            null,
            ResponseCode.SUCCESS,
          ));
        } catch (e) {
          logger.error(e);
          return Promise.reject(e);
        }

      case CREATED:
        return Promise.resolve(responseModel(
          await response.json(),
          null,
          ResponseCode.SUCCESS,
        ));
      case ACCEPTED:
      case NO_CONTENT:
        return Promise.resolve(responseModel(
          null,
          null,
          ResponseCode.SUCCESS,
        ));
      case NOT_MODIFIED:
        return Promise.resolve(responseModel(
          null,
          null,
          ResponseCode.SUCCESS,
        ));
      case GONE:
      case NOT_FOUND:
      case UNPROCESSABLE_ENTITY: // todo Used for validation errors
      case BAD_REQUEST:
        try {
          const payload = await response.json();
          return Promise.reject(responseModel(null, payload.error, ResponseCode.ERROR));
        } catch (e) {
          logger.error(e);
          return Promise.reject(responseModel(null, response.status, ResponseCode.ERROR));
        }
      case UNAUTHORIZED:
        logger.debug('httpHelper Unauthorized request');
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Unauthorized request');
      case FORBIDDEN:
        try {
          const payload = await response.json();
          return Promise.reject(responseModel(null, payload.error, ResponseCode.ERROR));
        } catch (e) {
          logger.error(e);
          return Promise.reject(responseModel(null, e, ResponseCode.ERROR));
        }
      case METHOD_NOT_ALLOWED:
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Http method not allowed');
      case UNSUPPORTED_MEDIA_TYPE:
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Incorrect content type');
      case TOO_MANY_REQUESTS:
      case INTERNAL_SERVER_ERROR:
        // eslint-disable-next-line no-use-before-define
        return tryagain(response.url, options, attempt, response.status);
      default:
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Http status code not defined');
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

// Fetch retry with n of attempts
const fetchRetry = (url, options, attempt) => fetch(url, options)
  .then((res) => processResponse(res, options, attempt))
  .catch((e) => {
    logger.error(`Failed to retrieve ${url}, attempt ${attempt} of ${numberOfAttempts}`, e);
    throw e;
  });

const tryagain = async (url, options, attempt, statusCode) => {
  if (attempt <= 1) {
    // sends back the Error resonse.
    return Promise.reject(responseModel(null, statusCode, ResponseCode.ERROR));
  }
  await sleep(delay);
  // eslint-disable-next-line no-param-reassign
  attempt--;
  return fetchRetry(url, options, attempt);
};

const getBody = (method, headers, session, body) => (['GET', 'DELETE'].includes(method) ? null : JSON.stringify({
  correlationId: headers.correlationId,
  sessionId: session.sessionId,
  payload: body,
}));

const getHttpOptions = (method, session, body) => {
  const headers = getHeaders(method, session.jwtToken, session.sessionId);
  const options = {
    method,
    headers,
    body: ['GET', 'DELETE'].includes(method) ? undefined : getBody(method, headers, session, body),
  };
  return options;
};

export const getEndpoint = (uri, queryParams, pathParams) => {
  if (pathParams) {
    // eslint-disable-next-line no-restricted-syntax,no-unused-vars
    for (const key in pathParams) {
      // eslint-disable-next-line no-prototype-builtins
      if (pathParams.hasOwnProperty(key)) {
        const val = pathParams[key];
        if (val) {
          // eslint-disable-next-line no-param-reassign
          uri = uri.replace(`{${key}}`, encodeURIComponent(val));
        } else {
          // eslint-disable-next-line no-useless-escape,no-param-reassign
          uri = uri.replace(`\{${key}}`, '');
        }
      }
    }
  }
  if (queryParams) {
    const esc = encodeURIComponent;
    const query = Object.keys(queryParams)
      .map((k) => `${esc(k)}=${esc(queryParams[k])}`)
      .join('&');
    if (query) {
      // eslint-disable-next-line no-param-reassign
      uri = `${uri}?${query}`;
    }
  }
  return uri;
};

// eslint-disable-next-line consistent-return
export const get = async (uri, queryParams, pathParams, autoLogout = true) => {
  const fullurl = getFullApiUrl(uri);
  const apiUrl = getEndpoint(fullurl, queryParams, pathParams);
  const session = await getSession();
  if (session) {
    const options = getHttpOptions('GET', session);
    return fetchRetry(apiUrl, options, numberOfAttempts);
  }
  if (autoLogout) {
    dispatch(push(RouteConstants.LOGOUT));
  }
};

// eslint-disable-next-line consistent-return
export const post = async (uri, queryParams, pathParams, payload, autoLogout = true) => {
  const fullurl = getFullApiUrl(uri);
  const apiUrl = getEndpoint(fullurl, queryParams, pathParams);
  const session = await getSession();
  if (session) {
    const options = getHttpOptions('POST', session, payload);
    return fetchRetry(apiUrl, options, numberOfAttempts);
  }
  if (autoLogout) {
    dispatch(push(RouteConstants.LOGOUT));
  }
};

// eslint-disable-next-line consistent-return
export const put = async (uri, queryParams, pathParams, payload, autoLogout = true) => {
  const fullurl = getFullApiUrl(uri);
  const apiUrl = getEndpoint(fullurl, queryParams, pathParams);
  const session = await getSession();
  if (session) {
    const options = getHttpOptions('PUT', session, payload);
    return fetchRetry(apiUrl, options, numberOfAttempts);
  }
  if (autoLogout) {
    dispatch(push(RouteConstants.LOGOUT));
  }
};

// eslint-disable-next-line consistent-return
export const anonymousPut = async (uri, queryParams, pathParams, payload) => {
  const fullurl = getFullApiUrl(uri);
  const apiUrl = getEndpoint(fullurl, queryParams, pathParams);
  const headers = new Headers();
  headers.append('correlationId', commons.generateUID(8));
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');
  const options = {
    method: 'PUT',
    headers,
    body: JSON.stringify({
      payload,
    }),
  };
  return fetchRetry(apiUrl, options, numberOfAttempts);
};

// eslint-disable-next-line consistent-return
export const anonymousPost = async (uri, queryParams, pathParams, payload) => {
  const fullurl = getFullApiUrl(uri);
  const apiUrl = getEndpoint(fullurl, queryParams, pathParams);
  const headers = new Headers();
  headers.append('correlationId', commons.generateUID(8));
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');
  headers.append('ApiKey', process.env.REACT_APP_API_KEY);
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      payload,
    }),
  };
  return fetchRetry(apiUrl, options, numberOfAttempts);
};
// eslint-disable-next-line consistent-return
export const patch = async (uri, queryParams, pathParams, payload, autoLogout = true) => {
  const fullurl = getFullApiUrl(uri);
  const apiUrl = getEndpoint(fullurl, queryParams, pathParams);
  const session = await getSession();
  if (session) {
    const options = getHttpOptions('PATCH', session, payload);
    return fetchRetry(apiUrl, options, numberOfAttempts);
  }
  if (autoLogout) {
    dispatch(push(RouteConstants.LOGOUT));
  }
};

// eslint-disable-next-line consistent-return
export const del = async (uri, queryParams, pathParams, autoLogout = true) => {
  const fullurl = getFullApiUrl(uri);
  const apiUrl = getEndpoint(fullurl, queryParams, pathParams);
  const session = await getSession();
  if (session) {
    const options = getHttpOptions('DELETE', session);
    return fetchRetry(apiUrl, options, numberOfAttempts);
  }
  if (autoLogout) {
    dispatch(push(RouteConstants.LOGOUT));
  }
};
