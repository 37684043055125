import { getState } from 'store';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import { loggerFunc } from 'utils/logger';
// eslint-disable-next-line import/no-cycle
import { GetContent } from 'services/ContentService';

const logger = loggerFunc('messageService');

const getStateContent = () => {
  const state = getState();
  if (state && state.content) {
    return state.content;
  }
  return false;
};

export const populateMessages = async () => {
  await GetContent('messages', null, null, true);
};

export const fallbackErrorMessage = 'Sorry something went wrong!';

const getEnrichedText = ((textToManage, replace) => {
  const text = replace ? textToManage.replace(replace.match, replace.replace) : textToManage;
  return ReactHtmlParser(text);
});

export const getMessage = (dictionary, isFailure = true, replace = false) => {
  const fallbackSuccessMessage = 'Success';
  const message = isFailure ? fallbackErrorMessage : fallbackSuccessMessage;
  if (!dictionary) {
    return message;
  }
  if (!dictionary.mapTo) {
    return getEnrichedText(dictionary.value, replace);
  }

  try {
    const content = getStateContent().pages;
    if (content && content.length) {
      const messageArrays = content.find((item) => Array.isArray(item));
      if (!messageArrays || !messageArrays.length) {
        return message;
      }
      const messages = messageArrays[0];
      const [pageKey, messageKey] = dictionary.mapTo.split('.');
      const component = messages.find((item) => item.title === pageKey);
      const keyText = component.message.find((x) => x.key === messageKey).text;

      return getEnrichedText(keyText, replace);
    }
    return message;
  } catch (e) {
    logger.error('getMessage', e);
    return message;
  }
};
