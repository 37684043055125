/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { intervalToDuration } from 'date-fns';

import './CountdownWithCtaTile.scss';
import trackEvent from '../../../../../services/AnalyticsService';
import ctaActions from '../../../../../utils/ctaActions.enum';
import { getClickAction } from '../../GridLayout/ManagedCta';

const CountdownWithCtaTile = (props) => {
  const { policy: { policyInfo }, pageModel } = props;
  const [setShowDialogue] = useState(false);

  const buttonStyle = {
    border: 'none',
    width: '100%',
    height: '100%',
    margin: '0',
    padding: '0',
  };

  const updateTimer = () => {
    let remainingTime;
    const today = new Date();
    const renewal = policyInfo && policyInfo.RenewalDate ? new Date(policyInfo.RenewalDate) : null;
    if (renewal) {
      const dateDifference = renewal - today;
      const timeLeft = intervalToDuration({
        start: today,
        end: renewal,
      });
      // Countdown
      if (dateDifference > 0) {
        // The slice() method is used to ensure 0 is added before values less than 10
        remainingTime = {
          days: (`0${timeLeft.days}`).slice(-2) || '-',
          hours: (`0${timeLeft.hours}`).slice(-2) || '-',
          minutes: (`0${timeLeft.minutes}`).slice(-2) || '-',
          seconds: (`0${timeLeft.seconds}`).slice(-2) || '-',
        };
      } else {
        remainingTime = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
    }
    return remainingTime;
  };

  const [countdownTimer, setCountdownTime] = useState(updateTimer());

  useEffect(() => {
    const setTimerInterval = setInterval(() => {
      setCountdownTime(updateTimer());
    }, 1000);
    // if component is unmounted then clear interval
    return () => clearInterval(setTimerInterval);
  });

  const outputStaticImage = () => (
    <img
      className="countdown-image-tile"
      src={pageModel.tileContent.desktopImage.url}
      alt={pageModel.tileContent.desktopImage.fileName}
      title={pageModel.tileContent.desktopImage.title}
    />
  );

  const getTarget = () => (pageModel.tileContent.button.callToAction.actionType === ctaActions.internal ? '_self' : '_blank');

  const outputClickableImage = () => {
    switch (pageModel.tileContent.button.callToAction.actionType) {
      case ctaActions.internal:
      case ctaActions.newWindow: {
        if (pageModel.tileContent.button && Array.isArray(pageModel.analyticsTags)) {
          const analyticsTag = pageModel.analyticsTags.find((tag) => tag.eventName === 'clickWidget');
          return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={pageModel.tileContent.button.callToAction.url} onClick={() => trackEvent(analyticsTag?.eventTag)} target={getTarget()}>
              {outputStaticImage()}
            </a>
          );
        }
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={pageModel.tileContent.button.callToAction.url} target={getTarget()}>
            {outputStaticImage()}
          </a>
        );
      }
      case ctaActions.dialogue: {
        if (pageModel.tileContent.button && Array.isArray(pageModel.analyticsTags)) {
          const analyticsTag = pageModel.analyticsTags.find((tag) => tag.eventName === 'clickWidget');
          return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <button
              style={buttonStyle}
              type="button"
              onClick={() => {
                trackEvent(analyticsTag?.eventTag);
                setShowDialogue(true);
              }}
            >
              {outputStaticImage()}
            </button>
          );
        }
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <button
            style={buttonStyle}
            type="button"
            onClick={() => setShowDialogue(true)}
          >
            {outputStaticImage()}
          </button>
        );
      }
      default: {
        if (pageModel.tileContent.button && Array.isArray(pageModel.analyticsTags)) {
          const analyticsTag = pageModel.analyticsTags.find((tag) => tag.eventName === 'clickWidget');
          return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <button
              style={buttonStyle}
              type="button"
              onClick={(params) => getClickAction(pageModel.tileContent.button.callToAction.actionType, {
                ...params, analyticsTag, url: pageModel.tileContent.button.callToAction.url,
              })}
            >
              {outputStaticImage()}
            </button>
          );
        }
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <button
            style={buttonStyle}
            type="button"
            onClick={(params) => getClickAction(pageModel.tileContent.button.callToAction.actionType, {
              ...params, analyticsTag: null, url: pageModel.tileContent.button.callToAction.url,
            })}
          >
            {outputStaticImage()}
          </button>
        );
      }
    }
  };

  const displayTimerWrapper = () => (
    <div className="countdown__container">
      <div className="countdown__row">
        <div className="countdown__number">
          {countdownTimer?.days}
          <div className="countdown__time">Days</div>
        </div>
        <div className="countdown__number">
          {countdownTimer?.hours}
          <div className="countdown__time">Hours</div>
        </div>
        <div className="countdown__number">
          {countdownTimer?.minutes}
          <div className="countdown__time">Minutes</div>
        </div>
        <div className="countdown__number">
          {countdownTimer?.seconds}
          <div className="countdown__time">Seconds</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="countdown">
      {pageModel.tileContent && pageModel.tileContent.button ? outputClickableImage() : outputStaticImage()}
      {displayTimerWrapper()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
});

export default connect(mapStateToProps)(CountdownWithCtaTile);
