import React from 'react';
import block from 'bem-css-modules';
import { format } from 'date-fns';
import styles from '../setVehicleHistoryModel.module.scss';
import motStatusTypes from '../../../../utils/motStatusTypes.enum';
import greenTickSvg from '../../../../assets/images/button/tick-circle-solid-green.svg';
import redCrossSvg from '../../../../assets/images/button/cross-solid-red.svg';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

const DisplayHistorySummaryItem = (props) => {
  const { vehicleMotHistory, toggleShow } = props;

  return (
    <div className={bem('summary')} key={vehicleMotHistory.motTestNumber}>
      <div className={bem('summary__inner')}>
        <div className={bem('summary__inner-info')}>
          <span className="mx-2">{format(new Date(vehicleMotHistory.completedDate), 'dd/MM/yyyy')}</span>
          <p>
            {vehicleMotHistory.testResult === motStatusTypes.pass ? 'Pass' : 'Fail'}
          </p>
          {vehicleMotHistory.testResult === motStatusTypes.pass
            ? <img src={greenTickSvg} height="20px" className="mb-1 px-2" alt="Pass" />
            : <img src={redCrossSvg} height="20px" className="mb-1 px-2" alt="Fail" />}
        </div>
        <p className="action">
          <button
            type="button"
            className="MyRacForm__button-link link btn-transparent"
            onClick={() => toggleShow(vehicleMotHistory)}
          >
            View details &gt;
          </button>
        </p>
      </div>
    </div>
  );
};

export default DisplayHistorySummaryItem;
