import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RenderLink from 'components/RenderLink';

const MenuItem = (props) => {
  const { link, onClick } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);

  const location = useLocation();

  const manageState = () => {
    const match = location.pathname.search(link.url);
    if ((match >= 0 && isCollapsed) || (match === -1 && !isCollapsed)) {
      setIsCollapsed(!isCollapsed);
    }
  };

  // set the initial state. If the initial request is within range, expad
  useEffect(() => {
    manageState();
  });

  return (
    link.subitems ? (
      <>
        <RenderLink link={link} />
        <ul style={{ display: isCollapsed ? 'none' : 'block' }}>
          {' '}
          {
              link.subitems.map((sub) => (
                <li key={sub.url + sub.text}>
                  <RenderLink link={sub} />
                </li>
              ))
            }
        </ul>
      </>
    ) : <RenderLink link={link} onClick={onClick} />
  );
};

export default MenuItem;
