import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as EditButtonsActions from 'actions/editableButtonsAction';
import * as CustomerActions from 'actions/customerInfoActions';
import { UpdateCustomerPhoneNumber } from 'services/MyRacService';
import { ResponseCode } from 'services/models/httpResponse';
import trackEvent from 'services/AnalyticsService';

class EditPhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.handleSaveNewPhoneNumber = this.handleSaveNewPhoneNumber.bind(this);
    this.cancelEditPhoneNumber = this.cancelEditPhoneNumber.bind(this);
    const { customer } = this.props;
    this.state = {
      mobilePhoneNumber: customer.custInfo.MobileTelephone
        ? customer.custInfo.MobileTelephone : null,
      homePhoneNumber: customer.custInfo.HomeTelephone
        ? customer.custInfo.HomeTelephone : null,
      workPhoneNumber: customer.custInfo.WorkTelephone
        ? customer.custInfo.WorkTelephone : null,
      mobilePhoneNumberErr: false,
      homePhoneNumberErr: false,
      workPhoneNumberErr: false,
      loading: false,
    };
  }

  componentDidMount() {
    trackEvent('myrac.telephone.open');
  }

  onChange(e) {
    const validPhoneNoRegEx = /^0(\d ?){10}$/;
    if (!(e.target.value === '' || validPhoneNoRegEx.test(e.target.value))) {
      if (e.target.name === 'mobilePhoneNumber') { this.setState({ mobilePhoneNumberErr: true }); }
      if (e.target.name === 'homePhoneNumber') { this.setState({ homePhoneNumberErr: true }); }
      if (e.target.name === 'workPhoneNumber') { this.setState({ workPhoneNumberErr: true }); }
    } else {
      if (e.target.name === 'mobilePhoneNumber') { this.setState({ mobilePhoneNumberErr: false }); }
      if (e.target.name === 'homePhoneNumber') { this.setState({ homePhoneNumberErr: false }); }
      if (e.target.name === 'workPhoneNumber') { this.setState({ workPhoneNumberErr: false }); }
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

    cancelEditPhoneNumber = (e) => {
      e.preventDefault();
      const { setEditPhoneNumber } = this.props;
      setEditPhoneNumber(false);
    };

  handleSaveNewPhoneNumber = async (e) => {
    e.preventDefault();
    trackEvent('myrac.telephone.submit');

    const {
      homePhoneNumber, workPhoneNumber, mobilePhoneNumber,
    } = this.state;

    const {
      customer, setCustomerInfo, setEditPhoneNumber, isNumberUpdated,
    } = this.props;

    const updateNumber = {
      HomeNumber: homePhoneNumber,
      WorkNumber: workPhoneNumber,
      MobileNumber: mobilePhoneNumber,
    };

    const { custInfo } = customer;

    this.setState({ loading: true });

    try {
      const response = await UpdateCustomerPhoneNumber(custInfo.Id, updateNumber);

      if (response.responseCode === ResponseCode.SUCCESS) {
        const custInformation = { ...custInfo };
        custInformation.MobileTelephone = mobilePhoneNumber;
        custInformation.HomeTelephone = homePhoneNumber;
        custInformation.WorkTelephone = workPhoneNumber;

        setCustomerInfo(custInformation);
        setEditPhoneNumber(false);
        isNumberUpdated(true);
        this.setState({ loading: false });
      }
    } catch (error) {
      isNumberUpdated(false);
      setEditPhoneNumber(false);
      this.setState({ loading: false });
    }
  };

  render() {
    const { pageModel } = this.props;
    const {
      homePhoneNumberErr, workPhoneNumberErr, mobilePhoneNumberErr, mobilePhoneNumber, homePhoneNumber, workPhoneNumber, loading,
    } = this.state;
    const isEmpty = !((mobilePhoneNumber || homePhoneNumber || workPhoneNumber));
    const validateTel = !((homePhoneNumberErr || workPhoneNumberErr || mobilePhoneNumberErr));

    if (!pageModel) { return null; }

    return (
      <div className="col-12 col-sm-6">
        <div className="tile box">
          <h4>{pageModel.title}</h4>
          <div className="Info rac-form sessioncamhidetext">
            <form onSubmit={this.handleSaveNewPhoneNumber}>
              <p className="long primary">
                <div className="row mb-2">
                  <div className="col-sm-2">
                    {pageModel.mobilePhoneLabel}
                  </div>
                  <div className="col-sm-10">
                    <input
                      type="tel"
                      maxLength="11"
                      name="mobilePhoneNumber"
                      onChange={(value) => this.onChange(value)}
                      value={mobilePhoneNumber}
                    />
                    {mobilePhoneNumberErr
                      ? (
                        <span
                          className="MyRacForm__error-msg mx-2"
                          name="mobilePhoneNumberErr"
                        >
                          {pageModel.invalidNumberMessage}
                        </span>
                      ) : <span />}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-2">
                    {pageModel.homePhoneLabel}
                  </div>
                  <div className="col-sm-10">
                    <input
                      type="tel"
                      maxLength="11"
                      name="homePhoneNumber"
                      onChange={(value) => this.onChange(value)}
                      value={homePhoneNumber}
                    />
                    {homePhoneNumberErr
                      ? (
                        <span
                          className="MyRacForm__error-msg mx-2"
                          name="homePhoneNumberErr"
                        >
                          {pageModel.invalidNumberMessage}
                        </span>
                      ) : <span />}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    {pageModel.workPhoneLabel}
                  </div>
                  <div className="col-sm-10">
                    <input
                      type="tel"
                      maxLength="11"
                      name="workPhoneNumber"
                      onChange={(value) => this.onChange(value)}
                      value={workPhoneNumber}
                    />
                    {workPhoneNumberErr
                      ? (
                        <span
                          className="MyRacForm__error-msg mx-2"
                          name="workPhoneNumberErr"
                        >
                          {pageModel.invalidNumberMessage}
                        </span>
                      ) : <span />}
                  </div>
                </div>
              </p>
              <div className="MyRacForm__button-wrapper px-2">
                <button
                  type="button"
                  disabled={loading}
                  className="MyRacForm__button
                      MyRacForm__button--vertical-spacing
                      MyRacForm__button--wide MyRacForm__button--grey"
                  onClick={this.cancelEditPhoneNumber}
                >
                  {pageModel.buttons.backButton}
                </button>
                {' '}
                  &nbsp;&nbsp;
                <button
                  className="MyRacForm__button
                        MyRacForm__button--vertical-spacing
                        MyRacForm__button--wide
                        MyRacForm__button--left-margin"
                  type="submit"
                  disabled={!validateTel || isEmpty || loading}
                >
                  {loading ? <i className="fas fa-spinner fa-pulse" /> : pageModel.buttons.submitButton}
                  {validateTel}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
  editValues: state.edit,
});

const mapDispatchToProps = (dispatch) => ({
  setEditPhoneNumber: (editPhoneNumber) => {
    dispatch(EditButtonsActions.setEditPhoneNumber(editPhoneNumber));
  },
  setCustomerInfo: (customerInfo) => {
    dispatch(CustomerActions.setCustomerInfo(customerInfo));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPhoneNumber);
