// eslint-disable-next-line default-param-last
const policyInfoReducer = (state = {
  policyInfo: {},
}, action) => {
  switch (action.type) {
    case 'SET_POLICYINFO':
      return {
        ...state,
        policyInfo: action.payload,
      };
    case 'SET_AUTO_RENEW':
      return {
        ...state,
        policyInfo: {
          ...state.policyInfo,
          AutoRenew: action.payload,
        },
      };
    case 'SET_POLICY_ACKNOWLEDGED':
      return {
        ...state,
        policyInfo: {
          ...state.policyInfo,
          PolicyAcknowledged: true,
        },
      };
    case 'POLICY_HAS_ERROR':
      return {
        ...state,
        policyInfo: {
          ...state.policyInfo,
          hasError: action.payload,
        },
      };
    case 'UPDATE_VEHICLEINFO':
      return {
        ...state,
        policyInfo: {
          ...state.policyInfo, Vehicles: action.payload,
        },
      };
    case 'UPDATE_ADDITIONALINFO':
      return {
        ...state,
        policyInfo: {
          ...state.policyInfo, Members: action.payload,
        },
      };
    case 'INVALIDATE_POLICYINFO':
      return {
        ...state,
        policyInfo: null,
      };
    case 'MOT_PLUS':
      return {
        ...state,
        policyInfo: {
          ...state.policyInfo,
          MotPlus: action.payload,
        },
      };
    default:
      return state;
  }
};

export default policyInfoReducer;
