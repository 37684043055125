import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import { GetLatestPolicyInfo, UpdateAutorenewal } from 'services/MyRacService';
import { ResponseCode } from 'services/models/httpResponse';
import './AutorenewalPanel.scss';
import PopupModal from '../../../../components/PopupModal/PopupModal';
import modalTypes from '../../../../utils/modalTypesEnum';
import trackEvent from '../../../../services/AnalyticsService';
import ProtectedForm from '../../../../shared/components/ProtectedForm/ProtectedForm';
import isInRenewalPeriod from '../../../../utils/autoRenewalUtils';

export const AutorenewalPanel = (props) => {
  const { pageModel, policy: { policyInfo } } = props;
  const [showDocPref, setShowDocPref] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [autoRenew, setAutoRenew] = useState(policyInfo.AutoRenew.IsAutoRenew);
  const [autoRenewInitialState, setAutoRenewInitialState] = useState(policyInfo.AutoRenew.IsAutoRenew);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showOptOutModal, setShowOptOutModal] = useState(false);
  const [showUnableToChangeModal, setShowUnableToChangeModal] = useState(false);
  const [showRenewalWindowModal, setShowRenewalWindowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [isInRenewalPeriodResult] = useState(isInRenewalPeriod(policyInfo.DaysOfCoverLeft));

  const toggleShowDocPref = (e) => {
    e.preventDefault();
    if (autoRenewInitialState === autoRenew) {
      setShowDocPref(!showDocPref);
      setSubmitDisabled(!submitDisabled);
    }
  };

  const optOut = () => {
    trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptOut').text);
    setAutoRenew(false);
  };

  const optIn = () => {
    trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptIn').text);
    setAutoRenew(true);
  };

  const blockOptIn = () => {
    if (!policyInfo.AutoRenew.IsAutoRenewChangeEligible && (!autoRenew && !autoRenewInitialState)) {
      trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptInNotAllowed').text);
      if (isInRenewalPeriodResult) {
        trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptInInRenewalNotAllowed').text);
        setTitle(pageModel.messages.RenewalInProgressDialogue.title);
        setMessage(pageModel.messages.RenewalInProgressDialogue.body);
        setShowRenewalWindowModal(true);
      } else {
        setShowUnableToChangeModal(true);
      }
    }
  };

  const blockOptOut = () => {
    if (!policyInfo.AutoRenew.IsAutoRenewChangeEligible && (autoRenew && autoRenewInitialState)) {
      if (isInRenewalPeriodResult) {
        trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptOutInRenewalNotAllowed').text);
        setTitle(pageModel.messages.RenewalInProgressDialogue.title);
        setMessage(pageModel.messages.RenewalInProgressDialogue.body);
        setShowRenewalWindowModal(true);
      } else {
        setShowUnableToChangeModal(true);
      }
    }
  };

  const confirmAutoRenewal = async () => {
    try {
      setShowOptOutModal(false);
      setWaitingForResponse(true);

      const response = await UpdateAutorenewal(policyInfo.Id, { isAutoRenew: autoRenew, policyId: policyInfo.PolicyId });
      if (response.responseCode === ResponseCode.SUCCESS) {
        // Tagging service
        if (autoRenew) {
          trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptInSubmitConfirm').text);
        } else {
          trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptOutSubmitConfirm').text);
        }
        await GetLatestPolicyInfo().then(() => {
          setShowSuccessModal(true);
          setTitle(pageModel?.messages.successTitle);
          setMessage(pageModel?.messages.successMessage);
          setAutoRenewInitialState(autoRenew);
        })
          .catch(() => {
            setShowSuccessModal(true);
            setTitle(pageModel?.messages.failTitle);
            setMessage(pageModel?.messages.failMessage);
          });
      }
    } catch (error) {
      setAutoRenew(autoRenewInitialState);
      // Tagging service
      if (autoRenew) {
        trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptInError').text);
      } else {
        trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptOutError').text);
      }

      setShowSuccessModal(true);
      setTitle(pageModel?.messages.failTitle);
      setMessage(pageModel?.messages.failMessage);
    }
  };

  const cancelOptOut = () => {
    // Tagging service
    trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptOutSubmitCancel').text);
    setShowOptOutModal(false);
    setAutoRenew(autoRenewInitialState);
  };

  const saveDocumentPreference = () => {
    setWaitingForResponse(true);
    confirmAutoRenewal();
  };

  const confirmOptOut = (e) => {
    e.preventDefault();
    setShowOptOutModal(false);
    saveDocumentPreference();
  };

  const submitAutoRenewalPreferences = (e) => {
    e.preventDefault();

    // Tagging service
    if (autoRenew) {
      trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptInSubmit').text);
    } else {
      trackEvent(pageModel.tagging.find((tag) => tag.key === 'AutoRenewOptOutSubmit').text);
    }

    if (!autoRenew && (autoRenewInitialState !== autoRenew) && policyInfo.AutoRenew.IsAutoRenewChangeEligible) {
      setShowOptOutModal(true);
    } else {
      saveDocumentPreference();
    }
  };

  return (
    <div className="row border pt-4 pb-3 mt-3">
      <div className="account-preferences__icon col-sm-1 col-1">
        <img src={pageModel?.image.url} alt="Email" />
      </div>
      <div className="col-sm-11 col-11">
        <h3 className="account-preferences__prefs-title">{pageModel?.title}</h3>
        <p className="action edit">
          <button
            type="button"
            className="MyRacForm__button-link link Panel__button"
            onClick={toggleShowDocPref}
          >
            {pageModel?.button}
            {' '}
            &gt;
          </button>
        </p>
        {showDocPref
          && (
            <div>
              <ProtectedForm formChanged={autoRenewInitialState !== autoRenew}>
                <p>{ReactHtmlParser(pageModel?.text)}</p>
                {isInRenewalPeriodResult ? <p className="warning">{ReactHtmlParser(pageModel?.optOutDisabledMessage) }</p> : null}
                <div className="myrac-doc__efulfilment--wrapper">
                  <div className="myrac__checkbox--wrapper">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <label
                      className="myrac__checkbox myrac__checkbox--label cursor__pointer"
                      htmlFor="opt-in"
                      onClick={blockOptIn}
                    >
                      <input
                        id="opt-in"
                        className="myrac__checkbox--input autorenew"
                        type="radio"
                        name="autorenew"
                        checked={autoRenew}
                        disabled={!policyInfo.AutoRenew.IsAutoRenewChangeEligible || isInRenewalPeriodResult}
                        onChange={optIn}
                        onClick={blockOptIn}
                      />
                      <span className="myrac__checkbox--span" />
                      <span
                        className="myrac__checkbox--text checkbox--verticalAlign"
                      >
                        {pageModel?.options.optIn}
                      </span>
                    </label>
                  </div>
                  <div className="myrac__checkbox--wrapper">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <label
                      className="myrac__checkbox--text myrac__checkbox--label autorenew"
                      htmlFor="opt-out"
                      onClick={blockOptOut}
                    >
                      <input
                        id="opt-out"
                        className="myrac__checkbox--input autorenew"
                        type="radio"
                        name="autorenew"
                        checked={!autoRenew}
                        disabled={!policyInfo.AutoRenew.IsAutoRenewChangeEligible || isInRenewalPeriodResult}
                        onClick={blockOptOut}
                        onChange={optOut}
                      />
                      <span className="myrac__checkbox--span" />
                      <span
                        className="myrac__checkbox--text checkbox--verticalAlign"
                      >
                        {pageModel?.options.optOut}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="account-preferences__button-wrapper">
                  <button
                    type="button"
                    onClick={submitAutoRenewalPreferences}
                    className="btn btn-cta btn-primary"
                    data-dismiss="modal"
                    disabled={submitDisabled || autoRenewInitialState === autoRenew || waitingForResponse}
                  >
                    {waitingForResponse
                      ? <i className="fas fa-spinner fa-pulse" />
                      : pageModel?.submit}
                  </button>
                </div>
              </ProtectedForm>
            </div>
          )}
        {showSuccessModal
          && (
            <PopupModal
              resultTitle={title}
              resultText={message}
              modalOk={pageModel.modalOk}
              action={() => {
                setShowSuccessModal(false);
                setWaitingForResponse(false);
              }}
              modalType={modalTypes.infoModal}
            />
          )}
        {showOptOutModal
          && (
            <PopupModal
              resultTitle={pageModel.messages.confirmDialogue.title}
              resultText={pageModel.messages.confirmDialogue.body.optOut}
              modalOk={pageModel.messages.confirmDialogue.confirmButton}
              cancelButton={pageModel.messages.confirmDialogue.cancelButton}
              action={confirmOptOut}
              cancelAction={cancelOptOut}
              modalType={modalTypes.infoModal}
            />
          )}
        {showRenewalWindowModal
          && (
            <PopupModal
              resultTitle={title}
              resultText={message}
              modalOk={pageModel.modalOk}
              action={() => setShowRenewalWindowModal(false)}
              modalType={modalTypes.infoModal}
            />
          )}
        {showUnableToChangeModal
          && (
            <PopupModal
              resultTitle={pageModel.messages.optInDialogue.title}
              resultText={pageModel.messages.optInDialogue.body}
              modalOk={pageModel.modalOk}
              action={() => setShowUnableToChangeModal(false)}
              modalType={modalTypes.infoModal}
            />
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
});

export default connect(mapStateToProps)(AutorenewalPanel);
