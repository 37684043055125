import React from 'react';
import RenderLink from 'components/RenderLink';

const PasswordPanel = (props) => {
  const { pageModel } = props;
  return (
    <div className="col-12 col-sm-6">
      <div className="tile box">
        <h4>{pageModel.title}</h4>
        <div className="Info">
          <p className="primary">{pageModel.password}</p>
          <p className="action edit">
            <RenderLink link={pageModel.link}>
              {pageModel.link.text}
              {' '}
              &gt;
            </RenderLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PasswordPanel;
