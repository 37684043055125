/* eslint-disable max-classes-per-file */
import React from 'react';
import {
  monthByNumber, daysInMonth,
} from './helper';

import DropdownDate from './date';

export default DropdownDate;

export class YearPicker extends React.Component {
  constructor(props) {
    super(props);
    this.renderYearOptions = this.renderYearOptions.bind(this);
  }

  renderYearOptions() {
    const {
      start, end, optionClasses, reverse, defaultValue,
    } = this.props;
    const startYear = start || 1900;
    const endYear = end || new Date().getFullYear();
    const years = [];
    if (startYear <= endYear) {
      for (let i = startYear; i <= endYear; ++i) { years.push(i); }
    } else {
      for (let i = endYear; i >= startYear; --i) { years.push(i); }
    }
    if (reverse) {
      years.reverse();
    }
    const yearOptions = [];
    yearOptions.push(
      <option value="" key={-1} className={optionClasses || null}>
        {defaultValue || ''}
      </option>,
    );
    years.forEach((year, index) => {
      yearOptions.push(
        // eslint-disable-next-line react/no-array-index-key
        <option value={year} key={index} className={optionClasses || null}>
          {year}
        </option>,
      );
    });
    return yearOptions;
  }

  render() {
    const {
      id, classes, name, required, disabled, onChange, value,
    } = this.props;
    return (
      <select
        id={id || null}
        className={classes || null}
        name={name || null}
        required={required === true}
        disabled={disabled === true}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        {this.renderYearOptions()}
      </select>
    );
  }
}

export class MonthPicker extends React.Component {
  constructor(props) {
    super(props);
    this.renderMonthOptions = this.renderMonthOptions.bind(this);
  }

  renderMonthOptions() {
    const today = new Date();
    let months = [];
    let month = 11;
    const {
      endYearGiven, year, numeric, caps, short, optionClasses, defaultValue,
    } = this.props;
    if (!endYearGiven) {
      if (year && parseInt(year) === today.getFullYear()) {
        month = today.getMonth();
      }
    }
    if (numeric) {
      for (let i = 0; i <= month; ++i) { months.push((i + 1).toString()); }
    } else {
      for (let i = 0; i <= month; ++i) { months.push(monthByNumber[i]); }
      if (caps) {
        months = months.map((m) => m.toUpperCase());
      }
      if (short) {
        months = months.map((m) => m.substring(0, 3));
      }
    }
    const monthOptions = [];
    monthOptions.push(
      <option
        value=""
        key={-1}
        className={optionClasses || null}
      >
        {defaultValue || ''}
      </option>,
    );
    months.forEach((m, index) => {
      monthOptions.push(
        <option
          value={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={optionClasses || null}
        >
          {m}
        </option>,
      );
    });
    return monthOptions;
  }

  render() {
    const {
      id, classes, name, required, disabled, onChange, value,
    } = this.props;
    return (
      <select
        id={id || null}
        className={classes || null}
        name={name || null}
        required={required === true}
        disabled={disabled === true}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        {this.renderMonthOptions()}
      </select>
    );
  }
}

export class DayPicker extends React.Component {
  constructor(props) {
    super(props);
    this.renderDayOptions = this.renderDayOptions.bind(this);
  }

  renderDayOptions() {
    const {
      month, year, endYearGiven, optionClasses, defaultValue,
    } = this.props;
    const mymonth = (month) ? parseInt(month) : null;
    const myyear = (year) ? parseInt(year) : null;
    let days;
    if (mymonth) {
      if (myyear && myyear % 4 === 0 && mymonth === 1) {
        days = 29;
      } else {
        days = daysInMonth[mymonth];
      }
    } else {
      days = 31;
    }
    const today = new Date();
    if (!endYearGiven) {
      if (myyear === today.getFullYear() && mymonth === today.getMonth()) {
        days = today.getDate();
      }
    }
    const dayOptions = [];
    dayOptions.push(
      <option
        value=""
        key={-1}
        className={optionClasses || null}
      >
        {defaultValue || ''}
      </option>,
    );
    for (let i = 1; i <= days; ++i) {
      dayOptions.push(
        <option
          value={i}
          key={i}
          className={optionClasses || null}
        >
          {i}
        </option>,
      );
    }
    return dayOptions;
  }

  render() {
    const {
      id, classes, name, required, disabled, onChange, value,
    } = this.props;
    return (
      <select
        id={id || null}
        className={classes || null}
        name={name || null}
        onChange={(e) => onChange(e.target.value)}
        required={required === true}
        disabled={disabled === true}
        value={value}
      >
        {this.renderDayOptions()}
      </select>
    );
  }
}
