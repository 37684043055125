import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import styles from './MembershipCover.module.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Button from '../../../../../shared/components/Button/Button';
import { getClickAction } from '../../GridLayout/ManagedCta';
import MembershipStatus from '../MembershipStatus/MembershipStatus';

export const MembershipCover = (props) => {
  const { policy, pageModel } = props;

  const tile = pageModel && pageModel.tileContent ? pageModel.tileContent : null;

  const title = tile.coverProduct && tile.coverProduct.coverTitle ? tile.coverProduct.coverTitle : null;

  const extras = tile.breakdownExtras ? tile.breakdownExtras : null;

  const extrasArray = extras.breakDownExtras && Array.isArray(extras.breakDownExtras) && extras.breakDownExtras.length > 0 ? extras.breakDownExtras : null;

  const benefits = tile.coverProduct && tile.coverProduct.benefits ? tile.coverProduct.benefits : null;

  const getExtras = () => (
    <div className={styles.extrasContainer}>
      <h3 className={styles.title}>{extras.breakDownExtrasHeading}</h3>
      <ul className={styles.list}>
        {extrasArray.map((icon) => (
          <li className={styles.item} key={icon}>
            <div className={styles.inner}>{icon}</div>
          </li>
        ))}
      </ul>
    </div>
  );

  const getTileData = () => (
    <div className={styles.wrapper}>
      <div className={styles.items}>
        <div className={styles.row}>
          <h3 className={styles.title}>{title}</h3>
          <ul className={styles.coveredList}>
            {benefits.map((product) => (
              <li className={styles.benefit} key={product}>
                {product}
              </li>
            ))}
          </ul>
        </div>
        {extras && extrasArray ? getExtras() : null}
      </div>
      {!policy.policyInfo.IsPayg && tile.button ? (
        <div className={styles.upgradeCoverButton}>
          <Button
            buttonText={tile.button.buttonText}
            onClickHandler={(params) => getClickAction(pageModel.tileContent.button.callToAction.actionType, {
              ...params, analyticsTag: { eventTag: 'MyRAC_Overview_view_breakdown_package' }, url: pageModel.tileContent.button.callToAction.url,
            })}
            chevronWhite
            small
            fullWidth={false}
          />
        </div>
      ) : null}
    </div>
  );
  return (
    <div className={styles.membershipCoverContainer}>
      <MembershipStatus pageModel={pageModel} />
      {!Object.keys(policy.policyInfo).length && !Object(pageModel) ? (
        <div className={styles.loader}>
          <Loader type="Oval" color="#f95108" height={50} width={50} />
        </div>
      ) : null}
      {Object.keys(policy.policyInfo).length && Object(pageModel) ? getTileData() : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
});

export default connect(mapStateToProps)(MembershipCover);
