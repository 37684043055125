import React, { useState } from 'react';
import styles from './UpgradePanel.module.scss';

const UpgradePanel = ({ icon, iconAlt, title, description, additionalDescription, confirmationType }) => {
  const [containerStyles] = useState(confirmationType ? { backgroundColor: 'transparent' } : null);

  return (
    <div className={styles.container} style={containerStyles}>
      <div className={styles.iconTitleContainer}>
        <img src={icon} alt={iconAlt} className={styles.icon} />
        <h2 className={styles.title}>{title}</h2>
      </div>
      <div className={styles.description}>{description}</div>
      {additionalDescription ? <div className={styles.additionalDescription}>{additionalDescription}</div> : null }
    </div>
  );
};

export default UpgradePanel;
