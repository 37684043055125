import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import block from 'bem-css-modules';
import styles from './PaymentFailedPage.module.scss';

import trackEvent from '../../../services/AnalyticsService';
import { ROOT } from '../../../constants/routeConstants';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(styles);

const PaymentFailedPage = () => {
  const { state } = useLocation();
  const { pageModel } = state || {}; // Read values passed in state
  const navigate = useNavigate();
  useEffect(() => {
    trackEvent(pageModel?.errorPage.analyticsTag);
  }, []); // eslint-disable-line

  return (
    <div className="row">
      <div className="col-md-12">
        <h3 className={`${bem('errorPage-header')}`}>
          {pageModel?.errorPage?.errorPageTitle}
        </h3>
        <div className={`${bem('errorPage-details-wrapper')}`}>
          <div className={`${bem('errorPage-info-wrapper')}`}>
            {pageModel?.errorPage?.errorPageDescription && (ReactHtmlParser(pageModel?.errorPage.errorPageDescription))}
          </div>
          <div className={`${bem('errorPage-image-wrapper')}`}>
            <div className={`${bem('errorPage-image')}`} />
          </div>
        </div>
        <div className={`${bem('back-button-container')}`}>
          <button onClick={() => navigate(ROOT, { state: {} })} type="button" fluid="true">
            {pageModel?.errorPage?.backButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailedPage;
