import { combineReducers } from 'redux';
import customer from 'reducers/customerInfoReducer';
import policy from 'reducers/policyInfoReducer';
import session from 'reducers/sessionReducer';
import edit from 'reducers/editableButtonsReducer';
import content from 'reducers/content';
import documents from 'reducers/documentsReducer';
import step from 'reducers/journeyManagerStepReducer';
import vehicles from 'reducers/vehicleInfoReducer';
import renewal from 'reducers/renewalReducer';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import ldRedux from 'ld-redux';

const { routerReducer } = createReduxHistoryContext({ history: createBrowserHistory() });

const appReducer = () => combineReducers({
  router: routerReducer,
  customer,
  policy,
  session,
  edit,
  content,
  documents,
  step,
  vehicles,
  renewal,
  featureToggle: ldRedux.reducer(),
});

export default appReducer;
