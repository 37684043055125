import React from 'react';

const FailureModal = (props) => {
  const { text, action } = props;
  return (
    <div className="modal-body failure-text">
      <div className="header">
        {text}
      </div>
      <div className="MyRacForm__center_button">
        <button
          className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide"
          type="button"
          data-dismiss="modal"
          onClick={action}
        >
          close
        </button>
      </div>
    </div>
  );
};

export default FailureModal;
