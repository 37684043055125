import React from 'react';
import { connect } from 'react-redux';

import * as SessionActions from '../../../actions/sessionActions';
import { generalMessages } from '../../../constants/cms/messageConstants';
import { getMessage } from '../../../services/MessageService';

import GridLayout from './GridLayout/GridLayout';
import InfoBanner from '../InfoBanner';

export const MembershipOverview = (props) => {
  const { featureFlags, session } = props;
  // check for IE11 as CSS Grid is not supported on IE, if true display old layout for membership overview
  const browserIsIE11 = navigator.userAgent.toUpperCase().indexOf('TRIDENT/') !== -1;
  const unsupportedBrowserBannerMessage = getMessage(generalMessages.UNSUPPORTED_BROWSER_MESSAGE);
  const toggleBannerClosed = (e) => {
    e.preventDefault();
    const { updateUnsupportedBrowserBannerClosed } = props;
    updateUnsupportedBrowserBannerClosed(true);
  };

  const displayUnsupportedBrowserBanner = () => (
    featureFlags?.showUnsupportedBrowserBanner && browserIsIE11
    && !session.bannerHidden?.unsupportedBrowserBanner
      ? <InfoBanner switchBannerDisplay={toggleBannerClosed} bannerMessage={unsupportedBrowserBannerMessage} /> : null
  );

  return (
    <>
      {displayUnsupportedBrowserBanner()}
      {featureFlags?.isLDReady && <GridLayout />}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateUnsupportedBrowserBannerClosed: (bannerHidden) => {
    dispatch(SessionActions.setBannerHidden({ unsupportedBrowserBanner: (bannerHidden) }));
  },
});

const mapStateToProps = (state) => ({
  session: state.session,
  featureFlags: state.featureToggle,
  customer: state.customer,
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipOverview);
