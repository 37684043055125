import React from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import styles from './ReminderItem.module.scss';
import TextButton from '../../../shared/components/TextButton/TextButton';
import SMRButton from './SMRButton';

import {
  DUE_DATE_TEXT,
  DUE_TOMORROW_DATE_TEXT,
  EXPIRED_DATE_TEXT,
  EXPIRED_YESTERDAY_DATE_TEXT,
  EXPIRES_DATE_TEXT,
  getIcon,
} from '../../../utils/vehicleHelpers';

const ReminderItem = ({ reminder, buttonText, buttonAction, loading, reminderSMRBtn, vehicleRegistration }) => {
  const formatReminderDateText = () => {
    if (reminder?.text === EXPIRED_DATE_TEXT || reminder?.text === EXPIRED_YESTERDAY_DATE_TEXT) {
      return <span className={styles.expired}>{reminder.text} {reminder.formattedDate}</span>;
    }
    if (reminder?.text === EXPIRES_DATE_TEXT || reminder?.text === DUE_TOMORROW_DATE_TEXT || reminder?.text === DUE_DATE_TEXT) {
      // Is the date within 30 days?
      if (differenceInDays(parseISO(reminder?.date), Date.now()) <= 30) {
        return <span className={styles.expired}>{reminder.text} {reminder.formattedDate}</span>;
      }
      return `${reminder?.text} ${reminder.formattedDate}`;
    }
    return reminder?.text;
  };

  return (
    <div className={styles.container}>
      <i className={`fs-5 ${getIcon(reminder?.reminderType)} ${styles.icon}`} />
      <div className={styles.infoContainer}>
        <h4 className={styles.title}>{reminder?.title}</h4>
        <p className={styles.text}>{formatReminderDateText()}</p>
        {/* eslint-disable-next-line no-nested-ternary */}
        {buttonText && buttonAction ? (loading ? <i className="fas fa-spinner fa-pulse" /> : <TextButton buttonText={buttonText} showArrow orange noUnderline onClickHandler={buttonAction} />) : null }
        {reminderSMRBtn && <SMRButton data={reminderSMRBtn} vehicleRegistration={vehicleRegistration} />}
      </div>
    </div>
  );
};

export default ReminderItem;
