export const USER_JOURNEY_ACTION_NAMES = {
  addCurrentUrlPath: 'ADD_CURRENT_URL_PATH',
  addNewUrlPath: 'ADD_NEW_URL_PATH',
  deletePreviousUrlPath: 'DELETE_PREVIOUS_URL_PATH',
};

export const storePreviousUrlPathAction = (urlPath) => ({
  type: USER_JOURNEY_ACTION_NAMES.addNewUrlPath,
  payload: urlPath,
});

export const deletePreviousUrlPathAction = () => ({
  type: USER_JOURNEY_ACTION_NAMES.deletePreviousUrlPath,
});

export const storeCurrentUrlPathAction = (urlPath) => ({
  type: USER_JOURNEY_ACTION_NAMES.addCurrentUrlPath,
  payload: urlPath,
});
