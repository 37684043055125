import React from 'react';
import * as Sentry from '@sentry/react';
import { getState } from 'store';

const META_DATA_CONTEXT = 'Meta data';

const SentryWrapper = ({ children }) => {
  // Don't call Sentry API if we are working locally
  if (process.env.REACT_APP_ENV === 'local') {
    return children;
  }

  const {
    session: {
      uid,
    },
  } = getState();

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: [
      'A network error occurred.',
    ],
    tracesSampleRate: 0.1,
    normalizeDepth: 5,
    release: process.env.REACT_APP_VERSION,
    integrations: [
      new Sentry.BrowserTracing(),
    ],
    beforeSend(event) {
      const metaDataContext = {
        gigyaId: uid,
      };

      // eslint-disable-next-line no-param-reassign
      event.contexts = {
        ...event.contexts,
        [META_DATA_CONTEXT]: {
          ...event.contexts?.[META_DATA_CONTEXT],
          ...metaDataContext,
        },
      };

      return event;
    },
  });

  return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>;
};

export default SentryWrapper;
