import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import trackEvent from 'services/AnalyticsService';
import Button from 'shared/components/Button/Button';

const Alert = (props) => {
  const { toggleShow, heading, message, eventTag, isButton, buttonText, linkRef } = props;
  const [alertClass, setAlertClass] = useState('show');

  const navigate = useNavigate();

  const handleCTA = (e) => {
    e.preventDefault();
    if (eventTag) {
      // Append action to tag as event tag name should always be the same
      trackEvent(`${eventTag}clicked`);
    }
    toggleShow(false);
    navigate(linkRef);
  };

  const close = (e) => {
    e.preventDefault();
    if (eventTag) {
      // Append action to tag as event tag name should always be the same
      trackEvent(`${eventTag}dismiss`);
    }
    setAlertClass('collapse');
    toggleShow(false);
  };

  useEffect(() => {
    if (eventTag) {
      trackEvent(eventTag);
    }
  }, [eventTag]);

  return (
    <div className={`alert alert-myrac fade ${alertClass}`} role="alert">
      <div className="position-absolute top-0 end-0 px-3 py-2">
        <span role="button" tabIndex={0} type="button" onKeyDown={close} onClick={close} className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div className="row align-items-center p-4">
        <div className="col-md-9 col-sm-12">
          <h3 className="orange">{heading}</h3>
          <p>{message}</p>
        </div>
        <div className="col-md-3">
          {isButton && (
            <Button
              onClickHandler={linkRef && (handleCTA)}
              buttonText={buttonText}
              chevronWhite
              buttonStyles={{ width: '85%' }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
