import { COVER_STATES } from 'constants/vehicleConstants';
import { format, isAfter, isBefore, isToday, isYesterday, isTomorrow, differenceInDays } from 'date-fns';

export const getIcon = (reminderType) => {
  if (reminderType) {
    switch (reminderType.toLowerCase()) {
      case 'mot':
        return 'myrac-icon-mot';
      case 'insurance':
        return 'myrac-icon-insurance-doc';
      case 'tax':
        return 'myrac-icon-vehicle-tax';
      case 'service':
        return 'myrac-icon-wrench';
      default:
        return '';
    }
  }
  return '';
};

export const getReminderText = (reminderType) => {
  if (reminderType) {
    switch (reminderType.toLowerCase()) {
      case 'mot':
        return 'MOT expiry';
      case 'insurance':
        return 'Car insurance expiry';
      case 'tax':
        return 'Vehicle tax expiry';
      case 'service':
        return 'Service due';
      default:
        return '';
    }
  }
  return '';
};

export const getVehicle = (vehicleId, vehicles) => vehicles && vehicles.find((v) => v.vehicleId === vehicleId);
export const getReminder = (vehicleId, reminders) => reminders && reminders.find((r) => r.vehicleId === vehicleId);

export const filterVehicleByCoverState = (vehicles, ...coverState) => {
  if (vehicles) {
    return vehicles.filter((v) => coverState.includes(v.coverState));
  }
  return undefined;
};

export const regNoRegex = /^[a-zA-Z0-9\s]*$/;
export const yearOfManufactureRegex = /^[0-9]*$/;
export const makeRegex = /^[a-zA-Z0-9\s.,/()-]*$/;
export const modelRegex = /^[a-zA-Z0-9()\s.,/+-]*$/;

export const getHelperTextByCover = (cover, pageModel) => {
  switch (cover) {
    case COVER_STATES.IS_COVERED_BY_BREAKDOWN:
    case COVER_STATES.IS_HYBRID_COVERED_BY_BREAKDOWN:
      return pageModel.vehicleBased;
    case COVER_STATES.IS_COVERED_FOR_REGISTERED_USERS:
      return pageModel.personalBased;
    case COVER_STATES.IS_HYBRID_COVERED_FOR_REGISTERED_USERS:
      return pageModel.personalBased;
    case COVER_STATES.IS_MEMBERSHIP_ONLY:
      return pageModel.notCovered;
    case COVER_STATES.IS_PAY_AS_YOU_GO_COVERED:
      return pageModel.payAsYouGo;
    case COVER_STATES.IS_NOT_ACTIVE:
    case COVER_STATES.IS_NOT_ACTIVE_REGISTERED_USER:
    case COVER_STATES.IS_NOT_ACTIVE_BREAKDOWN:
      return pageModel.notActive;
    case COVER_STATES.NONE:
    default:
      return pageModel.notCovered;
  }
};

export const getHelperTextByPolicy = (policy, isNotCovered, pageModel) => {
  if (!policy.CoverType) { return null; }

  if (policy.CoverType.Title === 'Membership Only') {
    return pageModel.notCovered;
  }
  if (policy.IsPayg) {
    return pageModel.notCovered;
  }
  if (policy.MembershipType === 'Personal') {
    return pageModel.covered;
  }
  if (isNotCovered) {
    return pageModel.notCovered;
  }
  if (policy.MembershipType === 'Vehicle') {
    return pageModel.notCovered;
  }
  if (policy.MembershipType === 'Hybrid') {
    return pageModel.covered;
  }
  return null;
};

export const determineVehicleInfoByCoverState = (coverState, pageModel) => {
  const response = {
    message: '',
    coverCssName: 'vehiclereminders-default-box',
    vrmCssName: '',
    vrmTextCssName: '',
  };

  switch (coverState) {
    case COVER_STATES.IS_COVERED_BY_BREAKDOWN:
    case COVER_STATES.IS_HYBRID_COVERED_BY_BREAKDOWN:
      response.message = pageModel.vehicleBased;
      response.coverCssName = 'vehiclereminders-breakdowncover-box';
      response.vrmCssName = 'vrm-green';
      response.vrmTextCssName = 'vrm-text-green';
      break;
    case COVER_STATES.IS_COVERED_FOR_REGISTERED_USERS:
      response.message = pageModel.personalBased;
      response.coverCssName = 'vehiclereminders-breakdowncover-box';
      response.vrmCssName = 'vrm-green';
      response.vrmTextCssName = 'vrm-text-green';
      break;
    case COVER_STATES.IS_HYBRID_COVERED_FOR_REGISTERED_USERS:
      response.message = pageModel.personalBased;
      response.vrmCssName = 'vrm-yellow';
      response.vrmTextCssName = 'vrm-text-yellow';
      break;
    case COVER_STATES.IS_MEMBERSHIP_ONLY:
      response.message = pageModel.notCovered;
      break;
    case COVER_STATES.IS_PAY_AS_YOU_GO_COVERED:
      response.message = pageModel.payAsYouGo;
      response.vrmCssName = 'vrm-yellow';
      response.vrmTextCssName = 'vrm-text-yellow';
      break;
    case COVER_STATES.IS_NOT_ACTIVE:
    case COVER_STATES.IS_NOT_ACTIVE_REGISTERED_USER:
    case COVER_STATES.IS_NOT_ACTIVE_BREAKDOWN:
      response.message = pageModel.lapsedOrCancelled;
      response.vrmCssName = 'vrm-red';
      response.vrmTextCssName = 'vrm-text-red';
      break;
    case COVER_STATES.NONE:
    default:
      response.message = pageModel.notCovered;
      break;
  }

  return response;
};

export const determineVehicleMessagesBasedOnPolicy = (policy, isNotCovered, pageModel) => {
  const response = {
    vehicleSectionTitle: null,
    addVehicleHelperText: null,
  };

  if (!policy.CoverType) { return response; }

  if (policy.CoverStatus === 'Lapsed' || policy.CoverStatus === 'Cancelled') {
    response.vehicleSectionTitle = pageModel.sectionTitles.lapsedOrCancelled;
    return response;
  }
  if (policy.CoverType.Title === 'Membership Only') {
    response.vehicleSectionTitle = pageModel.sectionTitles.membershipOnly;
    response.addVehicleHelperText = pageModel.notCovered;
    return response;
  }
  if (policy.IsPayg) {
    response.vehicleSectionTitle = pageModel.sectionTitles.payAsYouGo;
    response.addVehicleHelperText = pageModel.notCovered;
    return response;
  }
  if (policy.MembershipType === 'Personal') {
    response.vehicleSectionTitle = pageModel.sectionTitles.personalBased;
    response.addVehicleHelperText = pageModel.covered;
    return response;
  }
  if (isNotCovered) {
    response.vehicleSectionTitle = pageModel.sectionTitles.notCovered;
    response.addVehicleHelperText = pageModel.notCovered;
    return response;
  }
  if (policy.MembershipType === 'Vehicle') {
    response.vehicleSectionTitle = pageModel.sectionTitles.vehicleBased || '';
    response.addVehicleHelperText = pageModel.notCovered || '';
    return response;
  }
  if (policy.MembershipType === 'Hybrid') {
    response.vehicleSectionTitle = pageModel.sectionTitles.hybrid;
    response.addVehicleHelperText = pageModel.covered;
    return response;
  }
  return response;
};

export const DEFAULT_DATE_TEXT = 'Not set';
export const EXPIRES_DATE_TEXT = 'Expires';
export const EXPIRED_DATE_TEXT = 'Expired';
export const DUE_DATE_TEXT = 'Due today';
export const DUE_TOMORROW_DATE_TEXT = 'Due tomorrow';
export const EXPIRED_YESTERDAY_DATE_TEXT = 'Expired yesterday';

export const createReminderItemDateText = (reminder) => {
  if (!reminder.date) {
    return { text: DEFAULT_DATE_TEXT, formattedDate: null };
  }
  const reminderDate = new Date(reminder.date);
  const today = new Date();
  const formattedDate = format(reminderDate, 'd/MM/yy');

  if (isToday(reminderDate)) {
    return { text: DUE_DATE_TEXT, formattedDate };
  }
  if (isTomorrow(reminderDate)) {
    return { text: DUE_TOMORROW_DATE_TEXT, formattedDate };
  }
  if (isYesterday(reminderDate)) {
    return { text: EXPIRED_YESTERDAY_DATE_TEXT, formattedDate };
  }

  if (isBefore(today, reminderDate)) {
    return { text: EXPIRES_DATE_TEXT, formattedDate };
  }
  if (isAfter(today, reminderDate)) {
    return { text: EXPIRED_DATE_TEXT, formattedDate };
  }
  return null;
};

export const formatReminderItems = (remindersList) => {
  const emptyReminder = {
    set: false,
    text: DEFAULT_DATE_TEXT,
    date: undefined,
    emailReminderEnabled: undefined,
    expiresIn: undefined,
    reminderType: undefined,
    vehicleId: undefined,
  };
  let reminders = {
    service: { ...emptyReminder, reminderType: 'Service', title: 'Service' },
    tax: { ...emptyReminder, reminderType: 'Tax', title: 'Vehicle Tax' },
    mot: { ...emptyReminder, reminderType: 'MOT', title: 'MOT' },
    insurance: { ...emptyReminder, reminderType: 'Insurance', title: 'Insurance' },
  };
  remindersList.forEach((reminder) => {
    const proppertyName = [reminder.reminderType.toLowerCase().replace(/\s/g, '')];
    const { text, formattedDate } = createReminderItemDateText(reminder);
    reminders = { ...reminders, [proppertyName]: { ...reminders[proppertyName], ...reminder, set: true, text, formattedDate } };
  });
  return reminders;
};

export const createReminderItem2DateText = (reminder) => {
  if (!reminder.date) {
    return DEFAULT_DATE_TEXT;
  }
  const reminderDate = new Date(reminder.date);
  const today = new Date();

  if (isToday(reminderDate)) {
    return DUE_DATE_TEXT;
  }
  if (isTomorrow(reminderDate)) {
    return DUE_TOMORROW_DATE_TEXT;
  }
  if (isYesterday(reminderDate)) {
    return EXPIRED_YESTERDAY_DATE_TEXT;
  }
  if (isBefore(today, reminderDate)) {
    const numberOfDays = differenceInDays(reminderDate, today) + 1; // Date is 1 day off without + 1
    if (numberOfDays === 1) {
      return `Expires in ${numberOfDays} day`;
    }
    return `Expires in ${numberOfDays} days`;
  }
  if (isAfter(today, reminderDate)) {
    const numberOfDays = differenceInDays(today, reminderDate) + 1; // Date is 1 day off without + 1
    if (numberOfDays === 1) {
      return `Expired ${numberOfDays} day ago`;
    }
    return `Expired ${numberOfDays} days ago`;
  }
  return null;
};
