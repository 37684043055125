import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from '@orrisroot/react-html-parser';

const { string } = PropTypes;

const InformationBlock = ({ fields }) => <div id={fields.uid}>{ReactHtmlParser(fields.informationhtml)}</div>;

InformationBlock.propTypes = {
  informationhtml: string,
  uid: string,
};

InformationBlock.defaultProps = {
  informationhtml: '',
  uid: '',
};

export default InformationBlock;
