import React, { useEffect } from 'react';
import useViewport from '../../utils/useViewportHelper';
import trackEvent from '../../services/AnalyticsService';
import Button from '../../shared/components/Button/Button';

const PaymentIssue = () => {
  useEffect(() => {
    trackEvent('MyRAC_Page_DirectDebit_Error');
  }, []);

  const telephoneNo = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (useViewport().isMobile) {
      return <a href="tel:+443330702799" onClick={() => { trackEvent('MyRAC_CTA_DirectDebit_Error'); }}>0333 070 2799</a>;
    }
    return <span className="orange">0333 070 2799</span>;
  };

  const ctaAction = () => {
    trackEvent('MyRAC_CTA_DirectDebit_Error');
    window.open('tel:+443330702799', '_self');
  };

  return (
    <div className="container din-font">
      <div className="row">
        <div className="col-sm-8">
          <h2>We’ll help you get back on track</h2>
          <p className="mt-3">We couldn’t collect payment details for your RAC membership so you won’t be covered if you break down. But don’t worry, we can help you keep your cover - and your FREE RAC rewards too.</p>
          {useViewport().isMobile && (
            <div className="mb-3">
              <Button
                onClickHandler={() => ctaAction()}
                buttonText="Call Us"
                chevronWhite
                fullWidth
                rounded
              />
            </div>
          )}
          <p>We understand that circumstances can change. So if you need to make other arrangements, there are lots of options to make sure you get the right breakdown cover, at the right price.</p>
        </div>
        <div className="col-sm-4">
          <div className="border border-4 border-orange rounded-3 w-100 mb-4 py-4 px-4">
            <h2 className="fs-3">Get in touch</h2>
            <p>We’ll help you find the best way to stay covered. Call {telephoneNo()} <br /> 8am-7pm weekdays, 9am-5pm weekends.</p>
            {useViewport().isMobile && (
              <Button
                onClickHandler={() => ctaAction()}
                buttonText="Call Us"
                chevronWhite
                fullWidth
                rounded
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentIssue;
