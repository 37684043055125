import * as VehicleActions from 'actions/vehiclesInfoActions';
import FailureModal from 'components/VehicleRemindersPage/VehicleLookup/FailureModal';
import SearchUpdatingText from 'components/VehicleRemindersPage/VehicleLookup/SearchUpdatingText';
import Spinner from 'components/Spinner';
import { generalMessages, vehicleErrors } from 'constants/cms/messageConstants';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import { connect } from 'react-redux';
import { getMessage } from 'services/MessageService';
import { ResponseCode } from 'services/models/httpResponse';
import { FindVehicle, SaveVehicle, UpdateVehicle } from 'services/MyRacService';
import { isEmpty } from 'utils/utils_validation';
import {
  getHelperTextByCover, getHelperTextByPolicy, makeRegex, modelRegex, regNoRegex, yearOfManufactureRegex,
} from 'utils/vehicleHelpers';
import { addAttributesToNode } from 'utils/ContentHelpers';

const getDeleteVehicleButton = (canDelete, onDeleteCallback, pageModel) => {
  if (canDelete) {
    return (
      <button
        type="button"
        className="MyRacForm__button-link link btn-transparent"
        onClick={onDeleteCallback}
      >
        {ReactHtmlParser(pageModel.deleteVehicleButtonText)}
      </button>
    );
  }
  return null;
};

const getHelperText = (policy, isInEditMode, coverState, pageModel) => {
  if (!policy.policyInfo) { return null; }

  if (isInEditMode) {
    return getHelperTextByCover(coverState, pageModel.editHelperText);
  }
  return getHelperTextByPolicy(policy.policyInfo, false, pageModel.addHelperText);
};

const ManualAndListVehicle = (props) => {
  const {
    vehicledetails,
    session,
    policy,
    toggle,
    close,
    onDeleteCallback,
    isInEditMode,
    vehicleInfo,
    addVehicles,
    updateVehicles,
    pageModel,
    addedVehicleCallback,
    vehicles,
  } = props;

  const [showUpdateText, setShowUpdateText] = useState(false);
  const [searchingVehicle, setSearchingVehicle] = useState(false);
  const [inputs, setInputs] = useState(vehicledetails);
  const [invalidReg, setInvalidReg] = useState({ required: false, valid: false });
  const [invalidMake, setInvalidMake] = useState({ required: false, valid: false });
  const [invalidModel, setInvalidModel] = useState({ required: false, valid: false });
  const [invalidManufacturer, setInvalidManufacturer] = useState({ required: false, valid: false });
  const [vehicleError, setVehicleError] = useState(false);
  const [sameVehicle, setSameVehicle] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);
  const [invalidFormat, setInvalidFormat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oldReg, setOldReg] = useState('');

  useEffect(() => {
    setInputs(vehicledetails);
    setOldReg(vehicledetails.Registration);
  }, [vehicledetails]);

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    const updatedInputs = { ...inputs };
    updatedInputs[name] = value;
    setInputs(updatedInputs);

    // Clears the make, model, year when reg is empty, or the value changes
    if ((name === 'Registration' && value !== vehicledetails.Registration)) {
      setInputs({
        ...inputs,
        Registration: value,
        Make: '',
        Model: '',
        YearOfManufacture: '',
      });
    }

    if ((name === 'Registration') && isEmpty(value)) {
      setShowClearButton(false);
    } else {
      setShowClearButton(true);
    }
  };

  const validateForm = async () => {
    const {
      Registration, Make, Model, YearOfManufacture,
    } = inputs;

    setInvalidReg({ required: false, valid: false });
    setInvalidMake({ required: false, valid: false });
    setInvalidModel({ required: false, valid: false });
    setInvalidManufacturer({ required: false, valid: false });
    let validationError = false;
    if (isEmpty(Registration)) {
      validationError = true;
      setInvalidReg({ ...invalidReg, required: true });
    } else if (vehicles?.vehicleInfo?.reminderVehicles?.find((x) => x.Registration.toUpperCase() === Registration.toUpperCase())) {
      validationError = true;
      setInvalidReg({ ...invalidReg, required: false, dupe: true });
    }

    if (isEmpty(Make)) {
      validationError = true;
      setInvalidMake({ ...invalidMake, required: true });
    }
    if (isEmpty(Model)) {
      validationError = true;
      setInvalidModel({ ...invalidModel, required: true });
    }
    if (isEmpty(YearOfManufacture)) {
      validationError = true;
      setInvalidManufacturer({ ...invalidManufacturer, required: true });
    }
    if (!isEmpty(Registration) && (!regNoRegex.test(Registration))) {
      validationError = true;
      setInvalidReg({ valid: true });
    }
    if (!isEmpty(Make) && (!makeRegex.test(Make))) {
      validationError = true;
      setInvalidMake({ valid: true });
    }
    if (!isEmpty(Model) && (!modelRegex.test(Model))) {
      validationError = true;
      setInvalidModel({ valid: true });
    }
    if (!isEmpty(YearOfManufacture) && (!yearOfManufactureRegex.test(YearOfManufacture))) {
      validationError = true;
      setInvalidManufacturer({ valid: true });
    }

    if (!validationError) {
      return true;
    }
    return false;
  };

  const saveVehicle = async (e) => {
    e.preventDefault();
    const PassValidation = await validateForm();
    if (PassValidation) {
      try {
        setSearchingVehicle(true);
        setLoading(true);
        // normalize the Vehicle Registration to make value without spaces and in capital letters while keeping the rest of the input the same
        const modifiedInput = { ...inputs, Registration: inputs.Registration.replace(/\s/g, '').toUpperCase() };
        const savedVehicle = await SaveVehicle(session.uid, modifiedInput);
        if (savedVehicle.responseCode === ResponseCode.SUCCESS) {
          addVehicles(savedVehicle.payload);
          setShowUpdateText(true);
          setSearchingVehicle(false);
          addedVehicleCallback(savedVehicle.payload);
          setLoading(false);
        }
      } catch (err) {
        if (err.error?.statusCode === 422) {
          setSameVehicle(true);
        }
        setVehicleError(true);
        setShowUpdateText(false);
        setSearchingVehicle(false);
        setLoading(false);
      }
    }
  };

  const editSaveVehicle = async (e) => {
    e.preventDefault();
    const PassValidation = await validateForm();
    if (PassValidation) {
      try {
        setSearchingVehicle(true);
        setLoading(true);
        // normalize the Vehicle Registration to make value without spaces and in capital letters while keeping the rest of the input the same
        const modifiedInput = { ...inputs, canEditVehicle: true, Registration: inputs.Registration.replace(/\s/g, '').toUpperCase() };
        const editVehicle = await UpdateVehicle(session.uid, oldReg, modifiedInput, vehicleInfo.coverState);
        if (editVehicle.responseCode === ResponseCode.SUCCESS) {
          setShowUpdateText(true);
          setSearchingVehicle(false);
          setVehicleError(false);
          // Need callback with vehicle for this to work.
          addedVehicleCallback({ ...vehicledetails, ...modifiedInput });
          setLoading(false);
          updateVehicles(modifiedInput);
        }
      } catch (err) {
        if (err.error && err.error.statusCode === 422) {
          setSameVehicle(true);
        }
        setVehicleError(true);
        setShowUpdateText(false);
        setSearchingVehicle(false);
        setLoading(false);
      }
    }
  };

  const clearReg = (e) => {
    e.preventDefault();
    setInputs({
      ...inputs,
      Registration: '',
      Make: '',
      Model: '',
      YearOfManufacture: '',
    });
  };
  const findVehicle = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { Registration, vehicleId } = inputs;

    if (!isEmpty(Registration) && regNoRegex.test(Registration)) {
      setInvalidReg({
        valid: false,
        required: false,
      });
      setInvalidFormat(false);
      const findVehiclePayload = {
        Registration,
      };
      try {
        let vehicleSearchResult;
        const vehicleList = await FindVehicle(findVehiclePayload);
        if (vehicleList.responseCode === ResponseCode.SUCCESS) {
          vehicleSearchResult = vehicleList.payload;
          vehicleSearchResult.Registration = Registration;
          vehicleSearchResult.vehicleId = vehicleId;
          setInputs(vehicleSearchResult);
          setLoading(false);
        }
      } catch (err) {
        if (err.error?.statusCode === 400) {
          setInvalidReg({
            ...invalidReg,
            valid: true,
          });
        } else {
          setVehicleError(true);
        }
        setLoading(false);
      }
    } else {
      setInvalidReg({
        ...invalidReg,
        required: true,
      });
    }
  };

  const { canEditVehicle, canDeleteVehicle } = vehicleInfo;

  const title = isInEditMode ? pageModel.screenTitles.editTitle : pageModel.screenTitles.addTitle;

  let errMsg;
  if (sameVehicle) {
    errMsg = getMessage(vehicleErrors.VEHICLE_SAME_VEHICLE_ERROR);
  } else if (invalidFormat) {
    errMsg = getMessage(vehicleErrors.VEHICLE_INVALID_FORMAT);
  } else {
    errMsg = getMessage(vehicleErrors.VEHICLE_ERROR_MESSAGE);
  }

  if (showUpdateText && !searchingVehicle && !vehicleError) {
    toggle();
  }

  return (
    <>
      {!showUpdateText && !searchingVehicle && !vehicleError && (
        <div className="modal-body">
          <div>
            <h4 className="modal-title add-vehicle-title">{title}</h4>
          </div>
          <form noValidate className="MyRacForm model_info" name="vehicleRegForm">
            <div className="col-sm-12 col-12">
              <div className="col-sm-2 col-2 float-left text-right clear-input">
                <button
                  type="button"
                  aria-label="Edit vehicle"
                  className={`cross-icon ${(showClearButton || !isEmpty(inputs.Registration)) ? 'show' : 'hide'}`}
                  onClick={clearReg}
                  disabled={isInEditMode && !canEditVehicle}
                >
                  <i className="myrac-icon-cancel" />
                </button>
              </div>
              <div className="col-sm-8 col-8 float-left input-reg">
                <input
                  id="Registration"
                  className="MyRacForm__form-element MyRacForm__input-extra-height"
                  name="Registration"
                  type="text"
                  onChange={handleInputChange}
                  value={inputs.Registration}
                  readOnly={isInEditMode && !canEditVehicle}
                  placeholder="Reg"
                />
                {invalidReg.dupe && <span className="MyRacForm__error-msg">{pageModel.fieldValidation.registration.dupe}</span>}
                {invalidReg.valid && <span className="MyRacForm__error-msg">{pageModel.fieldValidation.registration.invalid}</span>}
                {invalidReg.required && <span className="MyRacForm__error-msg">{pageModel.fieldValidation.registration.required}</span>}
              </div>
              <div className="col-sm-2 col-2 float-left">
                {loading
                  ? <Spinner addClass="search-icon" iconSpinner />
                  : (
                    <button type="submit" aria-label="Search" className="search-icon" onClick={findVehicle} disabled={isInEditMode && !canEditVehicle}>
                      <i className="myrac-icon-RAC_search_icon" />
                    </button>
                  )}
              </div>
            </div>
          </form>
          <form noValidate className="MyRacForm model_info" name="vehicleForm">
            <div className="input-groups col-sm-8 col-8">
              <input
                id="Make"
                className="MyRacForm__form-element MyRacForm__input-extra-height"
                name="Make"
                type="text"
                onChange={handleInputChange}
                value={inputs.Make}
                placeholder="Make"
                required
                readOnly={isInEditMode && !canEditVehicle}
              />
              {invalidMake.valid && <span className="MyRacForm__error-msg">{pageModel.fieldValidation.make.invalid}</span>}
              {invalidMake.required && <span className="MyRacForm__error-msg">{pageModel.fieldValidation.make.required}</span>}
            </div>
            <div className="input-groups col-sm-8 col-8">
              <input
                id="Model"
                className="MyRacForm__form-element MyRacForm__input-extra-height"
                name="Model"
                type="text"
                onChange={handleInputChange}
                value={inputs.Model}
                placeholder="Model"
                required
                readOnly={isInEditMode && !canEditVehicle}
              />
              {invalidModel.valid && <span className="MyRacForm__error-msg">{pageModel.fieldValidation.model.invalid}</span>}
              {invalidModel.required && <span className="MyRacForm__error-msg">{pageModel.fieldValidation.model.required}</span>}
            </div>
            <div className="input-groups col-sm-8 col-8">
              <input
                id="yearOfManu"
                className="MyRacForm__form-element MyRacForm__input-extra-height"
                name="YearOfManufacture"
                type="number"
                onChange={handleInputChange}
                value={inputs.YearOfManufacture}
                placeholder="Year"
                required
                readOnly={isInEditMode && !canEditVehicle}
              />
              {invalidManufacturer.valid && <span className="MyRacForm__error-msg">{pageModel.fieldValidation.year.invalid}</span>}
              {invalidManufacturer.required && <span className="MyRacForm__error-msg">{pageModel.fieldValidation.year.required}</span>}
            </div>
            {isInEditMode && !canEditVehicle
              ? <p className="text-center text-danger">{ReactHtmlParser(pageModel.notAbleToEdit)}</p>
              : ReactHtmlParser(getHelperText(policy, isInEditMode, vehicleInfo.coverState, pageModel), addAttributesToNode('p', { class: 'text-center' }))}
            <div className="MyRacForm__center_button">
              <button
                className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--grey"
                type="button"
                data-dismiss="modal"
                onClick={isInEditMode && close ? close : toggle}
              >
                {getMessage(generalMessages.CANCEL_BUTTON_TEXT)}
              </button>
              {(!isInEditMode) && (
                <button
                  id="AddFormBtn"
                  className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--left-margin"
                  type="submit"
                  disabled={loading}
                  onClick={saveVehicle}
                >
                  {loading ? <i className="fas fa-spinner fa-pulse" /> : getMessage(generalMessages.SAVE_BUTTON_TEXT)}
                </button>
              )}
              {(isInEditMode && canEditVehicle) && (
              <button
                id="EditFormBtn"
                className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--left-margin"
                type="submit"
                disabled={loading}
                onClick={editSaveVehicle}
              >
                {loading ? <i className="fas fa-spinner fa-pulse" /> : getMessage(generalMessages.SAVE_BUTTON_TEXT)}
              </button>
              )}
            </div>
          </form>
          <div className="MyRacForm__center_button delete">
            { getDeleteVehicleButton(canDeleteVehicle, onDeleteCallback, pageModel)}
          </div>
        </div>
      )}
      {(searchingVehicle && !showUpdateText && !vehicleError) && <SearchUpdatingText />}
      {(!searchingVehicle && !showUpdateText && vehicleError) && <FailureModal title={title} text={errMsg} close={close} action={close} />}
    </>
  );
};

ManualAndListVehicle.defaultProps = {
  vehicledetails: {
    Registration: '',
    Make: '',
    Model: '',
    YearOfManufacture: '',
    Transmission: '',
    FuelType: '',
    vehicleId: '',
  },
  onDeleteCallback: PropTypes.func,
  vehicleInfo: {
    canEditVehicle: false,
    canEditVehicleAdditionalInfo: false,
    canDeleteVehicle: false,
  },
};

const mapStateToProps = (state) => ({
  session: state.session,
  policy: state.policy,
});

const mapDispatchToProps = (dispatch) => ({
  addVehicles: (vehicle) => {
    dispatch(VehicleActions.addVehicles(vehicle));
  },
  updateVehicles: (vehicle) => {
    dispatch(VehicleActions.updateVehicles(vehicle));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ManualAndListVehicle));
