import React from 'react';
import { isAfter, subMonths } from 'date-fns';
import trackEvent from '../../../../services/AnalyticsService';
import DisplayHistorySummaryItem from '../displayHistorySummaryItem';
import DisplayHistoryBreakdown from '../displayHistoryBreakdown';
import FailureText from '../failureText';

const DisplayHistorySummary = (props) => {
  const {
    motHistory, pageModel, action, isLoading, viewHistoryBreakdown, toggleShow, vehicleData,
  } = props;

  if (!isLoading) {
    if (motHistory && motHistory.motTests) {
      if (!viewHistoryBreakdown) {
        trackEvent('myrac.vehicledetails.MOT.historylist');

        const motHistoryList = [];

        motHistory.motTests.forEach((vehicle) => {
          if (isAfter(new Date(vehicle.completedDate), subMonths(new Date(), 43))) {
            motHistoryList.push(<DisplayHistorySummaryItem vehicleMotHistory={vehicle} toggleShow={toggleShow} />);
          }
        });
        if (!motHistoryList.length) {
          return <p style={{ textAlign: 'center' }}>No MOT history available.</p>;
        }

        return motHistoryList;
      }

      if (viewHistoryBreakdown) {
        return <DisplayHistoryBreakdown vehicleData={vehicleData} />;
      }
    }
  }

  return <FailureText motHistory={motHistory} pageModel={pageModel} action={action} />;
};

export default DisplayHistorySummary;
