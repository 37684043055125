import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-css-modules';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import {
  GetProductsIncludedInPolicy,
} from '../../../services/MyRacService';
import styles from './MyCover.module.scss';
import localStyles from './WhatIsCovered.module.scss';
import chevron from '../../../assets/images/button/down-arrow.svg';
import CollapsibleSection from './CollapsibleSection';
import Spinner from '../../../components/Spinner';
import { SourceCodes } from '../../../constants/enums';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(localStyles);

const WhatIsCovered = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const showAggsExcess = useSelector((state) => state.policy.policyInfo.sourceCode) === SourceCodes.RWAGG01;

  const fetchWhatIsCoveredData = async () => {
    const policyProductsIncludedRaw = await GetProductsIncludedInPolicy();

    setData({
      policyProductsIncluded: policyProductsIncludedRaw?.payload,
    });
  };

  const items = useMemo(() => {
    if (!data?.policyProductsIncluded?.breakdownProducts && !data?.policyProductsIncluded) return [];

    const nonBreakdownProducts = data?.policyProductsIncluded?.nonBreakdownProducts;
    const isMot = nonBreakdownProducts && nonBreakdownProducts.find((product) => product.productName === 'MOT Plus');
    const isMotPlusFree = isMot?.IsFreeProductRedeemed;
    const activePackage = data?.policyProductsIncluded?.packageProduct;
    const isUltimateCake = activePackage?.productName === 'Ultimate';
    const activeProducts = data?.policyProductsIncluded?.breakdownProducts.filter((product) => product.isActive) || [];

    const results = [];

    // CAKE/package included items
    if (activePackage) {
      const gatherPackageDetails = (text, benefits) => {
        if (!text || !benefits || benefits?.length === 0) { return []; }
        return benefits.map((benefit, index) => ({ title: benefit, details: index === 0 ? text : undefined }));
      };

      results.push(...gatherPackageDetails(activePackage?.text?.covered?.long, activePackage?.Benefits));
    }

    // Main coverage
    results.push(...activeProducts
      .filter((product) => product.productType === 'Cover options')
      .map((item) => ({
        title: item.productName,
        details: item.text?.covered?.long,
        isFree: false,
      })));

    // Complimentary extras
    if (!isUltimateCake) {
      results.push(...(data?.policyProductsIncluded?.complimentaryExtras || [])
        .filter((item) => item.isActive === true)
        .map((item) => ({
          title: item.productName,
          details: item.text?.covered.long,
          isFree: true,
        })));
    }

    // Breakdown extras for all
    results.push(...(data?.policyProductsIncluded?.breakdownExtras || [])
      .filter((item) => item.isActive === true)
      .map((item) => ({
        title: item.productName,
        details: item.text?.covered.long,
      })));

    if (isMot) {
      results.push(...isMot.Benefits.map((benefit) => {
        const benefitInfo = {
          title: benefit,
          isFree: isMotPlusFree,
        };
        return benefitInfo;
      }));
    }

    return results;
  }, [data?.policyProductsIncluded]);

  const translation = {
    title: 'What\'s covered',
  };

  useEffect(() => {
    if (data) {
      setLoading(false);
    } else {
      fetchWhatIsCoveredData();
    }
  }, [data]);

  return loading && !data ? <Spinner iconSpinner />
    : (
      <CollapsibleSection title={translation.title}>
        {showAggsExcess && <div className="mb-2"><strong>&#163;40 excess per callout</strong></div>}
        {items.map((item) => <AccordionRow key={item.title} item={item} />)}
      </CollapsibleSection>
    );
};

const AccordionRow = ({ item: { isFree, title, details } }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`d-flex flex-column py-2 ${open ? bem('opened') : bem('closed')}`}>
      {details && (
        <button type="button" className={`flex-fill d-flex justify-content-between ${bem('toggle-button')}`} onClick={() => setOpen((prev) => !prev)}>
          <strong>{isFree && (<span className={styles.primary}>FREE</span>)} {title}</strong>
          <img src={chevron} className={bem('chevron')} alt="Details" />
        </button>
      )}
      {!details && (
        <div className={`flex-fill d-flex justify-content-between ${bem('toggle-button')}`}>
          <strong>{isFree && (<span className={styles.primary}>FREE</span>)} {title}</strong>
        </div>
      )}
      <div className={`pb-3 pt-1 ${bem('content')}`}>{ReactHtmlParser(details)}</div>
    </div>
  );
};

export default WhatIsCovered;
