import appReducer from './appReducer';

const rootReducer = () => (state, action) => {
  if (action && action.type === 'RESET_STORE') {
    // eslint-disable-next-line no-param-reassign
    state = { featureToggle: { ...state.featureToggle } };
  }
  return appReducer()(state, action);
};

export default rootReducer;
