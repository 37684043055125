import React from 'react';
import { connect } from 'react-redux';

export const LeadMemberPersonalDetails = (props) => {
  const { customer } = props;
  return (
    <div className="col-12 col-sm-6">
      <div className="tile box">
        <h4>Lead member</h4>
        <div className="Info sessioncamhidetext">
          <p className="long primary">
            {customer.custInfo.Title}
            {' '}
            {customer.custInfo.Forename}
            {' '}
            {customer.custInfo.Surname}
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
});

export default connect(mapStateToProps)(LeadMemberPersonalDetails);
