import React from 'react';
import block from 'bem-css-modules';
import ReactHtmlParser from '@orrisroot/react-html-parser';

import styles from './ExpiredPolicyWarning.module.scss';
import Button from '../../../shared/components/Button/Button';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(styles);

const ExpiredPolicyWarning = ({ content: { title, description, icon }, action, daysToRenewal }) => (
  <div className={`${bem('container')} p-3`}>
    <div className="d-flex">
      {icon && (
        <img
          src={icon.url}
          alt={icon.title}
          width="24"
          height="24"
          className={bem('icon')}
        />
      )}
      <div>
        <h4 className={bem('title')}>{title}</h4>
        <div className={`${bem('description')} my-3`}>{ReactHtmlParser(description)}</div>
        {daysToRenewal < -60 && (
          <div className={`${bem('callUsBtn')}`}>
            Call us: <a href="tel:0330 159 1111"> 0330 159 1111</a>
          </div>
        )}
      </div>
    </div>
    {daysToRenewal >= -60 && action && (
      <Button
        onClickHandler={action.onClick}
        buttonText={action.label}
        chevronWhite
      />
    )}
  </div>
);

export default ExpiredPolicyWarning;
