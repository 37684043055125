import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import block from 'bem-css-modules';
import styles from './RenewalErrorPage.module.scss';
import useViewport from '../../../../utils/useViewportHelper';
import Button from '../../../../shared/components/Button/Button';
import { INELIGIBLE_ALREADY_RENEWED } from '../../../../constants/policyInfoConstants';
import { ROOT } from '../../../../constants/routeConstants';
import { getState } from '../../../../store';
import trackEvent from '../../../../services/AnalyticsService';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(styles);

const RenewalErrorPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // Get customer info from session
  const { customer: { custInfo } } = getState();

  const [pageModel, setPageModel] = useState();

  // Adobe tag switch
  const featureTag = state?.MonthlyLongerTermPolicy ? 'MyRAC_Renewal_MLTP' : 'MyRAC_Renewal';

  useEffect(() => {
    if (state) {
      if (!state?.eligibility && !state?.isPaymentError) {
        // Internal server error (500)
        setPageModel({
          title: 'Sorry, we need to speak with you.',
          description: '<p>Thank you for trying to renew your RAC Breakdown Cover. We’re sorry, you can’t renew this policy online.</p><p>Don’t worry, we’ll be happy to help over the phone. Just give us a call on <span><strong>0330 159 1111</strong></span> to renew your cover.</p>',
        });
        // MYR-14728
        trackEvent('MyRAC_Renewal_PDP_Error');
      }
      // Boolean flag passed through navigate state defines which content is to be shown here
      if (state?.isPaymentError) {
        // Payment error
        setPageModel({
          title: 'Sorry, something went wrong with your payment.',
          description: '<p>Thank you for trying to renew your RAC Breakdown Cover.</p><p>We’re sorry but your payment has been unsuccessful. Please double-check your payment details or give us a call on <span><strong>0330 159 1111.</strong></span></p>',
        });
        trackEvent(`${featureTag}_PaymentError_Payfail`);
        // MYR-14728
        trackEvent('MYRAC_Renewal_Payment_Error');
      } else {
        // Error not payment related
        trackEvent(`${featureTag}_PaymentError_JourneyError`);
      }
      if (state?.eligibility && !state?.isPaymentError) {
        switch (state?.eligibility) {
          case INELIGIBLE_ALREADY_RENEWED:
            // Already renewed
            setPageModel({
              title: `Thanks ${custInfo.Forename}, you’ve renewed your cover.`,
              description: `We’ve sent you an email confirming your cover to ${custInfo.Email}. It contains essential information for RAC members. If you haven’t received it, please check your junk or spam folder.`,
            });
            // MYR-14728
            trackEvent('MyRAC_Renewal_Error_Ineligible');
            break;
          default:
            // Not eligible
            setPageModel({
              title: 'Sorry, we need to speak with you.',
              description: '<p>Thank you for trying to renew your RAC Breakdown Cover. We’re sorry, you can’t renew this policy online.</p><p>Don’t worry, we’ll be happy to help over the phone. Just give us a call on <span><strong>0330 159 1111</strong></span> to renew your cover.</p>',
            });
            // MYR-14728
            trackEvent('MyRAC_Renewal_Error_Journeylocked');
        }
      }
    }
  }, []);

  return (
    <div className={`${useViewport().isMobile ? 'container-fluid px-3' : 'container p-5'}`}>
      <div className="row">
        <div className="col-md-12">
          <h3 className={`${bem('errorPage-header')}`}>
            {pageModel?.title}
          </h3>
          <div className={`${bem('errorPage-details-wrapper')}`}>
            <div className={`${bem('errorPage-info-wrapper')}`}>
              {ReactHtmlParser(pageModel?.description)}
            </div>
            <div className={`${bem('errorPage-image-wrapper')}`}>
              <div className={`${bem('errorPage-image')}`} />
            </div>
          </div>
          <div className={`${bem('back-button-container')}`}>
            <Button
              onClickHandler={() => navigate(ROOT)}
              buttonText="Back to MyRAC"
              chevronWhite
              fullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenewalErrorPage;
