import sessionStorageKey from '../utils/sessionStorageKeyEnum';

// Important to wrap loadState and saveState functions with a try/catch
// in the event that there is a failure in the retrieval of data from the store
// due to no data or delayed data coming from the server-side,
// the app does not crash as we will return undefined instead
export const loadState = () => {
  const preloadedStore = {};
  try {
    Object.keys(sessionStorageKey).forEach((key) => {
      const serializedState = sessionStorage.getItem(key);
      if (serializedState === null) {
        return undefined;
      }
      preloadedStore[`${key}`] = JSON.parse(serializedState);
      return null;
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return preloadedStore;
};

export const saveState = (state) => {
  try {
    Object.keys(sessionStorageKey).forEach((key) => {
      sessionStorage.setItem(key, JSON.stringify(state.getState()[`${key}`]));
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};
