import React from 'react';
import block from 'bem-css-modules';
import PropTypes from 'prop-types';
import styles from './InfoBlock.module.scss';
import imgInfo from '../../../assets/images/icons/info-blue.svg';

const {
  string,
} = PropTypes;

const bem = block(styles);

const InfoBlock = ({ fields }) => (
  <div className={bem('block')}>
    <div className="d-flex">
      <div className={bem('image')}>
        <img width="24" className={bem()} src={imgInfo} alt="info" />
      </div>
      <div>
        <div className={bem('header')}>
          {fields.heading}
        </div>
        <div className={bem('description')}>
          {fields.infoHtml}
        </div>
      </div>
    </div>
  </div>
);

InfoBlock.propTypes = {
  heading: string,
  infoHtml: string,
};

InfoBlock.defaultProps = {
  heading: '',
  infoHtml: '',
};

export default InfoBlock;
