import React, {
  createContext, useContext, useMemo, useReducer,
} from 'react';
import { initialState, userJourneyReducer } from '../reducers/userJourneyReducer';
import { storePreviousUrlPathAction, deletePreviousUrlPathAction, storeCurrentUrlPathAction } from '../actions/userJourneyActions';

const UserJourneyContext = createContext();

const UserJourneyProvider = (props) => {
  const [state, dispatch] = useReducer(userJourneyReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <UserJourneyContext.Provider value={value} {...props} />;
};

const useUserJourney = () => {
  const context = useContext(UserJourneyContext);
  if (!context) {
    throw new Error('useUserJourney must be used within a UserJourneyProvider');
  }
  const [userJourneyState, dispatch] = context;

  const storePreviousUrlPath = (urlPath) => dispatch(storePreviousUrlPathAction(urlPath));
  const deletePreviousUrlPath = () => dispatch(deletePreviousUrlPathAction());
  const storeCurrentUrlPath = (urlPath) => dispatch(storeCurrentUrlPathAction(urlPath));

  return {
    userJourneyState,
    dispatch,
    storePreviousUrlPath,
    deletePreviousUrlPath,
    storeCurrentUrlPath,
  };
};

export { useUserJourney, UserJourneyProvider };
