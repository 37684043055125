export function addTileContent(tile, tileSet) {
  return {
    type: 'ADD_TILES',
    payload: tile,
    tileSet,
  };
}

export function addPage(page) {
  return {
    type: 'ADD_PAGE',
    id: page?.uid,
    page,
  };
}

export function addFlagIsAppView(bool) {
  return {
    type: 'ADD_FLAG_IS_APP_VIEW',
    payload: bool,
  };
}
