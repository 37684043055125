import React from 'react';
import block from 'bem-css-modules';

import CoverBadge from './CoverBadge';
import CoverStatus from './CoverStatus';
import CoverDates from './CoverDates';

import useViewport from '../../../../../utils/useViewportHelper';

import styles from './MembershipStatus.module.scss';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

export const MembershipStatus = (props) => {
  const { pageModel } = props;
  const { tileContent } = pageModel;

  const desktopStyle = {
    backgroundColor: pageModel.backgroundColourDesktop ? pageModel.backgroundColourDesktop : null,
  };

  const mobileStyle = {
    backgroundColor: pageModel.backgroundColourMobile ? pageModel.backgroundColourMobile : null,
  };

  const coverStatusAppendText = tileContent?.coverStatus?.statusTextAppend ? tileContent.coverStatus.statusTextAppend : null;

  return (
    <div className={bem()} style={useViewport().isBootstrapSm ? mobileStyle : desktopStyle}>
      <CoverBadge coverImage={tileContent} />
      <div className={bem('status-and-dates-wrapper')}>
        <CoverStatus
          coverStatusText={tileContent?.membershipStatusLabel}
          status={tileContent?.coverStatus?.statusText}
          statusAppend={coverStatusAppendText}
          statusTheme={tileContent?.coverStatus?.statusTheme}
          description={tileContent?.coverStatus?.statusDescription}
        />
        <CoverDates coverDateContent={tileContent} />
      </div>
    </div>
  );
};

export default MembershipStatus;
