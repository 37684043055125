import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ResetStore from 'actions/appActions';
import passwordValidation from 'utils/passwordUtils/passwordValidation';
import { useLocation, useNavigate } from 'react-router-dom';
import GigyaClient from '../../../auth/GigyaClient';
import { ResetPassword, UpdatePassword as UpdateCustomerPassword } from '../../../services/MyRacService';
import PopupModal from '../../../components/PopupModal/PopupModal';
import modalTypes from '../../../utils/modalTypesEnum';
import Button from '../../../shared/components/Button/Button';
import styles from './PasswordResetForm.module.scss';
import { FORGOT_PASSWORD, LOGIN, ACCOUNT_DETAILS } from '../../../constants/routeConstants';
import trackEvent from '../../../services/AnalyticsService';

const upperCaseRegex = '(?=.*?[A-Z])';
const lowerCaseRegex = '(?=.*?[a-z])';
const numericRegex = '(?=.*?[0-9])';
const specialCharRegex = '(?=.*?[#?!@$%^&*-])';

const PasswordResetForm = ({ session, customer, resetStore, pageModel }) => {
  const [currentPassword, setCurrentPassword] = useState({ show: false, value: '', error: '', message: '' });
  const [newPassword, setNewPassword] = useState({ show: false, value: '', error: '', message: '' });
  const [confirmationPassword, setConfirmationPassword] = useState({ show: false, value: '', error: '', message: '' });
  const [acknowledgementPopUp, setAcknowledgementPopUp] = useState({ show: false, title: '', text: '', action: null });
  const [loading, setLoading] = useState(false);

  const gigyaClient = new GigyaClient();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent('MyRAC_ForgotPasswordReset'); // Track page landing
  }, []);

  const redirectAction = () => {
    setAcknowledgementPopUp({ ...acknowledgementPopUp, show: false, action: null });
    if (location.pathname === FORGOT_PASSWORD) {
      navigate(LOGIN);
    } else {
      navigate(ACCOUNT_DETAILS);
    }
  };

  const closeModal = () => {
    setAcknowledgementPopUp({ ...acknowledgementPopUp, show: false, action: null });
  };

  const changePassword = async (resetToken = '') => {
    const resetPasswordBody = {
      email: customer.custInfo.Email,
      newPassword: btoa(newPassword.value),
      resetToken,
    };
    try {
      if (session.isLoggedIn) {
        await UpdateCustomerPassword(resetPasswordBody);
      } else {
        await ResetPassword(resetPasswordBody);
      }
      // Password reset success modal
      setAcknowledgementPopUp({ show: true, title: pageModel.messages.successful.title, text: pageModel.messages.successful.text, action: redirectAction });
      trackEvent('MyRAC_ForgotPasswordResetSuccess');
    } catch (error) {
      setAcknowledgementPopUp({ show: true, title: pageModel.messages.fail.title, text: error?.error ? error.error.errorMessage : pageModel.messages.fail.text, action: closeModal });
      if (error?.error?.errorMessage === 'Invalid token') {
        trackEvent('MyRAC_ForgotPasswordInvalidToken');
      } else {
        trackEvent('MyRAC_ForgotPasswordResetErrorOld');
      }
    }
    if (!session.isLoggedIn) {
      // MYR-944 need to reset store in case there were object with errors.
      resetStore();
    }
    setLoading(false);
  };

  const verifyExistingPassword = (loginResponse) => {
    if (loginResponse.status !== 'OK') {
      setCurrentPassword({ ...currentPassword, error: true, message: pageModel.messages.invalidPassword });
      setLoading(false);
    } else {
      setCurrentPassword({ ...currentPassword, error: false, message: null });
      changePassword();
    }
  };

  const validateMinCharGroup = (minCharGroup) => {
    let counter = 0;
    if (new RegExp(lowerCaseRegex).test(newPassword.value)) counter++;
    if (new RegExp(upperCaseRegex).test(newPassword.value)) counter++;
    if (new RegExp(numericRegex).test(newPassword.value)) counter++;
    if (new RegExp(specialCharRegex).test(newPassword.value)) counter++;
    return (counter >= minCharGroup);
  };

  const validateNoCommonPhrases = () => {
    const value = passwordValidation(
      newPassword.value,
    );
    return !value.isCommonPhrase;
  };

  /**
   * A callback function used in GigyaClient.getPolicies(verifyPolicies).
   *
   * @param {Object} getPoliciesResponse Gigya policies response object passed to function after the call has been made.
   * to get policies.
   */
  const verifyPolicies = (getPoliciesResponse) => {
    if (!validateNoCommonPhrases()) {
      setNewPassword({ ...newPassword, error: true, message: 'Please do not use common phrases as your password' });
      trackEvent('MyRAC_ForgotPasswordResetError');
      setLoading(false);
      return;
    }

    if ((newPassword.value.length >= getPoliciesResponse.passwordComplexity.minLength)
      && (new RegExp(getPoliciesResponse.passwordComplexity.regExp).test(newPassword.value))
      && validateMinCharGroup(getPoliciesResponse.passwordComplexity.minCharGroups)) {
      if (newPassword.value === confirmationPassword.value) {
        setNewPassword({ ...newPassword, error: false, message: '' });
        setConfirmationPassword({ ...confirmationPassword, error: false, message: '' });
        if (session.isLoggedIn) {
          gigyaClient.login(session.loginId, currentPassword.value, verifyExistingPassword);
        } else {
          const locationUrl = window.location.href.split('?');
          const queryParams = locationUrl[1].split('&');
          const pwdRt = queryParams[1].split('=');
          changePassword(pwdRt[1]);
        }
      } else {
        setConfirmationPassword({ ...confirmationPassword, error: true, message: pageModel.fields.passwordsNotMatch });
        setLoading(false);
      }
    } else {
      setNewPassword({ ...newPassword, error: true, message: pageModel.fields.passwordFormat });
      trackEvent('MyRAC_ForgotPasswordResetError');
      setLoading(false);
    }
  };

  const updatePassword = async () => {
    if (newPassword.value && confirmationPassword.value) {
      setLoading(true);
      setCurrentPassword({ ...currentPassword, error: false, message: null });
      await gigyaClient.getPolicies(verifyPolicies);
    } else {
      setAcknowledgementPopUp({ show: true, title: 'Insufficient data', text: 'Please enter values in all fields.', action: closeModal });
    }
  };

  return (
    <>
      <form>
        <fieldset>
          <div className={styles.fieldContainer}>
            {session.isLoggedIn && (
              <>
                <label htmlFor="pwd-current" className={styles.passwordLabel}>
                  {pageModel.fields.currentPasswordLabel}
                </label>
                <div className={styles.buttonContainer}>
                  <input
                    required="required"
                    className={`form-control ${styles.passwordInput}`}
                    id="pwd-current"
                    name="pwd-current"
                    placeholder={pageModel.fields.currentPasswordPlaceholder}
                    type={currentPassword.show ? 'text' : 'password'}
                    onChange={(e) => setCurrentPassword({ ...currentPassword, value: e.target.value })}
                    value={currentPassword.value}
                  />
                  <button type="button" className={styles.buttonShow} onClick={() => { setCurrentPassword({ ...currentPassword, show: !currentPassword.show }); }}>Show</button>
                </div>
                {currentPassword.error && (
                <p className="MyRacForm__error-msg" name="currPwdErr">
                  {currentPassword.message}
                </p>
                )}
              </>
            )}
            <label htmlFor="pwd-one" className={styles.passwordLabel}>
              {pageModel.fields.newPasswordLabel}
            </label>
            <div className={styles.buttonContainer}>
              <input
                required="required"
                className={`form-control ${styles.passwordInput}`}
                id="pwd-one"
                name="pwd-one"
                placeholder={pageModel.fields.newPasswordPlaceholder}
                type={newPassword.show ? 'text' : 'password'}
                onChange={(e) => setNewPassword({ ...newPassword, value: e.target.value })}
                value={newPassword.value}
              />
              <button type="button" className={styles.buttonShow} onClick={() => { setNewPassword({ ...newPassword, show: !newPassword.show }); }}>Show</button>
            </div>
            {newPassword.error && (
              <p className="MyRacForm__error-msg" name="newPwdErr">
                {newPassword.message}
              </p>
            )}
            <label htmlFor="pwd-two" className={styles.passwordLabel}>
              {pageModel.fields.confirmPasswordLabel}
            </label>
            <div className={styles.buttonContainer}>
              <input
                required="required"
                className={`form-control ${styles.passwordInput}`}
                id="pwd-two"
                name="pwd-two"
                placeholder={pageModel.fields.confirmPasswordPlaceholder}
                type={confirmationPassword.show ? 'text' : 'password'}
                onChange={(e) => { setConfirmationPassword({ ...confirmationPassword, value: e.target.value }); }}
                value={confirmationPassword.value}
              />
              <button type="button" className={styles.buttonShow} onClick={() => { setConfirmationPassword({ ...confirmationPassword, show: !confirmationPassword.show }); }}>Show</button>
            </div>
            {confirmationPassword.error && (
              <p className="MyRacForm__error-msg" name="confPwdErr">
                {confirmationPassword.message}
              </p>
            )}
          </div>
          <Button buttonText={pageModel.fields.submitButton} chevronWhite onClickHandler={updatePassword} loading={loading} />
        </fieldset>
      </form>
      {acknowledgementPopUp.show
        && (
          <PopupModal
            resultTitle={acknowledgementPopUp.title}
            resultText={acknowledgementPopUp.text}
            action={acknowledgementPopUp.action}
            modalOk="OK"
            modalType={modalTypes.infoModal}
            headerOverrideStyle={{ fontWeight: 'normal' }}
          />
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
  editValues: state.edit,
});

const mapDispatchToProps = (dispatch) => ({
  resetStore: () => {
    dispatch(ResetStore());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm);
