import { USER_JOURNEY_ACTION_NAMES } from '../actions/userJourneyActions';

const initialState = {
  pageHistory: [],
  currentUrl: '',
};

function userJourneyReducer(state, action) {
  switch (action.type) {
    case USER_JOURNEY_ACTION_NAMES.addNewUrlPath:
      return {
        ...state,
        pageHistory: [...state.pageHistory, action.payload],
      };
    case USER_JOURNEY_ACTION_NAMES.deletePreviousUrlPath:
      return {
        ...state,
        pageHistory: state.pageHistory.slice(0, -1),
      };
    case USER_JOURNEY_ACTION_NAMES.addCurrentUrlPath:
      return {
        ...state,
        currentUrl: action.payload,
      };
    default:
      return state;
  }
}

export { initialState, userJourneyReducer };
