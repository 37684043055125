export const MembershipTiers = {
  orange: 'Orange',
  silver: 'Silver',
  black: 'Black',
};

export const SourceCodes = {
  RWAGG01: 'RWAGG01', // Aggregators with excess
  RWAFTS2: 'RWAFTS2', // Tesco
};

export default { MembershipTiers };
