import { format, isValid, isSameDay, isAfter } from 'date-fns';

import ProductName from '../../../utils/productName.enum';
import membershipStatus from '../../../constants/membershipType';
import coverStatusEnum from '../../../utils/coverStatusEnum';
import * as coverStatusConstant from '../../../constants/coverStatusConstants';

export const getCoverTypeFlags = (policyInfo) => {
  const products = policyInfo?.Products || [];
  const matches = {
    pbm: products.find((x) => x.productName === ProductName.Personal && x.isActive),
    vbm: products.find((x) => x.productName === ProductName.Vehicle && x.isActive),
    hybrid: products.find((x) => x.productName === ProductName.Hybrid && x.isActive),
  };

  let type;
  if (matches.pbm) {
    type = membershipStatus.personal;
  } else if (matches.vbm) {
    type = membershipStatus.vehicle;
  } else if (matches.hybrid) {
    type = membershipStatus.hybrid;
  }

  return {
    matches,
    isPbm: Boolean(matches.pbm),
    isVbm: Boolean(matches.vbm),
    isHybrid: Boolean(matches.hybrid),
    type,
  };
};

export const filterPolicyInfoProducts = (policy) => policy?.policyInfo?.Products?.filter((product) => (
  !(product.isActive
      || product.productType === 'Cover type'
      || product.productType === 'Cover package'
      || (policy.policyInfo.IsPackage && policy.policyInfo.IsEligibleForPackage && product.productType === 'Cover options')))) || [];

export const formatDate = (date) => (isValid(new Date(date)) ? format(new Date(date), 'dd/MM/yy') : null);

export const getCoverStatusMeta = (policyStatus, policyDetails, policyRenewalDetails) => {
  const statusRaw = policyStatus.coverStatus;
  const startedAt = policyDetails.coverStart;
  const cancelledAt = policyDetails.cancellationDate;

  // New changes applies that there is a difference between monthly payment
  // type based on if policy is LTP or not
  const isNonLTPMonthly = policyRenewalDetails.renewalType === 'Monthly Continuous'
    && !policyRenewalDetails.isLongerTermPolicy;

  // As there is a default BackEnd cancellation date of 0001-01-01T00:00:00,
  // additional check if cancellation date is today's date or a future day\
  // from today, used in Live status where cancellation date exist
  const isPostDatedCancellation = isSameDay(new Date(policyDetails.cancellationDate), new Date())
    || isAfter(new Date(policyDetails.cancellationDate), new Date());

  let status;
  switch (statusRaw) {
    case (coverStatusEnum.cancelled):
      status = coverStatusConstant.CANCELLED;
      break;
    case coverStatusEnum.active:
    case coverStatusEnum.reinstated:
      // Cancellation pending has a Drive Cover Status of Live, check if
      // cancellation date is postDated thus status is cancellation pending
      // else status is Live(active). Note there is a need to differentiate
      // live status with single annual as date displayed is renewal date
      status = coverStatusConstant.ACTIVE;

      if (isNonLTPMonthly) {
        status = coverStatusConstant.ACTIVE_MONTHLY_CONTINUOUS_NON_LTP;
      }

      if (isPostDatedCancellation) {
        status = coverStatusConstant.CANCELLATION_PENDING;
      }
      break;
    case (coverStatusEnum.lapsed):
      status = coverStatusConstant.LAPSED;
      break;
    case (coverStatusEnum.starting):
      status = coverStatusConstant.CONFIRMED;
      break;
    case (coverStatusEnum.expired):
      status = coverStatusConstant.EXPIRED;
      break;
    default:
      status = null;
      break;
  }

  return {
    startedAt,
    cancelledAt,
    status,
    isNonLTPMonthly,
  };
};
