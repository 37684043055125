import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as CustomerActions from 'actions/customerInfoActions';
import * as EditButtonsActions from 'actions/editableButtonsAction';
import { UpdateEmailAddress } from 'services/MyRacService';
import { ResponseCode } from 'services/models/httpResponse';
import * as DefaultText from 'configuration/defaultText';
import trackEvent from 'services/AnalyticsService';
import PopupModal from '../../../../components/PopupModal/PopupModal';
import modalTypes from '../../../../utils/modalTypesEnum';

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export class EmailAddressPanel extends Component {
  constructor(props) {
    super(props);
    const { customer } = this.props;
    this.state = {
      emailFormatIncorrect: false,
      emailAddress: customer.custInfo.Email,
      showModal: false,
      acknowledgementTitle: '',
      acknowledgementMessage: '',
      validEmail: false,
      loading: false,
    };
    this.toggleEditEmail = this.toggleEditEmail.bind(this);
  }

  toggleEditEmail = (e) => {
    e.preventDefault();
    trackEvent('myrac.email.open');
    const { customer, editValues, setEditEmail } = this.props;
    this.setState(
      {
        emailAddress: customer.custInfo.Email,
        emailFormatIncorrect: false,
      },
    );
    setEditEmail(!editValues.editEmail);
  };

    toggleShowModal = (e) => {
      e.preventDefault();
      const { showModal } = this.state;
      const { editValues, setEditEmail } = this.props;
      this.setState({ showModal: !showModal, acknowledgementMessage: '', acknowledgementTitle: '' });
      setEditEmail(!editValues.editEmail);
    };

    changeEmail = (e) => {
      e.preventDefault();
      this.setState({ emailAddress: e.target.value });
      // eslint-disable-next-line no-useless-escape
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
        this.setState({ validEmail: true });
      } else {
        this.setState({ validEmail: false });
      }
    };

    handleSaveNewEmailAddress = async (e) => {
      const { pageModel, session: { uid } } = this.props;
      const { emailAddress } = this.state;

      e.preventDefault();

      if (new RegExp(emailRegex).test(emailAddress)) {
        this.setState({
          emailFormatIncorrect: false,
          loading: true,
        });
        trackEvent('myrac.email.submit');
        try {
          const updateEmailResponse = await UpdateEmailAddress(emailAddress, uid);
          if (updateEmailResponse.responseCode === ResponseCode.SUCCESS) {
            this.setState({
              showModal: true,
              acknowledgementTitle: pageModel.messages.successTitle,
              acknowledgementMessage: pageModel.messages.successMessage,
              loading: false,
            });
          }
        } catch (exception) {
          this.setState({
            showModal: true,
            acknowledgementTitle: pageModel.messages.failTitle,
            acknowledgementMessage: pageModel.messages.failMessage,
            loading: false,
          });
        }
      } else {
        this.setState({ emailFormatIncorrect: true });
      }
    };

    render() {
      const {
        customer: { custInfo },
        editValues,
        policy: { policyInfo },
        pageModel,
      } = this.props;

      const {
        emailAddress, emailFormatIncorrect, showModal, acknowledgementTitle, acknowledgementMessage, validEmail, loading,
      } = this.state;

      const dialogHeaderOverride = {
        fontWeight: 'normal',
      };

      if (!pageModel) { return DefaultText.APIError; }

      return (
        <div className="col-12 col-sm-6">
          <div className="tile box">
            <h4>{pageModel.title}</h4>
            <div className="Info rac-form sessioncamhidetext">
              {editValues.editEmail ? (
                <div className="px-2">
                  <form onSubmit={this.handleSaveNewEmailAddress}>
                      &nbsp;&nbsp;
                    <input
                      type="text"
                      name="emailAddress"
                      onChange={(value) => this.changeEmail(value)}
                      className="MyRacForm__form-element Panel__input p-2"
                      value={emailAddress}
                    />
                    <br />
                    {emailFormatIncorrect
                      ? (
                        <span
                          className="MyRacForm__error-msg Panel__error-msg"
                          name="homePhoneNumberErr"
                        >
                          {pageModel.messages.invalidEmailFormat}
                        </span>
                      ) : <span />}
                    <br />
                    <div className="MyRacForm__button-wrapper">
                      <button
                        type="button"
                        disabled={loading}
                        className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--grey"
                        onClick={this.toggleEditEmail}
                      >
                        {pageModel.backButton}
                      </button>
                      <button
                        type="submit"
                        disabled={!validEmail || loading}
                        className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--left-margin"
                      >
                        {loading ? <i className="fas fa-spinner fa-pulse" /> : pageModel.submitButton}
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                <div>
                  <p className="long primary">{custInfo.Email}</p>
                  {policyInfo.CanEditEmailAddress && (
                    <p className="action edit">
                      <button
                        type="button"
                        className="MyRacForm__button-link link Panel__button"
                        onClick={this.toggleEditEmail}
                      >
                        {pageModel.button.text}
                      </button>
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          {showModal
                && (
                <PopupModal
                  resultTitle={acknowledgementTitle}
                  resultText={acknowledgementMessage}
                  modalOk={pageModel.modalOk}
                  action={this.toggleShowModal}
                  parseHtml={false}
                  innerCloseButton
                  modalType={modalTypes.infoModal}
                  headerOverrideStyle={dialogHeaderOverride}
                />
                )}
        </div>
      );
    }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
  editValues: state.edit,
});

const mapDispatchToProps = (dispatch) => ({
  setEditEmail: (editEmail) => {
    dispatch(EditButtonsActions.setEditEmail(editEmail));
  },
  setCustomerInfo: (customerInfo) => {
    dispatch(CustomerActions.setCustomerInfo(customerInfo));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailAddressPanel);
