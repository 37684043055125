import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as DefaultText from 'configuration/defaultText';
import { GetContent, ResponseCode, mapURL } from 'services/ContentService';
import MenuItem from 'components/MenuItem';
import block from 'bem-css-modules';
import styles from './NewNav.module.scss';
import Button from '../../shared/components/Button/Button';
import { FORGOT_PASSWORD, LOGIN } from '../../constants/routeConstants';

const bem = block(styles);

const NewNav = (props) => {
  const { session: { isLoggedIn }, policy: { policyInfo: { IsPayg } }, customer: { custInfo } } = props;
  const [pageModel, setPageModel] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const location = useLocation();

  const menuButtonStyle = {
    border: 'none',
    borderBottom: '1px solid #f95108',
    colour: '#47474A',
    boxShadow: '0 2px 4px rgba(0,0,0,.2)',
    backgroundColor: 'white',
  };

  const shouldRender = () => {
    // if logged in, let through
    // if anonymous && page not / or /forgot-password
    const match = [LOGIN, FORGOT_PASSWORD].find((route) => route === location.pathname);
    return (isLoggedIn && !match && custInfo);
  };

  const processPageModelContent = (result) => {
    if (result.responseCode === ResponseCode.ERROR) {
      return setPageModel({ error: DefaultText.APIError });
    }
    const items = result.payload?.menuitems.map((item) => {
      const model = mapURL(item);
      if (item.menusubitems.length) {
        model.subitems = item.menusubitems.map((sub) => mapURL(sub));
      }
      return model;
    });
    setPageModel({ menuItems: items });
    return null;
  };

  useEffect(() => {
    async function getNavigationElements() {
      const page = isLoggedIn ? 'Logged Left Hand Navigation - V2' : 'Anonymous Users Left Navigation';
      const pageData = await GetContent(
        'leftnavigationmenus',
        page,
        ['menuitems', 'menuitems.menusubitems'],
      );
      processPageModelContent(pageData);
    }
    getNavigationElements();
  }, [isLoggedIn]);

  const toggleNav = () => {
    // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
    setShowNav(!showNav);
  };

  const returnMenu = () => {
    if (!shouldRender()) { return null; }

    if (pageModel.error) { return pageModel.error; }

    const menuItems = IsPayg ? pageModel.menuItems.filter(
      (item) => item.text !== 'My cover',
    )
      : pageModel.menuItems;

    return (
      <div className={`col-sm-12 subnav myrac ${bem('menu-wrapper')}`}>
        <div className={bem('close-button-wrapper')}>
          <div className={bem('close-button')}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/destructuring-assignment */}
            <span className={bem('close-style')} onClick={toggleNav}>&#215;</span>
          </div>
        </div>
        <ul className={bem('menu-body')}>
          {menuItems.map((link) => (
            <li key={link.url + link.text}>
              <MenuItem link={link} onClick={toggleNav} />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const returnButton = () => (
    <Button
      className="menu-button"
      buttonText="Menu"
      onClickHandler={toggleNav}
      chevronBlack
      outlineBlack
      colorBlack
      small
      fullWidth={false}
      buttonStyles={menuButtonStyle}
    />
  );

  return (
    <div className={styles.navBarContainer}>
      { showNav ? returnMenu() : returnButton() }
    </div>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
  customer: state.customer,
  session: state.session,
});

export default connect(mapStateToProps)(NewNav);
