import React, { useState } from 'react';
import getTileContent from '../../../../services/GridTileContentService';
import useViewport from '../../../../utils/useViewportHelper';
import CollapsibleMobileTile from '../Tiles/CollapsibleMobileTile';

const TileWrapper = (props) => {
  const { tileData } = props;
  const [isCollapsed, setCollapsed] = useState(tileData.isCollapsed);

  const desktopStyle = {
    backgroundColor: tileData.backgroundColourDesktop,
  };

  const mobileStyle = {
    backgroundColor: tileData.backgroundColourMobile,
  };

  const toggle = () => {
    setCollapsed(!isCollapsed);
  };

  // Mobile layout - for collapsible widgets and regular widgets
  const displayMobileLayout = () => (
    tileData.isCollapsible ? (
      <CollapsibleMobileTile pageModel={tileData} action={toggle} displayState={isCollapsed}>
        {getTileContent(tileData.content_Type_UID, tileData)}
      </CollapsibleMobileTile>
    ) : getTileContent(tileData.content_Type_UID, tileData)
  );

  return (
    <div className="tileWrapper" style={useViewport().isBootstrapSm ? mobileStyle : desktopStyle}>
      { useViewport().isBootstrapSm ? displayMobileLayout()
        : getTileContent(tileData.content_Type_UID, tileData)}
    </div>
  );
};

export default TileWrapper;
