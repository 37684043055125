import { useDispatch, useSelector } from 'react-redux';
import { addFlagIsAppView } from '../actions/content';

const useReferrerParam = () => {
  const dispatch = useDispatch();
  const isAppView = useSelector((state) => state.content.isAppView);
  const params = new URLSearchParams(window.location.search);

  if (!isAppView && params.get('referrer') === 'app') {
    dispatch(addFlagIsAppView(true));
  }

  return isAppView;
};

export default useReferrerParam;
