import React, { Component } from 'react';
import ReactHtmlParser from '@orrisroot/react-html-parser';

import block from 'bem-css-modules';
import styles from 'shared/components/Faqs/Faqs.module.scss';

const bem = block(styles);

class QuestionPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.question.text,
      heading: props.question.heading,
    };

    this.collapsePanel = this.collapsePanel.bind(this);
  }

  collapsePanel = () => {
    this.setState((state) => ({
      isCollapsed: !state.isCollapsed,
    }));
  }

  render() {
    const { isCollapsed, text, heading } = this.state;
    return (
      <div className={bem('faq')}>
        <div tabIndex={0} role="button" className={bem('faq-question')} onClick={this.collapsePanel} onKeyDown={this.collapsePanel} aria-expanded={!isCollapsed}>
          <span className={bem('faq-question-title')}>
            {heading}
          </span>
          {isCollapsed ? (
            <i className={`${bem('orange')} fas fa-chevron-up`} />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
        </div>
        <div className={`${bem('faq-answer')} ${isCollapsed ? 'd-block' : 'd-none'}`}>
          {ReactHtmlParser(text)}
        </div>
      </div>
    );
  }
}

export default QuestionPanel;
