import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import block from 'bem-css-modules';
import styles from './RenewalConfirmationPage.module.scss';
import useViewport from '../../../../utils/useViewportHelper';
import Button from '../../../../shared/components/Button/Button';
import googleBadge from '../../../../assets/images/badge/google-play.png';
import appleBadge from '../../../../assets/images/badge/apple-store.png';
import paymentIcon from '../../../../assets/images/icons/payment.jpg';
import scheduleIcon from '../../../../assets/images/icons/schedule.jpg';
import appIcon from '../../../../assets/images/icons/app.jpg';
import { ROOT } from '../../../../constants/routeConstants';
import trackEvent from '../../../../services/AnalyticsService';
import closeWebView from '../../../../utils/reactNativeWebView';
import { GetLatestPolicyInfo, GetLatestPolicyDocuments } from '../../../../services/MyRacService';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(styles);

const RenewalConfirmationPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [pageModel, setPageModel] = useState();
  const [policyReloadError, setPolicyReloadError] = useState(false);

  // Adobe tag switch
  const featureTag = state?.MonthlyLongerTermPolicy ? 'MyRAC_Renewal_MLTP' : 'MyRAC_Renewal';

  const RefreshPolicyInformation = async () => {
    await GetLatestPolicyInfo().then(() => GetLatestPolicyDocuments().catch(() => {
      setPolicyReloadError(true);
    })).catch(() => {
      setPolicyReloadError(true);
    });
  };

  useEffect(() => {
    if (state) {
      trackEvent(`${featureTag}_Confirm`);
      trackEvent('MyRAC_Renewal_ConfirmationPage');
      // MYR-14728
      trackEvent('MyRAC_Renewal_Confirmation_Viewed');
      if (state.EnableAutoRenew) {
        trackEvent('MyRAC_Renewal_AutoOptinConfirm');
      }
      // Tracking for amendments
      if (state.Amendments?.length > 0) {
        state.Amendments.forEach((amendment) => {
          const paymentMethod = state.isDirectDebit ? 'Confirm' : 'Confirm_Card';
          if (amendment?.code === 'Vehicle') {
            // Changed vehicle
            trackEvent(`${featureTag}_Vehicle_Saved`);
          } else {
            // Paid upgrade
            trackEvent(`${featureTag}_Upgrade_${amendment?.code}_${paymentMethod}`);
          }
        });
      }
      // Payload from SetRenewalPaymentStatus() navigate hook
      setPageModel(state);
      RefreshPolicyInformation();
      // If user is accessing journey through app, the app will be informed renewal is completed
      closeWebView();
    } else {
      navigate(ROOT);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${useViewport().isMobile ? 'container-fluid px-3' : 'container'}`}>
      <div className="row">
        <div className="col-sm-8">
          <h3 className={`${bem('confirmation-header')} sessioncamhidetext`}>
            {ReactHtmlParser(pageModel?.ConfirmationPage?.header)}
          </h3>
        </div>
      </div>
      { policyReloadError
        && (
        <div className="row">
          <div className="col-sm-8">
            <div className={`${bem('policyreload-error')}`}>
              There was a problem reloading your policy records. Please login again to see your up to date records.
            </div>
          </div>
        </div>
        )}
      <div className="row">
        <div className={`col-sm-8 sessioncamhidetext ${bem('left-column-wrapper')}`}>
          {ReactHtmlParser(pageModel?.ConfirmationPage?.body)}
          <div className={`${bem('confirmation-what-happens-next')}`}>
            {ReactHtmlParser(pageModel?.ConfirmationPage?.whatHappensNextModel?.title)}
          </div>
          <div className="border border-2 border-secondary rounded p-4 mb-4">
            <div className="row">
              {pageModel?.isDirectDebit && (
              <>
                <div className={`col-sm-1 ${bem('confirmation-icons')}`}>
                  <img src={paymentIcon} width="50px" alt="Payment icon" />
                </div>
                <div className="col-sm-11 mb-4">
                  {ReactHtmlParser(pageModel?.ConfirmationPage?.whatHappensNextModel?.content[0])}
                </div>
              </>
              )}
              <div className={`col-sm-1 ${bem('confirmation-icons')}`}>
                <img src={scheduleIcon} width="50px" alt="Schedule icon" />
              </div>
              <div className="col-sm-11 mb-4">
                {ReactHtmlParser(pageModel?.ConfirmationPage?.whatHappensNextModel?.content[1])}
              </div>
              <div className={`col-sm-1 ${bem('confirmation-icons')}`}>
                <img src={appIcon} width="50px" alt="App icon" />
              </div>
              <div className="col-sm-11">
                {ReactHtmlParser(pageModel?.ConfirmationPage?.whatHappensNextModel?.content[2])}
              </div>
            </div>
            <div className={`${bem('app-store-badge-wrapper')}`}>
              <a
                href="https://play.google.com/store/apps/details?id=uk.co.rac.roadside&hl=en_GB"
                onClick={() => {
                  trackEvent('MyRAC_Renewal_Confirmation_Play_Store');
                }}
              >
                <img src={googleBadge} width="200px" alt="Get it on Google Play" />
              </a>
              <a
                href="https://apps.apple.com/gb/app/myrac/id350330435"
                onClick={() => {
                  trackEvent('MyRAC_Renewal_Confirmation_Apple_Store');
                }}
              >
                <img src={appleBadge} width="160px" alt="Download on the App Store" />
              </a>
            </div>
          </div>
        </div>
        <div className={`col-sm-4 ${bem('right-column-wrapper')}`}>
          <div className="card mb-3">
            <div className="card-text text-center mt-4">
              <p><img src={pageModel?.ConfirmationPage?.confirmationBannerModel?.logoImage?.url} alt={pageModel?.ConfirmationPage?.confirmationBannerModel?.logoImage?.title} width="50%" /></p>
            </div>
            <a href="https://rewards.rac.co.uk" target="_blank" rel="noopener noreferrer">
              <img className="card-img-top bg-light" src={pageModel?.ConfirmationPage?.confirmationBannerModel?.heroImage?.url} alt={pageModel?.ConfirmationPage?.confirmationBannerModel?.heroImage?.title} />
            </a>
            <div className="card-body">
              <div className="card-text text-left">
                <div className="w-75 mx-auto">
                  {ReactHtmlParser(pageModel?.ConfirmationPage?.confirmationBannerModel?.description)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8">
          <div className={`${bem('back-button-container')}`}>
            <Button
              onClickHandler={() => {
                trackEvent('MyRAC_Renewal_Confirmation_Back_Button');
                navigate(ROOT);
              }}
              buttonText="Back to MyRAC"
              chevronWhite
              fullWidth
            />
          </div>
          <div className={`${bem('confirmation-fca')}`}>
            Breakdown cover arranged and administered by RAC Financial Services Limited and provided by RAC Motoring
            Services and/or RAC Insurance Ltd. RAC Motoring Services and RAC Financial Services Limited are authorised
            and regulated by the Financial Conduct Authority. RAC Insurance Ltd is authorised by the Prudential
            Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation
            Authority.
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ConfirmationPage: state.ConfirmationPage,
});

export default connect(mapStateToProps)(RenewalConfirmationPage);
