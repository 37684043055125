import React, { useState } from 'react';
import block from 'bem-css-modules';
import motStatusTypes from 'utils/motStatusTypes.enum';
import styles from './setVehicleHistoryModel.module.scss';
import closeButtonSvg from '../../../assets/images/button/close-circle-white.svg';
import arrowWhiteSvg from '../../../assets/images/button/arrow-right-white.svg';
import ComponentView from '../../../common/ComponentView';
import { MOT_HISTORY_TITLE_TEXT, MOT_HISTORY_PASS_TEXT, MOT_HISTORY_FAIL_TEXT } from '../../../constants/motConstants';
import DisplayHistorySummary from './displayHistorySummary';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

const SetVehicleHistoryModel = (props) => {
  const {
    data, action, motHistory, pageModel, isLoading,
  } = props;
  const [viewHistoryBreakdown, setViewHistoryBreakdown] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);

  const toggleShow = (vehicle) => {
    setViewHistoryBreakdown(!viewHistoryBreakdown);
    if (vehicle) {
      setVehicleData(vehicle);
    }
  };

  return (
    <div className={`${bem()} mot-history-modal`} key={data?.Registration}>
      <div className={`${bem('dialog')} modal-dialog dialog`}>
        <div className={bem('content')}>
          <div className={bem('header')}>
            {viewHistoryBreakdown
              ? (
                <button
                  type="button"
                  className={`MyRacForm__button-link link ${bem('btn-transparent')}`}
                  onClick={toggleShow}
                >
                  <img src={arrowWhiteSvg} alt="back arrow" />
                  Back
                </button>
              )
              : null}
            <div className={bem('header-center')}>
              <div />
              <h4 className={viewHistoryBreakdown ? bem('reg-outer') : ''}>
                {!viewHistoryBreakdown
                  ? MOT_HISTORY_TITLE_TEXT
                  : null}
                <span className={bem('reg')}>{` ${data?.Registration}`}</span>
              </h4>
              {viewHistoryBreakdown
                ? (
                  <div className={bem('result-outer')}>
                    <span className={bem(`result-${vehicleData.testResult === motStatusTypes.pass ? 'pass' : 'fail'}`)}>{vehicleData.testResult === motStatusTypes.pass ? MOT_HISTORY_PASS_TEXT : MOT_HISTORY_FAIL_TEXT }</span>
                  </div>
                ) : null}
            </div>

            <button
              className={bem('close')}
              type="button"
              aria-hidden="true"
              data-dismiss="modal"
              onClick={action}
            >
              <img src={closeButtonSvg} alt="close button" />
            </button>
          </div>
          <div className={`${!viewHistoryBreakdown ? bem('inner') : bem('inner-breakdown')}`}>
            <ComponentView showLoading={isLoading} onErrorModalClose={() => { }}>
              <DisplayHistorySummary motHistory={motHistory} pageModel={pageModel} action={action} isLoading={isLoading} viewHistoryBreakdown={viewHistoryBreakdown} toggleShow={toggleShow} vehicleData={vehicleData} />
            </ComponentView>
          </div>

        </div>
      </div>
    </div>
  );
};

export default SetVehicleHistoryModel;
