import EditPhoneNumber from 'panels/EditPhoneNumber';
import PhoneNumberPanel from 'panels/PhoneNumberPanel';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMessage } from 'services/MessageService';
import PopupModal from '../components/PopupModal/PopupModal';
import { generalPreferences } from '../constants/cms/messageConstants';
import modalTypes from '../utils/modalTypesEnum';

export class PhoneNumberWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResultModal: false,
      resultTitle: '',
      resultText: '',
    };
  }

    closeResultModal = () => {
      this.setState({
        showResultModal: false,
      });
    }

    isNumberUpdated = (value) => {
      const { pageModel } = this.props;

      let isSuccessfulUpdate = false;
      let resultTitle = '';
      let resultText = '';

      if (value === true) {
        isSuccessfulUpdate = true;
        resultTitle = getMessage(generalPreferences.SUCCESS_TITLE);
        resultText = getMessage(generalPreferences.SUCCESS_TEXT);
      } else {
        isSuccessfulUpdate = true;
        resultTitle = pageModel.dialogues.fail.title;
        resultText = pageModel.dialogues.fail.text;
      }
      this.setState({
        showResultModal: isSuccessfulUpdate,
        resultTitle,
        resultText,
      });
    }

    render() {
      const {
        editValues, pageModel, equaliseTileHeights, row, rowRef, tileStyle, height,
      } = this.props;
      const { showResultModal, resultTitle, resultText } = this.state;

      const modalHeaderStyle = {
        fontWeight: 'normal',
      };

      return (
        <>
          {editValues.editPhoneNumber === true
            ? (
              <EditPhoneNumber
                pageModel={pageModel}
                equaliseTileHeights={equaliseTileHeights}
                row={row}
                rowRef={rowRef}
                tileStyle={tileStyle}
                height={height}
                isNumberUpdated={this.isNumberUpdated}
              />
            )
            : (
              <PhoneNumberPanel
                pageModel={pageModel}
                equaliseTileHeights={equaliseTileHeights}
                row={row}
                rowRef={rowRef}
                tileStyle={tileStyle}
                height={height}
              />
            )}
          {showResultModal && (
            <PopupModal
              resultTitle={resultTitle}
              resultText={resultText}
              modalOk={pageModel.modalOk}
              action={this.closeResultModal}
              parseHtml={false}
              innerCloseButton
              modalType={modalTypes.infoModal}
              headerOverrideStyle={modalHeaderStyle}
            />
          )}
        </>
      );
    }
}

const mapStateToProps = (state) => {
  const { edit } = state;
  return {
    editValues: edit,
  };
};

export default connect(mapStateToProps)(PhoneNumberWrapper);
