import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as RouteConstants from '../../constants/routeConstants';
import { MY_COVER, PRODUCT_UPGRADE_JOURNEY, PRODUCT_UPGRADE_PAGE, PERSONAL_DETAILS, DOCUMENTS, VEHICLE_DETAILS, ACCOUNT_DETAILS, MEMBER_REWARDS, LOGIN, ROOT, FAQS, TERMS_CONDITIONS, FORGOT_PASSWORD, UPDATE_PASSWORD, PAYMENT_ISSUE } from '../../constants/routeConstants';
import { useUserJourney } from '../../hooks/userJourney-Context';
import useViewport from '../../utils/useViewportHelper';
import TextButton from '../../shared/components/TextButton/TextButton';
import backIcon from '../../images/icons/caret-left-black.svg';
import NewNav from '../../views/MyRac/NewNav';
import styles from './loggedInView.module.scss';

const LoggedInView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const viewportHelper = useViewport();

  const [displayNav, setDisplayNav] = useState(null);
  const [navStyles, setNavStyles] = useState(null);

  const { userJourneyState } = useUserJourney();

  const { isLoggedIn } = props;

  const backButtonPages = [MY_COVER, PRODUCT_UPGRADE_JOURNEY, PRODUCT_UPGRADE_PAGE, PERSONAL_DETAILS, DOCUMENTS, VEHICLE_DETAILS, ACCOUNT_DETAILS, MEMBER_REWARDS, FAQS, TERMS_CONDITIONS, FORGOT_PASSWORD, UPDATE_PASSWORD, PAYMENT_ISSUE];

  const checkPreviousPage = () => {
    // If the last page was the Login page re-route the customer to the Membership Overview page
    if (userJourneyState.pageHistory[userJourneyState.pageHistory.length - 2] === LOGIN) {
      navigate(ROOT);
    } else {
      navigate(-1);
    }
  };

  const backButtonAppear = () => {
    if (backButtonPages.includes(location.pathname)) {
      return <TextButton buttonText="Back" onClickHandler={checkPreviousPage} iconImage={backIcon} iconImageAltText="Back Button" noUnderline />;
    }
    return null;
  };

  const displayMenu = () => {
    if (!viewportHelper.isMobile && isLoggedIn && (location.pathname !== RouteConstants.LOGIN && location.pathname !== RouteConstants.FORGOT_PASSWORD && location.pathname !== RouteConstants.SESSION_EXPIRED && location.pathname !== RouteConstants.LOGOUT)) {
      setDisplayNav(<NewNav />);
      setNavStyles(null);
    } else {
      setDisplayNav(null);
      setNavStyles({ flexDirection: 'row' });
    }
  };

  useEffect(() => {
    displayMenu();
  }, [viewportHelper.isMobile, isLoggedIn, location.pathname]);

  return (
    <div className={styles.container}>
      <div className={styles.navBarContainer} style={navStyles}>
        { displayNav }
        { backButtonAppear() }
      </div>
      <div className="col-md-12 logged-in-routes-container">
        <Outlet />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.session.isLoggedIn,
  policyLockToken: state.session.policyLockToken,
});

export default connect(mapStateToProps)(LoggedInView);
