import React from 'react';
import block from 'bem-css-modules';
import { format } from 'date-fns';
import trackEvent from '../../../../services/AnalyticsService';
import motNoteTypes from '../../../../utils/motNoteTypes.enum';
import motStatusTypes from '../../../../utils/motStatusTypes.enum';
import greenTickSvg from '../../../../assets/images/button/tick-circle-solid-green.svg';
import redCrossSvg from '../../../../assets/images/button/cross-solid-red.svg';
import styles from '../setVehicleHistoryModel.module.scss';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

const DisplayHistoryBreakdown = (props) => {
  const { vehicleData } = props;

  trackEvent('myrac.vehicledetails.MOT.record');
  const filterType = (vehicleInfo) => {
    const failures = vehicleInfo.rfrAndComments.filter((test) => test.type === motNoteTypes.fail);
    const advisories = vehicleInfo.rfrAndComments.filter((test) => test.type === motNoteTypes.advisory);
    const major = vehicleInfo.rfrAndComments.filter((test) => test.type === motNoteTypes.major);
    const minors = vehicleInfo.rfrAndComments.filter((test) => test.type === motNoteTypes.minor);
    const dangerous = vehicleInfo.rfrAndComments.filter((test) => test.type === motNoteTypes.dangerous);
    const passAtStation = vehicleInfo.rfrAndComments.filter((test) => test.type === motNoteTypes.prs);
    const displayType = (filteredType) => (
      <>
        <div
          className={`row ${bem('row')}`}
        >
          <div className="col-sm-4">
            {filteredType[0].type}
          </div>
          <div className={`col-sm-8 ${bem('emphasis')}`}>
            {filteredType.length}
          </div>
        </div>
        {filteredType.map((item, index) => (
          <div
            className={`row ${bem('row')} ${bem(`row-${(item.type).toLowerCase()}`)}`}
            /* eslint-disable-next-line react/no-array-index-key */
            key={item.text + index}
          >
            <div className="col-sm-4" />
            <div className="col-sm-8">
              {item.text}
            </div>
          </div>
        ))}
      </>
    );
    return (
      <>
        {failures.length ? displayType(failures) : null}
        {dangerous.length ? displayType(dangerous) : null}
        {major.length ? displayType(major) : null}
        {advisories.length ? displayType(advisories) : null}
        {minors.length ? displayType(minors) : null}
        {passAtStation.length ? displayType(passAtStation) : null}
      </>
    );
  };

  return (
    <div className="container-fluid">
      <div className="vehicle-details">
        <div
          className={`row ${bem('row')}`}
        >
          <div className="col-4">
            Test date
          </div>
          <div className={`col-8 ${bem('emphasis')}`}>
            {format(new Date(vehicleData.completedDate), 'dd/MM/yyyy')}
          </div>
        </div>
        <div
          className={`row ${bem('row')} ${vehicleData.testResult === motStatusTypes.pass ? bem('row-result-pass') : bem('row-result-fail')}`}
        >
          <div className="col-4">
            Result
          </div>
          <div className="col-8">
            {vehicleData.testResult === motStatusTypes.pass
              ? (
                <>
                  <img src={greenTickSvg} alt="close button" className={bem('icon')} />
                  <p className={bem('emphasis')}>Pass</p>
                </>
              )
              : (
                <>
                  <img src={redCrossSvg} alt="close button" className={bem('icon')} />
                  <p className={bem('emphasis')}>Fail</p>
                </>
              )}
          </div>
        </div>
        <div
          className={`row ${bem('row')}`}
        >
          <div className="col-sm-12">
            <div className="row">
              <div className="col-4">
                Test no.
              </div>
              <div className={`col-8 ${bem('emphasis')}`}>
                {vehicleData.motTestNumber}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={bem('mot-notes')}>
        {filterType(vehicleData)}
      </div>
    </div>
  );
};

export default DisplayHistoryBreakdown;
