import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as EditButtonsActions from 'actions/editableButtonsAction';
import * as CustomerActions from 'actions/customerInfoActions';
import { UpdateCustomerAddress, GetAddressById, GetAddressesByPostCode } from 'services/MyRacService';
import { ResponseCode } from 'services/models/httpResponse';
import trackEvent from 'services/AnalyticsService';
import './ChangeAddressModal.scss';

class ChangeAddressModal extends Component {
  constructor(props) {
    super(props);
    this.updateAddress = this.updateAddress.bind(this);
    this.cancelUpdateAddress = this.cancelUpdateAddress.bind(this);
    this.enterAddressManually = this.enterAddressManually.bind(this);
    this.findAddress = this.findAddress.bind(this);
    this.hideAddressList = this.hideAddressList.bind(this);
    this.loadAddress = this.loadAddress.bind(this);
    this.toggleBack = this.toggleBack.bind(this);
    this.state = {
      hideModal: 'block',
      enterAddressManually: false,
      postCodeError: false,
      address: {
        postcode: '',
        addressLine1: '',
        addressLine2: '',
        town: '',
        county: '',
      },
      disableFindAddress: true,
      address1RequiredError: false,
      townRequiredError: false,
      postCodeRequiredError: false,
      findAddressResponse: [],
      showAddressList: false,
      goBack: false,
      newAddressSaved: false,
      newAddressSaveFailed: false,
      loading: false,
      saving: false,
    };
  }

  componentDidMount() {
    trackEvent('myrac.address.open');
  }

  closeModal = () => {
    this.setState({ hideModal: 'none' });
    const { setEditAddress } = this.props;
    setEditAddress(false);
  };

  onChangeAddress = (e) => {
    const { address } = this.state;
    address[e.target.name] = e.target.value;

    this.setState({ address });
  };

  onChange = (e) => {
    const postCodeRegex = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
    if (!((e.target.value === ' ') || postCodeRegex.test(e.target.value))) {
      this.setState({ postCodeError: true, disableFindAddress: true, enterAddressManually: false });
    } else {
      this.setState({ postCodeError: false, disableFindAddress: false });
      this.setState({ postcode: e.target.value });
    }
  };

  keyOnPress=(e) => {
    const { postcode } = this.state;
    if (e.key === 'Enter' && postcode) {
      e.preventDefault();
      this.findAddress(e);
    }
  };

  enterAddressManually = () => {
    this.setState({ enterAddressManually: true, disableFindAddress: true });
  };

  updateAddress = async (e) => {
    e.preventDefault();
    const { address } = this.state;
    const { customer: { custInfo } } = this.props;
    const { setCustomerAddress, setEditAddress } = this.props;

    trackEvent('myrac.address.submit');

    this.setState({ saving: true });

    const newAddress = {
      Line1: address.addressLine1,
      Line2: address.addressLine2,
      Town: address.town,
      County: address.county,
      Postcode: address.postcode,
    };
    try {
      const response = await UpdateCustomerAddress(newAddress, custInfo.Id);
      if (response.responseCode === ResponseCode.SUCCESS) {
        this.setState({
          newAddressSaved: true,
          saving: false,
        });
        setCustomerAddress(newAddress);
      } else {
        this.setState({ saving: false });
        setEditAddress(false);
      }
    } catch {
      this.setState({
        newAddressSaveFailed: true,
        saving: false,
      });
    }
  };

  cancelUpdateAddress = (e) => {
    e.preventDefault();
    this.setState({ hideModal: 'none' });
    const { setEditAddress } = this.props;
    setEditAddress(false);
  };

    hideAddressList = () => {
      this.setState({ showAddressList: false });
    };

  loadAddress = async (address) => {
    const response = await GetAddressById(address.id);

    if (response.responseCode === ResponseCode.SUCCESS && response.payload && response.payload[0]) {
      const fullAddress = response.payload[0];
      let selectedAddress = {};
      if (fullAddress.company) {
        selectedAddress = {
          addressLine2: `${fullAddress.line1}, ${fullAddress.line2}`,
          addressLine1: fullAddress.company,
          town: fullAddress.city,
          county: fullAddress.province,
          postcode: fullAddress.postalCode,
        };
      } else {
        selectedAddress = {
          addressLine1: fullAddress.line1,
          addressLine2: fullAddress.line2,
          town: fullAddress.city,
          county: fullAddress.province,
          postcode: fullAddress.postalCode,
        };
      }
      this.setState({
        address: selectedAddress,
        showAddressList: false,
        enterAddressManually: true,
      });
    } else {
      const { setEditPhoneNumber } = this.props;
      setEditPhoneNumber(false);
    }
  };

  toggleBack = () => {
    const { goBack } = this.state;
    if (goBack) {
      this.setState({ goBack: false });
    } else {
      this.setState({ goBack: true });
    }
  };

  findAddress = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { postcode } = this.state;
    const response = await GetAddressesByPostCode(postcode);

    if (response.responseCode === ResponseCode.SUCCESS) {
      this.setState({
        findAddressResponse: response.payload,
        showAddressList: true,
        loading: false,
      });
    } else {
      const { setEditPhoneNumber } = this.props;
      this.setState({ loading: false });
      setEditPhoneNumber(false);
    }
  };

  render() {
    const {
      address, hideModal, address1RequiredError, townRequiredError, postCodeRequiredError, goBack, newAddressSaved, newAddressSaveFailed,
    } = this.state;
    const { pageModel } = this.props;
    const modalStyle = {
      display: hideModal,
    };

    const modal = {
      header: {
        title: '',
        small: '',
      },
      body: '',
    };

    const addressInputs = [
      {
        htmlFor: 'address1',
        text: `${pageModel.address.line1} *`,
        name: 'addressLine1',
        value: address.addressLine1,
        required: 'required',
        displayError: address1RequiredError,
      },
      {
        htmlFor: 'address2',
        text: 'Address 2',
        name: 'addressLine2',
        value: address.addressLine2,
        required: false,
      },
      {
        htmlFor: 'town',
        text: 'Town *',
        name: 'town',
        value: address.town,
        required: 'required',
        displayError: townRequiredError,
      },
      {
        htmlFor: 'county',
        text: 'County',
        name: 'county',
        value: address.county,
        required: false,
      },
      {
        htmlFor: 'postcode',
        text: 'Postcode *',
        name: 'postcode',
        value: address.postcode,
        required: 'required',
        displayError: postCodeRequiredError,
      },
    ];

    const manualAddressBody = () => {
      const { enterAddressManually, saving } = this.state;
      return (!enterAddressManually ? null
        : (
          <form onSubmit={this.updateAddress} className="MyRacForm">
            <h3>{pageModel.heading}</h3>
            { addressInputs.map((input) => (
              <>
                <label htmlFor={input.htmlFor} key={input.name}>
                  {input.text}
                </label>
                <input
                  id={input.htmlFor}
                  className="MyRacForm__form-element
                MyRacForm__input-default-height"
                  name={input.name}
                  type="text"
                  value={input.value}
                  required={input.required}
                  onChange={(value) => this.onChangeAddress(value)}
                />
                {input.error && input.error.display ? (
                  <span className="MyRacForm__error-msg">
                    {pageModel.messages.requiredField}
                  </span>
                ) : <span />}
              </>
            ))}
            <div className="MyRacForm__button-wrapper">
              <button
                className="MyRacForm__button
                MyRacForm__button--vertical-spacing
                MyRacForm__button--wide
                MyRacForm__button--grey"
                type="button"
                disabled={saving}
                onClick={this.toggleBack}
              >
                {pageModel.buttons.back}
              </button>
              <button
                className="MyRacForm__button
                MyRacForm__button--vertical-spacing
                MyRacForm__button--wide
                MyRacForm__button--left-margin"
                type="submit"
                disabled={saving}
              >
                {saving ? <i className="fas fa-spinner fa-pulse" /> : pageModel.buttons.submit}
              </button>
            </div>
          </form>
        ));
    };

    const postCodeBody = () => {
      const {
        isPostcodeEditable, postCodeError, showAddressList, findAddressResponse, disableFindAddress, loading, saving,
      } = this.state;
      return (
        <>
          <form className="MyRacForm" name="postCodeForm">
            <div className="MyRacForm__input-wrapper">
              <input
                className="MyRacForm__form-element MyRacForm__postCode
                MyRacForm__input-extra-height"
                type="text"
                placeholder={pageModel.address.postcodePlaceholder}
                name="editPostCode"
                onKeyDown={this.hideAddressList}
                onKeyPress={(e) => this.keyOnPress(e) || this.hideAddressList}
                onKeyUp={this.hideAddressList}
                onClick={this.hideAddressList}
                onFocus={this.hideAddressList}
                disabled={isPostcodeEditable}
                onChange={(value) => this.onChange(value)}
                required=""
              />
            </div>
            {postCodeError
              ? (
                <span className="MyRacForm__error-msg">
                  {pageModel.messages.invalidPostcode}
                </span>
              ) : <span />}
            {showAddressList
                && (
                <div className="AddressList__wrapper">
                  {findAddressResponse.map((addr) => (
                    <button
                      key={addr.id}
                      type="button"
                      className="AddressList__address"
                      name="selectedAddress"
                      onClick={this.loadAddress.bind(this, addr)}
                    >
                      {addr.text}
                      ,&nbsp;
                      {addr.description}
                    </button>
                  ))}
                </div>
                )}
            <div className="MyRacForm__postcode-button-wrapper">
              <button
                type="button"
                disabled={loading}
                className="MyRacForm__button-link
                    MyRacForm__enter-address-manual"
                onClick={this.enterAddressManually}
              >
                {pageModel.buttons.findManually}
              </button>
              <button
                className="MyRacForm__button MyRacForm__button--align-right MyRacForm__button--set-width"
                type="button"
                disabled={disableFindAddress || loading || saving}
                onClick={this.findAddress}
              >
                {loading ? <i className="fas fa-spinner fa-pulse" /> : pageModel.buttons.find}
              </button>
            </div>
          </form>
          {manualAddressBody()}
        </>
      );
    };

    const goBackBody = () => (
      <form className="MyRacForm">
        <div className="MyRacForm__button-wrapper
            MyRacForm__button-wrapper--center"
        >
          <button
            className="MyRacForm__button
              MyRacForm__button--vertical-spacing
              MyRacForm__button--wide
              MyRacForm__button--grey"
            type="button"
            onClick={this.closeModal}
            data-dismiss="modal"
          >
            {pageModel.buttons.backToMyRAC}
          </button>
          <button
            className="MyRacForm__button
              MyRacForm__button--vertical-spacing
              MyRacForm__button--wide
              MyRacForm__button--left-margin"
            type="button"
            onClick={this.toggleBack}
          >
            {pageModel.buttons.continueUpdating}
          </button>
        </div>
      </form>
    );

    const updateConfirmationBody = () => (
      <form className="MyRacForm">
        <div className="MyRacForm__button-wrapper
            MyRacForm__button-wrapper--center"
        >
          <button
            className="MyRacForm__button
              MyRacForm__button--vertical-spacing
              MyRacForm__button--wide"
            type="button"
            onClick={this.closeModal}
            data-dismiss="modal"
          >
            {pageModel.buttons.backToMyRAC}
          </button>
        </div>
      </form>
    );

    const updateFailedBody = () => (
      <form className="MyRacForm">
        <div className="MyRacForm__button-wrapper
            MyRacForm__button-wrapper--center"
        >
          <button
            className="MyRacForm__button
              MyRacForm__button--vertical-spacing
              MyRacForm__button--wide"
            type="button"
            onClick={this.closeModal}
            data-dismiss="modal"
          >
            {pageModel.buttons.backToMyRAC}
          </button>
        </div>
      </form>
    );

    if (!(goBack || newAddressSaved)) {
      modal.header.title = pageModel.messages.change.title;
      modal.header.small = pageModel.messages.change.text;
      modal.header.message = pageModel.messages.change.message;
      modal.body = postCodeBody();
    }
    if (newAddressSaved) {
      modal.header.title = pageModel.messages.saved.title;
      modal.header.small = pageModel.messages.saved.text;
      modal.header.message = pageModel.messages.change.message;
      modal.body = updateConfirmationBody();
    }
    if (goBack) {
      modal.header.title = pageModel.messages.goBack.title;
      modal.header.small = pageModel.messages.goBack.text;
      modal.header.message = pageModel.messages.change.message;
      modal.body = goBackBody();
    }
    if (newAddressSaveFailed && !newAddressSaved) {
      modal.header.title = 'Update Unsuccessful';
      modal.header.small = 'Update Unsuccessful, Please try again later';
      modal.body = updateFailedBody();
    }

    return (
      <>
        <div
          className="modal myrac changeAddress__modal"
          style={modalStyle}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header changeAddress__modal-header">
                <button
                  type="button"
                  className="close ascii-cross"
                  onClick={this.closeModal}
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &#215;
                </button>
                <div className="w-100 text-center">
                  <h4 className="modal-title changeAddress__modal-title">
                    {modal.header.title}
                  </h4>
                  <p className="modal-small-header changeAddress__modal-small-header">{modal.header.message}</p>
                  <p className="modal-small-header changeAddress__modal-small-header">{modal.header.small}</p>
                </div>
              </div>
              <div className="modal-body">
                { modal.body }
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal-backdrop fade in"
          style={{ display: hideModal }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAddress: (editAddress) => {
    dispatch(EditButtonsActions.setEditAddress(editAddress));
  },
  setCustomerAddress: (address) => {
    dispatch(CustomerActions.setUpdatedAddress(address));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAddressModal);
