import React from 'react';
import { useNavigate } from 'react-router-dom';
import block from 'bem-css-modules';
import styles from './PageNotFound.module.scss';
import useViewport from '../../utils/useViewportHelper';
import Button from '../../shared/components/Button/Button';
import { ROOT } from '../../constants/routeConstants';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(styles);

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className={`${useViewport().isMobile ? 'container-fluid px-3' : 'container px-5'} din-font`}>
      <div className="row">
        <div className="col-md-12">
          <h3 className={`${bem('header')}`}>
            Sorry, page not found
          </h3>
          <div className={`${bem('details-wrapper')}`}>
            <div className={`${bem('info-wrapper')}`}>
              <p>Please use the tabs at the top of the page to find what you wanted or visit our <a href="http://www.rac.co.uk">homepage</a>.</p>
              <p>Remember to update your favorites/bookmarks. Please contact us if we can be of further assistance.</p>
              <p>If you have a question visit our <a href="http://www.rac.co.uk/help">help</a> section.</p>
            </div>
            <div className={`${bem('image-wrapper')}`}>
              <div className={`${bem('image')}`} />
            </div>
          </div>
          <div className={`${bem('back-button-container')}`}>
            <Button
              onClickHandler={() => navigate(ROOT)}
              buttonText="Back to MyRAC"
              chevronWhite
              fullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
