/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './ImageTile.scss';
import trackEvent from '../../../../../services/AnalyticsService';
import ctaActions from '../../../../../utils/ctaActions.enum';
import PopupModal from '../../../../../components/PopupModal/PopupModal';
import modalTypes from '../../../../../utils/modalTypesEnum';
import { GetStaticContent } from '../../../../../services/MyRacService';
import { loggerFunc } from '../../../../../utils/logger';
import { getClickAction } from '../../GridLayout/ManagedCta';

const ImageTile = (props) => {
  const { pageModel } = props;
  const [showDialogue, setShowDialogue] = useState(false);
  const [dialogueContent, setDialogueContent] = useState();
  const logger = loggerFunc('ImageTile');

  const buttonStyle = {
    border: 'none',
    width: '100%',
    height: '100%',
    margin: '0',
    padding: '0',
  };

  useEffect(() => {
    if (pageModel.tileContent?.button?.callToAction?.actionType === ctaActions.dialogue) {
      GetStaticContent(pageModel.tileContent.button.callToAction.url).then((body) => {
        setDialogueContent(body.payload);
      }).catch((error) => logger.error(error));
    }
  }, []);

  const outputStaticImage = () => (
    <img
      className="image-tile"
      src={pageModel.tileContent.desktopImage.url}
      alt={pageModel.tileContent.desktopImage.fileName}
      title={pageModel.tileContent.desktopImage.title}
    />
  );

  const getTarget = () => (pageModel.tileContent.button.callToAction.actionType === ctaActions.internal ? '_self' : '_blank');

  const outputClickableImage = () => {
    switch (pageModel.tileContent.button.callToAction.actionType) {
      case ctaActions.internal:
      case ctaActions.newWindow: {
        if (pageModel.tileContent.button && Array.isArray(pageModel.analyticsTags)) {
          const analyticsTag = pageModel.analyticsTags.find((tag) => tag.eventName === 'clickWidget');
          return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={pageModel.tileContent.button.callToAction.url} onClick={() => trackEvent(analyticsTag?.eventTag)} target={getTarget()}>
              {outputStaticImage()}
            </a>
          );
        }
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={pageModel.tileContent.button.callToAction.url} target={getTarget()}>
            {outputStaticImage()}
          </a>
        );
      }
      case ctaActions.dialogue: {
        if (pageModel.tileContent.button && Array.isArray(pageModel.analyticsTags)) {
          const analyticsTag = pageModel.analyticsTags.find((tag) => tag.eventName === 'clickWidget');
          return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <button
              style={buttonStyle}
              type="button"
              onClick={() => {
                trackEvent(analyticsTag?.eventTag);
                setShowDialogue(true);
              }}
            >
              {outputStaticImage()}
            </button>
          );
        }
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <button
            style={buttonStyle}
            type="button"
            onClick={() => setShowDialogue(true)}
          >
            {outputStaticImage()}
          </button>
        );
      }
      default: {
        if (pageModel.tileContent.button && Array.isArray(pageModel.analyticsTags)) {
          const analyticsTag = pageModel.analyticsTags.find((tag) => tag.eventName === 'clickWidget');
          return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <button
              style={buttonStyle}
              type="button"
              onClick={(params) => getClickAction(pageModel.tileContent.button.callToAction.actionType, {
                ...params, analyticsTag, url: pageModel.tileContent.button.callToAction.url,
              })}
            >
              {outputStaticImage()}
            </button>
          );
        }
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <button
            style={buttonStyle}
            type="button"
            onClick={(params) => getClickAction(pageModel.tileContent.button.callToAction.actionType, {
              ...params, analyticsTag: null, url: pageModel.tileContent.button.callToAction.url,
            })}
          >
            {outputStaticImage()}
          </button>
        );
      }
    }
  };

  return (
    <>
      {pageModel.tileContent && pageModel.tileContent.button ? outputClickableImage() : outputStaticImage()}
      {showDialogue
        ? (
          <PopupModal
            resultTitle={dialogueContent?.header}
            resultText={dialogueContent?.body}
            modalOk="Ok"
            parseHtml
            innerCloseButton
            action={() => setShowDialogue(false)}
            modalType={modalTypes.productModal}
          />
        ) : null}
    </>
  );
};

export default ImageTile;
