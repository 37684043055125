import React from 'react';
import block from 'bem-css-modules';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import trackEvent from 'services/AnalyticsService';
import styles from './LandingBanner.module.scss';
import Button from '../Button/Button';
import myRacLogo from '../../../assets/images/landing/myrac_logo.png';
import InfoBlock from '../InfoBlock/InfoBlock';

const {
  string, bool, shape,
} = PropTypes;

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

const LandingBanner = (props) => {
  const { fields } = props;
  const mobileImage = `${fields.backgroundimagemobile.url}`;
  const desktopImage = `${fields.backgroundimagedesktop.url}`;
  const { featureFlags, oneTimeEmailDisabled } = props;
  const { useMagicLinkLayout } = featureFlags;

  const loginRegisterMyRacHandler = (e) => {
    e.preventDefault();
    fields.loginCtaHandler(e);
    trackEvent('MyRAC_BreakdownLogin');
  };

  const loginOneTimeEmailMyRacHandler = (e) => {
    e.preventDefault();
    fields.loginOneTimeEmailCtaHandler(e);
    trackEvent('MyRAC_BreakdownLoginOneTimeEmail');
  };

  const registrationFormMyRacHandler = (e) => {
    e.preventDefault();
    fields.registrationFormMyRacHandler(e);
    trackEvent('MyRAC_BreakdownRegister');
  };

  const loginBGLHandler = (e) => {
    e.preventDefault();
    if (fields.rightbuttonurl) {
      const target = fields.rightbuttonurl.openinnewwindow ? '_blank' : '_self';
      const win = window.open(fields.rightbuttonurl.href, target);
      win.focus();
    }

    trackEvent('MyRAC_CarInsuranceLogin');
  };

  const registerBGLHandler = (e) => {
    e.preventDefault();
    if (fields.insuranceregisterbutton) {
      const target = fields.insuranceregisterbutton.openinnewwindow ? '_blank' : '_self';
      const win = window.open(fields.insuranceregisterbutton.href, target);
      win.focus();
    }
    trackEvent('MyRAC_CarInsuranceRegisterButton');
  };

  const emailLogin = () => (
    <div className={bem('button-wrapper')}>
      <Button buttonText={fields.leftbuttontextemail} onClickHandler={loginOneTimeEmailMyRacHandler} chevronWhite />
    </div>
  );

  const registration = () => (
    <div className={bem('button-wrapper')}>
      <Button buttonText={fields.registrationbuttontext} onClickHandler={registrationFormMyRacHandler} darkGreyButton chevronWhite />
    </div>
  );

  const otherInsuranceLinkHandler = () => {
    trackEvent('MyRAC_OtherInsuranceLogin');
  };

  const loginMagicLinkFieldsInfoBlock = {
    heading: 'What is one time email?',
    infoHtml: <div>Forgotten password? We&apos;ll send a secure link to your inbox to access myRAC.</div>,
  };

  const otherInsuranceFieldsInfoBlock = {
    heading: 'Need another type of insurance?',
    infoHtml: <div>Visit our <a className={bem('insurance-link')} href={fields.rightsecondarybuttonurl.href} onClick={otherInsuranceLinkHandler}>Insurance page</a></div>,
  };

  const displayLogin = () => {
    if (useMagicLinkLayout && !oneTimeEmailDisabled) {
      return (
        <>
          <h3 className={bem('loginbutton-header')}>Login</h3>
          <div className={bem('button-wrapper')}>
            <Button buttonText={fields.leftbuttontext} onClickHandler={loginRegisterMyRacHandler} marginBottom chevronWhite />
          </div>
          {emailLogin()}
          <div className={bem('button-wrapper')}>
            <InfoBlock fields={loginMagicLinkFieldsInfoBlock} />
          </div>
          <h3 className={bem('loginbutton-header')}>Or</h3>
          {registration()}
        </>
      );
    }
    return (
      <>
        <h3 className={bem('loginbutton-header')}>Login</h3>
        <div className={bem('button-wrapper')}>
          <Button buttonText={fields.leftbuttontext} onClickHandler={loginRegisterMyRacHandler} chevronWhite />
        </div>
        <h3 className={bem('loginbutton-header', { extrapadding: true })}>Or</h3>
        {registration()}
      </>
    );
  };

  return (
    <section id={fields.uid} className={bem()} style={{ backgroundImage: `url(${desktopImage})` }}>
      <img className={bem('background-image', { desktop: true, newVersion: useMagicLinkLayout })} src={desktopImage} alt="myrac" />
      <img className={bem('background-image', { mobile: true, newVersion: useMagicLinkLayout })} src={mobileImage} alt="myrac" />
      <div className={bem('box')}>
        <div className={bem('heading', { main: true })}>
          <img src={myRacLogo} alt="MyRAC" height="49px" />
        </div>
        <div className={bem('wrapper')}>
          <div className={bem('inner-wrapper')}>
            <h2 className={bem('heading', { sub: true })}>{fields.leftheading}</h2>
            <p className={bem('body-text')}>{fields.leftbody}</p>
            {displayLogin()}
          </div>
          <div className={bem('inner-wrapper')}>
            <h2 className={bem('heading', { sub: true })}>{fields.rightheading}</h2>
            <p className={bem('body-text')}>{fields.rightbody}</p>
            <div className={bem('button-wrapper')}>
              <h3 className={bem('loginbutton-header')}>Login</h3>
              <Button buttonText={fields.rightbuttontext} onClickHandler={loginBGLHandler} chevronWhite />
              <h3 className={bem('loginbutton-header', { extrapadding: true })}>Or</h3>
              <div className={bem('button-wrapper')}>
                <Button buttonText={fields.registrationbuttontext} onClickHandler={registerBGLHandler} darkGreyButton chevronWhite />
              </div>
              <div className={bem('button-wrapper')}>
                <InfoBlock fields={otherInsuranceFieldsInfoBlock} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

LandingBanner.propTypes = {
  backgroundimagedesktop: shape({
    url: string,
  }),
  backgroundimagemobile: shape({
    url: string,
  }),
  leftbody: string,
  leftbuttontext: string,
  leftbuttontextemail: string,
  leftheading: string,
  registrationbuttontext: string,
  rightbody: string,
  rightbuttontext: string,
  rightheading: string,
  rightbuttonurl: shape({
    alttext: string,
    analyticstag: string,
    cssclass: string,
    href: string,
    ismyracpage: bool,
    openinnewwindow: bool,
    title: string,
  }),
  rightsecondarybuttonurl: shape({
    alttext: string,
    analyticstag: string,
    cssclass: string,
    href: string,
    ismyracpage: bool,
    openinnewwindow: bool,
    title: string,
  }),
  insuranceregisterbutton: shape({
    alttext: string,
    analyticstag: string,
    cssclass: string,
    href: string,
    ismyracpage: bool,
    openinnewwindow: bool,
    title: string,
  }),
};

LandingBanner.defaultProps = {
  backgroundimagedesktop: {
    url: '',
  },
  backgroundimagemobile: {
    url: '',
  },
  leftbody: '',
  leftbuttontext: '',
  leftbuttontextemail: '',
  leftheading: '',
  registrationbuttontext: '',
  rightbody: '',
  rightbuttontext: '',
  rightheading: '',
  rightbuttonurl: {
    alttext: '',
    analyticstag: '',
    cssclass: '',
    href: '',
    ismyracpage: false,
    openinnewwindow: false,
    title: '',
  },
  rightsecondarybuttonurl: {
    alttext: '',
    analyticstag: '',
    cssclass: '',
    href: '',
    ismyracpage: false,
    openinnewwindow: false,
    title: '',
  },
  insuranceregisterbutton: {
    alttext: '',
    analyticstag: '',
    cssclass: '',
    href: '',
    ismyracpage: false,
    openinnewwindow: false,
    title: '',
  },
};

const mapStateToProps = (state) => ({
  featureFlags: state.featureToggle,
  oneTimeEmailDisabled: state.session.oneTimeEmailDisabled,
});

export default connect(mapStateToProps)(LandingBanner);
