import React, { Component } from 'react';
import { isNull } from 'util';
import { GetContent, ResponseCode } from 'services/ContentService';
import * as DefaultText from 'configuration/defaultText';
import FAQQuestion from 'components/FAQQuestion';
import { connect } from 'react-redux';
import displayQuestion from 'utils/faq_user_validation';
import ComponentView from 'common/ComponentView';
import { loggerFunc } from 'utils/logger';
import { getMessage } from 'services/MessageService';

const logger = loggerFunc('FAQsPage');

class FAQsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageModel: null,
      errorText: undefined,
    };
    this.onErrorModalClose = this.onErrorModalClose.bind(this);
  }

  async componentDidMount() {
    try {
      const { pageModel } = this.state;
      if (isNull(pageModel)) {
        const pageData = await GetContent(
          'loggedinpages',
          'FAQS - My personal details',
          ['componentitems', 'componentitems.faqitems'],
        );
        this.processPageModelContent(pageData);
      }
    } catch (e) {
      // todo set message constant.
      this.handleError(e, getMessage());
    }
  }

  onErrorModalClose = () => {
    this.setState({
      errorText: undefined,
    });
  };

  handleError = (e, text) => {
    this.setState({
      errorText: text,
    });
    logger.error(text, e);
  }

  processPageModelContent = (result) => {
    if (result.responseCode === ResponseCode.ERROR) {
      return this.setState({
        pageModel: { error: DefaultText.APIError },
      });
    }

    this.setState({
      pageModel: {
        title: result.payload.pagetitle,
        heading: result.payload.heading,
        questions: {
          heading: result.payload.componentitems[0].heading,
          faqs: result.payload.componentitems[0].faqitems.map((item) => ({
            heading: item.heading,
            text: item.htmldescription,
            displayTo: item.usertodisplayfor,
          })),
        },
      },
    });
    return undefined;
  }

  render() {
    const { pageModel, errorText } = this.state;
    if (isNull(pageModel)) { return DefaultText.Waiting; }
    if (pageModel.error) { return pageModel.error; }
    const { session } = this.props;
    return (
      <ComponentView errorText={errorText} onErrorModalClose={this.onErrorModalClose}>
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-12">
                <h1>{pageModel.heading}</h1>
              </div>
            </div>
          </div>
          <section>
            <div className="row">
              <div className="col-sm-12">
                <h2 className="Breakdown FAQS">{pageModel.questions.heading}</h2>
                <div>
                  {pageModel.questions.faqs.filter((q) => displayQuestion(session, q)).map((item, key) => (
                    <FAQQuestion
                      question={{
                        isCollapsed: key !== 0,
                        text: item.text,
                        heading: item.heading,
                      }}
                      key={item.heading}
                    />
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </ComponentView>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(FAQsPage);
