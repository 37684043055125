import React from 'react';
import block from 'bem-css-modules';
import trackEvent from '../../../../services/AnalyticsService';
import styles from '../setVehicleHistoryModel.module.scss';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

const FailureText = (props) => {
  const { motHistory, pageModel, action } = props;

  trackEvent('myrac.vehicledetails.MOT.historylist');
  if (!motHistory) {
    trackEvent('myrac.vehicledetails.MOT.recordunavailable');
  }

  return (
    <div className={bem('error-text')}>
      <p>
        {(motHistory && !motHistory.motTests)
          ? pageModel.noMotErrorText
          : pageModel.errorText}
      </p>
      <div className="MyRacForm__center_button">
        <button
          className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide"
          type="button"
          data-dismiss="modal"
          onClick={action}
        >
          {(motHistory && !motHistory.motTests)
            ? 'Ok'
            : 'Close'}
        </button>
      </div>
    </div>
  );
};

export default FailureText;
