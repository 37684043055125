import React from 'react';
import styles from './MyCover.module.scss';
import ProductUpgradeCard from './ProductUpgradeCard';

const ProductUpgradeList = ({ upgrades }) => {
  const translation = {
    title: 'Add-ons for you',
  };

  return (
    <div>
      <h2 className={styles.addonSectionTitle}>{translation.title}</h2>
      <div className="clearfix flex-column my-2">
        {upgrades.map(({ upgrade, policyProduct }) => (
          <div key={upgrade.title} className="item py-3">
            <ProductUpgradeCard
              upgrade={upgrade}
              policyProduct={policyProduct}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductUpgradeList;
