const trackEvent = (eventName) => {
  try {
    const triggerEvent = window.Bootstrapper.ensEvent.trigger;
    if (triggerEvent && typeof triggerEvent === 'function') {
      triggerEvent(eventName);
    }
  } catch {
    // just swallow error as some browser may not support.
  }
};

export default trackEvent;
