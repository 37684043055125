import React, { useEffect, useState } from 'react';
import styles from './technicalError.module.scss';
import Button from '../../../../../../shared/components/Button/Button';

/**
 * Molecule level component.
 * Brad Frost - Atomic Design: https://atomicdesign.bradfrost.com/chapter-2/#molecules
 * A Note component that reflects the design located here: "".
 * @returns {JSX.Element}
 */

const TechnicalError = ({ buttonClickHandler }) => {
  const [loading, setLoading] = useState(false);

  /**
   * When loading is triggered set a loading timeout so the user can reattempt getting a payment ID.
   * * */
  const loadingTimeout = () => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  };

  const onClickHandler = () => {
    setLoading(true);
    buttonClickHandler();
    loadingTimeout();
  };

  /**
   * Clears loading timeout when component unmounts.
   * * */
  useEffect(() => clearTimeout(loadingTimeout));

  const buttonStyles = {
    width: '150px',
  };

  return (
    <div className={styles.container}>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p>We're very sorry, there has been a technical problem with our payment service. We don't want to delay you from obtaining your new breakdown product. So please call us on <span className={styles.telephoneNumber}>03301591466</span> and a member of the team can help you.</p>
      <div className={styles.buttonContainer}>
        <Button buttonStyles={buttonStyles} loading={loading} buttonText="Try again" chevronWhite onClickHandler={onClickHandler} />
      </div>
    </div>
  );
};

export default TechnicalError;
