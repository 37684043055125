import React from 'react';
import RenderLink from '../../../components/RenderLink';

const Advert = ({ adv }) => {
  const { url, image, body, button } = adv;
  return (
    <div className="navSub-promo">
      <RenderLink link={url}>
        {image ? (<img alt={image.alt} src={image.url} />) : null}
      </RenderLink>
      <h3><RenderLink link={url} /></h3>
      <p>{body}</p>
      <RenderLink link={url} className="btn btn-sm btn-primary">
        {button}
      </RenderLink>
    </div>
  );
};

export default Advert;
