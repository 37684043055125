export const SET_POLICYINFO = 'SET_POLICYINFO';
export const UPDATE_VEHICLEINFO = 'UPDATE_VEHICLEINFO';
export const INVALIDATE_POLICYINFO = 'INVALIDATE_POLICYINFO';
export const UPDATE_ADDITIONALINFO = 'UPDATE_ADDITIONALINFO';
export const SET_POLICY_ACKNOWLEDGED = 'SET_POLICY_ACKNOWLEDGED';
export const ELIGIBLE = 'Eligible';
export const INELIGIBLE = 'Ineligible';
export const INELIGIBLE_ALREADY_RENEWED = 'IneligibleAlreadyRenewed';
export const RENEWAL_TYPE = {
  annualContinuous: 'Annual Continuous',
  monthly: 'Single Annual', // Needs checking
};
