import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as EditButtonsActions from 'actions/editableButtonsAction';
import * as CustomerActions from 'actions/customerInfoActions';
import ChangeAddressModal from 'panels/ChangeAddressModal';
import * as DefaultText from 'configuration/defaultText';

export class PostalAddressPanel extends Component {
  constructor(props) {
    super(props);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.state = ({
      tileHeight: 0,
    });
  }

  handleChangeAddress = (e) => {
    e.preventDefault();
    const { setEditAddress } = this.props;
    setEditAddress(true);
  }

  render() {
    const {
      customer: { custInfo },
      policy: { policyInfo },
      pageModel,
      rowRef,
      tileStyle,
      editValues,
    } = this.props;
    const { tileHeight } = this.state;

    if (!pageModel) return null;

    return (
      <div className="col-12 col-sm-6">
        <div className="tile box" ref={rowRef} style={tileStyle} mountedheight={tileHeight}>
          <h4>{pageModel.title}</h4>
          <div className="Info sessioncamhidetext">
            {!custInfo.Id ? (<p>{DefaultText.Waiting}</p>) : (
              <>
                <p className="long primary">
                  {custInfo.Address.Line1}
                  <br />
                  {custInfo.Address.Line2 != null && (
                    <span className="parent-style">
                      {custInfo.Address.Line2}
                      <br />
                    </span>
                  )}
                  {custInfo.Address.Town != null && (
                    <span className="parent-style">
                      {custInfo.Address.Town}
                      <br />
                    </span>
                  )}
                  {custInfo.Address.County != null && (
                    <span className="parent-style">
                      {custInfo.Address.County}
                      <br />
                    </span>
                  )}
                  {custInfo.Address.Postcode != null && (
                    <span className="parent-style">
                      {custInfo.Address.Postcode}
                      <br />
                    </span>
                  )}
                </p>
                {policyInfo.CanEditAddress && (
                  <p className="action edit">
                    <button
                      type="button"
                      className="MyRacForm__button-link link Panel__button"
                      onClick={this.handleChangeAddress}
                    >
                      {pageModel.link.text}
                      {' '}
                      &gt;
                    </button>
                  </p>
                )}
                {editValues.editAddress === true ? <ChangeAddressModal pageModel={pageModel.modal} /> : ''}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
  editValues: state.edit,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAddress: (editAddress) => {
    dispatch(EditButtonsActions.setEditAddress(editAddress));
  },
  setCustomerInfo: (customerInfo) => {
    dispatch(CustomerActions.setCustomerInfo(customerInfo));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PostalAddressPanel);
