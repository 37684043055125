const { gigya } = window;

class GigyaClient {
  static instance;

  // A Singleton Class.
  constructor() {
    if (!GigyaClient.instance) {
      GigyaClient.instance = this;
    }
    // eslint-disable-next-line no-constructor-return
    return GigyaClient.instance;
  }

  getJWT = (handleResponseCallback, expiration, sessionInfo) => gigya?.accounts?.getJWT({
    callback: handleResponseCallback,
    fields: 'data.memberNo, data.customerID, data.clientId',
    expiration,
    context: sessionInfo,
  });

  showScreenSet = (
    onBeforeSubmitCallback,
    onAfterSubmitCallback,
    onAfterScreenLoadCallback,
    onSubmitCallback,
    onErrorCallback,
  ) => gigya?.accounts?.showScreenSet({
    screenSet: 'RAC-RegistrationLogin',
    startScreen: 'gigya-login-screen',
    onBeforeSubmit: onBeforeSubmitCallback,
    onAfterSubmit: onAfterSubmitCallback,
    onAfterScreenLoad: onAfterScreenLoadCallback,
    onSubmit: onSubmitCallback,
    onError: onErrorCallback,
    customLang: {
      account_temporarily_locked_out: 'Your account has been locked. Please try again in 3 minutes.',
      email_already_exists: 'Email already registered to a membership',
    },
  });

  showScreenSetPasswordLess = (
    onBeforeSubmitCallback,
    onAfterSubmitCallback,
    onAfterScreenLoadCallback,
    onSubmitCallback,
    onErrorCallback,
  ) => gigya?.accounts?.showScreenSet({
    screenSet: 'RAC-PasswordlessLogin-2024',
    startScreen: 'gigya-passwordless-login-screen',
    onBeforeSubmit: onBeforeSubmitCallback,
    onAfterSubmit: onAfterSubmitCallback,
    onAfterScreenLoad: onAfterScreenLoadCallback,
    onSubmit: onSubmitCallback,
    onError: onErrorCallback,
    customLang: {
      account_temporarily_locked_out: 'Your account has been locked. Please try again in 3 minutes.',
      email_already_exists: 'Email already registered to a membership',
      invalid_fieldname: 'This is not a valid email address',
      there_are_errors_in_your_form_please_try_again: 'There was a problem sending an email to the address entered.  Please check that is is valid.',
    },
  });

  showRegistrationScreenSet = (
    onBeforeSubmitCallback,
    onAfterSubmitCallback,
    onAfterScreenLoadCallback,
    onBeforeValidationCallback,
  ) => gigya?.accounts?.showScreenSet({
    screenSet: 'RAC-RegistrationLogin',
    startScreen: 'gigya-register-screen',
    onBeforeSubmit: onBeforeSubmitCallback,
    onBeforeValidation: onBeforeValidationCallback,
    onAfterSubmit: onAfterSubmitCallback,
    onAfterScreenLoad: onAfterScreenLoadCallback,
    customLang: {
      account_temporarily_locked_out: 'Your account has been locked. Please try again in 3 minutes.',
      email_already_exists: 'Email already registered to a membership',
    },
  });

  getPolicies = (verifyPoliciesCallback) => gigya?.accounts?.getPolicies({
    sections: 'passwordComplexity',
    callback: verifyPoliciesCallback,
  });

  login = (loginId, currentPassword, verifyExistingPasswordCallback) => gigya?.accounts?.login({
    loginID: loginId,
    password: currentPassword,
    loginMode: 'reAuth',
    sessionExpiration: process.env.REACT_APP_SESSION_TIME_IN_SECONDS,
    callback: verifyExistingPasswordCallback,
  });

  logout = (logoutCallback) => gigya?.accounts?.logout({ callback: logoutCallback })

  getAccountInfo = (getAccountInfoCallback) => gigya?.accounts?.getAccountInfo({ callback: getAccountInfoCallback })

  loginMagicLink = (vToken, code, magicLinkCallback) => gigya?.accounts?.auth.magiclink.email.login({ vToken, code, callback: magicLinkCallback })

  verifyLogin = () => gigya?.accounts?.verifyLogin()
}

export default GigyaClient;
