import { useState, useEffect } from 'react';

// custom hook to determine width of window on initialization and when window is resized
// Determine if window is mobile width as well. Mobile referenced here is for both mobile and tablet 768px
const useViewport = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const mobileBreakPoint = 768; // breakpoint value from tablet 768 below
  const bootstrapSmallBreakPoint = 991; // breakpoint added due to Bootstrap's usage in the application; bootstrap sm
  const smallDesktopSizeBreak = 1199; // breakpoint added for boostrap container width breakpoint

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []); // The empty array ensures that the effect is only run on mount

  const isMobile = windowSize < mobileBreakPoint;
  const isBootstrapSm = windowSize <= bootstrapSmallBreakPoint;
  const isSmallDesktop = windowSize <= smallDesktopSizeBreak;

  return {
    windowSize, isMobile, isBootstrapSm, isSmallDesktop,
  };
};

export default useViewport;
