import React from 'react';
import { connect } from 'react-redux';
import styles from './SMRButton.module.scss';

const SMRButton = ({ customer, data, vehicleRegistration, inline = false }) => {
  if (!data && !vehicleRegistration) return null;

  const postCode = customer?.custInfo?.Address?.Postcode;

  const url = new URL(data.externalUrl);

  if (!postCode && !url) return null;

  url.searchParams.set('plateNumber', vehicleRegistration);
  url.searchParams.set('postcode', postCode);

  return (
    <a href={url} className={`${styles.btnContainer} ${inline && styles.inline}`}>
      {data.serviceCta}
    </a>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
});

export default connect(mapStateToProps)(SMRButton);
