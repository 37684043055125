export function setPolicyInfo(policyInfo) {
  return {
    type: 'SET_POLICYINFO',
    payload: policyInfo,
  };
}

export function setUpdatedVehicle(vehiclePayload) {
  return {
    type: 'UPDATE_VEHICLEINFO',
    payload: vehiclePayload,
  };
}

export function setAutoRenew(autoRenewPayload) {
  return {
    type: 'SET_AUTO_RENEW',
    payload: autoRenewPayload,
  };
}

export function invalidatePolicyInfo() {
  return {
    type: 'INVALIDATE_POLICYINFO',
    payload: '',
  };
}

export function setUpdatedAddionalMember(memberPayload) {
  return {
    type: 'UPDATE_ADDITIONALINFO',
    payload: memberPayload,
  };
}

export function setPolicyAcknowledged() {
  return {
    type: 'SET_POLICY_ACKNOWLEDGED',
  };
}

export function policyHasError(hasError) {
  return {
    type: 'POLICY_HAS_ERROR',
    payload: hasError,
  };
}

export function setMotPlus(motplusPayload) {
  return {
    type: 'MOT_PLUS',
    payload: motplusPayload,
  };
}
