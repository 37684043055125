import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format, subYears } from 'date-fns';

import * as EditButtonsActions from 'actions/editableButtonsAction';
import * as PolicyActions from 'actions/policyInfoActions';
import ComponentView from 'common/ComponentView';
import DropdownDate from 'components/ReactDropdownDate';
import Spinner from 'components/Spinner';
import trackEvent from 'services/AnalyticsService';
import { getMessage } from 'services/MessageService';
import { ResponseCode } from 'services/models/httpResponse';
import { GetPolicyInfo, UpdateAdditionalMember } from 'services/MyRacService';
import { loggerFunc } from 'utils/logger';
import { isEmpty } from 'utils/utils_validation';

import './ChangeAdditionalMembers.scss';

const logger = loggerFunc('ChangeAdditionalMembers');
const nameRegex = /^[a-zA-Z '.-]*$/;

class ChangeAdditionalMembers extends Component {
  constructor(props) {
    super(props);
    this.getValidation = this.getValidation.bind(this);

    this.state = {
      cantEditMessage: null,
      newMemberSaved: false,
      firstNameError: false,
      surnameError: false,
      firstName: null,
      surname: null,
      title: null,
      dob: null,
      sequenceNumber: null,
      newMemberSavedError: false,
      spinner: false,
      validation: true,
      errorText: undefined,
      errorHeaderText: undefined,
    };
    this.onErrorModalClose = this.onErrorModalClose.bind(this);
  }

  componentDidMount() {
    const { policy: { policyInfo: { Members } }, sequence } = this.props;

    Members.forEach((element) => {
      if (element.SequenceId === parseInt(sequence)) {
        this.setState({
          title: element.Title,
          firstName: element.FirstName,
          surname: element.Surname,
          dob: element.DateOfBirth,
          sequenceNumber: parseInt(element.SequenceId),
        });
      }
    });

    trackEvent('myrac.additionalmemberdetails.modal');
  }

  onChange(e) {
    if ((e.target.name === 'firstName' || e.target.name === 'surname')
      && (!(nameRegex.test(e.target.value)) || isEmpty(e.target.value))) {
      if (e.target.name === 'firstName') {
        this.setState({
          firstNameError: true,
        });
      }
      if (e.target.name === 'surname') {
        this.setState({
          surnameError: true,
        });
      }
    } else {
      if (e.target.name === 'firstName') {
        this.setState({
          firstNameError: false,
        });
      }
      if (e.target.name === 'surname') {
        this.setState({
          surnameError: false,
        });
      }
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  getValidation(val) {
    this.setState({ validation: val });
  }

  onErrorModalClose = () => {
    this.setState({
      errorText: undefined,
      errorHeaderText: undefined,
      newMemberSaved: false,
      spinner: false,
    });
  };

  handleError = (e, text, headerText) => {
    this.setState({
      errorText: text,
      errorHeaderText: headerText,
    });
    logger.error(text, e);
  }

  toggleClose = (e) => {
    e.preventDefault();
    const { setEditAdditionalMembers } = this.props;
    setEditAdditionalMembers(false);
  }

  saveMember = async (e) => {
    e.preventDefault();
    this.setState({ spinner: true });

    const {
      policy, customer, setUpdatedAddionalMember, pageModel, setUpdatedVehicles,
    } = this.props;

    const { dob, sequenceNumber } = this.state;

    const additionalMemberArray = policy?.policyInfo?.Members?.map((element) => {
      if (element.SequenceId === sequenceNumber && !element.LeadMember) {
        return {
          ...element,
          Title: e.target.title.value,
          FirstName: e.target.firstName.value,
          Surname: e.target.surname.value,
          DateOfBirth: format(new Date(dob), 'yyyy-MM-dd'),
        };
      }
      return element;
    });

    trackEvent('myrac.additionalmemberdetails.modal.submit');

    try {
      const updatedVehiclesAndBeneficiaries = await UpdateAdditionalMember(customer.custInfo.Id, policy.policyInfo.PolicyId, additionalMemberArray);
      if (updatedVehiclesAndBeneficiaries.responseCode === ResponseCode.SUCCESS) {
        setUpdatedAddionalMember(updatedVehiclesAndBeneficiaries.payload.additionalMembers);
        setUpdatedVehicles(updatedVehiclesAndBeneficiaries.payload.vehicles);
        this.setState({ newMemberSaved: true, spinner: false });
      } else {
        this.handleError(null, pageModel.dialogues.fail.text, pageModel.dialogues.fail.title);
      }
    } catch (err) {
      try {
        await GetPolicyInfo(customer.custInfo.Id);
        this.handleError(err, pageModel.dialogues.fail.text, pageModel.dialogues.fail.title);
      } catch (error) {
        this.handleError(error, getMessage());
      }
    }
  }

  // MYR-890
  // MYR-890
  updateTitles(pageModel) {
    const { title } = this.state;
    if (title && pageModel.titleOptions) {
      if (pageModel.titleOptions.indexOf(title) === -1) {
        pageModel.titleOptions.push(title);
      }
    }
  }

  render() {
    const { policy: { policyInfo }, pageModel, setEditAdditionalMembers } = this.props;
    const {
      firstNameError, surnameError, cantEditMessage, newMemberSaved, newMemberSavedError,
      spinner, title, firstName, surname, dob, validation, errorHeaderText, errorText,
    } = this.state;

    if (!policyInfo.CanEditAdditionalMembers) {
      const message = pageModel.notEditReasons.filter(
        (item) => item.key === policyInfo.CantEditAdditionalMemberReason,
      )[0];
      this.setState({ cantEditMessage: message });
    }
    this.updateTitles(pageModel);

    const endDate = format(subYears(new Date(), 16), 'yyyy-MM-dd'); // 16 years ago from today
    const isValid = (!firstNameError && !surnameError);

    return (
      <ComponentView errorText={errorText} errorHeaderText={errorHeaderText} onErrorModalClose={() => { setEditAdditionalMembers(false); }}>
        <div className="modal myrac" role="dialog" style={{ display: 'block', overflowY: 'auto' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              {!policyInfo.CanEditAdditionalMembers
                && (
                  <div>
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close ascii-cross"
                        onClick={this.toggleClose}
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        &#215;
                      </button>
                      <h4>&nbsp;</h4>
                    </div>
                    <div className="modal-body">
                      <p className="modal-small-header">{cantEditMessage}</p>
                    </div>
                  </div>
                )}
              {policyInfo.CanEditAdditionalMembers
                && (
                  <div className="modal-header changeAdditionalMembers__modal-header">
                    <button
                      type="button"
                      className="close ascii-cross"
                      onClick={this.toggleClose}
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      &#215;
                    </button>
                    {newMemberSaved ? (
                      <div className="w-100 text-center">
                        <h4 className="modal-title">{pageModel.dialogues.success.title}</h4>
                        <p className="modal-small-header">{pageModel.dialogues.success.text}</p>
                      </div>
                    ) : (
                      <div>
                        {newMemberSavedError ? (
                          <div className="w-100 text-center">
                            <h4 className="modal-title">{pageModel.dialogues.fail.title}</h4>
                            <p className="modal-small-header">{pageModel.dialogues.fail.text}</p>
                          </div>
                        ) : (
                          <div className="w-100 text-center">
                            {!spinner
                                  && <h4 className="modal-title">{pageModel.title}</h4>}
                            <p className="changeAdditionalMembers__modal-small-header">{pageModel.headermessage}</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              {policyInfo.CanEditAdditionalMembers && !newMemberSaved && !newMemberSavedError && !spinner
                && (
                  <div className="modal-body">
                    <form noValidate className="MyRacForm" name="memberForm" onSubmit={this.saveMember}>
                      <label htmlFor="Title">
                        <span className="MyRacForm__label">
                          {pageModel.titleLabel}
                          {' '}
                          *
                        </span>
                        <select
                          id="Title"
                          className="MyRacForm__input-extra-height MyRacForm__input-third-width MyRacForm__form-element"
                          name="title"
                          value={title || ''}
                          onChange={(value) => this.onChange(value)}
                          required
                        >
                          {/* eslint-disable-next-line react/no-array-index-key */}
                          {pageModel.titleOptions.map((option, index) => <option key={index}>{option}</option>)}
                        </select>
                      </label>
                      <label htmlFor="FirstName">
                        {pageModel.firstNameLabel}
                        {' '}
                        *
                        <input
                          id="FirstName"
                          className="MyRacForm__form-element MyRacForm__input-extra-height"
                          name="firstName"
                          type="text"
                          pattern="/^[a-zA-Z\s]*$/"
                          value={firstName || ''}
                          onChange={(value) => this.onChange(value)}
                          required
                        />
                        {firstNameError
                          && <span className="MyRacForm__error-msg">{pageModel.firstNameInvalid}</span>}
                      </label>
                      <label htmlFor="Surname">
                        {pageModel.lastNameLabel}
                        {' '}
                        *
                        <input
                          id="Surname"
                          className="MyRacForm__form-element MyRacForm__input-extra-height"
                          name="surname"
                          type="text"
                          pattern="/^[a-zA-Z\s]*$/"
                          value={surname || ''}
                          onChange={(value) => this.onChange(value)}
                          required
                        />
                        {surnameError
                          && <span className="MyRacForm__error-msg">{pageModel.lastNameInvalid}</span>}
                      </label>
                      { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                      <label>
                        {pageModel.dobLabel}
                        {' '}
                        *
                      </label>
                      <DropdownDate
                        sendValidation={this.getValidation}
                        startDate="1909-01-01"
                        endDate={// optional, if not provided current date is endDate
                          endDate // 'yyyy-mm-dd' format only
                        }
                        selectedDate={// optional
                          dob
                          // 'yyyy-mm-dd' format only
                        }
                        order={// optional
                          ['day', 'month', 'year'] // Order of the dropdowns
                        }
                        onMonthChange={() => { // optional

                        }}
                        onDayChange={() => { // optional

                        }}
                        onYearChange={() => { // optional

                        }}
                        onDateChange={(date) => { // optional
                          this.setState({ dob: format(new Date(date), 'yyyy-MM-dd') });
                        }}
                        ids={// optional
                          {
                            year: 'select-year',
                            month: 'select-month',
                            day: 'select-day',
                          }
                        }
                        names={// optional
                          {
                            year: 'year',
                            month: 'month',
                            day: 'day',
                          }
                        }
                        classes={// optional
                          {
                            dateContainer: 'selectContainer2',
                            yearContainer: 'selectContainerDiv1',
                            monthContainer: 'selectContainerDiv2',
                            dayContainer: 'selectContainerDiv3',
                            year: 'selectContainer select',
                            month: 'selectContainer select',
                            day: 'selectContainer select',
                            yearOptions: 'classes',
                            monthOptions: 'classes',
                            dayOptions: 'classes',
                          }
                        }
                        defaultValues={// optional
                          {
                            year: 'select year',
                            month: 'select month',
                            day: 'select day',
                          }
                        }
                        options={// optional
                          {
                            yearReverse: true, // false by default
                            monthNumeric: true, // false by default
                          }
                        }
                      />
                      {!validation && (
                        <span className="MyRacForm__error-msg">{pageModel.dobInvalid}</span>
                      )}
                      <div className="MyRacForm__button-wrapper">
                        <button
                          className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--grey"
                          type="button"
                          data-dismiss="modal"
                          onClick={this.toggleClose}
                        >
                          {pageModel.buttons.back}
                        </button>
                        <button
                          className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--left-margin"
                          type="submit"
                          disabled={!isValid || !validation}
                        >
                          {pageModel.buttons.submit}
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              {spinner
                && (
                  <div className="modal-body">
                    <Spinner spinnerMsg="We are updating your information." defaultSpinner />
                  </div>
                )}
              {(newMemberSaved || !policyInfo.CanEditAdditionalMembers || newMemberSavedError)
                && (
                  <div className="modal-body">
                    <form noValidate className="MyRacForm">
                      <div className="MyRacForm__button-wrapper MyRacForm__button-wrapper--center">
                        <button
                          className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide"
                          type="button"
                          onClick={this.toggleClose}
                          data-dismiss="modal"
                        >
                          {pageModel.buttons.updateButton}
                        </button>
                      </div>
                    </form>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div
          className="modal-backdrop fade in changeAdditionalMembers__modal-backdrop"
        />
      </ComponentView>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
  editValues: state.edit,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAdditionalMembers: (editAdditionalMembers) => {
    dispatch(EditButtonsActions.setEditAdditionalMembers(editAdditionalMembers));
  },
  setUpdatedAddionalMember: (members) => {
    dispatch(PolicyActions.setUpdatedAddionalMember(members));
  },
  setUpdatedVehicles: (vehicles) => {
    dispatch(PolicyActions.setUpdatedVehicle(vehicles));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAdditionalMembers);
