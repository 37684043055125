/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as DefaultText from 'configuration/defaultText';
import * as EditButtonsActions from 'actions/editableButtonsAction';
import ChangeAdditionalMembers from 'panels/ChangeAdditionalMembers';
import * as CustomerActions from 'actions/customerInfoActions';

export class AdditionalMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sequenceId: null,
    };
  }

  handleChangeMember = (e) => {
    e.preventDefault();
    this.setState({ sequenceId: [e.target.name] });
    const { setEditAdditionalMembers } = this.props;
    setEditAdditionalMembers(true);
  };

  render() {
    const { policy: { policyInfo }, pageModel, editValues } = this.props;
    if (!pageModel) { return null; }

    const nonLeadMember = policyInfo?.Members?.filter((m) => !m.LeadMember);
    const { sequenceId } = this.state;
    return (
      <>
        {editValues.editAdditionalMembers
          && (
            <ChangeAdditionalMembers
              sequence={sequenceId}
              pageModel={pageModel.change}
            />
          )}
        <div className="col-12 col-sm-6">
          <div className="tile box">
            <h4>{pageModel.title}</h4>
            <div className="Info sessioncamhidetext">
              {policyInfo.hasError || !policyInfo.PolicyId ? (
                <p className="long primary member-wrapper">
                  {DefaultText.Waiting}
                </p>
              ) : (
                nonLeadMember.length ? (
                  nonLeadMember
                    .map((member) => (
                      <p className="long primary member-wrapper" key={member.SequenceId}>
                        {member.FirstName}
                        {' '}
                        {member.Surname}
                        {policyInfo.CanEditAdditionalMembers && (
                        <span className="action edit inline">
                          <button
                            type="button"
                            className="MyRacForm__button-link link Panel__button"
                            name={member.SequenceId}
                            onClick={this.handleChangeMember}
                          >
                            {pageModel.button.text}
                            {' '}
                            &gt;
                          </button>
                        </span>
                        )}
                      </p>
                    ))
                ) : (
                  <p className="long primary member-wrapper">
                    {pageModel.noAdditionalMembers}
                  </p>
                )
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  policy: state.policy,
  editValues: state.edit,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAdditionalMembers: (editAdditionalMembers) => {
    dispatch(EditButtonsActions.setEditAdditionalMembers(editAdditionalMembers));
  },
  setCustomerInfo: (customerInfo) => {
    dispatch(CustomerActions.setCustomerInfo(customerInfo));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalMembers);
