// eslint-disable-next-line default-param-last
const editableButtonsReducer = (state = {
  editPhoneNumber: false,
  editAddress: false,
  editDOB: false,
  editEmail: false,
  editAdditionalMembers: false,
  editVehicles: false,
}, action) => {
  switch (action.type) {
    case 'SET_EDITPHONENUMBER':
      return {
        ...state,
        editPhoneNumber: action.payload,
      };
    case 'SET_EDITADDRESS':
      return {
        ...state,
        editAddress: action.payload,
      };
    case 'SET_EDITDOB':
      return {
        ...state,
        editDOB: action.payload,
      };
    case 'SET_EDITEMAIL':
      return {
        ...state,
        editEmail: action.payload,
      };
    case 'SET_EDITADDITIONALMEMBERS':
      return {
        ...state,
        editAdditionalMembers: action.payload,
      };
    case 'SET_EDITVEHICLES':
      return {
        ...state,
        editVehicles: action.payload,
      };
    default:
      return state;
  }
};

export default editableButtonsReducer;
