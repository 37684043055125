import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ResponseCode } from 'services/models/httpResponse';
import { GetContent } from 'services/ContentService';
import * as DefaultText from 'configuration/defaultText';
import ResetStore from 'actions/appActions';
import Spinner from '../../components/Spinner';
import PasswordResetForm from './ProductUpgrade/PasswordResetForm';
import styles from './UpdatePassword.module.scss';

const UpdatePasswordPage = ({ session, resetStore }) => {
  const [loading, setLoading] = useState(true);
  const [pageModel, setPageModel] = useState();

  const getPageModel = async () => {
    let cmsData = await GetContent('passwordreset', null, null);
    if (cmsData.responseCode === ResponseCode.ERROR) {
      cmsData = { error: DefaultText.APIError };
    } else {
      cmsData = {
        title: cmsData.payload.pagetitle,
        heading: cmsData.payload.heading,
        preamble: {
          description: cmsData.payload.passwordresetdescription,
          passwordFormat: cmsData.payload.resetinformation,
          additional: cmsData.payload.additionalinformation,
        },
        fields: {
          currentPasswordLabel: cmsData.payload.currentpasswordlabel,
          currentPasswordPlaceholder: cmsData.payload.currentpasswordplaceholder,
          newPasswordLabel: cmsData.payload.newpasswordlabel,
          newPasswordPlaceholder: cmsData.payload.newpasswordplaceholder,
          confirmPasswordLabel: cmsData.payload.confirmpasswordlabel,
          confirmPasswordPlaceholder: cmsData.payload.confirmpasswordplaceholder,
          requiredFieldMessage: 'This field is required',
          submitButton: cmsData.payload.updatepasswordbuttontext,
          cancelButton: 'Cancel',
          passwordFormat: cmsData.payload.passworderrormessage,
          passwordsNotMatch: 'Passwords do not match',
        },
        messages: {
          invalidPassword: 'Incorrect password',
          successful: {
            title: 'Thank you',
            text: 'Your password has been updated.',
          },
          fail: {
            title: 'Update un-successful',
            text: 'Sorry, the update hasn\'t been successful. Please try again later.',
          },
        },
        modal: {
          close: 'Close',
          ok: 'OK',
        },
      };
      setPageModel(cmsData);
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      if (!pageModel) {
        getPageModel();
      }
      if (!session.isLoggedIn) {
        // MYR-944 need to reset store in case there were object with errors.
        resetStore();
      }
    } catch (e) {
      // this.handleError(e, getMessage());
    }
  }, []);

  return (
    loading ? <Spinner defaultSpinner />
      : (
        <div className={styles.container}>
          <h2 className={styles.title}>{pageModel.heading}</h2>
          <div className={styles.contentContainer}>
            <div className={styles.formContainer}>
              <PasswordResetForm pageModel={pageModel} />
            </div>
            <div className={styles.passwordInfoContainer}>
              <p className={styles.description}>{pageModel.preamble.description}</p>
              <ul className={styles.list}>
                {pageModel.preamble.passwordFormat.map((row) => <li key={row}>{row}</li>)}
              </ul>
              <p className={styles.additionalDescription}>{pageModel.preamble.additional}</p>
            </div>
          </div>
        </div>
      )
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
  editValues: state.edit,
});

const mapDispatchToProps = (dispatch) => ({
  resetStore: () => {
    dispatch(ResetStore());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordPage);
