const sessionStorageKey = {
  policy: 'policy',
  session: 'session',
  customer: 'customer',
  documents: 'documents',
  vehicles: 'vehicles',
  step: 'step',
  content: 'content',
};
export default sessionStorageKey;
