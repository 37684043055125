import React from 'react';
import { loggerFunc } from 'utils/logger';
import { logoutUser } from 'auth/userManager';
import * as RouteConstants from 'constants/routeConstants';
import { useNavigate } from 'react-router-dom';
import Button from '../../shared/components/Button/Button';
import styles from './Logout.module.scss';

const logger = loggerFunc('Logout');

const Logout = () => {
  const navigate = useNavigate();

  const toggleShowModal = () => {
    logoutUser(
      () => {
        logger.debug('Logged out');
        navigate(RouteConstants.LOGIN);
      },
      (e) => {
        logger.error('Error occured while logging out...', e);
      },
    );
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Leaving MyRAC...</h1>
      <p className={styles.text}>Are you sure you want to logout?</p>
      <div className={styles.buttonContainer}>
        <Button buttonStyles={{ width: '180px', lineHeight: '1.4rem', backgroundColor: '#4a4a4a' }} onClickHandler={() => { navigate(-1); }} buttonText="Back to MyRAC" />
        <Button buttonStyles={{ width: '180px', lineHeight: '1.4rem' }} onClickHandler={toggleShowModal} buttonText="Yes" />
      </div>
    </div>
  );
};

export default Logout;
