import * as StepConstants from 'constants/journeyManagerStepConstants';

export function startJourney(name, stepIndex, stepData) {
  return {
    type: StepConstants.SET_START,
    payload: {
      name,
      stepIndex,
      stepData,
    },
  };
}

export function setCurrentStep(stepindex) {
  return {
    type: StepConstants.SET_STEP,
    payload: stepindex,
  };
}

export function setStepData(stepData) {
  return {
    type: StepConstants.SET_DATA,
    payload: stepData,
  };
}

export function endJourney() {
  return {
    type: StepConstants.SET_END,
  };
}
