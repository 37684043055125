const ResponseCode = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
};

const Response = {
  payload: null,
  error: null,
  responseCode: ResponseCode.PENDING,
};

export { Response, ResponseCode };
