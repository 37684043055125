import React, {
  createContext, useContext, useMemo, useReducer,
} from 'react';
import { initialState, applicationStateReducer } from '../reducers/applicationStateReducer';
import { setLoadingAction } from '../actions/applicationStateActions';

const ApplicationStateContext = createContext();

const ApplicationStateProvider = (props) => {
  const [state, dispatch] = useReducer(applicationStateReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ApplicationStateContext.Provider value={value} {...props} />;
};

const useApplicationState = () => {
  const context = useContext(ApplicationStateContext);
  if (!context) {
    throw new Error('useApplicationState must be used within an ApplicationStateProvider');
  }
  const [state, dispatch] = context;

  const setPageLoading = (status, title) => dispatch(setLoadingAction({ status, title }));

  return {
    appState: state,
    dispatch,
    setPageLoading,
  };
};

export { useApplicationState, ApplicationStateProvider };
