import React from 'react';
import styles from './ReminderItem2.module.scss';
import { getIcon } from '../../../utils/vehicleHelpers';
import ReminderType from '../../../utils/reminderType.enum';
import SMRButton from './SMRButton';

const ReminderItem2 = ({ title, registration, dateReminderText, reminderType, reminderSMRBtn }) => (
  <div className={styles.container}>
    <i className={`fs-5 ${getIcon(reminderType)} ${styles.icon}`} />
    <div className={styles.infoContainer}>
      <p><span className={styles.title}>{title}</span> for {registration}</p>
      <p className={styles.dateReminderText}>{dateReminderText}</p>
    </div>
    {reminderType === ReminderType.service && reminderSMRBtn && <SMRButton data={reminderSMRBtn} vehicleRegistration={registration} inline />}
  </div>
);

export default ReminderItem2;
