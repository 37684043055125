import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setRenewalPaymentPayload } from 'actions/renewalActions';
import { dispatch } from 'store';
import { DOCUMENTS, RENEWAL_PAYMENT, RENEWAL_DIRECTDEBIT } from '../../../../constants/routeConstants';
import { GetDocument } from '../../../../services/MyRacService';
import { createUpgradeAmendment, createVehicleAmendment, createAdobeOfferPayload, selectedAdobeOfferStatus } from '../../../../utils/renewalHelpers';
import { SourceCodes } from '../../../../constants/enums';
import priceTrim from '../../../../utils/priceTrim';
import BulletList from '../shared/components/BulletList';
import Button from '../../../../shared/components/Button/Button';
import PopupModal from '../../../../components/PopupModal/PopupModal';
import infoIconSvg from '../../../../assets/images/button/info-blue.svg';
import trackEvent from '../../../../services/AnalyticsService';
import styles from './Basket.module.scss';

const Basket = (props) => {
  const { enableAutoRenew, enablePaperless } = props;
  const { customerId, memberNo, discountRenewal } = useSelector((state) => state.session);
  const pageModel = useSelector((state) => state.renewal.pageModel);
  const selectedUpgrades = useSelector((state) => state.renewal.basket.upgrades);
  const selectedFLR = useSelector((state) => state.renewal.basket.freeLoyaltyReward);
  const policyInfo = useSelector((state) => state.policy.policyInfo);
  const vehiclesAdded = useSelector((state) => state.renewal.basket.vehicles);

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const dummyPath = ''; // Dummy filepath created to avoid linting errors
  const linkRef = useRef();

  const originalPrice = pageModel?.price;
  const discountedOriginalPrice = discountRenewal?.originalPrice;

  let addedUpgradesTotal = 0;
  let addedUpgradesTotalDiscounted = 0;

  /* Calculating prices of paid upgrades only here */
  if (selectedUpgrades && selectedUpgrades.length) {
    selectedUpgrades.forEach((upgrade) => {
      if (upgrade?.discountedPrice && upgrade?.price) {
        addedUpgradesTotal += upgrade.discountedPrice;
        addedUpgradesTotalDiscounted += upgrade.price;
      } else if (upgrade?.price) {
        addedUpgradesTotal += upgrade.price;
      }
    });
  }

  /* not taking in to account monthly payments yet */
  const totalPrice = addedUpgradesTotal > 0 ? priceTrim(originalPrice + addedUpgradesTotal) : priceTrim(originalPrice);
  const totalDiscountedPrice = addedUpgradesTotalDiscounted > 0 ? priceTrim(discountedOriginalPrice + addedUpgradesTotalDiscounted) : priceTrim(discountedOriginalPrice);

  // Monthly Long Term Policy (MLTP)
  const listItems = (list) => list.map((item) => item && <BulletList header={item?.header} body={item?.body} />);

  const handleRenewNow = (e) => {
    e.preventDefault();
    // Amendments created using helpers where json schema defined for each type
    const amendments = [];
    // Upgrades
    if (selectedUpgrades?.length > 0) {
      selectedUpgrades.map((upgrade) => upgrade && amendments.push(createUpgradeAmendment(upgrade)));
    }
    // Vehicles
    if (vehiclesAdded?.length > 0) {
      // Filter by only those edited and add each to amendments array for state
      vehiclesAdded?.filter((vehicle) => vehicle?.OldReg).map((vehicle) => vehicle && amendments.push(createVehicleAmendment(vehicle)));
    }

    // Adobe More4More offer selection for applicable users
    const adobeOffer = createAdobeOfferPayload(pageModel?.adobeOfferStatus, pageModel?.freeLoyaltyRewards, selectedFLR.propositionId);

    dispatch(setRenewalPaymentPayload({
      CustomerId: customerId,
      MembershipNumber: memberNo,
      Price: totalPrice,
      MonthlyLongerTermPolicy: pageModel?.isMonthlyLongerTermPolicy,
      RenewalRiskVersion: pageModel?.renewalRiskVersion,
      StateToken: pageModel?.stateToken,
      PolicyNumber: policyInfo?.PolicyNumber,
      ConfirmationPage: pageModel?.confirmationPage,
      AdobeOfferStatus: selectedAdobeOfferStatus(pageModel?.adobeOfferStatus, selectedFLR.propositionId),
      PropositionId: adobeOffer?.propositionId ?? null,
      AdobeCode: adobeOffer?.adobeCode ?? null,
      AdobeOfferTitle: adobeOffer?.title ?? null,
      PaymentDate: pageModel?.directDebitPaymentDate,
      DirectDebitPage: pageModel?.directDebitPage,
      EnableAutoRenew: enableAutoRenew,
      EnablePaperless: enablePaperless,
      Amendments: amendments,
      PriceBeforeDiscount: totalDiscountedPrice,
    }));
    if (pageModel?.paymentMethod === 'DirectDebit') {
      navigate(RENEWAL_DIRECTDEBIT);
    } else {
      navigate(RENEWAL_PAYMENT);
    }
  };

  const getDocument = (e, data, custId) => {
    e.preventDefault();
    const doc = data?.documents?.termsConditions;
    if (doc) {
      const documentType = doc.DocumentType === 'Booklet' && doc.DocumentType.toLowerCase();
      return GetDocument(custId, documentType, doc.DocumentId)
        .then((blob) => {
          const file = new Blob([blob.payload], {
            type: 'application/pdf',
          });
          // The linkRef controls the document download
          linkRef.current.href = URL.createObjectURL(file);
          linkRef.current.download = `${doc.Name}.pdf`;
          linkRef.current.click();
        });
    }
    return false;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {discountRenewal?.isDiscountedPrice ? (
          <p><span className="fw-bold orange">£{totalPrice.toFixed(2)}</span> <s>£{totalDiscountedPrice.toFixed(2)}</s></p>
        ) : (
          <p>{pageModel?.isMonthlyLongerTermPolicy
            ? `£${pageModel?.basketContent?.monthlyPrice?.toFixed(2)} / month`
            : `£${totalPrice.toFixed(2)}`}
          </p>
        )}
        <i className="fas fa-shopping-cart fa-2x orange" />
      </div>
      {discountRenewal?.isDiscountedPrice && (
        <p className={styles.discountApplied}>{discountRenewal?.basketDiscountText} discount applied</p>
      )}
      {pageModel?.isMonthlyLongerTermPolicy && (
        <>
          <p className={styles.monthlyDuration}>{pageModel?.basketContent?.duration}</p>
          {listItems(pageModel?.basketContent?.instalments)}
        </>
      )}
      <div className={styles.termsAndConditions}>
        By renewing you confirm that the cover is right for you,&nbsp;
        {pageModel?.documents?.termsConditions?.documentLocation ? (
          <>
            you’ve read the <a onClick={(e) => { getDocument(e, pageModel, customerId, policyInfo); trackEvent('MyRAC_Renewal_TandCs'); }} href={dummyPath}>Terms and Conditions</a>
            <a className="d-none" role="button" href={dummyPath} ref={linkRef}>Download Ref</a>
          </>
        ) : (
          <>
            you’ve read the Terms and Conditions
          </>
        )}
        &nbsp;and you’ve read the <a onClick={() => { trackEvent('MyRAC_Renewal_Docs'); navigate(DOCUMENTS); }} href={dummyPath}>Renewal document</a>
        {policyInfo?.sourceCode === SourceCodes.RWAGG01 && (
          <div>
            <img src={infoIconSvg} alt="info" height="18" width="18" className={styles.icon} />
            <span
              onClick={() => setShowModal(true)}
              className={styles.findOutMoreLink}
              role="link"
              onKeyDown={() => setShowModal(true)}
              tabIndex={0}
            >
              &#163;40 excess per callout
            </span>
          </div>
        )}
      </div>
      {showModal && (
        <PopupModal
          resultTitle="Your policy excess"
          resultText="Your cover includes an excess which is payable each time you make a claim on your breakdown policy. If you'd like to discuss this, please call us on 0330 159 1111."
          action={() => setShowModal(false)}
          displayDialogInMiddle
          innerCloseButton
        />
      )}
      <Button
        onClickHandler={handleRenewNow}
        buttonText="Renew now"
        chevronWhite
      />
    </div>
  );
};

export default Basket;
