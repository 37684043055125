import { APPLICATION_STATE_ACTION_NAMES } from '../actions/applicationStateActions';

const initialState = {
  loading: {
    status: true,
    title: 'Welcome to MyRac',
  },
};

function applicationStateReducer(state, action) {
  switch (action.type) {
    case APPLICATION_STATE_ACTION_NAMES.setPageLoading:
      return {
        ...state,
        loading: {
          ...state.loading,
          status: action.payload.status,
          title: action.payload.title,
        },
      };
    default:
      return state;
  }
}

export { initialState, applicationStateReducer };
