import React from 'react';
import { useApplicationState } from '../../hooks/applicationState-Context';
import Spinner from '../../components/Spinner';

const Loading = () => {
  const { appState } = useApplicationState();

  return (
    appState.loading.status && <Spinner addClass="position-absolute top-50 start-50 translate-middle" bootSpinner />
  );
};

export default Loading;
