const contentUID = {
  membershipStatus: 'membershipstatus',
  membershipCover: 'membershipcover',
  membershipReminder: 'membershipyourreminders',
  membershipDocuments: 'membershipyourdocuments',
  moreForMore: 'm4moffertile',
  countdown: 'countdownoffertile',
  imageTile: 'imagetile',
  countdownWithCtaTile: 'countdownwithctatile',
  imageTileCTA: 'imagewithcta',
};

export default contentUID;
