import { BrowserRouter } from 'react-router-dom';
import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';
import { polyfill } from 'es6-promise';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from 'App';
import { store } from 'store';

import './globalStyles.scss';
import Sentry from 'logging/Sentry';
import { UserJourneyProvider } from './hooks/userJourney-Context';
import { ApplicationStateProvider } from './hooks/applicationState-Context';

polyfill();

// eslint-disable-next-line function-paren-newline
ReactDOM.render(
  <Sentry>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Provider store={store}>
        <ApplicationStateProvider>
          <UserJourneyProvider>
            <App />
          </UserJourneyProvider>
        </ApplicationStateProvider>
      </Provider>
    </BrowserRouter>
  </Sentry>,
  document.getElementById('root'),
  // eslint-disable-next-line function-paren-newline
);
