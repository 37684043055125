import React from 'react';
import styles from './TextButton.module.scss';

const TextButton = ({ buttonText, onClickHandler, iconImage, iconImageAltText, showArrow, orange, noUnderline, textUnderline, noPadding }) => (
  <div className={`${styles.textButtonContainer} ${orange && styles.orange} ${noUnderline && styles.noUnderline} ${textUnderline && styles.textUnderline} ${noPadding && styles.noPadding}`}>
    <button className={`${styles.button} ${orange && styles.orange} ${noUnderline && styles.noUnderline} ${noPadding && styles.noPadding}`} type="button" onClick={onClickHandler}>
      { iconImage && <img src={iconImage} alt={iconImageAltText} className={styles.iconImage} /> }
      { iconImage ? <u>{buttonText}</u> : buttonText }
      { showArrow && ' >'}
    </button>
  </div>
);

export default TextButton;
