import React, { useState } from 'react';
import block from 'bem-css-modules';
import { connect } from 'react-redux';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import { createSearchParams, useNavigate } from 'react-router-dom';
import modalTypes from '../../../utils/modalTypesEnum';
import featureStyles from './MyCover.module.scss';
import PopupModal from '../../../components/PopupModal/PopupModal';
import styles from './ProductUpgradeCard.module.scss';
import * as RouteConstants from '../../../constants/routeConstants';
import policyUpgradeEnum from '../../../utils/policyUpgrade.enum';
import trackEvent from '../../../services/AnalyticsService';
import productUpgradeEligibility from '../../../utils/productUpgradeEligibility.enum';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(styles);

const ProductUpgradeCard = ({ upgrade, policyProduct, policy }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  // Whether the modal should be opened with description or phone call
  const useModal = true;
  const hideModal = () => setModalVisible(false);
  const showModal = () => setModalVisible(true);

  const isUpgrading = upgrade.status === productUpgradeEligibility.UpgradeInProgress;
  const ctaDisabled = isUpgrading;

  const viewData = {
    analyticsTag: upgrade.title?.replace(/\s/g, ''),
    body: !isUpgrading ? upgrade.description : upgrade.inProgressDescription,
    ctaLabel: !isUpgrading ? upgrade.callToActionText : upgrade.inProgressTitle,
    imageUrl: upgrade.image?.url,
    priceText: upgrade.priceText || undefined,
    title: upgrade.title,
  };

  const onUpgrade = () => {
    if (policy.policyInfo.AllowPolicyProductUpgrade === policyUpgradeEnum.YesPayment) {
      navigate({
        pathname: RouteConstants.PRODUCT_UPGRADE_PAGE,
        search: `?${createSearchParams({
          product: policyProduct?.productName,
        })}`,
      });
    } else {
      navigate({
        pathname: RouteConstants.PRODUCT_UPGRADE_JOURNEY,
        search: `?${createSearchParams({
          product: policyProduct?.productName,
        })}`,
      });
    }
  };

  const onClick = () => {
    if (ctaDisabled) return;

    if (upgrade?.status === productUpgradeEligibility.UpgradeAllowed) {
      trackEvent(`MyRAC_MyCover_${viewData.analyticsTag}_CTA`);
      onUpgrade();
    } else if (upgrade?.status === productUpgradeEligibility.UpgradeNotAllowed) {
      if (useModal) {
        trackEvent(`MyRAC_MyCover_${viewData.analyticsTag}_Modal`);
        showModal();
      } else {
        trackEvent(`MyRAC_MyCover_${viewData.analyticsTag}_CallUs`);
        window.open('tel:03301591111', '_self');
      }
    }
  };

  return (
    <>
      {modalVisible && (
        <PopupModal
          resultTitle={policyProduct?.productName}
          resultText={policyProduct?.isActive
            ? policyProduct?.text?.covered?.long
            : policyProduct?.text?.notCovered?.long}
          action={hideModal}
          modalOk="Ok"
          parseHtml
          innerCloseButton
          modalType={modalTypes.infoModal}
        />
      )}
      <CardContainer isDisabled={ctaDisabled} onClick={onClick}>
        {viewData.imageUrl && <img src={viewData.imageUrl} alt={viewData.title} className={bem('card-image')} />}
        <div className="card-body px-2">
          <h5 className={`card-title ${bem('card-title')}`}>
            {
              viewData.priceText
                ? (<strong>{viewData.title} - <span className={bem('card-title-price')}>{viewData.priceText}</span></strong>)
                : <strong>{viewData.title}</strong>
            }
          </h5>
          <div className="card-text">
            <div className={bem('card-description')}>{ReactHtmlParser(viewData.body)}</div>
          </div>
        </div>
        <button
          className={`${bem('card-cta')} ${featureStyles.outlineButton} mb-2 mx-2 px-4`}
          type="button"
          disabled={ctaDisabled}
        >
          {viewData.ctaLabel || 'Tell me more'}
        </button>
      </CardContainer>
    </>
  );
};

const CardContainer = ({ children, isDisabled, onClick }) => {
  const classNames = `${bem('card')} card ${isDisabled ? bem('card-disabled') : ''}`;
  return !isDisabled
    ? <div className={classNames} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>{children}</div>
    : <div className={classNames}>{children}</div>;
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
});

export default connect(mapStateToProps)(ProductUpgradeCard);
