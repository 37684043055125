import * as CustomerActions from 'actions/customerInfoActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import trackEvent from 'services/AnalyticsService';
import { ResponseCode } from 'services/models/httpResponse';
import { UpdateDocumentPrefs } from 'services/MyRacService';
import PopupModal from '../../../../components/PopupModal/PopupModal';
import Spinner from '../../../../components/Spinner';
import modalTypes from '../../../../utils/modalTypesEnum';
import ProtectedForm from '../../../../shared/components/ProtectedForm/ProtectedForm';
import './DocumentPreferencesPanel.scss';

class DocumentPreferencePanel extends Component {
  constructor(props) {
    super(props);
    const { customer } = this.props;
    this.state = {
      showDocPref: false,
      online: customer.custInfo.isPaperLess,
      showModal: false,
      title: '',
      message: '',
      changesWereMade: false,
      loading: false,
    };
    this.toggleShowDocPref = this.toggleShowDocPref.bind(this);
    this.changeDocumentPreference = this.changeDocumentPreference.bind(this);
    this.saveDocumentPreference = this.saveDocumentPreference.bind(this);
    this.toggleShowModal = this.toggleShowModal.bind(this);
  }

  toggleShowModal = (e) => {
    e.preventDefault();
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  toggleShowDocPref = (e) => {
    e.preventDefault();

    const { changesWereMade, showDocPref } = this.state;
    if (!changesWereMade) {
      this.setState({ showDocPref: !showDocPref });
    }
  }

  changeDocumentPreference = (e) => {
    const { online } = this.state;
    const { customer } = this.props;
    /* eslint-disable-next-line eqeqeq */
    if (!online !== customer.custInfo.isPaperLess) this.setState({ changesWereMade: true });
    else {
      this.setState({ changesWereMade: false });
    }

    trackEvent(`myrac.efulfil.${e.target.id}`);
    this.setState({ online: !online });
  }

  saveDocumentPreference = async (e) => {
    e.preventDefault();

    const { customer: { custInfo }, pageModel, setCustomerInfo } = this.props;
    const { online } = this.state;

    trackEvent(`myrac.efulfil.${online ? 'online' : 'paper'}submit`);

    const updateDocPrefBody = {
      isPaperless: online,
    };

    this.setState({ loading: true });

    try {
      const response = await UpdateDocumentPrefs(custInfo.Id, updateDocPrefBody);
      const custInformation = { ...custInfo };
      if (response.responseCode === ResponseCode.SUCCESS) {
        custInformation.isPaperLess = online;
        setCustomerInfo(custInformation);
        this.setState({
          showModal: true,
          title: pageModel.messages.successTitle,
          message: pageModel.messages.successMessage,
          changesWereMade: false,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        showModal: true,
        title: pageModel.messages.failTitle,
        message: pageModel.messages.failMessage,
        loading: false,
      });
    }
  }

  render() {
    const { pageModel } = this.props;
    const {
      showDocPref, online, showModal, title, message, changesWereMade, loading,
    } = this.state;

    return (
      <div className="row border pt-4 pb-3 mt-3">
        {loading ? <Spinner defaultSpinner /> : (
          <>
            <div className="account-preferences__icon col-sm-1 col-1">
              <img src={pageModel.image.url} alt="Email" />
            </div>
            <div className="col-sm-11 col-11">
              <h3 className="account-preferences__prefs-title">{pageModel.title}</h3>
              <p className="action edit">
                <button
                  type="button"
                  className="MyRacForm__button-link link Panel__button"
                  onClick={this.toggleShowDocPref}
                >
                  {pageModel.button}
                  {' '}
                  &gt;
                </button>
              </p>
              {showDocPref && (
                <div>
                  <p>{pageModel.text}</p>
                  <div>
                    <ProtectedForm formChanged={changesWereMade} className="account-preferences__marketing-wrapper">
                      <div className="myrac__checkbox--wrapper">
                        <label
                          className="myrac__checkbox myrac__checkbox--label cursor__pointer"
                          htmlFor="online"
                        >
                          <input
                            id="online"
                            className="myrac__checkbox--input"
                            type="checkbox"
                            checked={online}
                            onChange={this.changeDocumentPreference}
                          />
                          <span className="myrac__checkbox--span" />
                          <span
                            className="myrac__checkbox--text checkbox--verticalAlign"
                          >
                            {pageModel.options.online}
                          </span>
                        </label>
                      </div>
                      <div className="myrac__checkbox--wrapper">
                        <label
                          className="myrac__checkbox--text myrac__checkbox--label"
                          htmlFor="paper"
                        >
                          <input
                            id="paper"
                            className="myrac__checkbox--input"
                            type="checkbox"
                            checked={!online}
                            onChange={this.changeDocumentPreference}
                          />
                          <span className="myrac__checkbox--span" />
                          <span
                            className="myrac__checkbox--text checkbox--verticalAlign"
                          >
                            {pageModel.options.paper}
                          </span>
                        </label>
                      </div>
                    </ProtectedForm>
                  </div>
                  <p className="paperless-env icon mt-4">
                    <img
                      src={pageModel.paperless.image.url}
                      alt={pageModel.paperless.image.title}
                    />
                    <span>{pageModel.paperless.warning}</span>
                  </p>
                  <div className="account-preferences__button-wrapper">
                    <button
                      type="button"
                      onClick={this.saveDocumentPreference}
                      className="btn btn-cta btn-primary"
                      data-dismiss="modal"
                      disabled={!changesWereMade}
                    >
                      {pageModel.submit}
                    </button>
                  </div>
                </div>
              )}
              {showModal
                && (
                  <PopupModal
                    resultTitle={title}
                    resultText={message}
                    modalOk={pageModel.modalOk}
                    action={this.toggleShowModal}
                    modalType={modalTypes.infoModal}
                  />
                )}
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomerInfo: (customerInfo) => {
    dispatch(CustomerActions.setCustomerInfo(customerInfo));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPreferencePanel);
