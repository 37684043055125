import * as React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import PopupModal from '../components/PopupModal/PopupModal';
import modalTypes from '../utils/modalTypesEnum';

class ComponentView extends React.Component {
  constructor(props) {
    super(props);
    this.closeErrorModal = this.closeErrorModal.bind(this);
  }

  closeErrorModal(e) {
    const { onErrorModalClose } = this.props;
    e.preventDefault();
    onErrorModalClose();
  }

  render() {
    const {
      showLoading, children, errorHeaderText, errorText,
      loadingText, loadingOverrideDefault, isGridLayout,
    } = this.props;

    const modalHeaderStyle = {
      fontWeight: 'normal',
    };

    if (errorText) {
      return isGridLayout
        ? (
          <PopupModal
            innerCloseButton
            modalOk="Ok"
            action={this.closeErrorModal}
            resultText={errorText}
            resultTitle={errorHeaderText}
            displayDialogAtTopQuarter
            alignBodyCenter
          />
        ) : (
          <PopupModal
            innerCloseButton
            action={this.closeErrorModal}
            resultTitle={errorHeaderText}
            modalOk="Ok"
            resultText={errorText}
            modalType={modalTypes.errorModal}
            headerOverrideStyle={modalHeaderStyle}
            displayDialogAtTopQuarter
          />
        );
    }
    if (showLoading) {
      // Initial loader before Membership Overview page loaded
      return (
        <Spinner addClass="position-absolute top-50 start-50 translate-middle" bootSpinner={!loadingOverrideDefault} spinnerMsg={loadingText} />
      );
    }
    return children || null; // everything is fine, just show content
  }
}

ComponentView.propTypes = {
  children: PropTypes.node.isRequired,
  loadingText: PropTypes.string,
  errorHeaderText: PropTypes.string,
  errorText: PropTypes.string,
  onErrorModalClose: PropTypes.func.isRequired,
  showLoading: PropTypes.bool,
  loadingOverrideDefault: PropTypes.bool,
  isGridLayout: PropTypes.bool,
};

ComponentView.defaultProps = {
  loadingText: undefined,
  errorHeaderText: undefined,
  errorText: undefined,
  loadingOverrideDefault: false,
  showLoading: false,
  isGridLayout: false,
};

export default ComponentView;
