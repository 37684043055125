import { convertNodeToElement } from '@orrisroot/react-html-parser';

export const filterPanels = (filterFor, data) => {
  const content = data.panels ? data.panels : data;
  const filteredContent = Array.isArray(content) ? content.filter((panel) => panel.key === filterFor) : undefined;
  return filteredContent ? filteredContent[0] : undefined;
};

export const filterComponents = (filterFor, data) => {
  const content = data.componentitems ? data.componentitems : data;
  // eslint-disable-next-line no-underscore-dangle
  const filteredContent = Array.isArray(content) ? content.filter((panel) => panel._content_type_uid === filterFor) : undefined;
  return filteredContent ? filteredContent[0] : undefined;
};

export const filterBanners = (filterFor, data) => {
  const content = data.banner ? data.banner : data;
  // eslint-disable-next-line no-underscore-dangle
  return content.filter((banner) => banner._content_type_uid === filterFor)[0];
};

export const transformAnchorToOpenNewTabOptions = {
  transform: function transform(node, index) {
    if (node.type === 'tag' && node.name === 'a') {
      const copy = { ...node };
      copy.attribs.target = '_blank';
      return convertNodeToElement(copy, index, transform);
    }
    return undefined;
  },
};

export const addAttributesToNode = (tagToMatch, attributes) => ({
  transform: function transform(node, index) {
    if (node.type === 'tag' && node.name === tagToMatch) {
      const copy = { ...node };
      Object.entries(attributes).forEach(([key, value]) => {
        copy.attribs[key] = value;
      });
      return convertNodeToElement(copy, index, transform);
    }
    return undefined;
  },
});
