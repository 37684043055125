import React from 'react';

import TileButton from '../../GridLayout/TileButton';

import './ImageWithCtaTile.scss';

const ImageWithCtaTile = (props) => {
  const { pageModel } = props;
  const outputStaticImage = () => {
    if (pageModel.tileContent.desktopImage) {
      return (
        <img
          className="ctaImage__image-cta-tile"
          src={pageModel.tileContent.desktopImage.url}
          alt={pageModel.tileContent.desktopImage.fileName}
          title={pageModel.tileContent.desktopImage.title}
        />
      );
    }

    return null;
  };

  const [analyticsTagging] = pageModel.analyticsTags;

  return (
    <div className="ctaImage">
      {outputStaticImage()}
      {pageModel.tileContent.button
        ? (
          <TileButton
            ctaData={pageModel.tileContent.button}
            ctaPosition={pageModel.tileContent.buttonLocation}
            analyticsTag={analyticsTagging}
          />
        )
        : null}
    </div>
  );
};

export default ImageWithCtaTile;
