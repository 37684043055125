import React from 'react';
import Switch from 'react-switch';

const ToggleControl = (props) => {
  const { onToggleChange, checked, disabled, offColor, onColor } = props;
  const handleChange = (value) => onToggleChange(value);

  return (
    <Switch
      onChange={handleChange}
      checked={!!checked}
      offColor={offColor ?? '#bebebe'}
      onColor={onColor ?? '#f95108'}
      uncheckedIcon={false}
      checkedIcon={false}
      disabled={disabled}
      className="col-sm-12 col-6 col-pull-2"
    />
  );
};

export default ToggleControl;
