import React from 'react';
import styles from './Contents.module.scss';
import ToggleControl from '../../../../common/ToggleControl';

const ToggleBox = ({ title, description, onToggleChange, checked }) => (
  <div className={styles.container}>
    <div className={styles.contentContainer}>
      <span>{title}</span>
      <div className="py-3">{description}</div>
    </div>
    <div>
      <ToggleControl
        onToggleChange={onToggleChange}
        checked={checked}
        offColor="#808080"
        onColor="#1E9771"
      />
    </div>
  </div>
);

export default ToggleBox;
