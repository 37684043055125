import React, { useState, useEffect } from 'react';
import { getDaysInMonth, getYear } from 'date-fns';
import PropTypes from 'prop-types';
import { monthByNumber } from 'components/ReactDropdownDate/helper';
import './DropdownDayMonthPicker.module.scss';

const DropdownDayMonthPicker = (props) => {
  const {
    selectedDayValue, selectedMonthValue, onChange,
  } = props;
  const [state, setState] = useState({ selectedDay: undefined, selectedMonth: undefined, days: [] });

  const getDaysForMonth = (monthNumber) => {
    // Get total of days in month
    const totalDays = getDaysInMonth(new Date(getYear(new Date()), monthNumber));
    // Return array of each day in month
    return Array.from(Array(totalDays).keys(), (n) => n + 1);
  };

  useEffect(() => {
    let days = [];
    if (selectedMonthValue !== undefined && selectedMonthValue > -1) {
      days = getDaysForMonth(selectedMonthValue);
    }
    setState((prevState) => ({
      ...prevState,
      selectedDay: selectedDayValue,
      selectedMonth: selectedMonthValue,
      days,
    }));
  }, [selectedDayValue, selectedMonthValue]);

  const { selectedDay, selectedMonth, days } = state;

  const monthChangeHandler = (e) => {
    e.preventDefault();
    if (e.target.value !== '' && e.target.value !== 'Month') {
      const currentSelectedMonth = parseInt(e.target.value, 10);
      const totalDaysForMonth = getDaysForMonth(currentSelectedMonth);
      setState({
        ...state,
        selectedMonth: currentSelectedMonth,
        selectedDay: undefined,
        days: totalDaysForMonth,
      });
      onChange({ selectedDay: undefined, selectedMonth: currentSelectedMonth });
    } else {
      const currentSelectedMonth = undefined;
      setState({
        ...state,
        selectedMonth: currentSelectedMonth,
        selectedDay: undefined,
        days: [],
      });
      onChange({ selectedDay: undefined, selectedMonth: currentSelectedMonth });
    }
  };

  const dayChangeHandler = (e) => {
    e.preventDefault();
    if (e.target.value !== '' && e.target.value !== 'Day') {
      const currentSelectedDay = parseInt(e.target.value, 10);
      setState({
        ...state,
        selectedDay: currentSelectedDay,
      });
      onChange({ selectedDay: currentSelectedDay, selectedMonth });
    } else {
      setState({
        ...state,
        selectedDay: undefined,
      });
      onChange({ selectedDay: undefined, selectedMonth });
    }
  };

  const myMonth = selectedMonth !== undefined && selectedMonth > -1 ? selectedMonth : '';

  return (
    <div className="row">
      <div
        key="month"
        id="dropdown-month"
        className="col-sm-6 col-6 dateContainer dateMonth"
      >
        <select
          id="month"
          onChange={monthChangeHandler}
          value={myMonth}
        >
          <option>Month</option>
          {Object.keys(monthByNumber).map((monthKey) => (
            <option
              key={monthKey}
              value={monthKey}
            >
              {monthByNumber[monthKey]}
            </option>
          ))}
        </select>
      </div>
      <div
        key="day"
        id="dropdown-day"
        className="col-sm-6 col-6 dateContainer"
      >
        <select
          id="day"
          onChange={dayChangeHandler}
          value={selectedDay || ''}
        >
          <option>Day</option>
          {days.map((day) => (
            <option
              key={day}
              value={day}
            >
              {day}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

DropdownDayMonthPicker.propTypes = {
  selectedDayValue: PropTypes.number,
  selectedMonthValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

DropdownDayMonthPicker.defaultProps = {
  selectedDayValue: undefined,
  selectedMonthValue: undefined,
};

const isValidDate = (day, month) => {
  if (day !== undefined && month !== undefined) {
    return true;
  }
  return false;
};

export { DropdownDayMonthPicker, isValidDate };
