import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectRenewalUpgrade, removeRenewalUpgrade } from 'actions/renewalActions';
import { dispatch } from 'store';
import PopupModal from '../../../../components/PopupModal/PopupModal';
import styles from './MoreForMoreExtra.module.scss';
import infoIconSvg from '../../../../assets/images/button/info-blue.svg';
import trackEvent from '../../../../services/AnalyticsService';
import priceTrim from '../../../../utils/priceTrim';

const PaidUpgrades = () => {
  const pageModel = useSelector((state) => state.renewal.pageModel);
  const featureTag = useSelector((state) => state.renewal.basket.featureTag);
  const selectedUpgrades = useSelector((state) => state.renewal.basket.upgrades);

  const [modalData, setModalData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const products = pageModel?.paidUpgrades;

  const onFindOutMoreClick = (upgradeTitle, upgradeDescription) => {
    trackEvent(`${featureTag}_AdditionalM4M_Info`);
    setModalData({ title: upgradeTitle, description: upgradeDescription });
    setShowModal(true);
  };

  const onUpgradeCheckboxClick = (upgrade) => {
    /**
     * First if statement is when product already added so we are removing it
     * from the state, else statement is just adding to state.
     * Both actions are tracked.
     */
    if (selectedUpgrades?.some((selectedItem) => selectedItem.productCode === upgrade.productCode)) {
      dispatch(removeRenewalUpgrade(upgrade));
      trackEvent(`${featureTag}_Upgrade_${upgrade.title.replace(' ', '')}_Removed`);
    } else {
      dispatch(selectRenewalUpgrade(upgrade));
      trackEvent(`${featureTag}_Upgrade_${upgrade.title.replace(' ', '')}_Selected`);
    }
  };

  // Price to be show as whole number or with a decimal value if not '.00'
  const formatPrice = (price) => parseFloat(priceTrim(price)).toFixed(2).replace(/[.,]00$/, '');

  const isSelectedUpgrade = (selected, upgrade) => selected?.some((item) => item.productCode === upgrade.productCode);

  return (
    <>
      <div className="row">
        <div className="col">
          {products && products.map((upgrade) => (
            <div key={`paidUpgrade-${upgrade.productCode}`}>
              <div className="pt-3">
                <span className={styles.upgradeTitle}>{upgrade.title}</span>
                <p className={styles.upgradeDescription}>{upgrade.shortDescription}</p>
                <img src={infoIconSvg} alt="info" height="18" width="18" className={styles.icon} />
                <span
                  onClick={() => onFindOutMoreClick(upgrade.title, upgrade.longDescription)}
                  className={styles.findOutMoreLink}
                  role="link"
                  onKeyDown={() => onFindOutMoreClick(upgrade.title, upgrade.longDescription)}
                  tabIndex={0}
                >
                  Find out more
                </span>
                <div className="py-3">
                  <button
                    onClick={() => onUpgradeCheckboxClick(upgrade)}
                    className={`${styles.addNowButton} ${isSelectedUpgrade(selectedUpgrades, upgrade) && styles.addNowSelected}`}
                    type="button"
                  >
                    <i className={`fas fa-plus-circle ${isSelectedUpgrade(selectedUpgrades, upgrade) && 'text-white'}`} />
                    <span className="px-3">{isSelectedUpgrade(selectedUpgrades, upgrade) ? 'Added to cover' : 'Add to cover'}</span>
                    {upgrade?.discountedPrice ? (
                      <span className={styles.price}>£{formatPrice(upgrade?.discountedPrice)} <s>£{formatPrice(upgrade?.price)}</s></span>
                    ) : (
                      <span className={styles.price}>£{formatPrice(upgrade?.price)}</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <PopupModal
          resultText={modalData.description}
          resultTitle={modalData.title}
          action={() => setShowModal(false)}
          displayDialogInMiddle
          innerCloseButton
          parseHtml
        />
      )}
    </>
  );
};

export default PaidUpgrades;
