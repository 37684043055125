import React, { useState } from 'react';
import RenderLink from '../../../components/RenderLink';

const MobileMenu = ({ menu, toggleMobileMenu }) => {
  const [secondLevel, setSecondLevel] = useState();

  const selectSecondLevel = (item, e) => {
    e.preventDefault();
    setSecondLevel(secondLevel ? undefined : item);
    toggleMobileMenu();
  };

  return (
    <ul>
      {menu.map((sub) => {
        if (sub.subitems) {
          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
            <li key={sub.url + sub.text} className={`${secondLevel && secondLevel.title === sub.title ? 'open clicked' : ''}`} onClick={() => { toggleMobileMenu(); }}>
              {sub.url
                ? (
                  <RenderLink
                    link={sub}
                    onClick={() => { toggleMobileMenu(); }}
                  />
                ) : sub.menutitle}
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <span onClick={(e) => { selectSecondLevel(sub, e); }} onKeyDown={(e) => { selectSecondLevel(sub, e); }} />
              {sub.subitems
                ? (
                  <div className="second-level">
                    <ul>
                      {sub.subitems.map((secondLev) => (
                        <li key={secondLev.url + secondLev.text}>
                          {secondLev.url
                            ? (
                              <RenderLink
                                link={secondLev}
                                onClick={toggleMobileMenu}
                              />
                            ) : secondLev.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
            </li>
          );
        }
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <li key={sub.url + sub.text} onClick={() => { toggleMobileMenu(); }}>
            {sub.url ? (<RenderLink link={sub} onClick={() => { toggleMobileMenu(); }} />) : sub.title}
          </li>
        );
      })}
    </ul>
  );
};

export default MobileMenu;
