import React from 'react';
import PropTypes from 'prop-types';
import styles from './note.module.scss';

/**
 * Molecule level component.
 * Brad Frost - Atomic Design: https://atomicdesign.bradfrost.com/chapter-2/#molecules
 * A Note component that reflects the design located here: "".
 * @param props
 * @returns {JSX.Element}
 */

const Note = (props) => {
  const { heading, headingColor, subHeading, text, textColor, imageSrc, imageAltText, backgroundColor, borderColor, reverseContentOrder } = props;

  /**
   * A function that builds CSS styles based on developer input.
   * @param props
   * @returns {Object}
   */
  const styleBuilder = () => {
    const borderStyle = (borderColor !== 'inherit') ? { border: `${borderColor} 3px solid` } : null;
    const backgroundColorStyle = { backgroundColor };
    const contentOrderStyle = reverseContentOrder ? { flexDirection: 'row-reverse' } : null;
    return {
      ...borderStyle,
      ...backgroundColorStyle,
      ...contentOrderStyle,
    };
  };

  return (
    <div className={styles.container} style={styleBuilder()}>
      <div className={styles.noteContainer}>
        <h3 style={{ color: headingColor }}>{ heading }</h3>
        { subHeading ? <p className={styles.subHeading}>{ subHeading }</p> : null }
        <p style={{ color: textColor }}>{ text }</p>
      </div>
      { imageSrc ? <img src={imageSrc} alt={imageAltText} className={styles.image} /> : null }
    </div>
  );
};

Note.propTypes = {
  heading: PropTypes.string.isRequired,
  headingColor: PropTypes.string,
  subHeading: PropTypes.string,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAltText: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  reverseContentOrder: PropTypes.bool,
};

Note.defaultProps = {
  headingColor: '',
  subHeading: '',
  textColor: '',
  imageSrc: '',
  imageAltText: '',
  backgroundColor: 'transparent',
  borderColor: 'inherit',
  reverseContentOrder: false,
};

export default Note;
