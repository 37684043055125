const motNoteTypes = {
  fail: 'FAIL',
  advisory: 'ADVISORY',
  major: 'MAJOR',
  minor: 'MINOR',
  dangerous: 'DANGEROUS',
  prs: 'PRS',
};

export default motNoteTypes;
