import React from 'react';
import { connect } from 'react-redux';
import RenderLink from '../../../components/RenderLink';
import styles from './Ribbon.module.scss';

const Ribbon = ({ pageModel, session, scroll }) => {
  const loggedInLinks = () => (
    <>
      <li className={styles.orangeLink}>
        <RenderLink link={pageModel?.ribbon?.logoutTab} />
      </li>
      <li className={styles.orangeLink}>
        <RenderLink link={pageModel?.ribbon?.myRACTabLogin} />
      </li>
    </>
  );

  const myRACTabLoggedOutLinks = () => (
    <li className={styles.orangeLink}>
      <RenderLink link={pageModel?.ribbon?.myRACTab} />
    </li>
  );

  return (
    <div className={`${(scroll > 80) ? styles.siteRibbonScroll : styles.siteRibbonOriginal}`}>
      <input type="hidden" id="userid" name="gigyaclientid" value={session.uid} />
      <div className={styles.container}>
        <ul className={styles.links} id="ulBusinessTab">
          <li id="p_lt_ctl01_RACBusinessTabWidget_liPersonal" className={styles.active}>
            <RenderLink link={pageModel?.ribbon?.personalTab} />
          </li>
          <li id="p_lt_ctl01_RACBusinessTabWidget_liPersonal">
            <RenderLink link={pageModel?.ribbon?.vansTab} />
          </li>
          <li id="p_lt_ctl01_RACBusinessTabWidget_liBusiness">
            <RenderLink link={pageModel?.ribbon?.businessTab} />
          </li>
        </ul>
        <ul className={styles.links}>
          <li>
            <a href={`tel:${pageModel?.ribbon?.phoneNumber}`} className="mainTel">
              <i className="fas fa-phone-alt" />
              &nbsp;&nbsp;
              {pageModel?.ribbon?.phoneNumber}
            </a>
          </li>
          <li>
            <RenderLink link={pageModel?.ribbon?.helpTab}>
              <i className="fa fa-info-circle" />
              &nbsp;&nbsp;
              {pageModel?.ribbon?.helpTab.text}
            </RenderLink>
          </li>
          {session.isLoggedIn
            ? loggedInLinks() : myRACTabLoggedOutLinks()}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(Ribbon);
