import * as StepConstants from '../constants/journeyManagerStepConstants';

// eslint-disable-next-line default-param-last
const journeyManagerStepReducer = (state = {
}, action) => {
  switch (action.type) {
    case StepConstants.SET_START:
      return {
        journeyInProgress: true,
        journeyTitle: action.payload.name,
        stepIndex: action.payload.stepIndex,
        stepData: action.payload.stepData,
      };
    case StepConstants.SET_DATA:
      return {
        ...state,
        stepData: action.payload,
      };
    case StepConstants.SET_STEP:
      return {
        ...state,
        stepIndex: action.payload,
      };
    case StepConstants.SET_END:
      return {
        journeyInProgress: false,
      };
    default:
      return state;
  }
};

export default journeyManagerStepReducer;
