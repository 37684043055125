import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './GridLayout.scss';
import { loggerFunc } from 'utils/logger';
import useViewport from '../../../../utils/useViewportHelper';
import { SourceCodes } from '../../../../constants/enums';
import WelcomeTile from '../../../Widgets/WelcomeTile';
import { GetContent, GetTileContent } from '../../../../services/ContentService';
import { GetRenewalPopup, GetRenewalEligibility, GetTescoLoginJID } from '../../../../services/MyRacService';
import { ResponseCode } from '../../../../services/models/httpResponse';
import TescoPanel from '../../../../panels/TescoPanel';
import Efulfilment from '../../../../modals/Efulfilment';
import * as SessionActions from '../../../../actions/sessionActions';
import Alert from '../../../../components/Alert';
import PopupModal from '../../../../components/PopupModal/PopupModal';
import Spinner from '../../../../components/Spinner';
import { eFulfilmentErrorMessages } from '../../../../constants/cms/messageConstants';
import { getMessage } from '../../../../services/MessageService';
import trackEvent from '../../../../services/AnalyticsService';
import AbstractGrid from './AbstractGrid';
import TileSets from '../../../../utils/tileSets.enum';
import NavQuickLinks from '../../../../components/NavQuickLinks/NavQuickLinks';
import { VEHICLE_DETAILS, RENEWAL } from '../../../../constants/routeConstants';
import { useApplicationState } from '../../../../hooks/applicationState-Context';
import { useUserJourney } from '../../../../hooks/userJourney-Context';

const logger = loggerFunc('GridLayout');

export const GridLayout = (props) => {
  const {
    customer: { custInfo },
    policy: { policyInfo },
    vehicles: { vehicleInfo },
    tiles: { membershipOverview },
    updateEfulfilmentShown,
    updateTescoPopupShown,
    updateReminderAlertShown,
    updateRenewBannerShown,
    storeRenewBannerImgUrl,
    session,
  } = props;

  const { appState, setPageLoading } = useApplicationState();
  const { userJourneyState } = useUserJourney();

  const [pageModel, setPageModel] = useState(null);
  const [eFulfilmentError, setEFulfilmentError] = useState(false);
  const [reminderAlert, setReminderAlert] = useState(false);
  const [renewNowBanner, setRenewNowBanner] = useState(false);
  const [renewNowBannerImage, setRenewNowBannerImage] = useState();
  const [tescoRenew, setTescoRenew] = useState(false);
  const [tescoJID, setTescoJID] = useState();
  const [renewNowStatus, setRenewNowStatus] = useState();

  const MAX_COLS = 4;
  const notificationLinks = [];

  // Captures a change to the mobile layout and causes a new render with subsequent re-request of the grid data
  const currentMobileState = useViewport().isBootstrapSm;

  // PopupModal override styles for 'Renew Now' banner as part of Renewals promotion
  const renewBannerStyle = {
    backgroundImage: `url(${renewNowBannerImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    height: currentMobileState ? '97vw' : '570px',
    marginTop: currentMobileState ? '70px' : '100px',
  };
  // Area map of where clickable
  const renewBannerButton = {
    margin: currentMobileState ? '60% auto' : '75% auto',
    height: '5rem',
    width: '70%',
  };

  // Check session vehicle data to determine whether to display 'Add Reminders' alert
  const vehicleReminderInfo = () => {
    // eslint-disable-next-line prefer-const
    let reminderDates = [];
    const vehicles = vehicleInfo?.reminderVehicles;
    if (vehicles?.length > 0) {
      vehicles.forEach((vehicle) => {
        const { reminders } = vehicle;
        if (reminders?.length > 0) {
          reminders.forEach((reminder) => {
            if (!reminder?.date) {
              // Null date value means reminder has not been set
              reminderDates.push('Not set');
            }
          });
        }
      });
    }
    // Show alert if no vehicles found or reminderDates[] contains values where reminders 'Not set'
    if (!vehicles?.length || reminderDates?.length > 0) {
      setReminderAlert(true);
    }
  };

  // Welcome content for the welcome tile is independent of the Grid tile data
  const welcomeContent = async () => GetContent('membershipoverviewpage', 'Membership Overview Desktop').then((response) => response).catch((err) => logger.debug(err));

  useEffect(() => {
    if (appState?.loading?.status && custInfo && custInfo?.Id) {
      vehicleReminderInfo();
      if (session?.isTescoLogin || policyInfo?.sourceCode === SourceCodes.RWAFTS2) {
        GetTescoLoginJID(custInfo.Id, session.memberNo).then((response) => {
          setTescoJID(response.payload);
        }).catch((err) => logger.error(err));
        // Tesco referral and is eligible for Renewal
        GetRenewalEligibility(custInfo?.Id).then(async (response) => {
          const { isEligibleForRenewal } = response.payload;
          if (isEligibleForRenewal) {
            setTescoRenew(true);
            trackEvent('MyRAC_Tesco_PopupDisplayed');
          }
        }).catch((err) => logger.debug(err));
      } else {
        // 'Renew Now' popup shown if member is eligible for Renewals
        GetRenewalPopup(custInfo?.Id).then(async (response) => {
          if (response?.payload) {
            const { url, fileName } = response.payload;
            // Add image url to page state
            setRenewNowBannerImage(url);
            // Store image url in session state for use on other pages
            storeRenewBannerImgUrl(url);
            // Image defines cover status therefore slice file extention from filename and use to set value in state
            const coverStatus = fileName && (fileName).slice(0, -4) === 'RenewNowPopup' ? 'renew' : 'expired';
            // Fallback in case filename uploaded to cms fails to match pattern above
            setRenewNowStatus(coverStatus ?? 'renew');
            // Popup not to be shown if member was redirected via deep link or has already visited the Renewal page
            const visitedRenewalPage = userJourneyState?.pageHistory.includes(RENEWAL);
            if (!visitedRenewalPage) {
              setRenewNowBanner(true);
              if (coverStatus) {
                trackEvent(`ukbc.${coverStatus}popupdisplayed`);
              }
            }
          }
        }).catch((err) => logger.debug(err));
      }
      GetTileContent(custInfo?.Id, session?.memberNo, TileSets?.membershipOverview, currentMobileState).then(() => {
        welcomeContent().then(async (result) => {
          if (result?.responseCode === ResponseCode?.SUCCESS) {
            await setPageModel(result?.payload);
          }
          setPageLoading(false, null);
        }).catch((err) => logger.debug(err));
      });
    }
  }, [custInfo?.Id, session?.memberNo, appState?.loading?.status, custInfo, currentMobileState]);

  const toggleShowEFulfilment = (event) => {
    event.preventDefault();
    updateEfulfilmentShown(true);
  };

  const toggleFulfilmentError = () => {
    setEFulfilmentError(!eFulfilmentError);
    updateEfulfilmentShown(true);
  };

  const toggleTescoPopup = () => {
    // Close modal window
    setTescoRenew(false);
    // Store action in session so that popup is not displayed again until next user session
    updateTescoPopupShown(true);
  };

  const toggleRenewNowBanner = () => {
    setRenewNowBanner(!renewNowBanner);
    updateRenewBannerShown(true);
    trackEvent(`ukbc.${renewNowStatus}popupclosed`);
  };

  const toggleAddReminderAlert = () => {
    // Hide alert using state
    setReminderAlert(!reminderAlert);
    // Store action in session so that alert is not displayed again until next user session
    updateReminderAlertShown(true);
  };

  return (
    <>
      { pageModel && custInfo && membershipOverview ? (
        <>
          <WelcomeTile pageModel={pageModel} notifications={notificationLinks} />
          {/* Pills menu (for mobile only) */}
          {currentMobileState && <NavQuickLinks />}
          {/* Add reminders alert */}
          {!session?.reminderAlertShown && reminderAlert && (
            <Alert
              toggleShow={toggleAddReminderAlert}
              heading="Never miss a key date again"
              message="You’re missing a few vehicle reminders. Add them now so you don’t miss them."
              eventTag="myrac.vehicleremindernotification"
              buttonText="Add reminders"
              linkRef={VEHICLE_DETAILS}
              isButton
            />
          )}
          {/* Grid Layout */}
          <div className="grid-wrapper" data-testid="GridLayoutContainer">
            <AbstractGrid columns={MAX_COLS} tiles={membershipOverview} />
          </div>
        </>
      ) : null }
      {tescoRenew && !renewNowBanner && !session?.tescoPopupShown && (
        <PopupModal
          resultTitle="Tesco Clubcard"
          form={tescoJID ? <TescoPanel jid={tescoJID} /> : <Spinner defaultSpinner />}
          action={() => toggleTescoPopup()}
          headerOverrideStyle={{ textAlign: 'center' }}
          innerCloseButton
        />
      )}
      {
        pageModel && session?.efulFilmentShown && !custInfo?.WebRegistered
        && !custInfo?.isPaperLess && !eFulfilmentError
        && <Efulfilment toggleShowModal={toggleShowEFulfilment} toggleHideEFulfilmentModal={toggleFulfilmentError} />
      }
      {eFulfilmentError && (
        <PopupModal
          modalOk="Ok"
          resultTitle={getMessage(eFulfilmentErrorMessages.ERROR_HEADER)}
          resultText={getMessage(eFulfilmentErrorMessages.ERROR_SUBTEXT)}
          alignBodyCenter
          alignHeaderCenter
          displayDialogAtTopQuarter
          action={toggleFulfilmentError}
          innerCloseButton
        />
      )}
      {!session?.renewBannerShown && renewNowBanner && (
        <PopupModal
          action={toggleRenewNowBanner}
          bannerCloseButton
          backgroundOverrideStyle={renewBannerStyle}
          backgroundButtonMap={renewBannerButton}
          backgroundButtonAlt="Renew now"
          eventTag={`ukbc.${renewNowStatus}popupclicked`}
          linkRef={RENEWAL}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
  customer: state.customer,
  session: state.session,
  vehicles: state.vehicles,
  featureFlags: state.featureToggle,
  tiles: state.content.tiles,
});

const mapDispatchToProps = (dispatch) => ({
  updateEfulfilmentShown: (efulfilmentShown) => {
    dispatch(SessionActions.setEfulfilmentShown(efulfilmentShown));
  },
  updateTescoPopupShown: (tescoPopupShown) => {
    dispatch(SessionActions.setTescoPopupShown(tescoPopupShown));
  },
  updateReminderAlertShown: (reminderAlertShown) => {
    dispatch(SessionActions.setReminderAlertShown(reminderAlertShown));
  },
  updateRenewBannerShown: (renewBannerShown) => {
    dispatch(SessionActions.setRenewBannerShown(renewBannerShown));
  },
  storeRenewBannerImgUrl: (renewBannerImgUrl) => {
    dispatch(SessionActions.setRenewBannerImgUrl(renewBannerImgUrl));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GridLayout);
