import React, { useEffect, useState } from 'react';
import { GetContent, ResponseCode } from 'services/ContentService';
import * as DefaultText from 'configuration/defaultText';
import FAQQuestion from 'components/FAQQuestion';
import { connect } from 'react-redux';
import displayQuestion from 'utils/faq_user_validation';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import ComponentView from 'common/ComponentView';
import { loggerFunc } from 'utils/logger';
import { getMessage } from 'services/MessageService';
import uniqid from 'uniqid';
import block from 'bem-css-modules';
import styles from 'shared/components/Faqs/Faqs.module.scss';
import { useApplicationState } from '../../hooks/applicationState-Context';

const bem = block(styles);

const logger = loggerFunc('FAQsPage');

const FAQsPage = ({ session }) => {
  const [pageModel, setPageModel] = useState(null);
  const [errorText, setErrorText] = useState(undefined);
  const { appState, setPageLoading } = useApplicationState();

  const processPageModelContent = (result) => {
    if (result.responseCode === ResponseCode.ERROR) {
      return setPageModel({ error: DefaultText.APIError });
    }

    setPageModel({
      title: result.payload.pagetitle,
      heading: result.payload.heading,
      info: {
        heading: result.payload.componentitems[0].title,
        text: result.payload.componentitems[0].html,
      },
      questions: {
        heading: result.payload.componentitems[0].heading,
        faqs: result.payload.componentitems[0].faqitems.map((item) => ({
          heading: item.heading,
          text: item.htmldescription,
          displayTo: item.usertodisplayfor,
        })),
      },
    });
    return undefined;
  };

  const displayFAQsContent = () => {
    if (pageModel?.error) { return pageModel?.error; }

    return (
      <ComponentView errorText={errorText} onErrorModalClose={() => { setErrorText(undefined); }}>
        <div className="row mb-5">
          <div className="col-sm-12 col-12">
            <h2 className={bem('heading', { main: true })}>{pageModel?.heading}</h2>
            {ReactHtmlParser(pageModel?.info.text)}
            <div key={uniqid()} className={bem('faq-stack')}>
              <h3 className={bem('heading', { sub: true })}>{pageModel?.questions.heading}</h3>
              {pageModel?.questions.faqs.filter((q) => displayQuestion(session, q)).map((item) => (
                <FAQQuestion
                  question={{
                    text: item.text,
                    heading: item.heading,
                  }}
                  key={item.heading}
                />
              ))}
            </div>
          </div>
        </div>
      </ComponentView>
    );
  };

  useEffect(() => {
    const fetchPageModelData = async () => {
      try {
        const pageData = await GetContent(
          'loggedinpages',
          'FAQs',
          ['componentitems', 'componentitems.faqitems'],
        );
        processPageModelContent(pageData);
      } catch (e) {
        // todo set message constant.
        const message = getMessage();
        setErrorText(message);
        logger.error(message, e);
      }
      setPageLoading(false);
    };
    if (!pageModel) {
      fetchPageModelData();
    }
  }, []);

  return ((appState?.loading.status) ? null : displayFAQsContent());
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(FAQsPage);
