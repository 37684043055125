import React, { useEffect, useState } from 'react';
import { GetContent, ResponseCode } from 'services/ContentService';
import * as DefaultText from 'configuration/defaultText';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import { transformAnchorToOpenNewTabOptions } from 'utils/ContentHelpers';
import { useApplicationState } from '../../hooks/applicationState-Context';

const TermsAndConditionsPage = () => {
  const [pageModel, setPageModel] = useState(null);
  const { appState, setPageLoading } = useApplicationState();

  const processPageModelContent = (result) => {
    if (result.responseCode === ResponseCode.ERROR) {
      return setPageModel({ error: DefaultText.APIError });
    }
    setPageModel({
      title: result.payload.pagetitle,
      heading: result.payload.heading,
      text: result.payload.componentitems[0].html_tandcs,
    });
    return null;
  };

  const displayTermsAndConditionsContent = () => {
    if (pageModel?.error) { return pageModel?.error; }

    return (
      <div>
        <div className="row mb-4">
          <div className="col-sm-12 col-12">
            <h2>{pageModel?.heading}</h2>
            <div className="mt-4">
              {ReactHtmlParser(pageModel?.text, transformAnchorToOpenNewTabOptions)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchPageModelData = async () => {
      const pageData = await GetContent(
        'loggedinpages',
        'Terms and Conditions',
        ['componentitems'],
      );
      processPageModelContent(pageData);
      setPageLoading(false);
    };

    if (!pageModel) {
      fetchPageModelData();
    }
  }, []);

  return ((appState?.loading.status) ? null : displayTermsAndConditionsContent());
};

export default TermsAndConditionsPage;
