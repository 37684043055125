import { Outlet } from 'react-router-dom';
import React from 'react';
import useReferrerParam from '../../hooks/useReferrerParam';
import ScrollToTop from '../../common/ScrollToTop';
import Header from '../../views/MyRac/Header/Header';
import Footer from '../../views/MyRac/Footer';
import Loading from '../../views/MyRac/Loading';
import styles from './MainContent.module.scss';

const MainContent = () => {
  // Flag to indicate referral from App (Webview)
  const isAppView = useReferrerParam();

  return (
    <>
      {!isAppView && <Header />}
      { /* On mobile, some pages do not scroll to the top, adding in ScrollToTop custom hook which was missing */ }
      <ScrollToTop />
      <main>
        <section className={styles.sectionWrapper}>
          <Loading />
          <Outlet />
        </section>
      </main>
      {!isAppView && <Footer />}
    </>
  );
};

export default MainContent;
