import nist10000BadPasswords from './nist10000BadPasswords';

// Validation against the badpassword list
// Can be extended to be used instead of gigya validation.
const passwordValidation = (
  value,
) => {
  const commonPhrase = !value || nist10000BadPasswords.includes(value.toLowerCase());

  return {
    isCommonPhrase: commonPhrase,
  };
};

export default passwordValidation;
