import React, { useEffect, useState } from 'react';
import { GetContent, mapURL } from '../../../services/ContentService';
import * as DefaultText from '../../../configuration/defaultText';
import Ribbon from './Ribbon';
import MainNav from './MainNav';
import styles from './Header.module.scss';
import useViewport from '../../../utils/useViewportHelper';

const Header = () => {
  const [pageModel, setPageModel] = useState();
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const [headerTop, setHeaderTop] = useState();
  const [scroll, setScroll] = useState(window.scrollY); // What is this being used for?
  const [loading, setLoading] = useState(true);

  const viewportHelper = useViewport();

  const scrollEventListener = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    async function getContent() {
      await GetContent(
        'header',
        '',
        ['menuitems', 'menuitems.menusubitems'],
      ).then((response) => {
        setPageModel({
          ribbon: {
            personalTab: mapURL(response.payload.personaltaburl),
            vansTab: mapURL(response.payload.vanstaburl),
            businessTab: mapURL(response.payload.businesstaburl),
            phoneNumber: response.payload.phonenumber,
            helpTab: mapURL(response.payload.helpurl),
            logoutTab: mapURL(response.payload.myraclogouturl),
            loginTab: mapURL(response.payload.myracloginurl),
            registerTab: mapURL(response.payload.myracregisterurl),
            myRACTab: mapURL(response.payload.myracurl),
            myRACTabLogin: mapURL(response.payload.myracloggedinurl),
          },
          header: {
            logo: {
              url: response.payload.logo.url,
              alt: response.payload.logo.description,
              title: response.payload.logo.title,
            },
            menuItems: response.payload.menuitems.map((item) => ({
              title: item.title,
              url: mapURL(item),
              advert: item.advert ? {
                show: item.advert.showadvertising,
                url: mapURL(item.advert.adverturlinfo),
                body: item.advert.adverttextbox,
                button: item.advert.advertbuttontext,
                image: item.advertimage ? {
                  url: item.advertimage.url,
                  alt: item.advertimage.description,
                } : null,
              } : null,
              leftSubItems: item.menusubitems.slice(0, item.menusubitems.length / 2).map((sub) => mapURL(sub.urlinfo)),
              rightSubItems: item.menusubitems.slice(item.menusubitems.length / 2).map((sub) => mapURL(sub.urlinfo)),
            })),
          },
        });
        setLoading(false);
      }).catch(() => {
        setPageModel({ error: DefaultText.APIError });
      });
    }
    if (!pageModel) {
      getContent();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollEventListener);
    return () => window.removeEventListener('scroll', scrollEventListener);
  }, []);

  return !loading && (
    <>
      <div className={styles.headerPlaceHolder} style={{ display: (scroll > 120 || viewportHelper.isMobile) ? 'block' : 'none' }} /> {/* Ensures document remains the same size for smooth navigation transition. */}
      <header className={`${styles.header} ${(scroll > 120 || viewportHelper.isMobile) ? styles.affix : styles.affixTop} ${toggleMobileMenu ? 'showNav' : ''}`}>
        <Ribbon pageModel={pageModel} />
        <MainNav pageModel={pageModel} toggleMobileMenu={() => setToggleMobileMenu(!toggleMobileMenu)} headerTop={headerTop} setHeaderTop={setHeaderTop} scroll={scroll} />
      </header>
    </>
  );
};

export default Header;
