import React, { useState } from 'react';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import PopupModal from '../../../../components/PopupModal/PopupModal';
import styles from './Contents.module.scss';
import infoIconSvg from '../../../../assets/images/button/info-blue.svg';

const Contents = ({ header, body, modal, borderless }) => {
  const [toggleInfo, setToggleInfo] = useState(false);

  return (
    <div className={`${styles.container} ${borderless && (styles.borderless)}`}>
      <span className={styles.checkmark}><i className="fas fa-check-circle fa-2x" /></span>
      <div className={styles.contentContainer}>
        <span>{header}</span>
        {ReactHtmlParser(body)}
        {modal?.link && (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <img src={infoIconSvg} alt="info" height="18" width="18" className={styles.icon} /><a onClick={() => setToggleInfo(true)} className="py-2">{modal?.link}</a>
            {toggleInfo && (
              <PopupModal
                resultTitle={ReactHtmlParser(modal?.link)}
                resultText={ReactHtmlParser(modal?.text)}
                action={() => setToggleInfo(false)}
                displayDialogInMiddle
                innerCloseButton
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Contents;
