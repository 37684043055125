import React from 'react';
import RenderLink from 'components/RenderLink';

const FreeBenefits = (props) => {
  const { freebenefits } = props;
  if (!freebenefits) { return null; }
  return (
    <div className="row free-benefits p-3 mx-2" style={{ backgroundImage: `url(${freebenefits.exclusiveofferimage.url})` }}>
      <h2 className="fs-3">{freebenefits.exclusiveoffertitle}</h2>
      {freebenefits.exclusiveoffers.map((value) => (
        // eslint-disable-next-line no-underscore-dangle
        <div className="col-6 col-sm-3 float-left" key={value._metadata.uid}>
          <RenderLink
            url={value.exclusiveofferurl.href}
            isExternal
            openInNewWindow
            title={value.exclusiveofferurl.title}
          >
            <img alt="offers" src={value.exclusiveofferimage.url} />
            <h3>{value.exclusiveofferurl.title}</h3>
          </RenderLink>
        </div>
      ))}
    </div>
  );
};

export default FreeBenefits;
