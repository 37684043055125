import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format, subYears } from 'date-fns';

import * as EditButtonsActions from 'actions/editableButtonsAction';
import * as CustomerActions from 'actions/customerInfoActions';
import DropdownDate from 'components/ReactDropdownDate';
import { ResponseCode } from 'services/models/httpResponse';
import { UpdateDateOfBirth } from 'services/MyRacService';
import trackEvent from 'services/AnalyticsService';

import PopupModal from '../components/PopupModal/PopupModal';
import modalTypes from '../utils/modalTypesEnum';

class DobPanel extends Component {
  constructor(props) {
    super(props);
    this.displayChangeDobPanel = this.displayChangeDobPanel.bind(this);
    this.updateDob = this.updateDob.bind(this);
    this.closeResultModal = this.closeResultModal.bind(this);
    this.getValidation = this.getValidation.bind(this);
    const { customer } = this.props;
    this.state = {
      newDob: '',
      selectedDate: customer.custInfo.DateOfBirth,
      showResultModal: false,
      resultTitle: '',
      resultText: '',
      validation: false,
      loading: false,
    };
  }

  componentDidMount() {}

  getValidation(val) {
    this.setState({ validation: val });
  }

  closeResultModal = () => {
    this.setState({
      showResultModal: false,
    });
  };

  updateDob = async (e) => {
    const displayUpdateResult = async (title, message) => {
      const { setEditDob } = this.props;
      setEditDob(false);
      this.setState({
        loading: false,
        showResultModal: true,
        resultTitle: title,
        resultText: message,
      });
    };

    e.preventDefault();
    const {
      customer: { custInfo },
      pageModel,
    } = this.props;
    trackEvent('myrac.dob.submit');

    const { newDob } = this.state;
    const { setCustomerInfo } = this.props;

    const dobPayload = {
      dob: newDob,
    };

    this.setState({
      loading: true,
    });

    try {
      const response = await UpdateDateOfBirth(custInfo.Id, dobPayload);
      if (response.responseCode === ResponseCode.SUCCESS) {
        const custInformation = { ...custInfo };
        custInformation.DateOfBirth = newDob;
        setCustomerInfo(custInformation);
        displayUpdateResult(pageModel.messages.success.title, pageModel.messages.success.text);
      }
    } catch (error) {
      displayUpdateResult(pageModel.messages.fail.title, pageModel.messages.fail.text);
    }
  };

  displayChangeDobPanel = (e) => {
    e.preventDefault();
    const { setEditDob } = this.props;
    setEditDob(true);
    trackEvent('myrac.dob.open');
  };

  render() {
    const {
      customer: { custInfo },
      policy: { policyInfo },
      pageModel,
      editValues,
      setEditDob,
    } = this.props;

    const {
      validation, showResultModal, resultTitle, resultText, newDob, loading,
    } = this.state;

    const endDate = format(subYears(new Date(), 16), 'yyyy-MM-dd');

    const modalHeaderStyle = {
      fontWeight: 'normal',
    };

    if (!pageModel) {
      return null;
    }

    return (
      <div className="col-12 col-sm-6">
        <div className="tile box">
          <h4>{pageModel.title}</h4>
          <div className="Info rac-form sessioncamhidetext">
            {editValues.editDOB === true ? (
              <form onSubmit={this.updateDob} className="mx-2">
                <DropdownDate
                  sendValidation={this.getValidation}
                  startDate="1909-01-01"
                  endDate={
                    // optional, if not provided current date is endDate
                    endDate // 'yyyy-mm-dd' format only
                  }
                  selectedDate={
                    // optional
                    // eslint-disable-next-line react/destructuring-assignment
                    this.state.selectedDate // 'yyyy-mm-dd' format only
                  }
                  order={
                    // optional
                    ['day', 'month', 'year'] // Order of the dropdowns
                  }
                  onMonthChange={() => {
                    // optional
                  }}
                  onDayChange={() => {
                    // optional
                  }}
                  onYearChange={() => {
                    // optional
                  }}
                  onDateChange={(date) => {
                    // optional
                    this.setState({
                      selectedDate: format(new Date(date), 'yyyy-MM-dd'),
                      newDob: format(new Date(date), 'yyyy-MM-dd'),
                    });
                  }}
                  ids={
                    // optional
                    {
                      year: 'select-year',
                      month: 'select-month',
                      day: 'select-day',
                    }
                  }
                  names={
                    // optional
                    {
                      year: 'year',
                      month: 'month',
                      day: 'day',
                    }
                  }
                  classes={
                    // optional
                    {
                      dateContainer: 'selectContainer2',
                      yearContainer: 'selectContainerDiv1',
                      monthContainer: 'selectContainerDiv2',
                      dayContainer: 'selectContainerDiv3',
                      year: 'selectContainer dobPanel',
                      month: 'selectContainer dobPanel',
                      day: 'selectContainer dobPanel',
                      yearOptions: 'classes',
                      monthOptions: 'classes',
                      dayOptions: 'classes',
                    }
                  }
                  defaultValues={
                    // optional
                    {
                      year: 'select year',
                      month: 'select month',
                      day: 'select day',
                    }
                  }
                  options={
                    // optional
                    {
                      yearReverse: true, // false by default
                      monthNumeric: true, // false by default
                    }
                  }
                />
                {!validation && newDob !== '' && (
                  <span className="MyRacForm__error-msg">{pageModel.dobInvalid}</span>
                )}
                <div className="MyRacForm__button-wrapper">
                  <button
                    type="button"
                    disabled={loading}
                    className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--grey"
                    onClick={() => {
                      setEditDob(false);
                    }}
                  >
                    {pageModel.backButton}
                  </button>
                  <button
                    type="submit"
                    disabled={!validation || loading}
                    className="MyRacForm__button MyRacForm__button--vertical-spacing MyRacForm__button--wide MyRacForm__button--left-margin"
                  >
                    {loading ? <i className="fas fa-spinner fa-pulse" /> : pageModel.submitButton}
                  </button>
                </div>
              </form>
            ) : (
              <>
                <p className="primary">
                  {format(new Date(custInfo.DateOfBirth), 'dd MMMM yyyy')}
                </p>
                {policyInfo.CanEditDob === true && (
                  <p className="action edit">
                    <button
                      type="button"
                      className="MyRacForm__button-link link Panel__button btn-transparent"
                      onClick={this.displayChangeDobPanel}
                    >
                      {pageModel.link.text}
                    </button>
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        {showResultModal && (
          <PopupModal
            resultTitle={resultTitle}
            resultText={resultText}
            modalOk={pageModel.modalOk}
            action={this.closeResultModal}
            parseHtml={false}
            innerCloseButton
            modalType={modalTypes.infoModal}
            headerOverrideStyle={modalHeaderStyle}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  session: state.session,
  editValues: state.edit,
});

const mapDispatchToProps = (dispatch) => ({
  setEditDob: (editPhoneNumber) => {
    dispatch(EditButtonsActions.setEditDob(editPhoneNumber));
  },
  setCustomerInfo: (customerInfo) => {
    dispatch(CustomerActions.setCustomerInfo(customerInfo));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DobPanel);
