// eslint-disable-next-line default-param-last
const customerInfoReducer = (state = {
  custInfo: {},
}, action) => {
  switch (action.type) {
    case 'SET_CUSTOMERINFO':
      return {
        ...state,
        custInfo: action.payload,
      };
    case 'INVALIDATE_CUSTOMERINFO':
      return {
        ...state,
        custInfo: null,
      };
    case 'UPDATE_PHONENUMBER':
      return {
        ...state,
        custInfo: action.payload,
      };
    case 'UPDATE_ADDRESS':
      return {
        ...state,
        custInfo: {
          ...state.custInfo, Address: action.payload,
        },
      };
    case 'UPDATE_DOB':
      return {
        ...state,
        custInfo: action.payload,
      };
    case 'SET_COMMS_PREFS':
      return {
        ...state,
        custInfo: {
          ...state.custInfo, CommunicationPreferences: action.payload,
        },
      };
    case 'CUSTINFO_HAS_ERROR':
      return {
        ...state,
        custInfo: {
          ...state.custInfo,
          hasError: action.payload,
        },
      };
    default:
      return state;
  }
};

export default customerInfoReducer;
