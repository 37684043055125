import React, { useState } from 'react';
import block from 'bem-css-modules';

import localStyles from './CollapsibleSection.module.scss';
import chevron from '../../../assets/images/button/down-arrow.svg';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const bem = block(localStyles);

const CollapsibleSection = ({ children, title }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`${open ? bem('opened') : bem('closed')}`}>
      <h3 className={`${bem('section-title')} d-none d-lg-block`}>{title}</h3>

      <button
        type="button"
        className={`d-flex d-lg-none justify-content-between align-items-center w-100 ${bem('toggle-button')}`}
        onClick={() => setOpen((prev) => !prev)}
      >
        <h3 className={bem('section-title')}>{title}</h3>
        <img src={chevron} className={bem('chevron')} alt="Details" />
      </button>

      <div className={`clearfix flex-column my-2 ${bem('content')}`}>{children}</div>
    </div>
  );
};

export default CollapsibleSection;
