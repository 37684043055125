import { format } from 'date-fns';
import React, { useState } from 'react';
import block from 'bem-css-modules';
import trackEvent from 'services/AnalyticsService';
import { ValidateAndGetMagicLink } from 'services/MyRacService';
import DropdownDate from 'components/ReactDropdownDate';
import styles from './VerifyUserDetails.module.scss';
import Button from '../Button/Button';

const bem = block(styles);

const VerifyUserDetails = (props) => {
  const { fields } = props;
  const [loading, setLoading] = useState(false);
  const [dateOfBith, setDateOfBirth] = useState('2000-01-01');
  const [dobValidation, setDobValidation] = useState(true);
  const [selectedPostcode, setPostcode] = useState('');
  const [errors, handleErrors] = useState({
    errorText: null,
    formError: false,
    invalidPostcode: false,
  });
  const verifyUserMyRacHandler = async () => {
    try {
      trackEvent('MyRAC_VerifyUserMagicLinkRequested');
      const membershipDetails = {
        userId: fields.gigyaUserId,
        dateOfBirth: dateOfBith,
        postcode: selectedPostcode.trim(),
        myRacRedirectPage: fields.redirectPage,
      };

      const response = await ValidateAndGetMagicLink(membershipDetails);
      if (response.responseCode) {
        if (response.payload.success) {
          trackEvent('MyRAC_VerifyUserMagicLinkRequestSuccess');
          fields.verifyApprovedHandler(`${response.payload.url}&verified=true`);
        } else {
          trackEvent('MyRAC_VerifyUserMagicLinkRequestFail');
          setLoading(false);
          handleErrors((prev) => ({ ...prev, formError: true, errorText: `There is an issue processing your renewal - ${response.payload.errorReason}` }));
        }
      }
    } catch {
      setLoading(false);
      handleErrors((prev) => ({ ...prev, formError: true, errorText: 'Error contacting server.  Please try again in a few minutes' }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    handleErrors((prev) => ({ ...prev, formError: false, invalidPostcode: false, errorText: '' }));
    if (!loading) {
      const postCodeRegex = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
      if (selectedPostcode && postCodeRegex.test(selectedPostcode?.trim())) {
        setLoading(true);
        await verifyUserMyRacHandler();
      } else {
        handleErrors((prev) => ({ ...prev, formError: false, invalidPostcode: true, errorText: selectedPostcode ? 'Valid postcode must be entered' : 'Postcode must be entered' }));
      }
    }
  };

  const passwordPreferredHandler = async (e) => {
    e.preventDefault();
    trackEvent('MyRAC_VerifyUserMagicLinkPreferPassword');
    fields.verifyRedirectToLogin(fields.redirectPage);
  };

  return (
    <section id={fields.uid} className={bem()}>
      <div className={bem('box')}>
        <h2 className={bem('heading')}>{fields.heading}</h2>
        <div className={bem('information')}>{fields.information}</div>
        <br />
        <form id="VerifyUserForm" className="w-100" onSubmit={onSubmit}>
          <div className={bem('form-wrapper')}>
            <label htmlFor="Postcode" className={bem('label')}>
              <span className={bem('labelText')}>{fields.postcodelabel}</span>
              <input
                className="MyRacForm__form-element
                MyRacForm__input-extra-height"
                type="text"
                placeholder="e.g. BS32 4QN"
                name="Postcode"
                required=""
                onChange={(e) => setPostcode(e.target.value)}
              />
              {errors.invalidPostcode && !loading && (
                <div className={bem('error')}>
                    {errors.errorText}
                </div>
              )}
            </label>
            <label htmlFor="DOB" className={bem('label')}>
              <span className={bem('labelText')}>{fields.dateofbirthlabel}</span>

              <DropdownDate
                sendValidation={setDobValidation}
                startDate="1909-01-01"
                selectedDate={
                  // optional
                  // eslint-disable-next-line react/destructuring-assignment
                  dateOfBith // 'yyyy-mm-dd' format only
                }
                order={
                  // optional
                  ['day', 'month', 'year'] // Order of the dropdowns
                }
                onDateChange={(date) => {
                  // optional
                  setDateOfBirth(format(new Date(date), 'yyyy-MM-dd'));
                }}
                ids={
                  // optional
                  {
                    year: 'select-year',
                    month: 'select-month',
                    day: 'select-day',
                  }
                }
                names={
                  // optional
                  {
                    year: 'year',
                    month: 'month',
                    day: 'day',
                  }
                }
                classes={
                  // optional
                  {
                    dateContainer: 'selectContainerMobileInline',
                    yearContainer: 'selectYear',
                    monthContainer: 'selectMonth',
                    dayContainer: 'selectDay',
                    year: 'selectContainerMobileInline dobPanel',
                    month: 'selectContainerMobileInline dobPanel',
                    day: 'selectContainerMobileInline dobPanel',
                    yearOptions: 'classes',
                    monthOptions: 'classes',
                    dayOptions: 'classes',
                  }
                }
                defaultValues={
                  // optional
                  {
                    year: 'year',
                    month: 'month',
                    day: 'day',
                  }
                }
                options={
                  // optional
                  {
                    yearReverse: true, // false by default
                    monthNumeric: true, // false by default
                  }
                }
              />

              {!errors.formError && !loading && !dobValidation && (
                <div className={bem('error')}>
                  Date of birth invalid
                </div>
              )}
            </label>

          </div>
          <div className={bem('button-wrapper')}>
            <Button buttonText={fields.buttontext} chevronWhite loading={loading} disabled={loading} isSubmit />
          </div>

          {errors.formError && !loading && (
            <div className={bem('error')}>
                {errors.errorText}
            </div>
          )}
          <br />
          <div>
            <a href="/" className={bem('password-link')} onClick={passwordPreferredHandler}>{fields.passwordlinktext}</a>
          </div>
        </form>
      </div>
    </section>
  );
};

export default VerifyUserDetails;
