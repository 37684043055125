import {
  SET_DOCUMENTS,
  SET_DOCUMENTS_ERROR,
} from '../constants/documentConstants';

export function setDocuments(documents) {
  return {
    type: SET_DOCUMENTS,
    payload: documents,
  };
}

export function setDocumentsError(error) {
  return {
    type: SET_DOCUMENTS_ERROR,
    payload: error,
  };
}
