import React from 'react';

import CountdownOffer from '../views/MyRac/MembershipOverview/Tiles/Countdown/CountdownOffer';
import ImageTile from '../views/MyRac/MembershipOverview/Tiles/ImageTile/ImageTile';
import ImageWithCtaTile from '../views/MyRac/MembershipOverview/Tiles/ImageWithCtaTile/ImageWithCtaTile';
import CountdownWithCtaTile from '../views/MyRac/MembershipOverview/Tiles/CountdownCTA/CountdownWithCtaTile';
import MembershipCover from '../views/MyRac/MembershipOverview/Tiles/MembershipCover/MembershipCover';
import MembershipDocuments from '../views/MyRac/MembershipOverview/Tiles/MembershipDocuments/MembershipDocuments';
import MembershipReminders from '../views/MyRac/MembershipOverview/Tiles/MembershipReminders/MembershipReminders';
import MembershipStatus from '../views/MyRac/MembershipOverview/Tiles/MembershipStatus/MembershipStatus';

import contentUID from '../utils/contentUID.enum';

// eslint-disable-next-line no-use-before-define
const getOffers = (tileData) => getTileContent(tileData.tileContent.offerTile.content_Type_UID, tileData.tileContent.offerTile);

const getTileContent = (contentTileUID, tileData, offers) => {
  switch (contentTileUID) {
    case (contentUID.membershipStatus): return (<MembershipStatus pageModel={tileData} />);
    case (contentUID.membershipCover): return (<MembershipCover pageModel={tileData} />);
    case (contentUID.membershipReminder): return (<MembershipReminders pageModel={tileData} />);
    case (contentUID.membershipDocuments): return (<MembershipDocuments pageModel={tileData} />);
    case (contentUID.imageTile): return (<ImageTile pageModel={tileData} />);
    case (contentUID.imageTileCTA): return (<ImageWithCtaTile pageModel={tileData} />);
    case (contentUID.countdown): return (<CountdownOffer pageModel={tileData} />);
    case (contentUID.countdownWithCtaTile): return (<CountdownWithCtaTile pageModel={tileData} />);
    case (contentUID.moreForMore): return getOffers(tileData, offers); // Tile4
    default: return (<h4>Retrieving your details</h4>);
  }
};

export default getTileContent;
