import { updateIn, fromJS } from 'immutable';

// eslint-disable-next-line default-param-last
const vehicleInfoReducer = (state = {
  vehicleInfo: {},
}, action) => {
  switch (action.type) {
    case 'SET_VEHICLE':
      return {
        ...state,
        vehicleInfo: action.payload,
      };
    case 'ADD_VEHICLE':
      return {
        ...state,
        vehicleInfo: {
          ...state.vehicleInfo, reminderVehicles: state.vehicleInfo.reminderVehicles.concat(action.payload),
        },
      };
    case 'UPDATE_VEHICLE':
      // eslint-disable-next-line no-case-declarations
      const vehicles = action.payload;
      return {
        ...state,
        vehicleInfo: {
          ...state.vehicleInfo,
          reminderVehicles: state.vehicleInfo.reminderVehicles.map((v) => (v.vehicleId === vehicles.vehicleId ? {
            ...v, Make: vehicles.Make, Registration: vehicles.Registration, Model: vehicles.Model, YearOfManufacture: vehicles.YearOfManufacture, canEditVehicle: vehicles.canEditVehicle,
          } : v)),
        },
      };

    case 'UPDATE_VEHICLE_REMINDERS':
      // eslint-disable-next-line no-case-declarations
      const { vehicleId, reminders } = action.payload;
      // eslint-disable-next-line no-case-declarations
      let newVehicleReminders;
      // eslint-disable-next-line no-case-declarations
      let index;
      if (state?.vehicleInfo?.reminderVehicles) {
        const myVehicleReminder = state.vehicleInfo.reminderVehicles.find((x) => x.vehicleId === vehicleId);
        if (myVehicleReminder) {
          index = state.vehicleInfo.reminderVehicles.findIndex((x) => x.vehicleId === vehicleId);
          newVehicleReminders = {
            ...myVehicleReminder,
            reminders,
          };
        }
      }
      if (newVehicleReminders) {
        const newState = fromJS(state);
        const value = updateIn(newState, ['vehicleInfo', 'reminderVehicles'], (list) => list.splice(index, 1, newVehicleReminders));
        return value.toJS();
      }
      return state;

    case 'DELETE_VEHICLE':
      if (action.payload && state && state.vehicleInfo && state.vehicleInfo.reminderVehicles) {
        const vehindex = state.vehicleInfo.reminderVehicles.findIndex((x) => x.vehicleId === action.payload);
        const newStateJs = fromJS(state);
        const newStateVehicle = newStateJs.toJS();
        if (vehindex > -1) {
          // first del vehilces and reminders
          newStateVehicle.vehicleInfo.reminderVehicles.splice(vehindex, 1);
        }
        if (newStateVehicle.vehicleInfo.upcomingReminders) {
          // now delete upcoming reminders
          const indexOfAll = newStateVehicle.vehicleInfo.upcomingReminders.reduce((acc, el, i) => (el.vehicleId === action.payload ? [...acc, i] : acc), []);
          for (let i = indexOfAll.length - 1; i >= 0; i--) {
            newStateVehicle.vehicleInfo.upcomingReminders.splice(indexOfAll[i], 1);
          }
        }
        return newStateVehicle;
      }
      return state;

    case 'VEHICLE_HAS_ERROR':
      return {
        ...state,
        vehicleInfo: {
          ...state.vehicleInfo,
          hasError: action.payload,
        },
      };
    default:
      return state;
  }
};

export default vehicleInfoReducer;
