import React from 'react';
import 'shared-styles/_core.scss';
import PropTypes from 'prop-types';
import block from 'bem-css-modules';
import styles from './Button.module.scss';

const {
  string,
  bool,
  func,
  shape, // shape
} = PropTypes;

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});
const b = block(styles);

const Button = ({
  buttonText,
  onClickHandler,
  ghostButton,
  greyButton,
  darkGreyButton,
  marginBottom,
  chevronWhite,
  chevronOrange,
  chevronBlack,
  outlineBlack,
  colorBlack,
  small,
  buttonStyles,
  fullWidth,
  disabled,
  rounded,
  loading,
  isSubmit,
}) => (
  <button
    type={isSubmit ? 'submit' : 'button'}
    onClick={disabled ? null : onClickHandler}
    disabled={disabled}
    className={b({
      'chevron-right-narrow': chevronWhite,
      'chevron-right-narrow-black': chevronBlack,
      'chevron-right-narrow-orange': chevronOrange,
      'outline-black': outlineBlack,
      'full-width': fullWidth,
      'color-black': colorBlack,
      'dark-grey': darkGreyButton,
      ghost: ghostButton,
      grey: greyButton,
      'margin-bottom': marginBottom,
      'small-button': small,
      disabled,
      rounded,
    })}
    style={buttonStyles}
  >
    { loading ? <i className="fs-5 fas fa-spinner fa-pulse" /> : buttonText }
  </button>
);

Button.propTypes = {
  buttonText: string,
  onClickHandler: func,
  ghostButton: bool,
  marginBottom: bool,
  chevronWhite: bool,
  chevronOrange: bool,
  chevronBlack: bool,
  colorBlack: bool,
  small: bool,
  fullWidth: bool,
  rounded: bool,
  buttonStyles: shape({}),
  isSubmit: bool,
  disabled: bool,
};

Button.defaultProps = {
  buttonText: '',
  onClickHandler: () => {},
  ghostButton: false,
  marginBottom: false,
  chevronWhite: false,
  chevronOrange: false,
  chevronBlack: false,
  colorBlack: false,
  small: false,
  fullWidth: true,
  rounded: false,
  buttonStyles: {},
  isSubmit: false,
  disabled: undefined,
};

export default Button;
