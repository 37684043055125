import React from 'react';
import styles from './Navigation.module.scss';
import logo from '../assets/logo.png';
import useViewport from '../../../../utils/useViewportHelper';
import RenderLink from '../../../../components/RenderLink';
import { ROOT } from '../../../../constants/routeConstants';
import trackEvent from '../../../../services/AnalyticsService';

const Navigation = (props) => {
  const { telNo } = props;
  return (
    <nav className={`${styles.container}`}>
      <div className={styles.mainContainer}>
        <RenderLink url={ROOT}>
          <img className={styles.logo} src={logo} alt="RAC Logo" />
        </RenderLink>
        <div className={styles.navItems}>
          <div className={styles.renewalQuery}>
            <b>Call</b>&nbsp;
            {useViewport().isMobile
              ? (<a href={`tel:+44${telNo && telNo.replace(/\s/g, '')}`} className={styles.telephoneNo} onClick={() => { trackEvent('MyRAC_Renewal_Nav_Phone'); }}>{telNo}</a>)
              : (<span className={styles.telephoneNo}>{telNo}</span>)}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
