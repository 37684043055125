import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { replaceValue } from '../utils/commons';
import { MembershipTiers } from '../constants/enums';

const TieredBenefits = (props) => {
  const { policy, freebenefits } = props;

  const [enhancedBenefits, setEnhancedBenefits] = useState(true);
  const [discountRate, setDiscountRate] = useState('10');

  useEffect(() => {
    if (policy.policyInfo.Tier === MembershipTiers.black) {
      setDiscountRate('15');
    }

    if (policy.policyInfo.Tier === MembershipTiers.orange || policy.policyInfo.IsRacStaff) {
      setEnhancedBenefits(false);
    }
  }, [policy]);

  const displayBenefitsForEveryone = () => freebenefits.orange.map((value) => (
    <div key={value.offerimage.uid} className={`col-12 col-sm-6 float-left ${value.offerurl.cssclass}`}>
      <a href={value.offerurl.href} target="_NEW" rel="noopener noreferrer">
        <img src={value.offerimage.url} alt="" className="reward-banner" />
        <h2>{value.offerurl.title}</h2>
        <p>{value.offerdescription}</p>
      </a>
    </div>
  ));

  const displayEnhancedBenefits = () => freebenefits.others.map((value) => (
    <div key={value.offerimage.uid} className={`col-12 col-sm-6 float-left ${value.offerurl.cssclass}`}>
      <a
        href={replaceValue(value.offerurl.href, '{tierLevel}', policy.policyInfo.Tier ? policy.policyInfo.Tier.toLowerCase() : '')}
        target={value.offerurl.openinnewwindow ? '_NEW' : '_SELF'}
        rel={value.offerurl.openinnewwindow ? 'noopener noreferrer' : null}
      >
        <img src={value.offerimage.url} alt="" className="reward-banner" />
        <h2>{replaceValue(value.offerurl.title, '{discount}', discountRate)}</h2>
        <p>{replaceValue(value.offerdescription, '{discount}', discountRate)}</p>
      </a>
    </div>
  ));

  return (
    <div className="row tiered-benefits">
      <div className="offers">
        { displayBenefitsForEveryone() }
        { enhancedBenefits ? displayEnhancedBenefits() : null }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
});

export default connect(mapStateToProps)(TieredBenefits);
