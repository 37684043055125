import React from 'react';
import styles from './BulletList.module.scss';

const BulletList = ({ header, body }) => (
  <div className={styles.container}>
    <ul>
      <li>
        <div>{header}</div>
        <div>{body}</div>
      </li>
    </ul>
  </div>
);

export default BulletList;
