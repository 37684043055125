import React from 'react';
import './InformationMessage.scss';

const InformationMessage = (props) => {
  const { text } = props;
  return (
    <div className="row">
      <div className="col-12">
        <div className="text-center">
          <p className="infoMessage__p"><b>{text}</b></p>
        </div>
      </div>
    </div>
  );
};

export default InformationMessage;
