import React from 'react';
import Spinner from 'components/Spinner';

const SearchVehicleText = (pageModel) => {
  const { spinnerText } = pageModel;

  return (
    <div className="modal-body">
      <Spinner spinnerMsg={spinnerText} defaultSpinner />
    </div>
  );
};

export default SearchVehicleText;
