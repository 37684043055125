import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { loggerFunc } from 'utils/logger';
import { getState } from '../../../store';
import { UsePrompt } from '../../../hooks/routerBlocker';
import { API_GET_PAYMENT_IFRAME } from '../../../constants/apiConstants';
import { RENEWAL_CONFIRMATION, RENEWAL_ERROR } from '../../../constants/routeConstants';
import { GetPaymentStatus, SetRenewalPaymentStatus } from '../../../services/MyRacService';
import paymentResponse from '../../../constants/paymentServiceConstants';
import TechnicalError from '../TechnicalError';
import TimeoutWarningModal from '../../TimeoutWarningModal';
import Spinner from '../../Spinner';
import styles from './PaymentIFrame.module.scss';

const PaymentIFrame = ({ paymentId, payload, renewalToken }) => {
  const [iFrameSrc, setIframeSrc] = useState(<Spinner bootSpinner />);
  const [iFrameError, setIframeError] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);

  UsePrompt('Changes you made may not be saved.', showPrompt);

  const logger = loggerFunc('PaymentService');

  const navigate = useNavigate();

  const {
    session: {
      discountRenewal,
    },
  } = getState();

  const handleConfirmation = async () => {
    // PUT request to add payment status to payment db
    await SetRenewalPaymentStatus(payload?.Price, paymentId, renewalToken)
      // Payment db updated now set relevant page redirect
      .then(() => {
        window.globalDataLayer.MyRAC_Upgrades_Confirmed = payload.Amendments?.length > 0 ? payload.Amendments.map((upgrade) => upgrade.title) : [];
        navigate(RENEWAL_CONFIRMATION, { state: { ...payload }, replace: true });
      })
      .catch((error) => {
        logger.debug(error);
      });
  };

  const handleError = async () => {
    // PUT request to add payment status to payment db
    await SetRenewalPaymentStatus(payload?.Price, paymentId, renewalToken)
      // Payment db updated now set relevant page redirect
      .then(() => {
        navigate(RENEWAL_ERROR, { state: { isPaymentError: true, ...payload }, replace: true });
      })
      .catch((error) => {
        logger.debug(error);
      });
  };

  const getPaymentStatus = async () => {
    // Polling payment service
    GetPaymentStatus(paymentId).then(async (response) => {
      const paymentStatus = response?.payload;
      if (paymentStatus.success && paymentStatus.statusCode === paymentResponse.SUCCESS && !paymentStatus.isAwaitingResponse) {
        // Payment polling service returned success therefore redirect user to confirmation page
        handleConfirmation();
      }
      if (paymentStatus.attempts === 3 && !paymentStatus.success) {
        // After 3 failed attempts redirect user to error page
        handleError();
      }
    }).catch((error) => {
      logger.debug(error);
    });
  };

  // Event listener waits for a message event to be triggered from the payment response service
  async function listener(event) {
    if (event.data && event.data.paymentId) {
      setShowPrompt(!(event.data.success || event.data.maxAttempt));
      // Method added to prevent further propagation of the current event for potential bubbling/conflict with javascript from live person chat bot
      event.stopPropagation();
    }
  }

  const getPaymentForm = async () => {
    if (paymentId) {
      fetch(API_GET_PAYMENT_IFRAME(paymentId))
        .then((response) => {
          if (response?.status === 200) {
            // Payment form
            setIframeSrc(<iframe id="paymentIFrame" title="Payment IFrame" src={API_GET_PAYMENT_IFRAME(paymentId)} width="100%" height="100%" scrolling="no" />);
            setIframeError(false);
          } else {
            // Error, try again
            setIframeSrc(<TechnicalError buttonClickHandler={getPaymentForm} />);
            setIframeError(true);
          }
        })
        .catch(() => {
          // Error, try again
          setIframeSrc(<TechnicalError buttonClickHandler={getPaymentForm} />);
          setIframeError(true);
        });
    }
  };

  useEffect(() => {
    // Event listener for GlobalPay message response
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [paymentId, navigate]);

  useEffect(() => {
    // Interval to poll payment status from RAC payment service
    const paymentStatusInterval = window.setInterval(
      getPaymentStatus,
      process.env.REACT_APP_PAYMENT_SERVICE_STATUS_INTERVAL_TIME,
    );
    return () => {
      window.clearInterval(paymentStatusInterval);
    };
  }, []);

  useEffect(() => {
    // Show payment form or technical error in iFrame
    async function renderIframe() {
      await getPaymentForm();
    }
    renderIframe();
  }, []);

  return (
    <div>
      <div className={styles.container}>
        {iFrameError && (
          iFrameSrc
        )}
        {!iFrameError && (
          <div className={styles.hpp_iframe}>
            {discountRenewal?.isDiscountedPrice
              ? <div className="fs-3 px-4 m-1"><span className="fw-bold orange">£{payload?.Price?.toFixed(2)}</span> <s>£{payload?.PriceBeforeDiscount?.toFixed(2)}</s><p className={styles.discountApplied}>{discountRenewal?.basketDiscountText} discount applied</p></div>
              : <div className="fs-3 px-4 m-1">£{payload?.Price?.toFixed(2)}</div>}
            { iFrameSrc }
          </div>
        )}
      </div>
      {renewalToken && (
        <TimeoutWarningModal policyLockToken={renewalToken} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(PaymentIFrame);
