import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import block from 'bem-css-modules';
import modalTypes from '../../utils/modalTypesEnum';
import styles from './PopupModal.module.scss';
import trackEvent from '../../services/AnalyticsService';

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const bem = block(styles);

const PopupModal = (props) => {
  const {
    parseHtml,
    resultText,
    resultTitle,
    form,
    action,
    modalOk,
    cancelButton,
    cancelAction,
    innerCloseButton,
    outerCloseButton,
    bannerCloseButton,
    displayDialogAtTopQuarter,
    displayDialogInMiddle,
    modalType,
    headerOverrideStyle,
    backgroundOverrideStyle,
    backgroundButtonMap,
    backgroundButtonAlt,
    linkRef,
    eventTag,
  } = props;
  let description;
  let title;
  if (parseHtml) {
    description = ReactHtmlParser(resultText);
    title = ReactHtmlParser(resultTitle);
  } else if ((resultText instanceof Array && React.isValidElement(resultText[0])) || resultTitle instanceof Array) {
    description = resultText;
    title = resultTitle;
  } else {
    description = <div>{resultText}</div>;
    title = resultTitle;
  }

  // Apply colour and width to the close bottom button based on modalType
  const applyBottomButtonColour = (cancel) => {
    if (cancel) {
      return 'button-secondary-grey';
    }

    if (modalType === modalTypes.packageModal) {
      return 'cta-bright-orange-button';
    }
    if (modalType === modalTypes.errorModal || modalType === modalTypes.infoModal) {
      return 'button-primary-orange';
    }
    return 'cta-bright-orange-button';
  };

  // This only applies to the 'x' button found inside the dialog box
  // as styles vary from grey (in the package dialog) to orange in info and errorModals
  const applyTopButtonSize = () => {
    if (modalType === modalTypes.packageModal) {
      return 'package-dialog-close-button';
    }
    if (modalType === modalTypes.infoModal || modalType === modalTypes.errorModal) {
      return 'info-dialog-close-button';
    }
    return null;
  };

  // Apply additional style to header based on ModalType
  const applyHeaderStyle = () => {
    if (modalType === modalTypes.infoModal || modalType === modalTypes.errorModal) {
      return 'info-dialog-header-container';
    }
    return null;
  };

  const applyDescriptionStyle = () => {
    if (modalType === modalTypes.infoModal || modalType === modalTypes.errorModal) {
      return 'info-dialog-body';
    }
    // eslint-disable-next-line no-use-before-define
    return `${smallOrRegularDialoguePrefix()}--dialog-body`;
  };

  const smallOrRegularDialoguePrefix = () => (modalType === modalTypes.smallModal ? 'small-dialog-box' : 'dialog-box');
  const smallDialogue = () => modalType === modalTypes.smallModal;

  const applyModalBackgroundOpacity = () => {
    switch (modalType) {
      case (modalTypes.errorModal):
      case (modalTypes.packageModal):
      case (modalTypes.smallModal):
      case (modalTypes.infoModal): return 'info-modal-container';
      default: return 'modal-container';
    }
  };
  const navigate = useNavigate();

  const handleCTA = (e) => {
    e.preventDefault();
    if (eventTag) {
      trackEvent(eventTag);
    }
    navigate(linkRef);
  };

  // Orange "X" inside modal window
  const displayInnerCloseButton = () => (
    innerCloseButton && (
      <div className={`${bem('header-row')}row`}>
        <button
          type="button"
          title="Close"
          className={`${bem('inner-close-button')} ${bem(applyTopButtonSize())} close ascii-cross`}
          onClick={action}
          data-dismmiss="modal"
          aria-hidden="true"
        >
          &#215;
        </button>
      </div>
    )
  );

  // Orange "X" inside modal overlaying banner background image
  const displayBannerCloseButton = () => (
    bannerCloseButton && (
      <div
        title="Close"
        className={`${bem('banner-close-button')} close ascii-cross`}
        onClick={action}
        data-dismmiss="modal"
        aria-hidden="true"
      >
        &#215;
      </div>
    )
  );

  // White "X" inside a blue circle positioned absolute top right of modal
  const displayOuterCloseButton = () => (
    outerCloseButton && (
      <div className={bem('outer-close-wrapper')}>
        <button
          type="button"
          title="Close"
          className={bem('outer-button')}
          onClick={action}
          data-dismmiss="modal"
          aria-hidden="true"
        >
          <span className={bem('outer-button-text')}>&#215;</span>
        </button>
      </div>
    )
  );

  return (
    <div className={bem()}>
      <div className={`${bem(applyModalBackgroundOpacity())} modal myrac Modal__overflow`} style={{ zIndex: 1000000050 }} role="dialog">
        <div
          className={`${bem(smallOrRegularDialoguePrefix())} ${bem({
            'dialog-top-quarter': displayDialogAtTopQuarter,
            'dialog-middle': displayDialogInMiddle,
          })} ${bem(backgroundOverrideStyle && 'banner-container')} modal-dialog Modal__visible`}
        >
          <div className={`${bem(!smallDialogue() ? 'modal-content-wrapper' : '')} modal-content`} style={backgroundOverrideStyle}>
            <div className={`${bem('header-container')} ${bem(applyHeaderStyle())}`}>
              {displayInnerCloseButton()}
              {displayOuterCloseButton()}
              {displayBannerCloseButton()}
              <div className={`${bem('header-row')}`}>
                <h4 className={`${bem(`${smallOrRegularDialoguePrefix()}--popup-title`)} modal-title Modal__title-white`} style={headerOverrideStyle}>
                  {title}
                </h4>
              </div>
            </div>
            <div
              className={`${(backgroundButtonMap && handleCTA && bem('popup-no-body')) ?? bem('popup-body')} ${bem(applyDescriptionStyle())}`}
            >
              {backgroundButtonMap && handleCTA && (
                <div
                  style={backgroundButtonMap}
                  tabIndex={0}
                  role="button"
                  onClick={handleCTA}
                  onKeyDown={handleCTA}
                  alt={backgroundButtonAlt}
                >
                  &nbsp;
                </div>
              )}
              {description}
              {form}
            </div>
            <div className={`${bem('modal-footer')}`}>
              <div
                className={`${bem(smallDialogue() ? 'small-dialog-box--button-wrapper' : 'button-wrapper')}`}
              >
                {cancelButton ? (
                  <button
                    className={`${bem(applyBottomButtonColour(true))} MyRacForm__button
                      MyRacForm__button--vertical-spacing
                      MyRacForm__button--wide`}
                    type="button"
                    onClick={cancelAction}
                    data-dismiss="modal"
                  >
                    {cancelButton}
                  </button>
                ) : null}
              </div>
              <div
                className={`${bem(smallDialogue() ? 'small-dialog-box--button-wrapper' : 'button-wrapper')}`}
              >
                {modalOk ? (
                  <button
                    className={`${bem(applyBottomButtonColour())} MyRacForm__button
                      MyRacForm__button--vertical-spacing
                      MyRacForm__button--wide`}
                    type="button"
                    onClick={action}
                    data-dismiss="modal"
                  >
                    {modalOk}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade in Modal__block"
        style={{ zIndex: 1000000049 }}
      />
    </div>
  );
};

export default PopupModal;
