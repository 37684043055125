import React from 'react';
import RenderLink from 'components/RenderLink';

export const AdvertorialPanel = (props) => {
  const { advertorial } = props;
  if (!advertorial) { return null; }
  return (
    <div
      className="row product-info"
      style={{
        backgroundImage:
          `url(${advertorial.background.url})`,
      }}
    >
      {advertorial.items.map((advert) => (
        <div className="col-sm px-3" key={advert.url + advert.title + advert.text}>
          <RenderLink link={advert.url}>
            <img alt={advert.image.title} src={advert.image.url} />
          </RenderLink>
          <p className="box-height">{advert.text}</p>
          <RenderLink
            link={advert.url}
            className="btn btn-cta btn-secondary"
          >
            {advert.url.text}
          </RenderLink>
        </div>

      ))}
    </div>
  );
};

export default AdvertorialPanel;
