import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './NavQuickLink.module.scss';

const NavQuickLink = ({ text, isScrolling, linkUrl, absoluteUrl }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (!isScrolling) {
      if (absoluteUrl) {
        window.location.href = absoluteUrl;
      } else {
        navigate(linkUrl);
      }
    }
  };

  return (
    // onclick was once onMouseUp. OnMouseUp works when you have cross compatibility for desktop and mobile. Mobile user will have to click twice as the only drawback.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={styles.container} onClick={handleOnClick}>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default NavQuickLink;
