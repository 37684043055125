import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import PopupModal from '../../../components/PopupModal/PopupModal';
import modalTypes from '../../../utils/modalTypesEnum';
import { UsePrompt } from '../../../hooks/routerBlocker';

export const ProtectedForm = (props) => {
  const { children, className, formChanged } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [showNavModal, setShowNavModal] = useState(false);

  useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      setConfirmedNavigation(false);
      setShowNavModal(false);
      navigate(nextLocation.pathname);
    }
  }, [location]);

  useBeforeunload((event) => {
    if (formChanged) {
      event.preventDefault();
      setShowNavModal(true);
    }
    return null;
  });

  // eslint-disable-next-line no-unused-vars
  const handleBlockedNav = (nextNavlocation) => {
    if (!confirmedNavigation && formChanged && location.pathname !== nextNavlocation?.pathname) {
      setShowNavModal(true);
      setNextLocation(nextNavlocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    setShowNavModal(false);
    setConfirmedNavigation(true);
    navigate(nextLocation.location.pathname);
  };

  const handleCancelNavigationClick = () => {
    setShowNavModal(false);
    setConfirmedNavigation(false);
  };

  return (
    <div className={className}>
      {children}
      {showNavModal
        && (
          <PopupModal
            resultTitle="You haven't saved your changes"
            resultText="Would you like to continue anyway?"
            modalOk="Yes"
            cancelButton="No"
            action={handleConfirmNavigationClick}
            cancelAction={handleCancelNavigationClick}
            parseHtml
            modalType={modalTypes.infoModal}
          />
        )}
      {UsePrompt('You haven\'t saved your changes. Would you like to continue anyway?', formChanged) }
    </div>
  );
};

export default ProtectedForm;
