import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';

import editIcon from '../../assets/images/button/edit-profile-icon.svg';
import breakdownButton from '../../assets/images/button/warning-triangle-orange.svg';
import RenderLink from '../../components/RenderLink';

import './WelcomeTile.scss';

export const WelcomeTile = (props) => {
  const {
    pageModel,
    customer: { custInfo },
    session,
    featureFlags,
  } = props;

  const welcomeDesktopMessage = pageModel.welcomedesktopmessage.replace('{0}', custInfo.Forename);
  const firstMobileMessage = pageModel.firstwelcomemobilemessage.replace('{0}', custInfo.Forename);
  const analyticsTag = 'MyRAC_Overview_Edit_my_profile';
  const EDIT_TEXT = 'Edit my profile';
  const redirect = '/personal-details';

  const getWelcomeData = () => (
    <div className="welcome__container">
      <div className="welcome__welcome-wrapper">
        <h3 className="welcome__welcome-header">
          {welcomeDesktopMessage}
        </h3>
        <div className="welcome__welcome-mobile-wrapper">
          <h2 className="welcome__hi-mobile">{firstMobileMessage}</h2>
        </div>
        <span className="welcome__membership-text">
          Membership no:
          {' '}
        </span>
        <span className="welcome__member-no">
          {session.memberNo}
        </span>
      </div>
      <div className="welcome__button-wrapper">
        {featureFlags.showUnsupportedBrowserBanner ? (
          <div className="welcome__breakdown-wrapper">
            <a href={`${process.env.REACT_APP_BREAKDOWN_URL}${process.env.REACT_APP_BREAKDOWN_BUTTON_QUERYSTRING}`} className="text-dark" target="_blank" rel="noopener noreferrer">
              <span className="welcome__breakdown-profile-icon-wrapper">
                <img className="welcome__edit-profile-icon" src={breakdownButton} alt="edit-profile-icon" />
              </span>
              <p className="welcome__breakdown-text">
                Broken down?
                <br />
                {' '}
                Get help
              </p>
            </a>
          </div>
        ) : null}
        <div className="welcome__edit-wrapper">
          <div className="welcome__edit-profile-icon-wrapper">
            <RenderLink url={redirect}>
              <img className="welcome__edit-profile-icon" src={editIcon} alt="edit-profile-icon" />
            </RenderLink>
          </div>
          <p className="welcome__edit-text">
            <RenderLink className="text-dark" url={redirect} text={EDIT_TEXT} analytics={analyticsTag} />
          </p>
        </div>
      </div>
    </div>
  );

  const getLoading = () => (
    <div className="welcome__loading">
      <Loader type="Oval" color="#f95108" height={40} width={40} />
    </div>
  );

  return (
    <div>
      {Object.keys(pageModel).length && Object.keys(custInfo) && custInfo.Forename ? getWelcomeData() : getLoading()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  session: state.session,
  featureFlags: state.featureToggle,
});

export default connect(mapStateToProps)(WelcomeTile);
