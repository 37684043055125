import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { intervalToDuration } from 'date-fns';

import TileButton from '../../GridLayout/TileButton';

import './CountdownOffer.scss';

const CountdownOffer = (props) => {
  const {
    policy: { policyInfo },
    pageModel,
  } = props;

  const updateTimer = () => {
    let remainingTime;
    const today = new Date();
    const renewal = policyInfo && policyInfo.RenewalDate ? new Date(policyInfo.RenewalDate) : null;
    if (renewal) {
      const dateDifference = renewal - today;
      const timeLeft = intervalToDuration({
        start: today,
        end: renewal,
      });
      // Countdown
      if (dateDifference > 0) {
        // The slice() method is used to ensure 0 is added before values less than 10
        remainingTime = {
          days: `0${timeLeft.days}`.slice(-2) || '-',
          hours: `0${timeLeft.hours}`.slice(-2) || '-',
          minutes: `0${timeLeft.minutes}`.slice(-2) || '-',
          seconds: `0${timeLeft.seconds}`.slice(-2) || '-',
        };
      } else {
        remainingTime = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
    }
    return remainingTime;
  };

  const [countdownTimer, setCountdownTime] = useState(updateTimer());

  useEffect(() => {
    const setTimerInterval = setInterval(() => {
      setCountdownTime(updateTimer());
    }, 1000);
    // if component is unmounted then clear interval
    return () => clearInterval(setTimerInterval);
  });

  const eventAnalytics = () => pageModel.analyticsTags.find((tag) => tag.eventName === 'clickWidget');

  const displayTileImage = () => {
    if (pageModel.tileContent) {
      if (pageModel.tileContent.desktopImage && pageModel.tileContent.desktopImage.url) {
        return (
          <img
            className="countdown__image-style"
            src={pageModel.tileContent.desktopImage.url}
            alt={pageModel.tileContent.desktopImage.title}
          />
        );
      }
    }
    return null;
  };

  const displayCtaButton = () => {
    if (pageModel && pageModel.tileContent && pageModel.tileContent.button) {
      return (
        <div className="countdown__button">
          <TileButton
            ctaData={pageModel.tileContent.button}
            ctaPosition={pageModel.tileContent.buttonLocation}
            analyticsTag={eventAnalytics()}
          />
        </div>
      );
    }
    return null;
  };

  const displayTimerWrapper = () => (
    <div className="countdown__container">
      <div className="countdown__row">
        <div className="countdown__number">
          {countdownTimer?.days}
          <div className="countdown__time">Days</div>
        </div>
        <div className="countdown__number">
          {countdownTimer?.hours}
          <div className="countdown__time">Hours</div>
        </div>
        <div className="countdown__number">
          {countdownTimer?.minutes}
          <div className="countdown__time">Minutes</div>
        </div>
        <div className="countdown__number">
          {countdownTimer?.seconds}
          <div className="countdown__time">Seconds</div>
        </div>
      </div>
      {displayCtaButton()}
    </div>
  );

  return (
    <div className="countdown">
      {displayTileImage()}
      {displayTimerWrapper()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
});

export default connect(mapStateToProps)(CountdownOffer);
