import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ComponentView from 'common/ComponentView';
import RenderLink from 'components/RenderLink';
import * as DefaultText from 'configuration/defaultText';
import AdditionalMembersPanel from 'panels/AdditionalMembersPanel';
import DobPanel from 'panels/DobPanel';
import LeadMemberPersonalDetails from 'panels/LeadMemberPersonalDetails';
import PostalAddressPanel from 'panels/PostalAddressPanel';
import AdvertorialPanel from 'panels/AdvertorialPanel';
import PhoneNumberWrapper from 'panels/PhoneNumberWrapperPanel';
import { GetContent, MapAdvertPanel, mapURL } from 'services/ContentService';
import { ResponseCode } from 'services/models/httpResponse';
import trackEvent from 'services/AnalyticsService';
import { getMessage } from 'services/MessageService';
import Button from 'shared/components/Button/Button';
import { filterComponents, filterPanels } from 'utils/ContentHelpers';
import { loggerFunc } from 'utils/logger';
import EmailAddressPanel from 'views/MyRac/AccountSettings/Panels/EmailAddressPanel';
import { useApplicationState } from '../../hooks/applicationState-Context';
import InformationMessage from '../../components/InformationMessage';

const logger = loggerFunc('PersonalDetails');

const PersonalDetails = ({ customer, policy }) => {
  const [pageModel, setPageModel] = useState();
  const [errorText, setErrorText] = useState();
  const { appState, setPageLoading } = useApplicationState();

  const manageData = async () => {
    if (!pageModel) {
      let pageData = await GetContent(
        'loggedinpages',
        'Personal Details Page',
        ['componentitems', 'componentitems.dataofbirthfields',
          'componentitems.addressfields', 'componentitems.emailaddressfields',
          'componentitems.additonalmembersfields',
          'componentitems.phonenumberfields',
        ],
      );

      if (pageData.responseCode === ResponseCode.ERROR) {
        pageData = { error: DefaultText.APIError };
      } else {
        const personalDetails = filterComponents('personaldetails', pageData.payload);

        pageData = {
          advertorial: MapAdvertPanel(pageData.payload),
          title: pageData.payload.pagetitle,
          heading: pageData.payload.heading,
          myDocumentsURL: {
            isExternal: false,
            url: '/documents',
            text: 'View my documents',
          },
          panels: [
            {
              key: 'leadMember',
              title: personalDetails.leadmembertitle,
            },
            {
              key: 'DoBPanel',
              title: personalDetails.dateofbirthtitle,
              submitButton: personalDetails.dataofbirthfields[0].submitbutton,
              backButton: personalDetails.dataofbirthfields[0].backbutton,
              dobInvalid: personalDetails.additonalmembersfields[0].invaliddateofbirth,
              link: mapURL(personalDetails.dateofbirthurl),
              modalOk: 'Ok',
              messages: {
                success: {
                  title: 'Update successful',
                  text: 'Your details have been updated',
                },
                fail: {
                  title: 'Update un-successful',
                  text: 'Sorry, your details haven\'t been updated',
                },
              },
            },
            {
              key: 'address',
              title: personalDetails.addresstitle,
              link: mapURL(personalDetails.addressurl),
              modal: {
                address: {
                  line1: personalDetails.addressfields[0].addressline1label,
                  line1Placeholder: personalDetails.addressfields[0].addressline1placeholder,
                  line2: personalDetails.addressfields[0].addressline2label,
                  line2Placeholder: personalDetails.addressfields[0].addressline2placeholder,
                  town: personalDetails.addressfields[0].townlabel,
                  townPlaceholder: personalDetails.addressfields[0].townplaceholder,
                  county: personalDetails.addressfields[0].countylabel,
                  countyPlaceholder: personalDetails.addressfields[0].countyplaceholder,
                  postcode: 'Postcode',
                  postcodePlaceholder: personalDetails.addressfields[0].postcodeplaceholder,
                },
                buttons: {
                  back: personalDetails.addressfields[0].backbutton,
                  submit: personalDetails.addressfields[0].submitbutton,
                  findManually: personalDetails.addressfields[0].enteraddressmanuallylabel,
                  find: personalDetails.addressfields[0].findaddressbutton,
                  backToMyRAC: 'Back to MyRAC',
                  continueUpdating: 'Continue Updating',
                },
                messages: {
                  invalidPostcode: personalDetails.addressfields[0].invalidpostcodemessage,
                  requiredField: personalDetails.addressfields[0].requiredfieldmessage,
                  yourAddress: personalDetails.addressfields[0].youraddresstitle,
                  change: {
                    title: personalDetails.addressfields[0].heading,
                    text: personalDetails.addressfields[0].postcodelabel,
                    message: personalDetails.addressfields[0].headermessage,
                  },
                  goBack: {
                    title: 'Are you sure you want to go back?',
                    text: 'If so, your updated information will be cancelled',
                  },
                  saved: {
                    title: 'Thank You',
                    text: 'Your details have been updated.',
                  },
                },
              },
            },
            {
              key: 'email',
              title: personalDetails.emailaddresstitle,
              button: mapURL(personalDetails.emailaddressurl),
              messages: {
                successTitle: 'Nearly done!',
                successMessage: `To finish updating your email address we need
                        to send you an email. Please check your inbox (or
                        possibly your junk mail folder), open the email from
                        us and click on the link.`,
                failTitle: 'Update un-successful',
                failMessage: `Sorry, the update hasn't been successful.
                        Please try again later.`,
                invalidEmailFormat: 'E-mail address is invalid',
              },
              modalOk: 'OK',
              submitButton: personalDetails.emailaddressfields[0].submitbutton,
              backButton: personalDetails.emailaddressfields[0].backbutton,
            },
            {
              key: 'additionalMembers',
              title: personalDetails.additionalmemberstitle,
              button: mapURL(personalDetails.additionalmembersurl),
              noAdditionalMembers: personalDetails.noadditionalmembersmessage,
              change: {
                title: personalDetails.additonalmembersfields[0].heading,
                headermessage: personalDetails.additonalmembersfields[0].headermessage,
                titleLabel: 'Title',
                titleOptions: [
                  'Mr',
                  'Mrs',
                  'Ms',
                  'Miss',
                  'Doctor',
                  'Sir',
                ],
                firstNameLabel: personalDetails.additonalmembersfields[0].firstnamelabel,
                firstNameInvalid: 'Please enter a valid first name.',
                lastNameLabel: personalDetails.additonalmembersfields[0].surnamelabel,
                lastNameInvalid: 'Please enter a valid surname.',
                dobLabel: personalDetails.additonalmembersfields[0].dateofbirthlabel,
                dobInvalid: personalDetails.additonalmembersfields[0].invaliddateofbirth,
                buttons: {
                  back: personalDetails.additonalmembersfields[0].backbutton,
                  submit: personalDetails.additonalmembersfields[0].submitbutton,
                  updateButton: 'Back to MyRAC',
                },
                dialogues: {
                  success: {
                    title: 'Thank you',
                    text: 'Your details have been updated',
                  },
                  fail: {
                    title: 'Update un-successful',
                    text: `Sorry, the update hasn't been successful.
                          Please try again later.`,
                  },
                },
                notEditReasons: [
                  {
                    key: 'FUTUREDATE',
                    message: `We're currently updating your membership,
                              so please try again later.`,
                  },
                  {
                    key: 'RENEWALDATE',
                    message: `Sorry, as your membership is due for renewal
                              we're unable to make any changes at this time.
                              Please try again after your renewal date.`,
                  },
                  {
                    key: 'CONFIRMEDSTATE',
                    message: `Sorry, your membership hasn't started yet so
                              unfortunately we can't make any changes at this
                              time. Please try again after the start date.`,
                  },
                ],
              },
            },
            {
              key: 'phone',
              title: personalDetails.phonenumberstitle,
              homePhoneLabel: 'Home',
              workPhoneLabel: 'Work',
              mobilePhoneLabel: 'Mobile',
              invalidNumberMessage: 'Invalid Number',
              buttons: {
                changeButton: mapURL(personalDetails.phonenumbersurl),
                backButton: personalDetails.phonenumberfields[0].backbutton,
                submitButton: personalDetails.phonenumberfields[0].submitbutton,
              },
              noNumberText: personalDetails.nophonenumbermessage,
              modalOk: 'Ok',
              dialogues: {
                success: {
                  title: 'Thank you',
                  text: 'Your details have been updated',
                },
                fail: {
                  title: 'Update un-successful',
                  text: 'Something’s not right. Please check your phone numbers and try again.',
                },
              },
            },
          ],
        };
      }

      // hacking it together until CMS is in place correctly:
      pageData.panels[1].link.url = '/';
      pageData.panels[1].link.isExternal = false;
      pageData.panels[3].button.isExternal = false;
      pageData.panels[4].button.isExternal = false;
      pageData.panels[4].change.firstNameLabel = 'First name';
      pageData.panels[4].change.lastNameLabel = 'Last name';
      pageData.panels[5].buttons.changeButton.isExternal = false;

      setPageModel(pageData);
    }
    setPageLoading(false, null);
  };

  useEffect(() => {
    if (!pageModel) {
      try {
        manageData();
      } catch (e) {
        // todo set message constant.
        const message = getMessage();
        setErrorText(message);
        logger.error(message, e);
      }
      trackEvent('Page Change - personal-details');
    }
  }, []);

  const displayPersonalDetailsContent = () => {
    if (customer?.custInfo?.hasError || policy?.policyInfo?.hasError || pageModel.error) {
      return <InformationMessage text={DefaultText.APIError} />;
    }

    return (
      <ComponentView errorText={errorText} onErrorModalClose={() => { setErrorText(undefined); }}>
        <div className="col-sm-12 col-12">
          <div className="salutation">
            <h3 className="section-header">
              {pageModel.heading}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row.make-equal">
              <div className="col-sm-12">
                <div className="row display-flex">
                  <LeadMemberPersonalDetails
                    pageModel={filterPanels('leadMember', pageModel)}
                  />
                  <DobPanel pageModel={filterPanels('DoBPanel', pageModel)} />
                </div>
                <div className="row display-flex">
                  <PostalAddressPanel
                    pageModel={filterPanels('address', pageModel)}
                  />
                  <EmailAddressPanel
                    pageModel={filterPanels('email', pageModel)}
                  />
                </div>
                <div className="row display-flex">
                  <AdditionalMembersPanel
                    pageModel={filterPanels('additionalMembers', pageModel)}
                  />
                  <PhoneNumberWrapper
                    pageModel={filterPanels('phone', pageModel)}
                  />
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12">
                    <div className="mt-3 mb-4 float-end">
                      <RenderLink
                        link={pageModel.myDocumentsURL}
                      >
                        <Button
                          buttonStyles={{ width: 'auto' }}
                          buttonText={pageModel.myDocumentsURL.text}
                          chevronWhite
                        />
                      </RenderLink>
                    </div>
                  </div>
                </div>
                <AdvertorialPanel advertorial={pageModel.advertorial} />
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-sm-pull-9">
            <div ng-if="main.racMember" />
          </div>
        </div>
      </ComponentView>
    );
  };

  return (
    (appState?.loading.status) ? null : displayPersonalDetailsContent()
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
});

export default connect(mapStateToProps)(PersonalDetails);
