import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loggerFunc } from '../utils/logger';
import { logoutUser } from '../auth/userManager';
import { ReleaseLockToken } from '../services/MyRacService';
import { SESSION_EXPIRED } from '../constants/routeConstants';

const eventTypes = [
  'keypress',
  'mousemove',
  'mousedown',
  'scroll',
  'touchmove',
  'pointermove',
];

const TimeoutWarningModal = (props) => {
  const { policyLockToken } = props;
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  const logger = loggerFunc('TimeoutWarning');

  const navigate = useNavigate();

  const addEventListeners = (listener) => {
    eventTypes.forEach((type) => {
      window.addEventListener(type, listener, false);
    });
  };
  const removeEventListeners = (listener) => {
    if (listener) {
      eventTypes.forEach((type) => {
        window.removeEventListener(type, listener, false);
      });
    }
  };

  const unsetPolicyLockToken = async () => {
    try {
      const response = await ReleaseLockToken(policyLockToken);
      if (response) {
        logger.debug('Policy unlock successful');
      }
    } catch (error) {
      logger.debug(error);
    }
  };

  const forceLogout = () => {
    setWarningModalOpen(false);
    logoutUser(
      () => {
        // Redirect user to session expiry page after logoutUser action complete
        navigate(SESSION_EXPIRED);
      },
    );
  };

  useEffect(() => {
    const createTimeout = () => setTimeout(() => {
      if (policyLockToken) {
        // Release policy lock if token found passed through props
        unsetPolicyLockToken();
      }
      // Show modal after timeout value set
      setWarningModalOpen(true);
    }, process.env.REACT_APP_PAYMENT_SESSION_TIMEOUT);

    // Initialise
    let timeout = !isWarningModalOpen && createTimeout();

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout);
        timeout = createTimeout();
      }
    };

    addEventListeners(listener);

    // Clear
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isWarningModalOpen]);

  return (
    isWarningModalOpen && (
      <>
        <div className="modal myrac Modal__overflow din-font" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body text-center pt-5">
                Your payment session has expired due to inactivity
              </div>
              <div className="text-center pb-4">
                <button
                  className="MyRacForm__button MyRacForm__button--vertical-spacing"
                  data-dismiss="modal"
                  type="button"
                  onClick={forceLogout}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade in Modal__block" />
      </>
    )
  );
};

export default TimeoutWarningModal;
