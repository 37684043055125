import React from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import { connect } from 'react-redux';
import styles from './SMRBanner.module.scss';

const SMRBanner = ({ customer, data, vehicleRegistration, serviceDate }) => {
  /**
   * Checking if service date is above 60 days
   * If date is within 60 days or null or past expiry -> then show this banner
   */
  if (serviceDate && differenceInDays(parseISO(serviceDate), Date.now()) > 60) return null;

  if (!data && !vehicleRegistration) return null;

  const postCode = customer?.custInfo?.Address?.Postcode;

  const url = new URL(data.externalUrl);

  if (!postCode && !url) return null;

  url.searchParams.set('plateNumber', vehicleRegistration);
  url.searchParams.set('postcode', postCode);

  return (
    <a href={url} className={styles.banner}>
      <p>
        <img className={styles.leftIcon} src={data.icon.url} alt={data.icon.title} />
        <b>{data.title}</b>
        <span>{data.subtitle}</span>
      </p>
      {data.secondaryIcon && <img src={data.secondaryIcon.url} alt={data.secondaryIcon.title} />}
    </a>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer,
});

export default connect(mapStateToProps)(SMRBanner);
