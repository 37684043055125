export function setEditPhoneNumber(editPhoneNumber) {
  return {
    type: 'SET_EDITPHONENUMBER',
    payload: editPhoneNumber,
  };
}

export function setEditAddress(editAddress) {
  return {
    type: 'SET_EDITADDRESS',
    payload: editAddress,
  };
}

export function setEditDob(editDob) {
  return {
    type: 'SET_EDITDOB',
    payload: editDob,
  };
}

export function setEditEmail(editEmail) {
  return {
    type: 'SET_EDITEMAIL',
    payload: editEmail,
  };
}

export function setEditAdditionalMembers(editAdditionalMembers) {
  return {
    type: 'SET_EDITADDITIONALMEMBERS',
    payload: editAdditionalMembers,
  };
}

export function setEditVehicles(editVehicles) {
  return {
    type: 'SET_EDITVEHICLES',
    payload: editVehicles,
  };
}
