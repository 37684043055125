import { Navigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loggerFunc } from './utils/logger';
import * as RouteConstants from './constants/routeConstants';
import { getSessionExpirationTimeInMilliseconds, hasValidToken, populateNewSessionToken } from './auth/userManager';
import tokenStatus from './constants/tokenStatus';

const RequireAuth = (props) => {
  const { children, session } = props;
  const [output, setOutput] = useState(null);
  const location = useLocation();

  const logger = loggerFunc('PrivateRoute');

  const sessionTimeout = () => {
    if (session.isLoggedIn && hasValidToken() === tokenStatus.isInvalid) {
      setOutput(<Navigate to={RouteConstants.SESSION_EXPIRED} />);
    }
  };

  const timeout = () => setTimeout(sessionTimeout, getSessionExpirationTimeInMilliseconds());
  useEffect(() => {
    if (session.isLoggedIn) {
      clearTimeout(timeout);
      timeout();
    }
    return clearTimeout(timeout);
  }, [location.pathname, session.jwtToken]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (typeof session.uid !== 'undefined' && typeof window._detector !== 'undefined'
      && process.env.REACT_APP_ENV !== 'local') {
      // eslint-disable-next-line no-underscore-dangle
      window._detector.triggerCustomEvent('gigyaId', session.uid);
    }
  // eslint-disable-next-line no-underscore-dangle
  }, [session.uid, window._detector]);

  const isAuthenticated = () => {
    if (!session.isLoggedIn) {
      // Query string preserved for url redirects (i.e. Tesco)
      const params = window.location.search;
      return setOutput(<Navigate to={RouteConstants.LOGIN + params} />);
    }
    const loggedIn = hasValidToken();
    switch (loggedIn) {
      case tokenStatus.isInvalid:
        logger.debug('logging out...');
        return setOutput(<Navigate to={RouteConstants.SESSION_EXPIRED} />);
      case tokenStatus.requiresNewToken:
        logger.debug('getting new token...');
        populateNewSessionToken().catch((e) => {
          logger.debug('error occurred while renewing token', e);
        });
        break;
      default:
        logger.debug('user is logged in');
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return setOutput(children);
  };

  useEffect(() => {
    isAuthenticated();
  }, [location.pathname]);

  return output;
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(RequireAuth);
