export const accountSettings = {
  SUCCESS_TITLE: {
    mapTo: 'GeneralMessages.AccountSuccessTitle',
  },
  SUCCESS_TEXT: {
    mapTo: 'GeneralMessages.AccountSuccessText',
  },
  INVALID_EMAIL_TEXT: {
    value: 'E-mail address is invalid',
  },
};

export const documentErrors = {
  DOWNLOAD_ERROR: {
    mapTo: 'GeneralMessages.DocumentDownloadError',
  },
};

export const generalMessages = {
  SUCCESS_TITLE: {
    value: 'Update successful',
  },
  CONTINUE_BUTTON_LABEL: {
    value: 'Continue',
  },
  OK_BUTTON_TEXT: {
    value: 'OK',
  },
  CANCEL_BUTTON_TEXT: {
    value: 'Cancel',
  },
  BACK_BUTTON_TEXT: {
    value: 'Back',
  },
  CLOSE_BUTTON_TEXT: {
    value: 'Close',
  },
  SUBMIT_BUTTON_TEXT: {
    value: 'Submit',
  },
  UPDATE_BUTTON_TEXT: {
    value: 'Update',
  },
  UPDATE_FAILURE_TITLE: {
    value: 'Update un-successful',
  },
  UPDATE_FAILURE_ERROR: {
    mapTo: 'GeneralMessages.UpdateFailureError',
  },
  REQUIRED_ERROR: {
    value: 'This field is required',
  },
  MEMBERSHIP_NOT_ACTIVE_ERROR: {
    mapTo: 'GeneralMessages.MembershipNotActiveError',
  },
  EMAIL_IN_USE_ERROR: {
    mapTo: 'GeneralMessages.EmailInUseError',
  },
  FUTURE_START_DATE_ERROR: {
    mapTo: 'GeneralMessages.FutureStartDateError',
  },
  REQUIRED_FIELD: {
    value: 'Please enter a valid {fieldname}.',
  },
  REQUIRED_FIELDS: {
    value: 'Please complete all required fields',
  },
  UPDATING_MESSAGE: {
    mapTo: 'GeneralMessages.UpdatingMessage',
  },
  FIND_BUTTON_TEXT: {
    value: 'Find',
  },
  SAVE_BUTTON_TEXT: {
    value: 'Save',
  },
  BUY_NOW_BUTTON_TEXT: {
    value: 'Buy now',
  },
  UNSUPPORTED_BROWSER_MESSAGE: {
    mapTo: 'GeneralMessages.UnsupportedBrowserMessage',
  },
};

export const generalPreferences = {
  SUCCESS_TITLE: {
    value: 'Thank you',
  },
  SUCCESS_TEXT: {
    value: '<p>Your details have been updated.</p>',
  },
};

export const eFulfilmentMessages = {
  PAPERLESS_HEADER: {
    value: 'Thanks for registering with MyRAC',
  },
  PAPERLESS_TEXT: {
    value: 'To reduce unnecessary paper waste and to protect our planet, you can access your membership documents online at MyRAC. Should you prefer to receive paper copies, please select the option here.',
  },
  PAPERLESS_SUBTEXT: {
    value: 'Please consider the environment before requesting printed documents',
  },
};

export const eFulfilmentErrorMessages = {
  ERROR_HEADER: {
    value: 'Sorry...',
  },
  ERROR_SUBTEXT: {
    value: 'We were unable to update your preferences. Please go to ‘Account settings’ and try again.',
  },
};

export const loginMessages = {
  GENERAL_ERROR: {
    mapTo: 'LoginMessages.LoginGeneralError',
  },
};

export const more4MoreErrors = {
  UPDATE_ERROR: {
    mapTo: 'GeneralMessages.More4MoreUpdateError',
  },
};

export const vehicleFormErrors = {
  REG_SEARCH_ERROR: {
    value: 'Could not find vehicle',
  },
  INVALID_REG: {
    value: 'Invalid vehicle registration',
  },
  EMPTY_REG: {
    value: 'Please enter a valid vehicle registration',
  },
  SAME_REG: {
    value: 'Vehicle already covered on your policy',
  },
};

export const passwordReset = {
  INCORRECT_CURRENT_PASSWORD_MESSAGE: {
    value: 'Incorrect password',
  },
  PASSWORD_UPDATE_MESSAGE: {
    value: 'Your password has been updated.',
  },
  PASSWORD_MISMATCH_MESSAGE: {
    value: 'Passwords do not match.',
  },
};

export const personalDetails = {
  BACK_TO_MY_RAC_BUTTON_TEXT: {
    value: 'Back to MyRAC',
  },
  GENERAL_FAILURE_TEXT: {
    value: '<p>Sorry, your details haven\'t been updated</p>',
  },
  GENERAL_SUCCESS_TEXT: {
    value: '<p>Your details have been updated</p>',
  },
  ADDRESS_CHANGE_BACK_TITLE: {
    value: 'Are you sure you want to go back?',
  },
  ADDRESS_CHANGE_BACK_TEXT: {
    value: 'If so, your updated information will be cancelled',
  },
  SUCCESS_TITLE: {
    value: 'Thank you',
  },
  EMAIL_ADDRESS_UPDATE_SUCCESS_TEXT: {
    mapTo: 'GeneralMessages.AccountSuccessText',
  },
  EMAIL_ADDRESS_UPDATE_FAILURE_TEXT: {
    mapTo: 'GeneralMessages.EmailInUseError',
  },
  FIRST_NAME_INVALID_TEXT: {
    value: 'Please enter a valid first name.',
  },
  LAST_NAME_INVALID_TEXT: {
    value: 'Please enter a valid surname.',
  },
  INVALID_PHONE_NUMBER_TEXT: {
    value: 'Invalid Number',
  },
};

export const registrationErrors = {
  DETAILS_MISMATCH: {
    mapTo: 'LoginMessages.RegistrationDetailsMismatch',
  },
  EMAIL_AND_DETAILS_MISMATCH: {
    mapTo: 'LoginMessages.RegistrationEmailMismatch',
  },
  NOT_REGISTERED: {
    mapTo: 'LoginMessages.EmailNotRegistered',
  },
  INVALID_MEMBERSHIP_NUMBER: {
    mapTo: 'LoginMessages.RegistrationInvalidMembershipNumber',
  },
  INCORRECT_DETAILS: {
    mapTo: 'LoginMessages.RegistrationIncorrectDetails',
  },
  INVALID_POLICY: {
    mapTo: 'LoginMessages.RegistrationInvalidPolicy',
  },
  CODE_EXCLUDED: {
    mapTo: 'LoginMessages.RegistrationCodeExcluded',
  },
  NOT_ELIGIBLE_FOR_REGISTRATION: {
    mapTo: 'LoginMessages.RegistrationNotEligible',
  },
  REGISTRATION_DEFAULT_MESSAGE: {
    mapTo: 'LoginMessages.RegistrationDefaultMessage',
  },
};

export const renewalNotices = {
  CANCELLED_CONFIRMED_PANEL_MESSAGE: {
    mapTo: 'RenewalMessages.CancelledConfirmedRenewalNotice',
  },
  CANCELLED_CONFIRMED_MODEL_TEXT: {
    mapTo: 'RenewalMessages.CancelledConfirmedMessage',
  },
  CANCELLED_CONFIRMED_MODAL_TITLE: {
    mapTo: 'RenewalMessages.CancelledConfirmedRenewalTitle',
  },
  CANCELLED_PENDING_PANEL_MESSAGE: {
    mapTo: 'RenewalMessages.CancelledPendingRenewalNotice',
  },
  CANCELLED_PENDING_MODAL_TEXT: {
    mapTo: 'RenewalMessages.CancelledPendingMessage',
  },
  CANCELLED_PENDING_MODAL_TITLE: {
    mapTo: 'RenewalMessages.CancelledPendingRenewalTitle',
  },
  CONFIRMED_PANEL_MESSAGE: {
    mapTo: 'RenewalMessages.ConfirmedRenewalNotice',
  },
  CONFIRMED_MODAL_TEXT: {
    mapTo: 'RenewalMessages.ConfirmedMessage',
  },
  CONFIRMED_MODAL_TITLE: {
    mapTo: 'RenewalMessages.ConfirmedRenewalTitle',
  },
  CONTINUOUS_PANEL_MESSAGE: {
    mapTo: 'RenewalMessages.ContinuousRenewalNotice',
  },
  CONTINUOUS_MODAL_TEXT: {
    mapTo: 'RenewalMessages.ContinuousMessage',
  },
  CONTINUOUS_MODAL_TITLE: {
    mapTo: 'RenewalMessages.ContinuousRenewalTitle',
  },
  DAYS_OF_COVER_LEFT_PANEL_MESSAGE: {
    mapTo: 'RenewalMessages.DaysOfCoverLeftRenewalNotice',
  },
  DAYS_OF_COVER_LEFT_MODAL_TEXT: {
    mapTo: 'RenewalMessages.DaysOfCoverLeftMessage',
  },
  DAYS_OF_COVER_LEFT_MODAL_TITLE: {
    mapTo: 'RenewalMessages.DaysOfCoverLeftRenewalTitle',
  },
  LAPSED_PANEL_MESSAGE: {
    mapTo: 'RenewalMessages.LapsedRenewalNotice',
  },
  LAPSED_MODAL_TEXT: {
    mapTo: 'RenewalMessages.LapsedMessage',
  },
  LASPED_MODAL_TITLE: {
    mapTo: 'RenewalMessages.LapsedRenewalTitle',
  },
  SINGLE_ANNUAL_PANEL_MESSAGE: {
    mapTo: 'RenewalMessages.SingleAnnualRenewalNotice',
  },
  SINGLE_ANNUAL_MODAL_TEXT: {
    mapTo: 'RenewalMessages.SingleAnnualMessage',
  },
  SINGLE_ANNUAL_MODAL_TITLE: {
    mapTo: 'RenewalMessages.SingleAnnualRenewalTitle',
  },
  HELP_LINK: {
    mapTo: 'GeneralMessages.CancelledMembershipHelpLinkText',
  },
  AUTO_RENEW_OPT_OUT_SUBMIT_TITLE: {
    mapTo: 'RenewalMessages.AutoRenewOptOutSubmitTitle',
  },
  AUTO_RENEW_OPT_OUT_SUBMIT_DESCRIPTION: {
    mapTo: 'RenewalMessages.AutoRenewOptOutSubmitDescription',
  },
  AUTO_RENEW_OPT_IN_SUBMIT_DESCRIPTION: {
    mapTo: 'RenewalMessages.AutoRenewOptInSubmitDescription',
  },
  AUTO_RENEW_OPT_OUT_SUCCESS_TITLE: {
    mapTo: 'RenewalMessages.AutoRenewOptOutSuccessTitle',
  },
  AUTO_RENEW_OPT_OUT_SUCCESS_DESCRIPTION: {
    mapTo: 'RenewalMessages.AutoRenewOptOutSuccessDescription',
  },
  AUTO_RENEW_OPT_OUT_ERROR_TITLE: {
    mapTo: 'RenewalMessages.AutoRenewOptOutErrorTitle',
  },
  AUTO_RENEW_OPT_OUT_ERROR_DESCRIPTION: {
    mapTo: 'RenewalMessages.AutoRenewOptOutErrorDescription',
  },
  AUTO_RENEW_UNABLE_TO_CHANGE_TITLE: {
    mapTo: 'RenewalMessages.AutoRenewUnableToChangeTitle',
  },
  AUTO_RENEW_UNABLE_TO_CHANGE_DESCRIPTION: {
    mapTo: 'RenewalMessages.AutoRenewUnableToChangeDescription',
  },
  AUTO_RENEW_RENEWAL_IN_PROGRESS_TITLE: {
    mapTo: 'RenewalMessages.AutoRenewRenewalInProgressTitle',
  },
  AUTO_RENEW_RENEWAL_IN_PROGRESS_DESCRIPTION: {
    mapTo: 'RenewalMessages.AutoRenewRenewalInProgressDescription',
  },
};

export const tags = {};

export const tescoErrors = {
  NOT_DUE_FOR_RENEWAL_ERROR: {
    mapTo: 'TescoErrors.NotDueForRenewal',
  },
  REDIRECT_MESSAGE: {
    mapTo: 'TescoErrors.RedirectingMessage',
  },
  REDIRECT_TITLE: {
    mapTo: 'TescoErrors.RedirectingTitle',
  },
  PAST_RENEWAL_DATA_ERROR: {
    mapTo: 'TescoErrors.PastRenewalDateError',
  },
};

export const vehicleErrors = {
  VEHICLE_ERROR_TITLE: {
    mapTo: 'VehicleMessages.VehicleErrorTitle',
  },
  VEHICLE_ERROR_MESSAGE: {
    mapTo: 'VehicleMessages.VehicleErrorMessage',
  },
  VEHICLE_INVALID_FORMAT: {
    mapTo: 'VehicleMessages.InvalidFormat',
  },
  VEHICLE_SAME_VEHICLE_ERROR: {
    mapTo: 'VehicleMessages.SameVehicleError',
  },
};
